<template>
  <div class="wrapper" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div class="card-form">
          <div class="card-input">
            <label for="email" class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              placeholder="Email"
              v-model="member.email"
            />
          </div>
        </div>
        <button
          class="card-form__button gradient-button"
          @click="this.resetPassword"
        >
          Send Reset Password Email
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      member: {
        email: "",
        currentURL: process.env.VUE_APP_CLIENT_URL,
      },
    };
  },
  methods: {
    async resetPassword() {
      let url = process.env.VUE_APP_DB_URL + "membership/memberForgotPassword";
      if (this.validateInfo()) {
        axios
          .post(url, this.member)
          .then((response) => {
            let success = response.data.success;
            if (success) {
              swal(
                "Success",
                "Email sent successfully. Please check your inbox",
                "success"
              );
              this.$router.push("/memberResetPassword");
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message);
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },
    validateInfo() {
      if (this.member.email == "") {
        swal("Error", "Please enter email", "error");
        return false;
      }
      return true;
    },
  },
};
</script>
