<template>
  <div
    :class="{
      login: true,
      loginFootball: getBackgroundClass === 'loginFootball',
      loginBaseball: getBackgroundClass === 'loginBaseball',
      loginBasketball: getBackgroundClass === 'loginBasketball',
      loginSoccer: getBackgroundClass === 'loginSoccer',
    }"
  >
    <membership-login-component />
    <div class="AuthorizeNetSeal"></div>
  </div>
</template>

<script>
import membershipLoginComponent from "../components/MemberLoginComponent.vue";
export default {
  components: {
    membershipLoginComponent,
  },
  computed: {
    getBackgroundClass() {
      const classes = [
        "loginFootball",
        "loginBaseball",
        "loginBasketball",
        "loginSoccer",
      ];
      return classes[Math.floor(Math.random() * classes.length)];
    },
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("type", "text/javascript");
    recaptchaScript.setAttribute("language", "javascript");
    recaptchaScript.text =
      'var ANS_customer_id="e6adf9e6-2043-4e61-8ca3-d812f4ebf49e"';
    let secondScript = document.createElement("script");
    secondScript.setAttribute("type", "text/javascript");
    secondScript.setAttribute("language", "javascript");
    secondScript.setAttribute(
      "src",
      "https://verify.authorize.net/anetseal/seal.js"
    );

    document
      .getElementsByClassName("AuthorizeNetSeal")[0]
      .appendChild(recaptchaScript);
    document
      .getElementsByClassName("AuthorizeNetSeal")[0]
      .appendChild(secondScript);
  },
};
</script>
