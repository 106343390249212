<template>
  <div class="container">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="campLogo" class="card-img-top" id="logoTop" />
        <h1 class="text-center" style="margin-top: 50px">Team Info</h1>

        <div class="row">
          <div class="card-input col">
            <label for="cardName" class="card-input__label">Team Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              data-ref="cardName"
              autocomplete="off"
              v-model="team.teamName"
              required
            />
          </div>
        </div>

        <div class="row">
          <div class="card-input col">
            <label for="cardName" class="card-input__label"
              >Coach First Name</label
            >
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              data-ref="cardName"
              autocomplete="off"
              v-model="team.teamCoachFirstName"
              required
            />
          </div>

          <div class="card-input col">
            <label for="cardName" class="card-input__label"
              >Coach Last Name</label
            >
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              data-ref="cardName"
              autocomplete="off"
              v-model="team.teamCoachLastName"
              required
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Phone Number</label>
            <input
              type="number"
              id="phoneNumber"
              class="card-input__input"
              v-model="team.teamCoachPhoneNumber"
              required
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              v-model="team.teamCoachEmail"
              required
            />
          </div>

          <!--Start of Custom Forms-->
          <li
            style="list-style-type: none"
            v-for="index in customFields.length"
            :key="index"
          >
            <CustomField
              :field="customFields[index - 1]"
              @changed="handleChange(index, $event)"
            ></CustomField>
          </li>

          <!--End of Custom Forms-->
        </div>
        <button class="card-form__button" @click="proceed">
          {{ this.buttonName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import CustomField from "../../components/CustomField.vue";

export default {
  components: { CustomField },
  props: {
    customFields: Array,
    campLogo: String,
    buttonName: String,
  },
  data() {
    return {
      team: {
        teamName: "",
        teamCoachFirstName: "",
        teamCoachLastName: "",
        teamCoachPhoneNumber: "",
        teamCoachEmail: "",
        customFieldValues: [{}],
      },
    };
  },
  methods: {
    proceed() {
      console.log("proceeding");
      this.$emit("proceed", this.team);
    },
    handleChange(index, value) {
      this.team.customFieldValues[index - 1] = this.customFields[index - 1];
      this.team.customFieldValues[index - 1].fieldValue = value;
      delete this.team.customFieldValues[index - 1]._id;
    },
  },
  computed: {},
  created() {},
};
</script>
<style scoped>
.center {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
  top: calc(50% - 65px);
}
</style>
