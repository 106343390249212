<template>
  <div
    class="wrapper container justify-content-center"
    style="max-width: 90%; padding: 3% 0px"
  >
    <div class="row justify-content-center">
      <div class="col-md-7" v-if="getCheckoutVisibility == 1">
        <Checkout
          :billingInfo="this.billingInfo"
          :isMobile="this.isMobile"
          :payPeriod="this.getPeriod"
          :type="this.subscription.subscriptionName"
          :subscriptionName="this.subscription.subscriptionName"
          :period="this.getPeriod"
          :yearly="this.payYearly"
          :price="this.getPrice"
          :external="true"
        ></Checkout>
      </div>
      <div class="col-md-5" v-if="getCheckoutVisibility == 1">
        <OrderSummary
          :subscriptionName="this.subscription.subscriptionName"
          :period="this.getPeriod"
          :price="this.getPrice"
          :monthlyPrice="this.getMonthlyPrice"
          :isMobile="this.isMobile"
          :billingInfo="this.billingInfo"
          :isExternal="true"
          :trialApplied="false"
          :isDigitalAI="this.isDigitalAI"
          @proceed="submitPayment()"
          @changeBillingPeriod="changeBillingPeriod()"
        ></OrderSummary>
      </div>
      <SubscriptionSuccess
        v-if="getCheckoutVisibility == 2"
        :link="this.getLink"
      ></SubscriptionSuccess>
    </div>
  </div>
</template>
<script>
import Checkout from "../../components/Subscriptions/Checkout.vue";
import OrderSummary from "../../components/Subscriptions/OrderSummary.vue";
import SubscriptionSuccess from "../../components/Subscriptions/SubscriptionSuccess.vue";
import axios from "axios";
import swal from "sweetalert";

export default {
  components: {
    Checkout,
    OrderSummary,
    // CollectCoachDetails,
    SubscriptionSuccess,
  },
  data() {
    return {
      user: {},
      subscription: {},
      showCheckout: 1,
      successLink: "",
      billingInfo: {
        cardFirstName: "",
        cardLastName: "",
        firstName: "",
        lastName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        phoneNumber: "",
        email: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
      },
      showOrderSummary: false,
      subscriptionName: "Hall of Fame",
      payYearly: true,
      coachFirstName: "",
      coachLastName: "",
      coachEmail: "",
      coachPhoneNumber: "",
      coachAddress: "",
      allStarID: "659cdc3d7d42169b72bf341e",
    };
  },
  methods: {
    getSubscription() {
      let url =
        process.env.VUE_APP_DB_URL +
        "subscription/getSubscription?id=" +
        this.allStarID;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.subscription = response.data.subscription;
          console.log(response.data.subscription);
        });
    },
    changeBillingPeriod() {
      this.payYearly = !this.payYearly;
      this.$route.params.payYearly = this.payYearly;
    },
    submitPayment() {
      swal({
        title: "Wait for confirmation",
        text: "Please wait for confirmation",
        icon: "warning",
        buttons: false,
      });
      let oldSubscriptionID = null;
      this.billingInfo.firstName = this.billingInfo.cardFirstName;
      this.billingInfo.lastName = this.billingInfo.cardLastName;
      let url = process.env.VUE_APP_DB_URL + "user/subscriptionPurchase";
      axios
        .post(
          url,
          {
            period: this.getPeriod,
            price: this.getPrice,
            billingInfo: this.billingInfo,
            externalBoolean: true,
            id: this.allStarID,
            aiDemo: true,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          swal("Success", "Purchase Successful", "success");
          console.log(response.data);
          console.log("refresh working");
          if (window.dataLayer) {
            window.dataLayer.push({
              event: "signUpComplete",
            });
          }
          this.showCheckout = 2;
          this.successLink =
            process.env.VUE_APP_CLIENT_URL + "otl/" + response.data.otl;
          setTimeout(function () {
            window.open(
              process.env.VUE_APP_CLIENT_URL + "otl/" + response.data.otl,
              "_blank"
            );
          }, 500);
        })
        .catch(() => {
          swal(
            "Purchase Failed",
            "Please check your billing information and try again",
            "error"
          );
        });
      if (oldSubscriptionID) {
        let url =
          process.env.VUE_APP_DB_URL + "subscription/cancelSubscription";
        axios.post(
          url,
          {
            subscriptionID: oldSubscriptionID,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        );
      }
    },
    setContactInfo() {
      let contactID = this.$route.query.contact;
      //axios get call to get web traffic contact from contactID
      let url =
        process.env.VUE_APP_DB_URL +
        "user/getWebTrafficContact?contactID=" +
        contactID;
      axios.get(url).then((response) => {
        this.billingInfo.cardFirstName = response.data.contact.firstName;
        this.billingInfo.cardLastName = response.data.contact.lastName;
        this.billingInfo.phoneNumber = response.data.contact.phoneNumber;
        this.billingInfo.email = response.data.contact.email;
        this.billingInfo.address = response.data.contact.address;
      });
    },
  },
  created() {
    this.getSubscription();
    if (this.$route.params.payYearly === "true") {
      this.payYearly = !!this.$route.params.payYearly;
    } else {
      this.payYearly = false;
    }
    this.setContactInfo();
  },
  computed: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    getPeriod() {
      if (this.payYearly) {
        return "Year";
      } else {
        return "Month";
      }
    },
    getPrice() {
      let price;
      if (this.payYearly) {
        price = this.subscription.yearlyPrice;
      } else {
        price = this.subscription.monthlyPrice;
      }
      return price;
    },
    getMonthlyPrice() {
      return this.subscription.monthlyPrice;
    },
    getCheckoutVisibility() {
      return this.showCheckout;
    },
    getLink() {
      return this.successLink;
    },

    getTrialApplied() {
      return (
        this.subscription.subscriptionTier < 4 &&
        this.subscription.subscriptionTier > 1
      );
    },
    isDigitalAI() {
      return this.$route.query.contact != null;
    },
  },
};
</script>
<style scoped>
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  z-index: 30;
}

.navigation .container {
  max-width: unset;
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.sa-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-logo {
  border-radius: 20%;
}

.sa-header .sa-logo-wrapper {
  width: 90px;
  display: flex;
}

.sa-nav-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 54px;
}

.sa-nav-wrapper .sa-menu {
  height: 100%;
  display: flex;
  gap: 40px;
}

.sa-menu .sa-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover,
.sa-menu .sa-menu-item.active {
  color: #363166;
}

.sa-menu .sa-menu-item::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #363166;
  position: absolute;
  bottom: 0;
  left: -100%;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover::after,
.sa-menu .sa-menu-item.active::after {
  left: 0;
}

.sa-menu-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-image {
  width: 100%;
  height: 100%;
  display: block;
}

.sa-image-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sa-image-cover.fit-pos-top {
  object-position: top;
}

.sa-image-contained {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
</style>
