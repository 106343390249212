var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal fade",attrs:{"id":"websiteSettings","tabindex":"-1","aria-labelledby":"websiteSettingsLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title text-center",attrs:{"id":"websiteSettingsLabel"}},[_vm._v(" "+_vm._s(this.title)+" ")])]),_c('div',{staticClass:"modal-body"},[(this.copied)?_c('div',{},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"center justify-content-center text-center"},[_c('table',{staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_vm._m(1),_c('tr',[_c('td',[_c('i',{staticClass:"fa-solid fa-copy fa-xl",staticStyle:{"cursor":"pointer","color":"#363166"},on:{"click":function($event){return _vm.copyLink()}}})]),_c('td',[_c('div',{style:({
                    maxWidth: _vm.isMobile ? '300px' : '',
                    overflow: 'auto',
                    fontSize: _vm.isMobile ? '' : '19px',
                    fontWeight: 'bold',
                    color: '#363166',
                  })},[_vm._v(" "+_vm._s(this.url)+" ")])])])])]),_c('br'),_c('div',{staticClass:"row",style:({ marginLeft: '21px' })},[_vm._m(2),_vm._m(3)]),_c('br')]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button","id":"websiteSettingsClose","data-bs-dismiss":"modal"}},[_vm._v(" Close ")]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.editWebsite()}}},[_vm._v(" Edit Website ")]),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteWebsite()}}},[_vm._v(" Delete Website ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('h5',{staticClass:"text-center"},[_vm._v(" Copied "),_c('i',{staticClass:"fa-solid fa-circle-check"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',[_c('th'),_c('th')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-6 col-sm-6 justify-content-center"},[_c('label',[_c('span',{staticClass:"text-center"},[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Website Active")])]),_c('br'),_c('label',[_c('span',{staticClass:"text-center"},[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Monitoring Active")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-6 col-sm-6 justify-content-center"},[_c('label',[_c('span',{staticClass:"text-center"},[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Payments Active")])]),_c('br'),_c('label',[_c('span',{staticClass:"text-center"},[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Links Active")])])])
}]

export { render, staticRenderFns }