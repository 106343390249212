<template>
  <div>
    <ClientLayout :user="user"></ClientLayout>
    <section style="margin-top: 7%">
      <div class="container mt-5">
        <h1 style="text-align: center">Analytics</h1>
        <div
          class="d-flex row justify-content-center"
          v-bind:style="{
            marginLeft: isMobile() ? '21px' : '',
          }"
        >
          <div class="col-md-3 col-sm-6">
            <multiselect
              v-model="eventOptionValues"
              placeholder="Event"
              label="name"
              track-by="code"
              :options="eventOptions"
              :multiple="false"
              @input="updateChart()"
            ></multiselect>
          </div>
          <div class="col-md-3 col-sm-6">
            <multiselect
              v-model="metricOptionValues"
              placeholder="Metric"
              label="name"
              track-by="code"
              :options="metricOptions"
              :multiple="false"
              @input="updateChart()"
            ></multiselect>
          </div>
          <div class="col-md-3 col-sm-6">
            <multiselect
              v-model="graphOptionValues"
              placeholder="Graph Type"
              label="name"
              track-by="code"
              :options="graphOptions"
              :multiple="false"
            ></multiselect>
          </div>
        </div>
        <div class="chart-section" v-if="getGraphType != 'none'">
          <LineGraph
            v-if="getGraphType == 'line'"
            :chartData="this.getChartData()"
          />
          <BarGraph
            v-if="getGraphType == 'bar'"
            :chartData="this.getChartData()"
          />
          <RadarGraph
            v-if="getGraphType == 'radar'"
            :chartData="this.getChartData()"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ClientLayout from "../components/ClientLayout.vue";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";
import swal from "sweetalert";
import Multiselect from "vue-multiselect";
import LineGraph from "../components/Charts/LineGraph.vue";
import BarGraph from "../components/Charts/BarGraph.vue";
import RadarGraph from "../components/Charts/RadarGraph.vue";

export default {
  components: { ClientLayout, LineGraph, BarGraph, RadarGraph, Multiselect },
  data() {
    return {
      user: {},
      unformattedData: {},
      chartData: {
        labels: ["Eating", "Drinking"],
        datasets: [
          {
            label: "My First dataset",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgba(179,181,198,1)",
            pointBackgroundColor: "rgba(179,181,198,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: [65, 59, 90, 81, 56, 55, 40],
          },
          {
            label: "My Second dataset",
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: [28, 48, 40, 19, 96, 27, 100],
          },
        ],
      },
      eventOptionValues: [],
      metricOptionValues: [],
      graphOptionValues: [],
      eventOptions: [
        { name: "Camps", code: "camp" },
        { name: "Leagues/Tournaments", code: "teamCamp" },
        //{ name: "Memberships", code: "membership" },
        //{ name: "One-on-One", code: "trainingSession" },
      ],
      metricOptions: [
        { name: "Revenue", code: "revenue" },
        { name: "Registrations", code: "registrations" },
        //{ name: "One-on-One", code: "trainingSession" },
      ],
      graphOptions: [
        { name: "Line Graph", code: "line" },
        { name: "Bar Graph", code: "bar" },
        { name: "Radar Graph", code: "radar" },
      ],
    };
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    async getInitialData() {
      let url =
        process.env.VUE_APP_DB_URL + "user/getAnalytics?id=" + this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.unformattedData = response.data;
          console.log(this.unformattedData);
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    updateChart() {
      console.log("updating");
      this.getDataSet();
    },
    getDataSet() {
      console.log("dataSet");
      this.chartData.datasets = [];
      if (this.metricOptionValues.length != 0) {
        if (this.metricOptionValues.code == "registrations") {
          console.log("here");
          let string = "Total";
          if (this.eventOptionValues.name != "Total") {
            string = this.eventOptionValues.name.substring(
              0,
              this.eventOptionValues.name.length - 1
            );
          }
          this.chartData.datasets.push({
            label: string + " Registrations",
            backgroundColor: "#19d412",
            borderColor: "#19d412",
            pointBackgroundColor: "#19d412",
            pointBorderColor: "#19d412",
            pointHoverBackgroundColor: "#19d412",
            pointHoverBorderColor: "#19d412",
            data: this.getSpecificData(
              this.eventOptionValues.code,
              "totalRegistrations"
            ),
          });
          this.setLabels(this.eventOptionValues.code);
        }
        if (this.metricOptionValues.code == "revenue") {
          console.log("here");
          let string = "Total";
          if (this.eventOptionValues.name != "Total") {
            string = this.eventOptionValues.name.substring(
              0,
              this.eventOptionValues.name.length - 1
            );
          }
          this.chartData.datasets.push({
            label: string + " Revenue",
            backgroundColor: "#19d412",
            borderColor: "#19d412",
            pointBackgroundColor: "#19d412",
            pointBorderColor: "#19d412",
            pointHoverBackgroundColor: "#19d412",
            pointHoverBorderColor: "#19d412",
            data: this.getSpecificData(
              this.eventOptionValues.code,
              "totalRevenue"
            ),
          });
          this.setLabels(this.eventOptionValues.code);
        }
      }
    },
    getSpecificData(eventType, dataType) {
      console.log("getting");
      console.log(eventType);
      if (eventType == "camp") {
        console.log(this.unformattedData.camps.map((obj) => obj[dataType]));
        return this.unformattedData.camps.map((obj) => obj[dataType]);
      }
      if (eventType == "teamCamp") {
        console.log(this.unformattedData.teamCamps.map((obj) => obj[dataType]));
        return this.unformattedData.teamCamps.map((obj) => obj[dataType]);
      }
    },
    setLabels(eventType) {
      this.chartData.labels = [];
      if (eventType == "camp") {
        this.chartData.labels = this.unformattedData.camps.map((obj) => {
          const month = new Date(obj.year, obj.month - 1, 1).toLocaleString(
            "default",
            { month: "long" }
          );
          const year = obj.year.toString();
          return `${month} ${year}`;
        });
      }
      if (eventType == "teamCamp") {
        this.chartData.labels = this.unformattedData.teamCamps.map((obj) => {
          const month = new Date(obj.year, obj.month - 1, 1).toLocaleString(
            "default",
            { month: "long" }
          );
          const year = obj.year.toString();
          return `${month} ${year}`;
        });
      }
    },
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    getChartData() {
      return this.chartData;
    },
  },
  computed: {
    getGraphType() {
      if (this.graphOptionValues.length == 0) {
        return "none";
      }
      return this.graphOptionValues.code;
    },
  },
  created() {
    this.getUserDetails();
    this.getInitialData();
  },
};
</script>

<style>
.chart-section {
  margin-top: 40px;
}
</style>
