<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          :src="this.campLogo"
          class="card-img-top rounded-corners"
          id="logoTop"
        />

        <div class="card-body" style="margin-top: 10px">
          <h5 class="card-title" style="margin-bottom: 10px">
            {{ this.campName }}
          </h5>
          <br />
          <p
            class="card-text"
            style="white-space: pre-wrap; margin-bottom: 5px"
          >
            {{ this.campDescription }}
          </p>
        </div>
        <div>
          <hr />

          <div><strong>Location:</strong> {{ this.campLocation }}</div>
          <hr />
          <div><strong>Price:</strong> ${{ getFormattedPrice }}</div>
          <hr />
          <div>
            <div v-for="(day, index) in this.campDates" :key="index">
              <div>
                <b>Day {{ index + 1 }}</b>
                <ul class="list-group">
                  <li class="list-group-item">
                    Start Time: {{ dayToString(day.start) }}
                  </li>
                  <li class="list-group-item">
                    End Time: {{ dayToString(day.end) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="list-group-item mt-3"
            v-if="this.campRegistrationDeadline"
          >
            Registration Deadline:
            {{ deadlineToString(this.campRegistrationDeadline) }}PM
          </div>
        </div>
        <button
          class="card-form__button rounded-pill"
          @click="proceed"
          :style="{ backgroundColor: primaryColor, color: 'white' }"
        >
          {{ this.buttonName }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import numeral from "numeral";
import moment from "moment";
export default {
  components: {},
  props: {
    campLogo: String,
    campName: String,
    campDescription: String,
    campLocation: String,
    campPrice: Number,
    campDates: Array,
    campRegistrationDeadline: String,
    campWaiver: String,
    buttonName: String,
    priceSuperlative: String,
    hidePlatformFees: Boolean,
    totalUpchargePercentage: Number,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
  },
  data() {
    return {};
  },
  methods: {
    dayToString(date) {
      let temp = new Date(date);
      return temp.toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    deadlineToString(date) {
      return moment(date).format("MM/DD/YYYY 11:59");
    },
    async proceed() {
      swal({
        title: "Agree and Accept Liability",
        text: this.campWaiver,
        icon: "warning",
        buttons: ["Cancel registration", "I understand and accept liability"],
        dangerMode: true,
      }).then((isConfirm) => {
        if (isConfirm) {
          this.$emit("proceed");
        } else {
          swal("Cancelled", "Registration cancelled.", "error");
        }
      });
    },
  },
  created() {},
  computed: {
    getTotal() {
      if (this.hidePlatformFees) {
        if (this.campPrice === 0) {
          return (0).toFixed(2);
        } else if (this.campPrice < 30) {
          let total = this.campPrice + 0.99;
          return Number(total).toFixed(2);
        } else {
          let total =
            this.campPrice * (1 + (this.totalUpchargePercentage - 3) / 100) -
            0.01;
          return Number(total).toFixed(2);
        }
      } else {
        let total = this.campPrice;
        return Number(total).toFixed(2);
      }
    },

    getFormattedPrice() {
      return numeral(this.getTotal).format("0,0.00");
    },
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
