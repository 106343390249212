<template>
  <div class="bodyNew">
    <figure>
      <div class="divNew"></div>
      <div class="divNew"></div>
      <div class="divNew"></div>
      <h1 class="text-center" style="color: white; margin-top: 400px">
        Generating the experience...
      </h1>
      <br />
    </figure>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    SecondaryColor: String,
  },

  created() {
    let divs = document.getElementsByClassName(".divNew");
    for (let div of divs) {
      div.style.setProperty("--spinner-color", this.primaryColor);
    }
  },

  mounted() {
    let divs = document.getElementsByClassName("divNew");
    console.log(divs);
    for (let div of divs) {
      div.style.setProperty("--spinner-color", this.primaryColor);
    }
  },

  computed: {},
};
</script>

<style lang="scss">
$X: 70deg;
$color: #1ec0f5;
$speed: 1s;

html {
  height: 100%;
}
.bodyNew {
  background: linear-gradient(#232f44, #000);
}

figure {
  width: 400px;
  height: 400px;
  transform: translate(-50%, -65%);
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
}
.divNew {
  width: 100%;
  height: 100%;
  position: absolute;
}
.divNew:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-top: 25px solid var(--spinner-color);
  border-right: 5px solid var(--spinner-color);
  border-bottom: 10px solid transparent;
  border-left: 0px solid transparent;
  border-radius: 50%;
}

.divNew:nth-child(1):after {
  animation: ring $speed linear infinite;
  transform: rotateX($X) rotateY(0deg) rotate(0deg);
}
.divNew:nth-child(2):after {
  animation: ring2 $speed linear infinite;
  animation-delay: calc($speed / -3);
  transform: rotateX($X) rotateY(60deg) rotate(0deg);
}
.divNew:nth-child(3):after {
  animation: ring3 $speed linear infinite;
  animation-delay: calc($speed / -3);
  transform: rotateX($X) rotateY(-60deg) rotate(0deg);
}

@keyframes ring {
  100% {
    transform: rotateX($X) rotateY(0deg) rotate(360deg);
  }
}
@keyframes ring2 {
  100% {
    transform: rotateX($X) rotateY(60deg) rotate(360deg);
  }
}
@keyframes ring3 {
  100% {
    transform: rotateX($X) rotateY(-60deg) rotate(360deg);
  }
}
</style>
