<template>
  <div style="margin-top: 20px; max-width: 90%">
    <div
      :style="{ display: 'flex', alignItems: 'center', marginBottom: '20px' }"
    >
      <img
        :src="getImage"
        :style="{
          borderRadius: '50%',
          width: '150px',
          height: '150px',
          objectFit: 'cover',
          marginRight: '20px',
        }"
      />
      <div :style="{ display: 'flex', flexDirection: 'column', flexGrow: 1 }">
        <h1 :style="{ margin: '0', color: '#343a40', textAlign: 'right' }">
          {{ recruitProfile.firstName }} {{ recruitProfile.lastName }}
        </h1>
      </div>
    </div>

    <div class="info-section">
      <h3
        :style="{
          borderBottom: '2px solid #343a40',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#343a40',
        }"
      >
        Personal Information
      </h3>
      <div class="container">
        <div class="row mb-0" v-if="recruitProfile.school">
          <div class="col">
            <p><strong>School:</strong> {{ recruitProfile.school }}</p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.state">
          <div class="col">
            <p><strong>State:</strong> {{ recruitProfile.state }}</p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.class">
          <div class="col">
            <p><strong>Graduation Class:</strong> {{ recruitProfile.class }}</p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.profileLinks.twitter">
          <div class="col">
            <p>
              <strong>Twitter Profile:</strong>
              <a :href="recruitProfile.profileLinks.twitter" target="_blank">
                {{ recruitProfile.profileLinks.twitter }}
              </a>
            </p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.profileLinks.hudl">
          <div class="col">
            <p>
              <strong>Hudl Profile:</strong>
              <a :href="recruitProfile.profileLinks.hudl" target="_blank">
                {{ recruitProfile.profileLinks.hudl }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-section" :style="{ marginTop: '30px' }">
      <h3
        :style="{
          borderBottom: '2px solid #343a40',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#343a40',
        }"
      >
        Athletic Information
      </h3>
      <div class="container">
        <div class="row mb-0" v-if="recruitProfile.athleticInfo.height">
          <div class="col">
            <p>
              <strong>Height:</strong> {{ recruitProfile.athleticInfo.height }}
            </p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.athleticInfo.weight">
          <div class="col">
            <p>
              <strong>Weight:</strong> {{ recruitProfile.athleticInfo.weight }}
            </p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.athleticInfo.primarySport">
          <div class="col">
            <p>
              <strong>Primary Sport:</strong>
              {{ recruitProfile.athleticInfo.primarySport }}
            </p>
          </div>
        </div>
        <div
          class="row mb-0"
          v-for="metric in filteredAdditionalMetrics"
          :key="metric.title"
        >
          <div class="col">
            <p>
              <strong>{{ metric.title }}:</strong> {{ metric.value }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="info-section" :style="{ marginTop: '30px' }">
      <h3
        :style="{
          borderBottom: '2px solid #343a40',
          paddingBottom: '10px',
          marginBottom: '20px',
          color: '#343a40',
        }"
      >
        Academic Information
      </h3>
      <div class="container">
        <div class="row mb-0" v-if="recruitProfile.academicInfo.gpa">
          <div class="col">
            <p><strong>GPA:</strong> {{ recruitProfile.academicInfo.gpa }}</p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.academicInfo.act">
          <div class="col">
            <p><strong>ACT:</strong> {{ recruitProfile.academicInfo.act }}</p>
          </div>
        </div>
        <div class="row mb-0" v-if="recruitProfile.academicInfo.sat">
          <div class="col">
            <p><strong>SAT:</strong> {{ recruitProfile.academicInfo.sat }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      user: {},
      recruitProfile: {
        image: "",
        firstName: "",
        lastName: "",
        contactPhone: "",
        contactEmail: "",
        school: "",
        class: "",
        state: "",

        athleticInfo: {
          height: "",
          weight: "",
          primarySport: "",
          additionalMetrics: [],
        },
        academicInfo: {
          gpa: "",
          act: "",
          sat: "",
        },
        profileLinks: {
          facebook: "",
          instagram: "",
          twitter: "",
          hudl: "",
        },
      },
    };
  },

  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    getRecruitData() {
      let id = this.$route.params.id;
      console.log(id);
      let url =
        process.env.VUE_APP_DB_URL + "recruit/getRecruitProfile?id=" + id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.recruitProfile = response.data;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
  },
  created() {
    this.getRecruitData();
  },
  computed: {
    getImage() {
      if (!this.recruitProfile.imageURL) {
        return require("../../../public/headshot-icon.png");
      } else {
        return this.recruitProfile.imageURL;
      }
    },
    filteredAdditionalMetrics() {
      return this.recruitProfile.athleticInfo.additionalMetrics.filter(
        (metric) => metric.title && metric.value
      );
    },
  },
};
</script>
