<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section class="container justify-content-center">
      <div class="container mt-5 justify-content-center"></div>
      <div class="row text-center v-step-3" style="margin-top: 35px">
        <div
          class="card-form__inner col-md-1"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            marginLeft: isMobile() ? '21px' : '',
            textAlign: isMobile() ? 'center' : '',
            padding: '10px',
            marginBottom: '10px',
          }"
        >
          <h4 style="float: left">{{ getCredits }}</h4>
          <img src="../../../../public/SaCoin.png" height="29" />
        </div>
        <div class="col-md-9">
          <b
            ><h1
              v-bind:style="{
                marginLeft: isMobile() ? '21px' : '11%',
                textAlign: 'center',
              }"
            >
              Ad Campaign Store
              <i
                class="fa-solid fa-circle-info fa-sm"
                style="color: #45487a; cursor: pointer; margin-left: 3px"
                @click="adCampaignStoreExplanation"
              ></i></h1
          ></b>
        </div>
        <div class="col-sm-2">
          <button
            class="btn rounded-pill"
            style="background-color: #363166; color: white; float: right"
            @click="purchaseCredits()"
          >
            Purchase Credits
          </button>
        </div>

        <div class="col-md-9">
          <input
            class="form-control me-2"
            type="search"
            placeholder="Search"
            aria-label="Search"
            style="margin-bottom: 10px; min-width: 80%"
            v-model="searchQuery"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
            }"
          />
        </div>

        <div class="col-md-3 col-sm-6">
          <multiselect
            v-model="searchValues"
            placeholder="Status"
            label="name"
            track-by="code"
            :options="options"
            :multiple="true"
            v-bind:style="{
              marginLeft: isMobile() ? '21px' : '',
            }"
          ></multiselect>
        </div>

        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            marginLeft: isMobile() ? '21px' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <ul
            class="list-group"
            v-for="(campaign, index) in resultQuery"
            :key="index"
          >
            <AdCampaignCard
              class="grow"
              v-if="index % 3 == 0"
              :id="campaign._id"
              :logo="campaign.logo"
              :credits="campaign.creditCost"
              :title="campaign.title"
              :objective="campaign.objective"
              :platform="campaign.platform"
              :description="campaign.description"
              @updateBalance="updateCredits"
            >
            </AdCampaignCard>
          </ul>
        </div>

        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            marginLeft: isMobile() ? '21px' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <!-- <h2 v-if="this.getEventList.length == 0" style="text-align: center">
            You have no payment links
          </h2>
          <button
            v-if="this.getEventList.length == 0"
            class="mt-3 card-form__button order rounded-pill"
            style="text-align: center"
          >
            <a
              @click="createFirstEvent()"
              class="v-step-2"
              style="text-decoration: none; color: white"
              >Create your first payment link!</a
            >
          </button> -->
          <ul
            class="list-group"
            v-for="(campaign, index) in resultQuery"
            :key="index"
          >
            <AdCampaignCard
              class="grow"
              v-if="index % 3 == 1"
              :id="campaign._id"
              :logo="campaign.logo"
              :credits="campaign.creditCost"
              :title="campaign.title"
              :objective="campaign.objective"
              :platform="campaign.platform"
              :description="campaign.description"
              @updateBalance="updateCredits"
            >
            </AdCampaignCard>
          </ul>
        </div>

        <div
          class="col-md-4 col-sm-6"
          v-bind:style="{
            width: isMobile() ? '99%' : '',
            marginLeft: isMobile() ? '21px' : '',
            textAlign: isMobile() ? 'center' : '',
          }"
        >
          <ul
            class="list-group"
            v-for="(campaign, index) in resultQuery"
            :key="index"
          >
            <AdCampaignCard
              class="grow"
              v-if="index % 3 == 2"
              :id="campaign._id"
              :logo="campaign.logo"
              :credits="campaign.creditCost"
              :title="campaign.title"
              :objective="campaign.objective"
              :platform="campaign.platform"
              :description="campaign.description"
              @updateBalance="updateCredits"
            >
            </AdCampaignCard>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import ClientLayout from "../../components/ClientLayout.vue";
import Multiselect from "vue-multiselect";
import swal from "sweetalert";
import AdCampaignCard from "../../components/Advertisements/AdCampaignCard.vue";
export default {
  components: {
    ClientLayout,
    AdCampaignCard,
    Multiselect,
  },
  data() {
    return {
      user: {},
      credits: null,
      adCampaigns: [],
      searchValues: [],
      options: [
        { name: "Available", code: "Available" },
        { name: "Active", code: "Active" },
        { name: "Used", code: "Used" },
      ],
    };
  },
  methods: {
    updateCredits(data) {
      this.credits = data.data;
      this.$router.push("/Dashboard/AdOverview");
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    purchaseCredits() {
      this.$router.push("/Dashboard/CreditStore");
    },
    async getAdCampaigns() {
      let url =
        process.env.VUE_APP_DB_URL + "advertisment/getAllAdCampaignsForSale";
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.adCampaigns = response.data;
          console.log(this.adCampaigns);
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    async getAdCredits() {
      const url =
        process.env.VUE_APP_DB_URL +
        "advertisment/getUserCreditBalance?id=" +
        this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.credits = response.data.creditBalance;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    adCampaignStoreExplanation() {
      swal({
        title: "Ad Campaign Store",
        text: "This is the Ad Campaign Store. Here you can purchase different campaigns to advertise on different social media platforms.",
        icon: "info",
      });
    },
  },

  created() {
    this.getUserDetails();
    this.isMobile();
    this.getAdCampaigns();
    this.getAdCredits();
  },

  mounted() {},

  computed: {
    getCredits() {
      return this.credits;
    },
    resultQuery() {
      if (this.searchValues.length == 0) {
        if (this.searchQuery) {
          return this.adCampaigns.filter((item) => {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.name.toLowerCase().includes(v));
          });
        } else {
          return this.adCampaigns;
        }
      } else {
        let temp = [];
        let values = this.searchValues;
        for (let x = 0; x < this.searchValues.length; x++) {
          temp.push(
            ...this.adCampaigns.filter(function (y) {
              return y.type == values[x].code;
            })
          );
        }
        //return temp;
        if (this.searchQuery) {
          return temp.filter((item) => {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.name.toLowerCase().includes(v));
          });
        } else {
          return temp;
        }
      }
    },
  },
};
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
