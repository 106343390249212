<template>
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section
      class="container justify-content-center"
      v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
    >
      <AddEventQuestions
        :date="this.newEventDate"
        :calendarID="this.calendarID"
      ></AddEventQuestions>
      <button
        data-bs-toggle="modal"
        data-bs-target="#addEventQuestions"
        style="display: none"
        id="openAddEventQuestions"
      ></button>

      <ViewScheduledPaymentLink :currentScheduledSession="currentCampEvent">
      </ViewScheduledPaymentLink>
      <button
        data-bs-toggle="modal"
        data-bs-target="#viewScheduledSession"
        style="display: none"
        id="openScheduledEvent"
      ></button>

      <ViewAvailability
        :availabilitySetting="availabilitySetting"
        :userID="this.user._id"
        :calendarID="this.calendarID"
        :sessionSupportedEvents="sessionSupportedEvents"
        :currentAvailabilityClicked="currentAvailabilityClicked"
        :selectedCampsFormattedForMultiSelect="
          this.selectedCampsFormattedForMultiSelect
        "
        v-on:changedAvailability="changedAvailability"
      ></ViewAvailability>
      <button
        data-bs-toggle="modal"
        data-bs-target="#viewAvailability"
        style="display: none"
        id="openViewAvailability"
      ></button>

      <ViewSimpleScheduledEvent
        :simpleScheduledEvent="selectedSimpleScheduledEvent"
        :schedule="simpleEventSchedule"
        :calendarID="calendarID"
        :userID="user._id"
        @updatedSimpleScheduledEvent="simpleScheduledEventChanges"
        @deletedSimpleScheduledEvent="simpleScheduledEventChanges"
      ></ViewSimpleScheduledEvent>
      <button
        data-bs-toggle="modal"
        data-bs-target="#viewSimpleScheduledEvent"
        style="display: none"
        id="openViewSimpleScheduledEvent"
      ></button>

      <div
        :class="{
          container: !isMobile,
          'mt-5': true,
          'justify-content-center': true,
        }"
      >
        <div :class="{ card: !isMobile, 'card-form__inner': !isMobile }">
          <div class="text-end">
            <button
              class="btn"
              @click="goToBookedSessions()"
              style="background-color: #c7e6fd; color: gray"
            >
              View Booked Sessions
            </button>
          </div>
          <h1 class="card-title text-center">My Schedule</h1>
          <br />
          <br />

          <span
            ><h3 class="text-center">
              {{ getCalendarName }}
            </h3>
          </span>

          <div
            :class="{
              'card-body': !isMobile,
              'justify-content-center': true,
              center: true,
              'text-center': true,
            }"
          >
            <full-calendar
              :events="fcEvents"
              locale="en"
              @dayClick="logDate"
              @eventClick="logEvent"
            ></full-calendar>
          </div>
          <br />

          <AddCalendar
            :userID="this.user._id"
            v-on:addedCalendar="addedCalendar"
          ></AddCalendar>
          <button
            data-bs-toggle="modal"
            data-bs-target="#addCalendar"
            style="display: none"
            id="openAddCalendar"
          ></button>
          <div class="row" v-if="!isMobile">
            <div class="col-md-7">
              <div class="text-end">
                <select
                  class="card-input__input -select"
                  style="background-position: 95% center; max-width: 300px"
                  v-model="selectedCalendarIndex"
                  @change="getUserCalendars()"
                >
                  <option disabled selected value="">Select</option>
                  <template v-for="(calendar, index) in this.calendars">
                    <option :key="calendar._id" :value="index">
                      {{ calendar.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="col-md-5">
              <div :class="{ 'text-start': !isMobile }">
                <button class="btn btn-primary text-end" @click="addCalendar()">
                  Add New Calendar
                </button>
                <button
                  class="btn btn-danger text-end ms-3"
                  @click="removeCalendar()"
                >
                  Remove This Calendar
                </button>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-else>
            <div class="col-sm-6 m-1 justify-content-center center text-center">
              <div>
                <select
                  class="card-input__input -select"
                  style="background-position: 95% center; max-width: 300px"
                  v-model="selectedCalendarIndex"
                  @change="getUserCalendars()"
                >
                  <option disabled selected value="">Select</option>
                  <template v-for="(calendar, index) in this.calendars">
                    <option :key="calendar._id" :value="index">
                      {{ calendar.name }}
                    </option>
                  </template>
                </select>
              </div>
            </div>
            <div class="col-sm-6 m-1 justify-content-center center text-center">
              <button
                class="btn btn-primary center text-center"
                @click="addCalendar()"
              >
                Add New Calendar
              </button>
            </div>
            <div class="col-sm-6 m-1 justify-content-center center text-center">
              <button
                class="btn btn-danger center text-center"
                @click="removeCalendar()"
              >
                Remove This Calendar
              </button>
            </div>
          </div>
          <br />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// import axios from "axios";
import ClientLayout from "../../components/ClientLayout.vue";
import axios from "axios";
import swal from "sweetalert";
import fullCalendar from "vue-fullcalendar";
import ViewAvailability from "../../components/Scheduling/ViewAvailability.vue";
import AddCalendar from "../../components/Scheduling/AddCalendar.vue";
import moment from "moment";
import ViewScheduledPaymentLink from "../../components/Scheduling/ViewScheduledSession.vue";
import ViewSimpleScheduledEvent from "../../components/Scheduling/ViewSimpleScheduledEvent.vue";
import AddEventQuestions from "../../components/Scheduling/AddEventQuestions.vue";

// import swal from "sweetalert";

export default {
  components: {
    ViewScheduledPaymentLink,
    ViewSimpleScheduledEvent,
    ClientLayout,
    fullCalendar,
    ViewAvailability,
    AddCalendar,
    AddEventQuestions,
  },
  data() {
    return {
      user: {},
      availabilitySetting: {
        date: "",
        startTime: "",
        endTime: "",
        repeating: false,
        repeatingSetting: "",
        selectedCamps: [],
      },
      currentCampEvent: {
        title: "",
        start: "",
        end: "",
        camperName: "",
        campName: "",
        campID: "",
        isCamp: false,
        camperLength: 0,
      },
      calendarID: "",
      selectedCampsFormattedForMultiSelect: [],
      currentAvailabilityClicked: {},
      allAvailabilities: [],
      sessionSupportedEvents: [],
      calendars: [],
      selectedCalendarIndex: 0,
      fcEvents: [],
      currentUserAvailability: [],
      campsWithDates: [],
      simpleScheduledEvents: [],
      selectedSimpleScheduledEventID: "",
      selectedSimpleScheduledEvent: {},
      simpleEventSchedule: {},
      newEventDate: "",
    };
  },
  methods: {
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },

    goToBookedSessions() {
      this.$router.push("/Dashboard/Calendar/BookedSessions");
    },

    simpleScheduledEventChanges() {
      window.location.reload();
    },

    addAllCalendarFcEvents() {
      let allAvailability =
        this.calendars[this.selectedCalendarIndex]?.availability;
      this.calendarID = this.calendars[this.selectedCalendarIndex]?._id;
      allAvailability = allAvailability.sort((a, b) => {
        return (
          Number(a.startTime.split(":")[0]) - Number(b.startTime.split(":")[0])
        );
      });
      allAvailability = allAvailability.sort((a, b) => {
        return b.date - a.date;
      });
      this.currentUserAvailability = allAvailability;
      let url =
        process.env.VUE_APP_DB_URL +
        "scheduling/getAllCalendarFcEvents?calendarID=" +
        this.calendars[this.selectedCalendarIndex]?._id +
        "&userID=" +
        this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.fcEvents = response.data.fcEvents;
          this.colorCodeEvents(false);
        });
    },

    getUserCalendars() {
      let url =
        process.env.VUE_APP_DB_URL +
        "scheduling/getUserCalendars?userID=" +
        this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.calendars = response.data.calendars;
          this.calendarID = response.data.calendars[0]._id;
          this.addAllCalendarFcEvents();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    logDate(day, jsEvent) {
      this.availabilitySetting = {
        date: "",
        startTime: "",
        endTime: "",
        repeating: false,
        repeatingSetting: "",
        selectedCamps: [],
      };
      console.log(jsEvent);
      if (this.calendars.length > 0) {
        this.availabilitySetting.date = day;
        this.newEventDate = day.toLocaleDateString();
        document.getElementById("openAddEventQuestions").click();
      } else {
        swal(
          "Info",
          "You must setup your calendar before you can add events",
          "info"
        );
        document.getElementById("openAddCalendar").click();
      }
    },

    async logEvent(event, jsEvent) {
      console.log(event);
      console.log(jsEvent);
      if (event.isCamp) {
        this.currentCampEvent = event;
        document.getElementById("openScheduledEvent").click();
      } else if (event.isSimpleScheduledEvent) {
        this.selectedSimpleScheduledEventID = event.id;
        console.log("Start: " + event.start);
        await this.getSelectedSimpleScheduledEvent(event);
        document.getElementById("openViewSimpleScheduledEvent").click();
      } else {
        this.currentAvailabilityClicked = event;
        await this.getSelectedAvailability();
        document.getElementById("openViewAvailability").click();
      }
    },

    async getSelectedSimpleScheduledEvent(calendarEvent) {
      let url =
        process.env.VUE_APP_DB_URL +
        "scheduling/getSimpleScheduledEvent?eventID=" +
        this.selectedSimpleScheduledEventID;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.selectedSimpleScheduledEvent =
            response.data.simpleScheduledEvent;
          this.simpleEventSchedule =
            response.data.simpleScheduledEvent.schedule;

          this.simpleEventSchedule.date = calendarEvent.start;

          for (const alteration of response.data.simpleScheduledEvent.schedule
            .alterations) {
            if (
              moment(alteration.date).format("YYYY-MM-DD") ===
              moment(calendarEvent.start).format("YYYY-MM-DD")
            ) {
              this.simpleEventSchedule.date = alteration.date;
              this.simpleEventSchedule.startTime = alteration.startTime;
              this.simpleEventSchedule.endTime = alteration.endTime;
              this.selectedSimpleScheduledEvent.name = alteration.name;
              this.selectedSimpleScheduledEvent.location = alteration.location;
              this.selectedSimpleScheduledEvent.description =
                alteration.description;
            }
          }
        });
    },

    async getSelectedAvailability() {
      let url =
        process.env.VUE_APP_DB_URL +
        "scheduling/getSelectedAvailability?userID=" +
        this.user._id +
        "&availabilityID=" +
        this.currentAvailabilityClicked.id +
        "&calendarID=" +
        this.calendarID;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.availabilitySetting = response.data.availability;

          for (const alteration of response.data.availability.alterations) {
            if (
              moment(alteration.date).add(1, "days").format("YYYY-MM-DD") ===
              moment(this.currentAvailabilityClicked.start).format("YYYY-MM-DD")
            ) {
              this.availabilitySetting.startTime = alteration.startTime;
              this.availabilitySetting.endTime = alteration.endTime;
              this.availabilitySetting.selectedCamps = alteration.selectedCamps;
            }
          }

          this.selectedCampsFormattedForMultiSelect =
            response.data.availability.selectedCamps.map((selectedCamp) => {
              return {
                name: selectedCamp.campName,
                code: selectedCamp.campStripeId,
              };
            });
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },

    addedAvailability(allAvailability) {
      this.currentUserAvailability = allAvailability.allAvailability;

      this.addAllCalendarFcEvents();
    },

    changedAvailability(allAvailability) {
      this.currentUserAvailability = allAvailability.allAvailability;
      this.fcEvents = [];
      this.addAllCalendarFcEvents();
    },

    getAllSessionSupportedEvents() {
      let url =
        process.env.VUE_APP_DB_URL +
        "individualCamp/getAllSessionSupportedEvents?userID=" +
        this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.sessionSupportedEvents = response.data.sessionSupportedEvents;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },

    addedCalendar(allCalendars) {
      this.calendars = allCalendars.calendars;
      if (this.calendars.length === 1) {
        this.calendarID = this.calendars[0]._id;
        this.fcEvents = [];
        this.addAllCalendarFcEvents();
      }
      document.getElementById("addCalendarClose").click();
    },

    addCalendar() {
      document.getElementById("openAddCalendar").click();
    },

    removeCalendar() {
      swal({
        title: "Are you sure?",
        text: "This will will remove the selected calendar and all associated availabilities will be deleted. Existing scheduled sessions will NOT be deleted.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willCreate) => {
        if (willCreate) {
          let url =
            process.env.VUE_APP_DB_URL +
            "scheduling/removeCalendar?userID=" +
            this.user._id +
            "&calendarID=" +
            this.calendarID;

          swal("Loading...", "removing calendar...", "info");
          axios
            .delete(url, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            })
            .then((response) => {
              console.log(response);
              if (this.selectedCalendarIndex > 0) {
                this.selectedCalendarIndex--;
              }
              swal("Success", "Calendar removed", "success");
              this.getUserCalendars();
            })
            .catch((error) => {
              swal("Error", error.response.data.error, "error");
            });
        }
      });
    },
    colorCodeEvents(onReload) {
      const timeOut = onReload ? 100 : 100;
      setTimeout(() => {
        let campEvents = document.getElementsByClassName("event-item");
        for (let event of campEvents) {
          if (event.innerText.includes("*")) {
            console.log("Found *");
            event.style.backgroundColor = "#fadadd";
            event.style.color = "#000000";
          } else if (
            !event.innerText.includes("am -") &&
            !event.innerText.includes("pm -")
          ) {
            event.style.backgroundColor = "#E6E6FA";
            event.style.color = "#000000";
          } else {
            event.style.backgroundColor = "#C7E6FD";
            event.style.color = "#000000";
          }
        }
      }, timeOut);
    },
  },

  created() {
    this.getUserDetails();
    this.getUserCalendars();
    this.getAllSessionSupportedEvents();
    this.colorCodeEvents(false);
  },
  computed: {
    getCalendarName() {
      return this.calendars[this.selectedCalendarIndex]?.name;
    },
    isMobile() {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  mounted() {
    let days = document.getElementsByClassName("events-day");
    for (let day of days) {
      day.style.minHeight = "99px";
      day.style.maxHeight = "99px";
    }

    let title = document.getElementsByClassName("title")[0];
    title.className = "title h5";

    let previousArrow = document.getElementsByClassName("prev-month")[0];
    let nextArrow = document.getElementsByClassName("next-month")[0];
    previousArrow.textContent = "";
    nextArrow.textContent = "";
    let backArrow = document.createElement("i");
    backArrow.className = "fa fa-solid fa-arrow-circle-o-left fa-xl";
    backArrow.style.color = "#363166";
    let forwardArrow = document.createElement("i");
    forwardArrow.className = "fa fa-solid fa-arrow-circle-o-right fa-xl";
    forwardArrow.style.color = "#363166";
    previousArrow.appendChild(backArrow);
    nextArrow.appendChild(forwardArrow);

    let header = document.getElementsByClassName("header-center")[0];
    header.style.minWidth = "100%";

    nextArrow.addEventListener("click", () => {
      this.colorCodeEvents(true);
    });

    previousArrow.addEventListener("click", () => {
      this.colorCodeEvents(true);
    });
  },
};
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.currencyinput {
  border: 1px inset #ccc;
}
.currencyinput input {
  border: 0;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}

.newGradient {
  background-color: #ff3cac;
  background-image: linear-gradient(
    225deg,
    #32bd28 0%,
    #45487a 50%,
    #45487a 100%
  );
}

@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
