<template>
  <div class="container" style="width: 100%">
    <div class="section">
      <div class="row outer fade-left justify-content-center">
        <div class="col-md-6 col-sm-12" v-if="isMobile">
          <HomeContactImageCard
            :primaryColor="primaryColor"
            :secondaryColor="secondaryColor"
            :photo="homePage.photoOne"
            :recruitID="recruitID"
            :recruitName="
              recruitProfile.firstName + ' ' + recruitProfile.lastName
            "
            :isMobile="isMobile"
            style="margin-left: 12px"
          ></HomeContactImageCard>
        </div>
        <div class="col" :style="{ padding: isMobile ? '21px' : '' }">
          <h1 :style="{ color: this.primaryColor }">{{ title }}</h1>
          <h4 :style="{ color: this.secondaryColor }">
            {{ homePage.subheadingOne }}
          </h4>
          <h5 style="color: white" v-html="homePage.descriptionOne">
            <!--            {{ `${homePage.descriptionOne}` }}-->
          </h5>
          <div class="mt-5 text-center">
            <button
              class="btn"
              :style="getHighlightsButtonCSS"
              @click="goToHighlights()"
            >
              View My Highlights
            </button>
          </div>
        </div>
        <div class="col-md-6 col-sm-12" v-if="!isMobile">
          <HomeContactImageCard
            :primaryColor="primaryColor"
            :secondaryColor="secondaryColor"
            :photo="homePage.photoOne"
            :recruitID="recruitID"
            :recruitName="
              recruitProfile.firstName + ' ' + recruitProfile.lastName
            "
            :isMobile="isMobile"
          ></HomeContactImageCard>
        </div>
      </div>

      <div
        class="row outer reveal fade-right"
        :style="{ marginTop: isMobile ? '50px' : '200px' }"
      >
        <HomePageCard
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :heading="homePage.subheadingTwo"
          :description="homePage.descriptionTwo"
          :photo="homePage.photoTwo"
        ></HomePageCard>
      </div>
    </div>
    <div
      class="row justify-content-center mt-5 promo"
      :style="getMetricBannerCSS"
    >
      <MetricBanner
        :class="{ 'm-5': !isMobile }"
        :primaryColor="this.primaryColor"
        :secondaryColor="this.secondaryColor"
        :isMobile="isMobile"
        :state="recruitProfile.state"
        :academicInfo="recruitProfile.academicInfo"
        :athleticInfo="recruitProfile.athleticInfo"
        fontColor="#000"
        @goToMetricsPage="goToMetricsPage()"
      ></MetricBanner>
    </div>
    <div class="container">
      <div
        class="row outer reveal fade-right mb-5"
        :style="{ marginTop: isMobile ? '100px' : '200px' }"
      >
        <HomePageCard
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :heading="homePage.subheadingThree"
          :description="homePage.descriptionThree"
          :photo="homePage.photoThree"
          :photoRightAlign="true"
        ></HomePageCard>
      </div>
    </div>
  </div>
</template>

<script>
import HomeContactImageCard from "./HomeContactImageCard.vue";
import MetricBanner from "../Metrics/MetricBanner.vue";
import HomePageCard from "./HomePageCard.vue";
export default {
  components: {
    HomeContactImageCard,
    MetricBanner,
    HomePageCard,
  },

  props: {
    primaryColor: String,
    secondaryColor: String,
    title: String,
    homePage: Object,
    recruitProfile: Object,
    recruitID: String,
    isMobile: Boolean,
  },

  methods: {
    goToHighlights() {
      this.$emit("goToHighlights");
    },

    goToMetricsPage() {
      console.log("Hit on home");
      this.$emit("goToMetricsPage");
    },
  },

  computed: {
    getHighlightsButtonCSS() {
      return `color: white; min-width: 330px; min-height: 50px; font-size: 33px; background-color: ${this.primaryColor};

`;
    },

    getMetricBannerCSS() {
      return `background: linear-gradient(0.75turn, #000, ${
        this.primaryColor
      }); min-width: ${this.isMobile ? "105%" : "200%"}; min-height: ${
        this.isMobile ? "350px" : "330px"
      }; max-height: ${
        this.isMobile ? "350px" : ""
      }; overflow: hidden; margin-top: ${
        this.isMobile ? "100px" : "200px"
      }; margin-left: -50%;

      `;
    },

    getMainCardBackCSS() {
      // return `min-height: 500px;
      //                 background: linear-gradient(
      //                   90deg,
      //                   ${this.secondaryColor} 60%,
      //                   ${this.primaryColor} 100%
      //                 );`;
      return `background: linear-gradient(90deg, ${this.primaryColor} 60%, ${this.secondaryColor} 100%);`;
    },

    //     getBackCSS() {
    //       return `
    //   background: ${this.primaryColor};
    //   background: -webkit-linear-gradient(45deg, ${this.secondaryColor} 0%, ${this.secondaryColor} 100%);
    //   background: -o-linear-gradient(45deg, ${this.secondaryColor} 0%, ${this.secondaryColor} 100%);
    //   background: linear-gradient(45deg, ${this.secondaryColor} 0%, ${this.secondaryColor} 100%);
    //   min-height: 500px;
    // `;

    // .back {
    //     background: #cedce7;
    //     background: -webkit-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    //     background: -o-linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    //     background: linear-gradient(45deg, #cedce7 0%, #596a72 100%);
    //   }
    // },
  },
};
</script>

<style scoped>
@media screen and (max-width: 64rem) {
  .col {
    width: calc(33.333333% - 2rem);
  }
}

@media screen and (max-width: 48rem) {
  .col {
    width: calc(50% - 2rem);
  }
}

@media screen and (max-width: 32rem) {
  .col {
    width: 100%;
    margin: 0 0 2rem 0;
  }
}
</style>
