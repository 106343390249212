<template>
  <div class="wrapper container" id="checkIn">
    <ClientLayout :user="user"></ClientLayout>
    <section class="main container">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile() ? '21px' : '' }"
      >
        <div class="row justify-content-center">
          <div class="col-md-10 col-sm-6 center"></div>
          <div
            class="col-md-2 col-sm-6 center"
            v-if="this.user.subscription.subscriptionTier >= 2"
          >
            <JsonExcel
              :data="people"
              :name="'myDirectory.xls'"
              :fields="json_fields"
            >
              <button
                class="btn float-end rounded-pill"
                style="background-color: #363166; color: white"
              >
                Export Data
              </button>
            </JsonExcel>
          </div>
          <div class="col-md-2 col-sm-6 center" v-else>
            <button
              class="btn float-end rounded-pill"
              style="background-color: #363166; color: white"
              @click="showDownloadCusotmerAd()"
            >
              Export Data
            </button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-6 col-sm-6">
            <h1 class="center text-center mb-3">My Directory</h1>
          </div>
        </div>
        <div class="row justify-content-center">
          <form class="d-flex justify-content-center">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              style="
                margin-bottom: 10px;
                min-width: 300px;
                max-width: 500px;
                margin-left: 15px;
              "
              v-model="searchQuery"
            />
          </form>
        </div>
        <div
          style="
            background-color: rgb(255, 255, 255);
            border-style: solid;
            border-radius: 5px;
          "
        >
          <b-table
            class="table-transition-example"
            :items="resultQuery"
            :fields="fields"
            striped
            hover
            small
            responsive
            primary-key='"email"+"firstName"+"lastName"'
          >
            <template #cell(show_details)="row">
              <b-button
                size="sm"
                @click="row.toggleDetails"
                class="mr-2 float-end rounded-pill"
                style="background-color: #363166"
              >
                {{ row.detailsShowing ? "Hide" : "Expand" }}
              </b-button>
            </template>

            <template #row-details="row">
              <PeopleDetails
                :firstName="row.item.firstName"
                :lastName="row.item.lastName"
                :camps="row.item.camps"
                :teamCamps="row.item.teamCamps"
                :memberships="row.item.memberships"
              ></PeopleDetails>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import ClientLayout from "../../components/ClientLayout.vue";
import PeopleDetails from "../../components/CRM/PeopleDetails.vue";
import JsonExcel from "vue-json-excel";
export default {
  components: { ClientLayout, PeopleDetails, JsonExcel },
  data() {
    return {
      fields: [
        { key: "firstName", sortable: true },
        { key: "lastName", sortable: true },
        { key: "email", sortable: true },
        { key: "show_details", sortable: false },
      ],
      json_fields: {
        "First Name": "firstName",
        "Last Name": "lastName",
        Email: "email",
        "Phone Number": "phone",
        Camps: {
          callback: (value) => {
            let camps = "";
            value.camps.forEach((camp) => {
              camps += camp.campName + ", ";
            });
            return camps;
          },
        },
        Memberships: {
          callback: (value) => {
            let memberships = "";
            value.memberships.forEach((membership) => {
              memberships += membership.membershipName + ", ";
            });
            return memberships;
          },
        },
        Tournaments: {
          callback: (value) => {
            let camps = "";
            value.teamCamps.forEach((camp) => {
              camps += camp.campName + ", ";
            });
            return camps;
          },
        },
      },
      user: {},
      campId: "",
      campName: "",
      people: [],
      searchQuery: "",
    };
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    async getAllPeople() {
      let url =
        process.env.VUE_APP_DB_URL + "crm/getAllPeople?id=" + this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          console.log(response);
          this.people = response.data;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    showDownloadCusotmerAd() {
      swal({
        title: "Upgrade Your Plan",
        text: "You must be at least an Pro user to download customer data locally. Upgrade your plan to continue.",
        icon: "info",
      });
      document.getElementById("openAdvertisementPopUp").click();
    },
  },

  created() {
    this.campId = this.$route.params.campId;
    this.getUserDetails();
    this.getAllPeople();
  },
  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.people.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v) //||
              // item.email.toLowerCase().includes(v) ||
              // item.phoneNumber.includes(v)
            );
        });
      } else {
        return this.people;
      }
    },
  },
};
</script>
<style scoped>
#table-transition-example .flip-list-move {
  transition: transform 1s;
}
</style>
