<template>
  <div :class="{ container: !isMobile, wrapper: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="this.membershipLogo" class="card-img-top rounded-corners" />

        <h1 class="text-center" style="margin-top: 50px">Registrant Info</h1>
        <div class="row">
          <div class="card-input col">
            <label for="cardName" class="card-input__label">First Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="accountInfo.memberFirstName"
              data-ref="cardName"
              autocomplete="given-name"
              required
            />
          </div>

          <div class="card-input col">
            <label for="cardName" class="card-input__label">Last Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="accountInfo.memberLastName"
              data-ref="cardName"
              autocomplete="family-name"
            />
          </div>
        </div>
        <!--Start of Custom Forms-->
        <li
          style="list-style-type: none"
          v-for="index in customFields.length"
          :key="index"
        >
          <CustomField
            :field="customFields[index - 1]"
            :prePopulatedFieldValue="fieldValueInputs[index - 1].fieldValue"
            :prePopulatedFieldType="fieldValueInputs[index - 1].fieldType"
            @changed="handleChange(index, $event)"
          ></CustomField>
        </li>

        <!--End of Custom Forms-->
        <div v-if="this.tshirtBool">
          <label>T-shirt size</label>
          <select
            class="card-input__input -select"
            style="margin-bottom: 20px"
            v-model="accountInfo.tShirtSize"
            required
          >
            <option disabled selected value="">Size</option>
            <option value="YS" class="option">Youth Small</option>
            <option value="YM" class="option">Youth Medium</option>
            <option value="YL" class="option">Youth Large</option>
            <option value="AS" class="option">Adult Small</option>
            <option value="AM" class="option">Adult Medium</option>
            <option value="AL" class="option">Adult Large</option>
            <option value="AXL" class="option">Adult XLarge</option>
            <option value="AXXL" class="option">Adult XXLarge</option>
          </select>
        </div>

        <h3 v-show="!emergencyContactInfo.emergencyBool">
          Emergency Contact Information
        </h3>
        <div
          v-show="!emergencyContactInfo.emergencyBool"
          style="margin-top: 15px"
        >
          <div class="row">
            <div class="card-input col">
              <label class="card-input__label">First Name</label>
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                v-model="emergencyContactInfo.firstName"
              />
            </div>
            <div class="card-input col">
              <label class="card-input__label">Last Name</label>
              <input
                type="text"
                id="name"
                class="card-input__input"
                v-model="emergencyContactInfo.lastName"
              />
            </div>
          </div>
          <div class="card-input">
            <label class="card-input__label">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              class="card-input__input"
              v-model="emergencyContactInfo.phoneNumber"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              v-model="emergencyContactInfo.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Address</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.address"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">City</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.city"
            />
          </div>

          <div class="card-form__row">
            <div class="card-form__col">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">State</label>
                <select
                  class="card-input__input -select"
                  id="cardMonth"
                  v-model="emergencyContactInfo.state"
                >
                  <option value="" disabled selected>State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
            <div class="card-form__col">
              <div class="card-input">
                <label for="cardCvv">Zip Code</label>
                <input
                  type="text"
                  class="card-input__input"
                  v-model="emergencyContactInfo.zipCode"
                />
              </div>
            </div>
          </div>
        </div>
        <input
          v-if="!this.requireEmergency"
          class="form-check-input me-2"
          type="checkbox"
          value=""
          id="form6Example8"
          v-model="emergencyContactInfo.emergencyBool"
        />
        <label
          v-if="!this.requireEmergency"
          class="form-check-label"
          for="form6Example8"
        >
          Emergency Contact is Billing Info
        </label>
        <br />
        <div class="row" v-if="!isMobile">
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              @click="back"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>

          <div class="col-md-9 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              :style="{ backgroundColor: primaryColor, color: 'white' }"
              @click="submitMemberInfo"
            >
              {{ this.buttonName }}
            </button>
          </div>
        </div>
        <div v-else>
          <button
            class="card-form__button rounded-pill"
            :style="{ backgroundColor: primaryColor, color: 'white' }"
            @click="submitMemberInfo"
          >
            {{ this.buttonName }}
          </button>
          <br />
          <br />
          <br />
          <button
            :class="{
              'card-form__button': true,
              'w-50': isMobile,
              'rounded-pill': true,
            }"
            :style="{
              backgroundColor: secondaryColor,
              color: 'white',
            }"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
// import axios from "axios";
import CustomField from "../../../camps/components/CustomField.vue";

export default {
  components: {
    CustomField,
  },
  props: {
    tshirtBool: Boolean,
    customFields: Array,
    membershipLogo: String,
    requireEmergency: Boolean,
    buttonName: String,
    accountInfo: Object,
    emergencyContactInfo: Object,
    fieldValueInputs: Array,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
  },
  data() {
    return {};
  },
  methods: {
    handleChange(index, value) {
      this.fieldValueInputs[index - 1] = this.customFields[index - 1];
      this.fieldValueInputs[index - 1].fieldValue = value.value;
      delete this.fieldValueInputs[index - 1]._id;
    },
    back() {
      this.$emit("back");
    },
    submitMemberInfo() {
      if (this.validateInfo() === 0) {
        window.scrollTo(0, 0);
        let data = {
          accountInfo: this.accountInfo,
          emergencyContactInfo: this.emergencyContactInfo,
          customFieldValues: this.fieldValueInputs,
        };
        this.$emit("proceed", data);
      }
    },
    validateInfo() {
      if (this.accountInfo.memberFirstName == "") {
        swal("Error", "Please enter camper first name", "error");
        return 1;
      }
      if (this.accountInfo.memberLastName == "") {
        swal("Error", "Please enter camper last name", "error");
        return 1;
      }
      if (this.accountInfo.tShirtSize == "" && this.tshirtBool) {
        swal("Error", "Please enter camper shirt size", "error");
        return 1;
      }

      //Check to make sure if custom fields exist for the camp. If so, validate camper inputs for them.
      if (
        this.customFields &&
        this.customFields[0] &&
        this.fieldValueInputs &&
        this.fieldValueInputs[0] !== {}
      ) {
        if (this.fieldValueInputs.length !== this.customFields.length) {
          swal(
            "Error",
            "Please fill out all form fields before continuing",
            "error"
          );
          return 1;
        } else {
          for (let index = 0; index < this.customFields.length; index++) {
            if (
              this.fieldValueInputs[index].fieldName !==
              this.customFields[index].fieldName
            ) {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            } else if (this.fieldValueInputs[index].fieldValue === "") {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            }
          }
        }
      }
      return 0;
    },
  },

  created() {
    this.info = this.accountInfo;
    if (!this.customFields) {
      this.customFields = [];
    }
    if (!this.fieldValueInputs[0]?.fieldValue) {
      for (let x = 0; x < this.customFields.length - 1; x++) {
        this.fieldValueInputs.push("");
      }
    }
    if (this.requireEmergency) {
      this.emergencyContactInfo.emergencyBool = false;
    }
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
