<template>
  <div
    class="card m-3 card-form__inner"
    style="max-width: 700px; min-height: 300px"
  >
    <div class="card-body row">
      <div class="col-md-6 col-sm-6" style="margin-top: 10px">
        <img :src="getImage" class="profile-image" />
        <h2 v-bind:style="{ textAlign: isMobile ? 'center' : '' }">
          <b>{{ this.camperFirstName }} {{ this.camperLastName }}</b>
        </h2>
        <button class="card-form__button rounded-pill" style="margin-top: 10px">
          <a @click="toCamperProfile()">View Profile</a>
        </button>
      </div>
      <div
        class="col-md-6 col-sm-6"
        v-bind:style="{ textAlign: isMobile ? 'center' : '' }"
      >
        <div class="" style="margin-top: 40px">
          <label for="CheckedIn" style="margin-right: 1px; font-size: large"
            >Checked In
          </label>
          <input
            type="checkbox"
            id="CheckedIn"
            name="CheckedIn"
            v-model="isCheckedIn"
            @change="updateStatus()"
            style="
              width: 17px;
              height: 17px;
              accent-color: #19d412;
              margin-left: 5px;
            "
            class="checkbox"
          />
        </div>
        <div class="" v-if="getTShirtString != ''">
          <label for="Received" style="margin-right: 3px; font-size: large"
            >Received T-Shirt
          </label>
          <input
            type="checkbox"
            id="Recieved"
            name="Recieved"
            v-model="camperTShirtReceived"
            @change="updateStatus()"
            style="
              width: 17px;
              height: 17px;
              accent-color: #19d412;
              margin-left: 5px;
            "
            class="checkbox"
          />
          <br />
          <span>
            - {{ getTShirtString }}
            <i class="fa-solid fa-shirt m-1" style="color: #363166"></i>
          </span>
        </div>
        <div class="" v-if="getCustomAddOns.length != 0">
          <b>Camper Purchased: </b>
          <ul v-for="(temp, index) in getCustomAddOns" :key="index">
            {{
              getCustomAddOns[index]
            }}
          </ul>
        </div>
        <div class="" v-if="this.discountCode">
          <b>Used discount code: {{ this.discountCode }} </b>
        </div>
        <div class="mt-3">
          <b>Amount Paid: ${{ this.pricePaid.toFixed(2) }}</b>
        </div>

        <div
          v-if="checkScheduledSession"
          class="card card-body mt-3"
          style="border: 3px solid #45487a; border-radius: 15px"
        >
          <b>Scheduled Date:</b> {{ getFormattedDate }}
          <br />
          <hr />
          <b>Scheduled Time:</b> {{ getFormattedTime }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      teamId: "",
      campId: "",
      camperFirstName: "",
      camperLastName: "",
      camperIsCheckedIn: "",
      camperTShirtReceived: "",
      camperTShirtSize: "",
      camperCampName: "",
      customAddOns: {},
      showInfo: false,
    };
  },
  components: {},
  props: {
    firstName: String,
    lastName: String,
    isCheckedIn: Boolean,
    tShirtReceived: Boolean,
    tShirtSize: String,
    campName: String,
    camperId: String,
    camperAddOns: Array,
    discountCode: String,
    isTeamCamp: Boolean,
    camperProfileImage: String,
    bundlePricePaid: Number,
    scheduledSession: Object,
    pricePaid: Number,
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    toCamperProfile() {
      if (this.isTeamCamp) {
        this.$router.push(
          "/Dashboard/TeamList/" +
            this.$route.params.campId +
            "/" +
            this.$route.params.teamId +
            "/" +
            this.camperId
        );
      } else {
        this.$router.push(
          "/Dashboard/CheckIn/" + this.campId + "/" + this.camperId
        );
      }
    },

    updateStatus() {
      let obj = {
        campId: this.campId,
        camperId: this.camperId,
        isCheckedIn: !this.camperIsCheckedIn,
        tShirtReceived: this.camperTShirtReceived,
      };
      let url = process.env.VUE_APP_DB_URL + "individualCamp/setCamperStatus";
      if (this.isTeamCamp) {
        this.teamId = this.$route.params.teamId;
        url = process.env.VUE_APP_DB_URL + "teamCamp/setTeamCamperStatus";
        obj.teamId = this.teamId;
      }
      axios
        .post(url, obj, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
  },

  created() {
    this.campId = this.$route.params.campId;
    this.camperFirstName = this.firstName;
    this.camperLastName = this.lastName;
    this.camperIsCheckedIn = this.isCheckedIn;
    this.camperTShirtReceived = this.tShirtReceived;
    this.camperTShirtSize = this.tShirtSize;
    this.camperCampName = this.campName;
    this.camperEmergencyContactInfo = this.emergencyContactInfo;
    this.customAddOns = this.camperAddOns;
  },
  computed: {
    getImage() {
      if (!this.camperProfileImage) {
        return require("../../../../public/headshot-icon.png");
      } else {
        return this.camperProfileImage;
      }
    },
    getCustomAddOns() {
      let temp = [];
      let count;
      for (count = 0; count < this.customAddOns.length; count++) {
        if (this.customAddOns[count].isPurchased) {
          temp.push(this.customAddOns[count].addOnName);
        }
      }
      return temp;
    },
    getTShirtString() {
      if (this.camperTShirtSize == "YS") {
        return "Youth Small";
      }
      if (this.camperTShirtSize == "YM") {
        return "Youth Medium";
      }
      if (this.camperTShirtSize == "YL") {
        return "Youth Large";
      }
      if (this.camperTShirtSize == "AS") {
        return "Adult Small";
      }
      if (this.camperTShirtSize == "AM") {
        return "Adult Medium";
      }
      if (this.camperTShirtSize == "AL") {
        return "Adult Large";
      }
      if (this.camperTShirtSize == "AXL") {
        return "Adult XL";
      }
      if (this.camperTShirtSize == "AXXL") {
        return "Adult XXL";
      }

      return "";
    },
    getFormattedDate() {
      if (this.scheduledSession) {
        return moment(this.scheduledSession?.date)
          .add(1, "days")
          .format("MM/DD/YYYY");
      }
      return "";
    },

    getFormattedTime() {
      let time = this.scheduledSession.time;
      if (this.scheduledSession?.time) {
        let startTime = time.split(" - ")[0];
        let endTime = time.split(" - ")[1];

        let startHour = startTime.split(":")[0];
        let endHour = endTime.split(":")[0];

        let startTag = "";
        let endTag = "";

        if (parseInt(startHour) < 12) {
          startTag = "am";
        } else {
          if (startHour > 12) {
            startHour = parseInt(startHour) - 12;
          }
          startTag = "pm";
        }

        if (parseInt(endHour) < 12) {
          endTag = "am";
        } else {
          if (endHour > 12) {
            endHour = parseInt(endHour) - 12;
          }
          endTag = "pm";
        }

        return `${startHour}:${
          startTime.split(":")[1]
        }${startTag} - ${endHour}:${endTime.split(":")[1]}${endTag}`;
      }
      return "";
    },

    checkScheduledSession() {
      if (this.scheduledSession) {
        if (this.scheduledSession.date && this.scheduledSession.time) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.card3 {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;

  .go-corner {
    opacity: 0.7;
  }

  &:hover {
    border: 1px solid #363166;
    background-color: #f0eff7;
    /*box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);*/
    /*z-index: 500;*/
    transition: 0.3s linear;
  }
}

.card3:hover {
  p {
    transition: 0.3s linear;
  }
  .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
}
</style>
