<template>
  <div class="wrapper container">
    <MemberNav :member="member.firstName"></MemberNav>

    <div class="container" id="app" style="margin-top: 7%">
      <div class="card-form">
        <div class="card-form__inner">
          <img src="../../../public/SummerAthletes.png" class="card-img-top" />
          <div class="card-form">
            <div class="row">
              <h3>Account Info</h3>
              <div class="col">
                <div class="card-input">
                  <label for="firstName" class="card-input__label"
                    >First Name</label
                  >
                  <input
                    type="text"
                    id="firstName"
                    class="card-input__input"
                    placeholder="First Name"
                    v-model="member.firstName"
                    required
                    readonly
                  />
                </div>
              </div>
              <div class="col">
                <div class="card-input">
                  <label for="lastName" class="card-input__label"
                    >Last Name</label
                  >
                  <input
                    type="text"
                    id="lastName"
                    class="card-input__input"
                    placeholder="Last Name"
                    v-model="member.lastName"
                    required
                    readonly
                  />
                </div>
              </div>
              <div class="card-input">
                <label for="email" class="card-input__label">Email</label>
                <input
                  type="email"
                  id="email"
                  class="card-input__input"
                  placeholder="Email"
                  v-model="update.email"
                  required
                />
              </div>
            </div>
            <div class="card-input">
              <label>Address</label>
              <input
                type="text"
                id="address"
                class="card-input__input"
                placeholder="Address"
                v-model="update.address"
              />
            </div>
            <div class="card-input">
              <label for="city" class="card-input__label">City</label>
              <input
                type="text"
                id="city"
                placeholder="City"
                class="card-input__input"
                style="margin-bottom: 0"
                v-model="update.city"
              />
            </div>

            <div class="row">
              <div class="col">
                <div class="card-input">
                  <label for="cardMonth">State</label>
                  <select
                    required
                    class="card-input__input"
                    id="cardMonth"
                    v-model="update.state"
                  >
                    <option value="" disabled selected>State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="card-input">
                  <label for="cardCvv">Zip Code</label>
                  <input
                    type="text"
                    class="card-input__input"
                    placeholder="Zip Code"
                    v-model="update.zipCode"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-input">
            <label>Phone Number</label>
            <input
              type="number"
              id="phoneNumber"
              class="card-input__input"
              placeholder="Phone Number"
              v-model="update.phoneNumber"
            />
          </div>

          <br />
          <h3>Emergency Contact Info</h3>
          <div class="card-input">
            <label>Emergency Contact First Name</label>
            <input
              type="text"
              id="emFirstName"
              class="card-input__input"
              placeholder="First Name"
              v-model="update.emergencyContactInfo.firstName"
            />
          </div>

          <div class="card-input">
            <label>Emergency Contact Last Name</label>
            <input
              type="text"
              id="emLastName"
              class="card-input__input"
              placeholder="Last Name"
              v-model="update.emergencyContactInfo.lastName"
            />
          </div>

          <div class="card-input">
            <label>Emergency Contact Email</label>
            <input
              type="email"
              id="emEmail"
              class="card-input__input"
              placeholder="Email"
              v-model="update.emergencyContactInfo.email"
            />
          </div>

          <div class="card-input">
            <label>Emergency Contact Address</label>
            <input
              type="text"
              id="emAddress"
              class="card-input__input"
              placeholder="Address"
              v-model="update.emergencyContactInfo.address"
            />
          </div>

          <div class="card-input">
            <label>Emergency Contact City</label>
            <input
              type="text"
              id="emCity"
              class="card-input__input"
              placeholder="City"
              v-model="update.emergencyContactInfo.city"
            />
          </div>

          <div class="card-input">
            <label for="cardMonth">Emergency Contact State</label>
            <select
              required
              class="card-input__input"
              id="emState"
              v-model="update.emergencyContactInfo.state"
            >
              <option value="" disabled selected>State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>

          <div class="card-input">
            <label>Emergency Contact Zip Code</label>
            <input
              type="text"
              id="emZipCode"
              class="card-input__input"
              placeholder="Zip Code"
              v-model="update.emergencyContactInfo.zipCode"
            />
          </div>

          <div class="card-input">
            <label>Emergency Contact Phone Number</label>
            <input
              type="text"
              id="emPhoneNumber"
              class="card-input__input"
              placeholder="Phone Number"
              v-model="update.emergencyContactInfo.phoneNumber"
            />
          </div>

          <br />
          <h3>Confirm</h3>
          <div class="card-input">
            <!-- Password -->
            <label for="password"
              ><span style="color: red">**</span>Current Password</label
            >
            <input
              type="password"
              id="password"
              class="card-input__input"
              placeholder="Current Password"
              v-model="update.password"
              required
            />
          </div>
          <div class="card-input">
            <!-- Password -->
            <label for="password">New Password (Optional)</label>
            <input
              type="password"
              id="password"
              class="card-input__input"
              placeholder="New Password"
              v-model="update.newPassword"
            />
          </div>

          <center>
            <button class="card-form__button" @click="this.updateMember">
              Save {{ this.member.firstName }}'s Info
            </button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import MemberNav from "../components/MemberNav.vue";

export default {
  components: { MemberNav },
  data() {
    return {
      member: {},
      update: {
        email: "",
        password: "",
        newPassword: "",
        address: "",
        city: "",
        state: "",
        zipCode: "",
        phoneNumber: "",
        emergencyContactInfo: {
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
          phoneNumber: "",
        },
      },
    };
  },
  methods: {
    //needs try catch validation with
    async updateMember() {
      let url =
        process.env.VUE_APP_DB_URL + "membership/updateMemberInformation";
      axios
        .post(
          url,
          {
            memberID: this.member._id,
            accountInfo: {
              email: this.update.email,
              password: this.update.password,
              newPassword: this.update.newPassword,
              address: this.update.address,
              city: this.update.city,
              state: this.update.state,
              zipCode: this.update.zipCode,
              phoneNumber: this.update.phoneNumber,
              emergencyContactInfo: {
                firstName: this.update.emergencyContactInfo.firstName,
                lastName: this.update.emergencyContactInfo.lastName,
                email: this.update.emergencyContactInfo.email,
                address: this.update.emergencyContactInfo.address,
                city: this.update.emergencyContactInfo.city,
                state: this.update.emergencyContactInfo.state,
                zipCode: this.update.emergencyContactInfo.zipCode,
                phoneNumber: this.update.emergencyContactInfo.phoneNumber,
              },
            },
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then(function () {
          swal("Success", "User Info Updated", "success");
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
      this.$router.push("/MemberDashboard");
    },
    async getMemberDetails() {
      let url =
        process.env.VUE_APP_DB_URL +
        "membership/getMember?memberID=" +
        this.member._id;
      await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.member = response.data.member;
          this.update.email = this.member.email;
          this.update.address = this.member.address;
          this.update.state = this.member.state;
          this.update.city = this.member.city;
          this.update.zipCode = this.member.zipCode;
          this.update.phoneNumber = this.member.phoneNumber;
          this.update.emergencyContactInfo = this.member.emergencyContactInfo;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error, "error");
        });
    },
  },

  created() {
    let token = localStorage.getItem("jwt");
    this.member = VueJwtDecode.decode(token);
    this.getMemberDetails();
  },
};
</script>
<style scoped></style>
