<template>
  <div>
    <div class="card-input" v-for="account in this.accounts" :key="account._id">
      <AccountCard :account="account"></AccountCard>
    </div>
  </div>
</template>
<script>
import AccountCard from "../components/AccountCard.vue";
export default {
  components: {
    AccountCard,
  },
  props: {
    accounts: Array,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  computed: {
    getRegisterURL() {
      return process.env.VUE_APP_CLIENT_URL + "register";
    },
    getForgotPasswordURL() {
      return process.env.VUE_APP_CLIENT_URL + "forgotpassword";
    },
    getSubscriptionRookieCheckoutLink() {
      return process.env.VUE_APP_CLIENT_URL + "externalcheckoutsecondary";
    },
    hasMultipleAccounts() {
      return this.multipleAccounts;
    },
  },
};
</script>
<style>
.card-form {
  /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom,*/
  /*from(rgba(0,0,1,1)), to(rgba(0,0,0,.9)));*/
  /*box-shadow: 0 30px 40px rgba(0,0,0,.9);*/
}

.gradient-button {
  background: rgb(54, 49, 102);
  background: linear-gradient(
    90deg,
    rgba(54, 49, 102, 1) 8%,
    rgba(25, 212, 18, 1) 78%
  );
}
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 100%;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 20px 15px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}
</style>
