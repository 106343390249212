<template>
  <div class="wrapper container" id="app" style="min-width: 100%">
    <ClientLayout :user="user"></ClientLayout>
    <section
      class="container"
      v-bind:style="{ marginLeft: isMobile() ? '21px' : '' }"
    >
      <div class="card-form container mt-5" style="min-width: 100%">
        <div
          class="card-form__inner justify-content-center"
          style="text-align: center"
        >
          <center>
            <img
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
              style="max-width: 50%; max-height: 30%"
            />
            <h1>{{ team.teamName }}</h1>
          </center>
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="card-input">
                <label for="firstName" class="card-input__label"
                  >Emergency Contact First Name</label
                >
                <input
                  type="text"
                  id="firstName"
                  class="card-input__input"
                  placeholder="First Name"
                  v-model="team.teamCoachFirstName"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="card-input">
                <label for="lastName" class="card-input__label"
                  >Emergency Contact Last Name</label
                >
                <input
                  type="text"
                  id="lastName"
                  class="card-input__input"
                  placeholder="Last Name"
                  v-model="team.teamCoachLastName"
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="card-input">
                <label>Emergency Contact Phone Number</label>
                <input
                  type="number"
                  id="phoneNumber"
                  class="card-input__input"
                  placeholder="Phone Number"
                  v-model="team.teamCoachPhoneNumber"
                />
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="card-input">
                <label for="email">Emergency Contact Email</label>
                <input
                  type="email"
                  id="email"
                  class="card-input__input"
                  placeholder="Email"
                  v-model="team.teamCoachEmail"
                />
              </div>
            </div>
          </div>

          <!--Start of tri-column info-->
          <div class="row">
            <!--Start of col 1-->
            <div class="c">
              <li
                style="list-style-type: none"
                v-for="index in getTriCol.length"
                :key="index"
              >
                <CustomFieldDisplay
                  v-if="
                    getTriCol[index - 1].fieldType != 'textLong' &&
                    (index + getTextLongNum) % 3 == 0
                  "
                  :field="getTriCol[index - 1]"
                  @change="formatData($event, value2)"
                ></CustomFieldDisplay>
              </li>
            </div>
            <!--Start of col 2-->
            <div class="col">
              <li
                style="list-style-type: none"
                v-for="index in getTriCol.length"
                :key="index"
              >
                <CustomFieldDisplay
                  v-if="
                    getTriCol[index - 1].fieldType != 'textLong' &&
                    (index + getTextLongNum) % 3 == 1
                  "
                  :field="getTriCol[index - 1]"
                  @change="formatData($event, value2)"
                ></CustomFieldDisplay>
              </li>
            </div>

            <!--Start of col 3-->
            <div class="col">
              <li
                style="list-style-type: none"
                v-for="index in getTriCol.length"
                :key="index"
              >
                <CustomFieldDisplay
                  v-if="
                    getTriCol[index - 1].fieldType != 'textLong' &&
                    (index + getTextLongNum) % 3 == 2
                  "
                  :field="getTriCol[index - 1]"
                  @change="formatData($event, index)"
                ></CustomFieldDisplay>
              </li>
            </div>
          </div>
          <!--Start of text long info-->
          <li
            style="list-style-type: none"
            v-for="index in getTriCol.length"
            :key="index + getTriCol.length"
          >
            <CustomFieldDisplay
              v-if="getTriCol[index - 1].fieldType == 'textLong'"
              :field="getTriCol[index - 1]"
            ></CustomFieldDisplay>
          </li>
          <br />
          <center>
            <h3 class="card-title mt-5">Active Links</h3>
            <br />
            <div class="row justify-content-center">
              <a
                class="card2 col-md-3 col-sm-6"
                :href="getCampSpecificURL"
                target="_blank"
              >
                <h5>Link to Team-Specific Camper Signup</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>

              <a
                class="card2 col-md-3 col-sm-6"
                :href="getCampSpecificRosterURL"
                target="_blank"
              >
                <h5>Link to Coach Roster</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>
            </div>
          </center>
          <br />
          <div class="row justify-content-center" style="margin-top: 30px">
            <div class="col">
              <center><h1>Campers</h1></center>
            </div>
          </div>

          <form class="d-flex justify-content-center">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              style="
                margin-bottom: 10px;
                min-width: 300px;
                max-width: 500px;
                margin-left: 15px;
              "
              v-model="searchQuery"
            />
          </form>

          <div
            class="row justify-content-center"
            v-for="camper in resultQuery"
            :key="camper._id"
          >
            <CamperCard
              :firstName="camper.firstName"
              :lastName="camper.lastName"
              :isTeamCamp="true"
              :camperAddOns="camper.customAddOnValues"
              style="margin-bottom: 10px"
              :camperId="camper._id"
              :tShirtSize="camper.tShirtSize"
              :tShirtReceived="camper.tShirtReceived"
              :isCheckedIn="camper.isCheckedIn"
              :camperProfileImage="camper.camperProfileImage"
            ></CamperCard>
          </div>
          <div
            v-bind:class="{ row: true, 'justify-content-center': isMobile() }"
          >
            <div class="col-md-4 col-sm-6">
              <center>
                <button
                  class="card-form__button"
                  type="submit"
                  style="margin-top: 10px"
                  @click="backToTeamList"
                >
                  Back to Teams
                </button>
              </center>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!--import swal from "sweetalert";
  import axios from "axios";--->
</template>
<script>
import CamperCard from "../components/Campers/CamperCard.vue";
import CustomFieldDisplay from "../components/CustomFieldDisplay.vue";
import swal from "sweetalert";
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import ClientLayout from "../components/ClientLayout.vue";

export default {
  components: { ClientLayout, CamperCard, CustomFieldDisplay },
  data() {
    return {
      user: {},
      team: {},
      numFields: 0,
      test: "",
      searchQuery: "",
    };
  },

  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    formatData() {
      console.log("formatting");
      for (let i = 0; i < this.data.customFieldValues.length; i++) {
        console.log("test");
        if (this.data.customFieldValues[i].value2) {
          this.data.customFieldValues[i].fieldValue =
            this.data.customFieldValues[i].fieldValue +
            "'" +
            this.data.customFieldValues[i].value2;
          delete this.data.customFieldValues[i].value2;
        }
      }
    },
    noteChange(x, y) {
      console.log(x);
      console.log(y);
      this.data.camperNotes[y - 1] = x;
    },
    saveCamperInfo() {
      console.log("saving");
      let campId = this.$route.params.id;
      swal({
        title: "Confirm",
        text: "Confirm save of camper information",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willSave) => {
        if (willSave) {
          console.log("here");
          this.formatData();
          let url = process.env.VUE_APP_DB_URL + "teamCamp/updateCamperInfo";
          axios
            .post(
              url,
              {
                id: this.user._id,
                camp: campId,

                data: this.data,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
              this.newCamp = this.savedData;
            });

          swal("Success", "Camper Info Updated", "success");
        }
      });
    },
    addNotes() {
      console.log("here");
      let temp = { title: "", notes: "" };
      this.data.camperNotes.push(temp);
      this.numFields++;
    },
    async refundAndDelete() {
      let campId = this.$route.params.id;
      swal({
        title: "Confirm Details",
        text: "Confirm deletion and refund of camper",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let url =
            process.env.VUE_APP_DB_URL + "teamCamp/deleteAndRefundCamper";
          axios
            .post(
              url,
              {
                id: this.user._id,
                camp: campId,
                camper: this.data._id,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .then((response) => {
              console.log("here");
              console.log(response);
              swal(
                "Success",
                "Camper refunded and deleted successfully",
                "success"
              );
              this.$router.push("/Dashboard/CheckIn/" + campId);
            })
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
              this.newCamp = this.savedData;
              this.$router.push("/Dashboard/CheckIn/" + campId);
            });
        }
      });
    },
    backToTeamList() {
      let id = this.$route.params.campId;
      this.$router.push("/Dashboard/TeamList/" + id);
    },
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    getTeamData() {
      let campId = this.$route.params.campId;
      let teamId = this.$route.params.teamId;
      let url =
        process.env.VUE_APP_DB_URL +
        "teamCamp/getTeamDataForCoach?coachId=" +
        this.user._id +
        "&teamCampId=" +
        campId +
        "&teamId=" +
        teamId;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          console.log(response);
          this.team = response.data;
          if (!this.data.camperNotes) {
            this.data.camperNotes = [];
            this.numFields = 0;
          } else {
            this.numFields = this.data.camperNotes.length;
          }
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
  },
  created() {
    this.getUserDetails();
    this.getTeamData();
  },
  computed: {
    getCampSpecificURL() {
      let string =
        process.env.VUE_APP_TEAMCAMP_URL +
        "register/camper/" +
        this.$route.params.campId +
        "/" +
        this.$route.params.teamId;
      return string;
    },
    getCampSpecificRosterURL() {
      let string =
        process.env.VUE_APP_TEAMCAMP_URL +
        "Roster/" +
        this.$route.params.campId +
        "/" +
        this.$route.params.teamId;
      return string;
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.team.campers.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every(
              (v) =>
                item.firstName.toLowerCase().includes(v) ||
                item.lastName.toLowerCase().includes(v)
            );
        });
      } else {
        return this.team.campers;
      }
    },
    getTriCol() {
      let temp = [];
      for (let x = 0; x < this.team.customFieldValues.length; x++) {
        temp.push(this.team.customFieldValues[x]);
      }
      return temp;
    },
    getTextLongNum() {
      let temp = 0;
      for (let x = 0; x < this.team.customFieldValues.length; x++) {
        if (this.team.customFieldValues[x].fieldType == "textLong")
          temp = temp + 1;
      }
      return temp;
    },
  },
};
</script>
