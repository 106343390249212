<template>
  <div class="container" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          :src="this.campLogo"
          class="card-img-top rounded-corners"
          id="logoTop"
        />
        <div class="card-body">
          <center>
            <h1 class="card-title">Success!</h1>
            <br />
            <h4>You're signed up for {{ this.campName }}</h4>
            <br />
            <button
              class="card-form__button rounded-pill"
              @click="nextSteps"
              style="max-width: 45%"
            >
              Next Steps
            </button>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    campLogo: String,
    campName: String,
  },
  data() {
    return {};
  },
  methods: {
    nextSteps() {
      this.$emit("nextSteps");
    },
  },
};
</script>
