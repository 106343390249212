<script setup></script>

<template>
  <div>
    <!--    IMPORTANT!!! unused to change the css of the nth child after this element, specifically for photo alignment-->
    <article
      v-if="photoRightAlign"
      class="postcard dark red"
      style="display: none"
    >
      <a class="postcard__img_link" href="#">
        <img
          class="postcard__img"
          src="https://picsum.photos/501/500"
          alt="Image Title"
        />
      </a>
      <div class="postcard__text">
        <h1 class="postcard__title red"><a href="#">Podcast Title</a></h1>
        <div class="postcard__subtitle small">
          <time datetime="2020-05-25 12:00:00">
            <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
          </time>
        </div>
        <div class="postcard__bar"></div>
        <div class="postcard__preview-txt"></div>
        <ul class="postcard__tagbox">
          <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
          <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
          <li class="tag__item play red">
            <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
          </li>
        </ul>
      </div>
    </article>
    <article class="postcard dark blue" :style="getCardColor">
      <a class="postcard__img_link" href="#">
        <img class="postcard__img" :src="photo" alt="Image Title" />
      </a>
      <div class="postcard__text">
        <h1
          class="postcard__title m-1"
          :style="{
            color: photoRightAlign ? secondaryColor : primaryColor,
          }"
        >
          <a href="#" style="text-decoration: none">{{ heading }}</a>
        </h1>

        <div
          class="postcard__bar m-1"
          :style="{
            color: secondaryColor,
            backgroundColor: photoRightAlign ? primaryColor : secondaryColor,
          }"
        ></div>
        <div
          class="postcard__preview-txt m-1"
          v-html="description"
          style="color: white"
        >
          <!--          {{ description }}-->
        </div>
        <ul class="postcard__tagbox" v-if="photoRightAlign">
          <li class="tag__item">Dedicated</li>
          <li class="tag__item">Driven</li>
          <li class="tag__item">Unbreakable Focus</li>
        </ul>
        <ul class="postcard__tagbox" v-else>
          <li class="tag__item">Unstoppable</li>
          <li class="tag__item">Groundbreaking</li>
          <li class="tag__item">Team Leader</li>
        </ul>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
    heading: String,
    description: String,
    photo: String,
    photoRightAlign: Boolean,
  },

  computed: {
    getCardColor() {
      return this.photoRightAlign
        ? `background-image: linear-gradient(30deg, ${this.primaryColor}, transparent 50%);`
        : `background-image: linear-gradient(-30deg, ${this.primaryColor}, transparent 50%);`;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Baloo+2&display=swap");
$main-green: #79dd09 !default;
$main-green-rgb-015: rgba(121, 221, 9, 0.1) !default;
$main-yellow: #bdbb49 !default;
$main-yellow-rgb-015: rgba(189, 187, 73, 0.1) !default;
$main-red: #bd150b !default;
$main-red-rgb-015: rgba(189, 21, 11, 0.1) !default;
$main-blue: #0076bd !default;
$main-blue-rgb-015: rgba(0, 118, 189, 0.1) !default;

/* This pen */
body {
  font-family: "Baloo 2", cursive;
  font-size: 16px;
  text-rendering: optimizeLegibility;
  font-weight: initial;
}

.dark {
  background: #110f16;
}

.light {
}

a,
a:hover {
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

#pageHeaderTitle {
  margin: 2rem 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 2.5rem;
}

/* Cards */
.postcard {
  flex-wrap: wrap;
  display: flex;

  box-shadow: 0 4px 21px -12px rgba(0, 0, 0, 0.66);
  border-radius: 10px;
  margin: 0 0 2rem 0;
  overflow: hidden;
  position: relative;

  &.dark {
    background-color: #18151f;
  }
  &.light {
  }

  .t-dark {
    color: #18151f;
  }

  a {
    color: inherit;
  }

  h1,
  .h1 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .small {
    font-size: 80%;
  }

  .postcard__title {
    font-size: 1.75rem;
  }

  .postcard__img {
    max-height: 180px;
    width: 100%;
    object-fit: cover;
    position: relative;
  }

  .postcard__img_link {
    display: contents;
  }

  .postcard__bar {
    width: 50px;
    height: 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: #424242;
    transition: width 0.2s ease;
  }

  .postcard__text {
    padding: 1.5rem;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .postcard__preview-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    height: 100%;
  }

  .postcard__tagbox {
    display: flex;
    flex-flow: row wrap;
    font-size: 14px;
    margin: 20px 0 0 0;
    padding: 0;
    justify-content: center;

    .tag__item {
      display: inline-block;
      background: rgba(83, 83, 83, 0.4);
      border-radius: 3px;
      padding: 2.5px 10px;
      margin: 0 5px 5px 0;
      cursor: default;
      user-select: none;
      transition: background-color 0.3s;

      &:hover {
        background: rgba(83, 83, 83, 0.8);
      }
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(-70deg, #424242, transparent 50%);
    opacity: 1;
    border-radius: 10px;
  }

  &:hover .postcard__bar {
    width: 100px;
  }
}

@media screen and (min-width: 769px) {
  .postcard {
    flex-wrap: inherit;

    .postcard__title {
      font-size: 2rem;
    }

    .postcard__tagbox {
      justify-content: start;
    }

    .postcard__img {
      max-width: 300px;
      max-height: 100%;
      transition: transform 0.3s ease;
    }

    .postcard__text {
      padding: 3rem;
      width: 100%;
    }

    .media.postcard__text:before {
      content: "";
      position: absolute;
      display: block;
      background: #18151f;
      top: -20%;
      height: 130%;
      width: 55px;
    }

    &:hover .postcard__img {
      transform: scale(1.1);
    }

    &:nth-child(2n + 1) {
      flex-direction: row;
    }

    &:nth-child(2n + 0) {
      flex-direction: row-reverse;
    }

    &:nth-child(2n + 1) .postcard__text::before {
      left: -12px !important;
      transform: rotate(4deg);
    }

    &:nth-child(2n + 0) .postcard__text::before {
      right: -12px !important;
      transform: rotate(-4deg);
    }
  }
}
@media screen and (min-width: 1024px) {
  .postcard__text {
    padding: 2rem 3.5rem;
  }

  .postcard__text:before {
    content: "";
    position: absolute;
    display: block;

    top: -20%;
    height: 130%;
    width: 55px;
  }

  .postcard.dark {
    .postcard__text:before {
      background: #18151f;
    }
  }
  .postcard.light {
    .postcard__text:before {
    }
  }
}

/* COLORS */
.postcard .postcard__tagbox .green.play:hover {
  background: $main-green;
  color: black;
}
.green .postcard__title:hover {
  color: $main-green;
}
.green .postcard__bar {
  background-color: $main-green;
}
.green::before {
  background-image: linear-gradient(
    -30deg,
    $main-green-rgb-015,
    transparent 50%
  );
}
.green:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    $main-green-rgb-015,
    transparent 50%
  );
}

.postcard .postcard__tagbox .blue.play:hover {
  background: $main-blue;
}
.blue .postcard__title:hover {
  color: $main-blue;
}
.blue .postcard__bar {
  background-color: $main-blue;
}
.blue::before {
  background-image: linear-gradient(
    -30deg,
    $main-blue-rgb-015,
    transparent 50%
  );
}
.blue:nth-child(2n)::before {
  background-image: linear-gradient(30deg, $main-blue-rgb-015, transparent 50%);
}

.postcard .postcard__tagbox .red.play:hover {
  background: $main-red;
}
.red .postcard__title:hover {
  color: $main-red;
}
.red .postcard__bar {
  background-color: $main-red;
}
.red::before {
  background-image: linear-gradient(-30deg, $main-red-rgb-015, transparent 50%);
}
.red:nth-child(2n)::before {
  background-image: linear-gradient(30deg, $main-red-rgb-015, transparent 50%);
}

.postcard .postcard__tagbox .yellow.play:hover {
  background: $main-yellow;
  color: black;
}
.yellow .postcard__title:hover {
  color: $main-yellow;
}
.yellow .postcard__bar {
  background-color: $main-yellow;
}
.yellow::before {
  background-image: linear-gradient(
    -30deg,
    $main-yellow-rgb-015,
    transparent 50%
  );
}
.yellow:nth-child(2n)::before {
  background-image: linear-gradient(
    30deg,
    $main-yellow-rgb-015,
    transparent 50%
  );
}

@media screen and (min-width: 769px) {
  .green::before {
    background-image: linear-gradient(
      -80deg,
      $main-green-rgb-015,
      transparent 50%
    );
  }
  .green:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      $main-green-rgb-015,
      transparent 50%
    );
  }

  .blue::before {
    background-image: linear-gradient(
      -80deg,
      $main-blue-rgb-015,
      transparent 50%
    );
  }
  .blue:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      $main-blue-rgb-015,
      transparent 50%
    );
  }

  .red::before {
    background-image: linear-gradient(
      -80deg,
      $main-red-rgb-015,
      transparent 50%
    );
  }
  .red:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      $main-red-rgb-015,
      transparent 50%
    );
  }

  .yellow::before {
    background-image: linear-gradient(
      -80deg,
      $main-yellow-rgb-015,
      transparent 50%
    );
  }
  .yellow:nth-child(2n)::before {
    background-image: linear-gradient(
      80deg,
      $main-yellow-rgb-015,
      transparent 50%
    );
  }
}
</style>
