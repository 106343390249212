<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          :src="this.campLogo"
          class="card-img-top rounded-corners mb-1"
          id="logoTop"
        />
        <br />
        <br />
        <img
          src="../../../../public/checkRoutingExample.png"
          style="max-width: 100%"
          class="center text-center"
        />
        <label class="form-label" for="bankDetailsForm" style="margin-top: 5px"
          >Routing Number</label
        >
        <input
          v-model="newAccount.routingNumber"
          required
          id="form6Example1"
          class="form-control"
          type="number"
        />
        <br />
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <label
              class="form-label"
              for="bankDetailsForm"
              style="margin-top: 5px"
              >Account Number</label
            >
            <input
              v-model="newAccount.accountNumber"
              required
              id="form6Example1"
              class="form-control"
              type="number"
            />
          </div>
          <div class="col-md-6 col-sm-6">
            <label
              class="form-label"
              for="bankDetailsForm"
              style="margin-top: 5px"
              >Confirm Account Number</label
            >
            <input
              v-model="newAccount.accountNumberConfirmation"
              required
              id="form6Example1"
              class="form-control"
              type="number"
            />
          </div>
        </div>

        <br />
        <label class="form-label" for="bankDetailsForm" style="margin-top: 5px"
          >Name On Account
          <i
            class="fa-solid fa-circle-info"
            style="color: #45487a; cursor: pointer"
            @click="showAccountNameExplanation"
          ></i
        ></label>
        <input
          v-model="newAccount.nameOnAccount"
          required
          id="form6Example1"
          class="form-control"
          type="text"
        />
        <br />
        <label class="form-label" for="bankDetailsForm" style="margin-top: 5px"
          >Account Type</label
        >
        <br />
        <div class="row justify-content-center">
          <div
            :class="{
              'col-md-6': true,
              'col-sm-6': true,
              'text-end': !isMobile,
            }"
          >
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="checkingRadio"
                value="CHECKING"
                v-model="newAccount.type"
              />
              <label for="checkingRadio">Checking</label>
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="savingRadio"
                value="SAVINGS"
                checked
                v-model="newAccount.type"
              />
              <label for="savingRadio">Savings</label>
            </div>
          </div>
        </div>
        <hr />
        <div class="card-input mt-3">
          <label class="card-input__label">Phone Number</label>
          <input
            type="text"
            id="phoneNumber"
            class="card-input__input"
            v-model="newAccount.phoneNumber"
          />
        </div>
        <div class="card-input">
          <label class="card-input__label">Email</label>
          <input
            type="email"
            id="email"
            class="card-input__input"
            v-model="newAccount.email"
          />
        </div>

        <div class="card-input">
          <label for="address" class="card-input__label"
            >Address
            <span
              v-if="!addressVerified"
              class="text-muted"
              style="font-size: 12px"
              >Unverified</span
            >
            <span v-else class="text-muted" style="font-size: 12px"
              ><i class="fa-solid fa-circle-check"></i> Verified</span
            ></label
          >
          <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Address"
            class="card-input__input"
            v-on:placechanged="getAddressData"
            v-on:change="setAddressData"
            :country="['us']"
            v-model="billingAddress.address"
          >
          </vue-google-autocomplete>
        </div>
        <!--        <div class="card-input">-->
        <!--          <label class="card-input__label">Address</label>-->
        <!--          <input-->
        <!--            type="text"-->
        <!--            id="address"-->
        <!--            class="card-input__input"-->
        <!--            v-model="billingAddress.address"-->
        <!--          />-->
        <!--        </div>-->
        <div class="card-input">
          <label class="card-input__label">City</label>
          <input
            type="text"
            id="address"
            class="card-input__input"
            v-model="billingAddress.city"
          />
        </div>

        <div class="card-form__row">
          <div class="card-form__col">
            <div class="card-form__group">
              <label for="cardMonth" class="card-input__label">State</label>
              <select
                class="card-input__input -select"
                id="cardMonth"
                v-model="billingAddress.state"
              >
                <option value="" disabled selected>State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div class="card-form__col">
            <div class="card-input">
              <label for="cardCvv">Zip Code</label>
              <input
                type="text"
                class="card-input__input"
                v-model="billingAddress.zipCode"
              />
            </div>
          </div>
        </div>
        <div class="row" v-if="!isMobile">
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              :style="{
                maxHeight: isMobile ? '60%' : '',
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back()"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>

          <div class="col-md-9 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              :style="{
                backgroundColor: primaryColor,
                color: 'white',
              }"
              @click="submitPayment()"
            >
              Order Summary
            </button>
          </div>
        </div>
        <div v-else>
          <button
            class="card-form__button rounded-pill"
            :style="{
              backgroundColor: primaryColor,
              color: 'white',
            }"
            @click="submitPayment()"
          >
            Order Summary
          </button>
          <br />
          <br />
          <br />
          <button
            :class="{
              'card-form__button': true,
              'w-50': isMobile,
              'rounded-pill': true,
            }"
            :style="{
              backgroundColor: secondaryColor,
              color: 'white',
            }"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    campLogo: String,
    newAccount: Object,
    billingAddress: Object,
    paymentType: String,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
  },
  data() {
    return {
      addressVerified: false,
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    setAddressData(e) {
      if (!this.billingAddress.address) {
        this.billingAddress.address = e.toString();
      }
    },
    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData);
      console.log(id);
      console.log(addressData);
      this.billingAddress.address =
        addressData.street_number + " " + addressData.route;
      this.billingAddress.city = addressData.locality;
      this.billingAddress.state = addressData.administrative_area_level_1;
      this.billingAddress.zipCode = addressData.postal_code;
      this.addressVerified = true;
    },
    showAccountNameExplanation() {
      swal({
        title: "Account Name",
        text: "This is the primary owner's name on the account. Please make it match as closely as possible.",
        icon: "info",
      });
    },
    submitPayment() {
      const validatedPayment = this.validateNewAccount(this.newAccount);
      const validatedPersonal = this.validatePersonalInformation();
      if (validatedPayment && validatedPersonal) {
        const billingInformation = {
          newAccount: this.newAccount,
          billingAddress: this.billingAddress,
          paymentType: this.paymentType,
        };
        this.$emit("proceed", billingInformation);
      }
      return;
    },
    validateNewAccount(newAccount) {
      if (
        !newAccount.routingNumber ||
        !newAccount.accountNumber ||
        !newAccount.accountNumberConfirmation ||
        !newAccount.nameOnAccount ||
        !newAccount.type
      ) {
        swal({
          title: "Missing Fields",
          text: "Please fill out all fields",
          icon: "error",
        });
        return false;
      }
      if (
        newAccount.routingNumber.length < 9 ||
        newAccount.accountNumber.length < 5
      ) {
        swal({
          title: "Invalid Fields",
          text: "Please enter a valid routing number and account number",
          icon: "error",
        });
        return false;
      }
      if (newAccount.accountNumber !== newAccount.accountNumberConfirmation) {
        swal({
          title: "Account Number Mismatch",
          text: "Account Numbers Do Not Match",
          icon: "error",
        });
        return false;
      }
      return true;
    },
    validatePersonalInformation() {
      if (
        !this.newAccount.phoneNumber ||
        !this.newAccount.email ||
        !this.billingAddress.address ||
        !this.billingAddress.city ||
        !this.billingAddress.state ||
        !this.billingAddress.zipCode
      ) {
        swal({
          title: "Missing Fields",
          text: "Please fill out all fields",
          icon: "error",
        });
        return false;
      }
      if (
        !this.newAccount.email.includes("@") ||
        !this.newAccount.email.includes(".")
      ) {
        swal({
          title: "Invalid Email",
          text: "Please enter a valid email address",
          icon: "error",
        });
        return false;
      }
      return true;
    },
  },
  created() {},
  computed: {},
};
</script>

<style scoped>
img.rounded-corners {
  border-radius: 30px;
}
</style>
