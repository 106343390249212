<template>
  <div class="mt-5">
    <div class="scroll-banner" style="margin-left: -12px">
      <div class="scroll-text">
        <div class="row" style="width: 1500%; max-height: 350px">
          <div
            class="col"
            v-for="setIndex in 12"
            :key="setIndex"
            style="max-width: 4000px; max-height: 350px"
          >
            <div class="row" style="max-width: 4000px; max-height: 350px">
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="Primary Sport"
                :value="athleticInfo.primarySport"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="State of Play"
                :value="state"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="Height & Weight"
                :value="getHeightAndWeightText"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                v-if="academicInfo.gpa"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="GPA"
                :value="academicInfo.gpa"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                v-if="academicInfo.act"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="ACT"
                :value="academicInfo.act"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                v-if="academicInfo.sat"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                title="SAT"
                :value="academicInfo.sat"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
              <MetricBannerCard
                style="min-width: 350px; max-width: 350px"
                v-for="metric in athleticInfo.additionalMetrics"
                :key="setIndex + '-' + metric._id"
                :primaryColor="primaryColor"
                :secondaryColor="secondaryColor"
                :title="metric.title"
                :value="metric.value"
                @goToMetricsPage="goToMetricsPage()"
              ></MetricBannerCard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MetricBannerCard from "./MetricBannerCard.vue";
export default {
  components: {
    MetricBannerCard,
  },
  props: {
    primaryColor: String,
    secondaryColor: String,
    fontColor: String,
    isMobile: Boolean,
    state: String,
    academicInfo: Object,
    athleticInfo: Object,
  },

  methods: {
    goToMetricsPage() {
      console.log("Hit on banner");
      this.$emit("goToMetricsPage");
    },
  },

  computed: {
    getMetricCardStyle() {
      console.log("Getting metric card style");
      // return `background: linear-gradient(0.5turn, ${this.primaryColor}, ${this.secondaryColor}); min-width: 250px; min-height: 175px; color: white`;
      return `background: linear-gradient(0.5turn, ${this.primaryColor}, ${this.primaryColor}); min-width: 250px; min-height: 175px; color: ${this.fontColor};`;
    },

    getHeightAndWeightText() {
      return `${this.athleticInfo.height} & ${this.athleticInfo.weight}lbs`;
    },
  },
};
</script>

<style scoped>
.scroll-banner {
  width: 100%;

  overflow: hidden;
}

.scroll-text {
  display: inline-block;

  white-space: nowrap;

  min-width: 200%;

  animation: scrollText 1000s linear infinite;
}

.metric-card {
  opacity: 0.7;
  max-width: 300px;
  transition: opacity 0.3s ease;
}

.metric-card:hover {
  opacity: 1;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-2000%);
  }
}
</style>
