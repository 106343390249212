<template>
  <div class="fullContainer">
    <Particles :color="this.secondaryColor" />
    <div v-if="this.isLoading">
      <LoadingScreen
        :primaryColor="primaryColor"
        :secondaryColor="secondaryColor"
      ></LoadingScreen>
    </div>
    <div v-else class="fade-in">
      <Navbar
        :primaryColor="this.primaryColor"
        :secondaryColor="this.secondaryColor"
        :recruitProfile="this.recruit.recruitProfile"
        :isMobile="this.isMobile"
        @goToHome="pageToggle = 0"
        @goToMetrics="pageToggle = 1"
        @goToHighlights="pageToggle = 2"
      ></Navbar>
      <div class="centered-text" style="margin-top: 5%">
        <HomePage
          v-if="this.pageToggle === 0"
          :primaryColor="this.primaryColor"
          :secondaryColor="this.secondaryColor"
          :title="website.title"
          :homePage="website.homePage"
          :recruitProfile="this.recruit.recruitProfile"
          :recruitID="this.recruit.recruitID"
          :isMobile="this.isMobile"
          @goToHighlights="pageToggle = 2"
          @goToMetricsPage="pageToggle = 1"
        ></HomePage>

        <MetricsPage
          v-if="this.pageToggle === 1"
          :primaryColor="this.primaryColor"
          :secondaryColor="this.secondaryColor"
          :isMobile="this.isMobile"
          :recruitProfile="this.recruit.recruitProfile"
          :metricsPage="this.website.metricsPage"
        ></MetricsPage>

        <HighlightsPage
          v-else-if="this.pageToggle === 2"
          :primaryColor="this.primaryColor"
          :secondaryColor="this.secondaryColor"
          :galleryPage="this.website.galleryPage"
          :isMobile="this.isMobile"
        ></HighlightsPage>
        <Footer
          class="mt-5"
          :primaryColor="this.primaryColor"
          :secondaryColor="this.secondaryColor"
          :isMobile="this.isMobile"
          :subdomain="this.website.subdomain"
        ></Footer>
        <ContactModal
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :recruitID="this.recruit.recruitID"
          :isMobile="this.isMobile"
        ></ContactModal>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Navbar from "../../../components/TemplateComponents/CustomTemplateOne/NavigationBar.vue";
import Footer from "../../../components/TemplateComponents/CustomTemplateOne/Footer.vue";
import Particles from "../../../components/TemplateComponents/CustomTemplateOne/Particles.vue";
import HomePage from "../../../components/TemplateComponents/CustomTemplateOne/Home/HomePage.vue";
import MetricsPage from "../../../components/TemplateComponents/CustomTemplateOne/Metrics/MetricsPage.vue";

import HighlightsPage from "../../../components/TemplateComponents/CustomTemplateOne/Highlights/HighlightsPage.vue";
import LoadingScreen from "../../../components/TemplateComponents/CustomTemplateOne/LoadingScreen.vue";
import ContactModal from "../../../components/TemplateComponents/CustomTemplateOne/Contact/ContactModal.vue";
export default {
  components: {
    ContactModal,
    Navbar,
    Particles,
    HomePage,
    MetricsPage,
    HighlightsPage,
    Footer,
    LoadingScreen,
  },
  props: {
    website: {
      type: Object,
      default: () => ({
        title: "",
        homePage: {},
        galleryPage: {},
        metricsPage: {},
        primaryColor: "",
        secondaryColor: "",
      }),
    },
  },

  data() {
    return {
      html: null,
      primaryColor: "",
      secondaryColor: "",
      pageToggle: 0,
      isLoading: true,
      recruit: {
        recruitID: "",
        recruitProfile: {
          firstName: "",
          lastName: "",
          athleticInfo: {
            additionalMetrics: {},
          },
          academicInfo: {},
        },
      },
    };
  },
  methods: {
    handleScroll() {
      console.log(window.scrollY);
      if (window.scrollY < 2 && this.scrollCounter === 0) {
        this.show = true;
      } else if (window.scrollY >= 40 && this.scrollCounter === 0) {
        this.scrollCounter = 1;

        window.scrollTo(0, 0);
      }
    },

    async getRecruit() {
      let url =
        process.env.VUE_APP_DB_URL +
        "recruit/getRecruitBySubdomain?subdomain=" +
        this.website.subdomain;
      await axios.get(url).then((response) => {
        this.recruit = response.data.recruit;
      });
    },
  },

  created() {
    this.primaryColor = this.website.primaryColor;
    this.secondaryColor = this.website.secondaryColor;

    this.getRecruit();

    setTimeout(() => {
      this.isLoading = false;
    }, 3000);

    // window.addEventListener("scroll", this.handleScroll);

    function reveal() {
      var reveals = document.querySelectorAll(".reveal");

      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    }

    window.addEventListener("scroll", reveal);
  },

  computed: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },

  mounted() {
    let root = document.querySelector(":root");
    root.style.setProperty("--secondaryColor", this.secondaryColor);
    root.style.setProperty("--primaryColor", this.primaryColor);
  },
};
</script>

<style>
:root {
  --primaryColor: #363166;
  --secondaryColor: #19d412;
}
.centered-text {
  position: absolute;
  text-align: left;
  top: 12%;
  max-width: 100%;
  overflow-x: hidden;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  opacity: 1;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 7s;
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}
.active.fade-left {
  animation: fade-left 1s ease-in;
}
.active.fade-right {
  animation: fade-right 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-left {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Gradient Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 7px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: var(--primaryColor);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    var(--primaryColor),
    var(--secondaryColor),
    var(--primaryColor)
  );
}
</style>
