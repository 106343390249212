<script setup></script>

<template>
  <div>
    <nav class="navbar navbar-expand-lg bottom" :style="getFooterCSS">
      <div v-if="!isMobile">
        <a class="nav-item" href="#">
          <img
            src="../../../../../public/DashLogo.png"
            alt=""
            style="margin-left: 5px; max-height: 50px"
          />
        </a>
        <h4 style="color: white; margin-left: 15px; font-style: mokoto">
          Powered By Summer Athletes
        </h4>
      </div>

      <ul class="socialList">
        <li class="socialListItem">
          <a class="socialListLink" :href="getFacebookShareLink">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </li>
        <li class="socialListItem">
          <a class="socialListLink" :href="getTwitterShareLink" target="_blank">
            <i class="fa-brands fa-x-twitter"></i>
          </a>
        </li>
        <li class="socialListItem">
          <a
            class="socialListLink"
            href="https://www.instagram.com/accounts/login"
            target="_blank"
          >
            <i class="fa-brands fa-instagram"></i>
          </a>
        </li>
        <li class="socialListItem">
          <a
            class="socialListLink"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#ContactModal"
          >
            <i class="fa-solid fa-envelope"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
    isMobile: Boolean,
    subdomain: String,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    getFooterCSS() {
      return `background: linear-gradient(0.5turn, ${this.primaryColor}, ${this.primaryColor}); max-height: 100px; min-height: 100px; opacity: 0.8;`;
    },
    getTwitterShareLink() {
      let link = "https://twitter.com/intent/tweet?text=";
      let text =
        "Check%20out%20this%20new%20athlete%20profile!%20It's%20the%20best%20way%20to%20showcase%20your%20skills%20to%20scouts%20and%20recruiters! \n" +
        this.getQRLink;
      return link + text;
    },
    getFacebookShareLink() {
      let link = "https://www.facebook.com/sharer/sharer.php?u=";
      let text = "https://" + this.subdomain + ".summerathletes.com";
      return link + text;
    },
  },
  mounted() {
    let root = document.querySelector(":root");
    root.style.setProperty("--secondaryColor", this.secondaryColor);
  },
};
</script>

<style scoped>
:root {
  --secondaryColor: #000;
}
.socialList {
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.socialList .socialListItem {
  list-style: none;
  margin: 0 15px;
}

.socialList .socialListItem .socialListLink {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: #333;
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: 0.5s;
}

.socialList .socialListItem .socialListLink::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--secondaryColor);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.socialList .socialListItem .socialListLink:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--secondaryColor);
}

.socialList .socialListItem .socialListLink:hover {
  color: var(--secondaryColor);
  box-shadow: 0 0 5px var(--secondaryColor);
  text-shadow: 0 0 5px var(--secondaryColor);
}
</style>
