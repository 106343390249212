<template>
  <div id="app" class="mt-5 container">
    <div class="card-form">
      <div class="card-form__inner">
        <ul
          class="list-group"
          style="margin-top: 20px; background-color: lightgray"
        >
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <div>
                <b>Summer Athletes </b>
                <span
                  class="badge actionFeature"
                  style="margin-left: 7px; font-size: 15px"
                >
                  {{ this.subscription.subscriptionName }}
                </span>
              </div>
            </div>
            <div></div>
          </li>
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Billing Period - Monthly</div>
            </div>
          </li>

          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Today's Total:</div>
              <div>${{ getPrice }}</div>
            </div>
          </li>
        </ul>
        <div>
          <br />
          <PayPalSubscription
            class="mt-3"
            :planID="subscription.planID"
          ></PayPalSubscription>
          <button
            class="card-form__button rounded-pill"
            @click="submitPayment"
            :disabled="cardInfoIncomplete"
            :style="{
              backgroundColor: cardInfoIncomplete ? 'lightgray' : '',
              transition: cardInfoIncomplete ? 'none' : 'all 1s ease',
              cursor: cardInfoIncomplete ? 'not-allowed' : 'pointer',
            }"
          >
            Purchase
          </button>
          <br />
          <h5 class="text-center text-muted mt-3"><i>Cancel at anytime</i></h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import numeral from "numeral";
import swal2 from "sweetalert2";
import PayPalSubscription from "../PayPalSubscription.vue";
// import swal from "sweetalert";
export default {
  components: { PayPalSubscription },
  props: {
    isMobile: Boolean,
    subscriptionName: String,
    period: String,
    price: Number,
    monthlyPrice: Number,
    billingInfo: Object,
    isExternal: Boolean,
    trialApplied: Boolean,
    subscription: Object,
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submitPayment() {
      this.$emit("proceed");
    },

    changeBillingPeriod() {
      if (this.period !== "Month") {
        swal2
          .fire({
            title: "Are you sure?",
            text: "You will lose out on up to 30% savings by paying monthly.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, change it!",
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$emit("changeBillingPeriod");
            }
          });
      } else {
        this.$emit("changeBillingPeriod");
      }
    },
  },
  created() {},
  computed: {
    getPrice() {
      return numeral(this.subscription.monthlyPrice).format("0,0.00");
    },

    getPotentialPriceFees() {
      return numeral(this.price * 0.07).format("0,0.00");
    },

    getPotentialYearlyPrice() {
      return numeral(this.monthlyPrice * 12).format("0,0.00");
    },

    getSavingPercentage() {
      return Math.floor(100 - (this.price / (this.monthlyPrice * 12)) * 100);
    },

    cardInfoIncomplete() {
      if (this.billingInfo.cardNumber == "") {
        return true;
      }
      if (this.billingInfo.cardMonth == "") {
        return true;
      }
      if (this.billingInfo.cardYear == "") {
        return true;
      }
      if (this.billingInfo.cardCvv == "") {
        return true;
      }
      if (this.billingInfo.firstName == "") {
        return true;
      }
      if (this.billingInfo.lastName == "") {
        return true;
      }
      if (this.billingInfo.zipCode == "") {
        return true;
      }
      if (this.billingInfo.email == "") {
        return true;
      }

      return false;
    },
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.actionFeature {
  background-color: #a39ed0;
}

.link {
  color: #0d6efd;
  text-decoration: underline;
}

.link:hover {
  opacity: 0.8;
}
</style>
