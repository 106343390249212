var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container justify-content-center",attrs:{"id":"app"}},[_c('Header',{attrs:{"title":this.websiteInfo.title,"primaryColor":_vm.primaryColor}}),_c('div',{staticClass:"container mt-5"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Active Links")]),_c('div',{staticClass:"d-flex row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-sm-6"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"form-control me-2",staticStyle:{"margin-bottom":"10px","min-width":"80%"},attrs:{"type":"search","placeholder":"Search","aria-label":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":function($event){if($event.target.composing)return;_vm.searchQuery=$event.target.value}}})]),_c('div',{staticClass:"col-md-3 col-sm-6"},[_c('multiselect',{style:(_vm.primaryColor),attrs:{"placeholder":"Type of event","label":"name","track-by":"code","options":_vm.options,"multiple":true},model:{value:(_vm.searchValues),callback:function ($$v) {_vm.searchValues=$$v},expression:"searchValues"}})],1),_c('div',{staticClass:"col-md-3 col-sm-6 text-start"},[_c('div',{staticClass:"card",staticStyle:{"min-height":"40px"}},[_c('VueDatePicker',{attrs:{"color":this.primaryColor,"max-date":this.getMaxDate,"min-date":this.getMinDate,"id":"datePicker"},model:{value:(_vm.chosenDate),callback:function ($$v) {_vm.chosenDate=$$v},expression:"chosenDate"}})],1)])]),_c('div',{staticClass:"row mt-3 justify-content-center"},[_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.resultQuery),function(event,index){return _c('ul',{key:event.id,staticClass:"list-group"},[(index % 3 == 0)?_c('EventSummaryCard',{attrs:{"date":event.date,"price":event.price,"title":event.name,"type":event.type,"logo":event.logo,"id":event.id,"primaryColor":_vm.primaryColor,"secondaryColor":_vm.secondaryColor}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},[(this.events.length == 0)?_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(" There are no links currently posted ")]):_vm._e(),_vm._l((_vm.resultQuery),function(event,index){return _c('ul',{key:event.id,staticClass:"list-group"},[(index % 3 == 1)?_c('EventSummaryCard',{attrs:{"date":event.date,"price":event.price,"title":event.name,"type":event.type,"logo":event.logo,"id":event.id,"primaryColor":_vm.primaryColor,"secondaryColor":_vm.secondaryColor}}):_vm._e()],1)})],2),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
          width: _vm.isMobile() ? '99%' : '',
          textAlign: _vm.isMobile() ? 'center' : '',
        })},_vm._l((_vm.resultQuery),function(event,index){return _c('ul',{key:event.id,staticClass:"list-group"},[(index % 3 == 2)?_c('EventSummaryCard',{attrs:{"date":event.date,"price":event.price,"title":event.name,"type":event.type,"logo":event.logo,"id":event.id,"primaryColor":_vm.primaryColor,"secondaryColor":_vm.secondaryColor}}):_vm._e()],1)}),0)])]),_c('Footer',{attrs:{"primaryColor":this.primaryColor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }