<template>
  <div class="modal fade" id="ShowVideoModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">My Highlight Video</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <video
            controls
            :src="video"
            alt=""
            style="max-height: 1100px; max-width: 770px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowVideoModal",
  props: {
    video: String,
  },
};
</script>

<style scoped></style>
