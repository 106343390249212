import { render, staticRenderFns } from "./ShowPhotoModal.vue?vue&type=template&id=33cb1ee1&scoped=true"
import script from "./ShowPhotoModal.vue?vue&type=script&lang=js"
export * from "./ShowPhotoModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33cb1ee1",
  null
  
)

export default component.exports