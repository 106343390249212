<template>
  <div
    class="information-card p-4 shadow-sm"
    @click="buySubscription(subscription)"
    v-bind:style="{
      maxWidth: isMobile ? '90%' : '275px',
      cursor: 'pointer',
      backgroundColor: 'white',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }"
  >
    <h5 class="card-title text-center mb-3">
      <span class="badge actionFeature mb-2 d-block">{{
        subscription.subscriptionName
      }}</span>
      <span class="text-muted" style="font-size: 15px">{{
        yearly ? "Billed Annually" : "Billed Monthly"
      }}</span>
    </h5>

    <h5 class="card-body text-center">
      <label
        v-if="yearly"
        class="d-block"
        style="font-size: 15px; text-decoration: line-through"
      >
        ${{ getFakeYearly }}
      </label>
      <span class="price">
        <i class="fa-solid fa-trophy fa-xl m-1" style="color: #363166"></i>
        <label
          class="d-inline-block"
          style="font-size: 24px; font-weight: bold"
        >
          ${{ yearly ? getYearly + " / year" : getMonthly + " / month" }}
        </label>
      </span>
    </h5>
    <button
      class="btn btn-primary rounded-pill mt-4 w-100"
      id="regularSignInBtn"
      @click="buySubscription(subscription)"
    >
      <h5>Get a Demo</h5>
    </button>
    <p class="text-muted text-center mt-2">
      Everything in the All Star, Plus...
    </p>

    <!-- Enhanced Feature List -->
    <ul class="plan-details__item list-unstyled">
      <li
        v-for="(feature, index) in features"
        :key="index"
        :class="{ 'has-description': feature.description }"
        class="mb-3"
      >
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          {{ feature.title }}
        </p>
        <!-- Tooltip for description -->
        <div
          v-if="feature.description"
          class="plan-details__item-description env-dark"
        >
          <div class="tooltip-arrow"></div>
          <p>{{ feature.description }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>


<script>
import axios from "axios";
import numeral from "numeral";
export default {
  props: {
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    promo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: "",
      },
      features: [
        { title: "Custom Implementation" },
        {
          title: "VIP email and phone support",
          description: "Get personalized AI-driven ad content.",
        },
        {
          title: "Enterprise-specific success manager",
          description: "Optimize website traffic with targeted ads.",
        },
        { title: "Quarterly business reviews" },
        { title: "Tailored onboarding and training sessions" },
      ],
    };
  },
  methods: {
    buySubscription(plan) {
      this.$emit("buySubscription", plan);
    },
    getSubscription() {
      let url =
        process.env.VUE_APP_DB_URL +
        "subscription/getSubscriptionByName?name=Hall of Fame";
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.subscription = response.data.subscription;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format("0,0.00");
    },
    getYearly() {
      return numeral(this.subscription.yearlyPrice).format("0,0.00");
    },
    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format("0,0.00");
    },
  },
  mounted() {
    this.getSubscription();
  },
};
</script>

<style scoped>
.actionFeature {
  background-color: #363166;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.information-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
}

.btn-primary {
  background-color: #363166;
  border-color: #363166;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.list-unstyled li {
  display: flex;
  align-items: center;
}

.list-unstyled li i {
  margin-right: 8px;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
  color: #363166;
}

.price {
  font-size: 24px;
  color: #363166;
}
.plan-details__item-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.plan-details__item-description {
  display: none;
  position: relative;
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 250px;
  font-size: 14px;
  margin-top: 5px;
}

.has-description:hover .plan-details__item-description {
  display: block;
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 1000;
  width: 250px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: #333 transparent transparent transparent;
  left: 15px;
  top: -5px;
}

.plan-details__item li {
  margin-bottom: 15px;
}

.plan-details__item-title i {
  margin-right: 10px;
  color: #19d412;
}
</style>
