<template>
  <div
    class="modal fade"
    id="initiatePayout"
    tabindex="-1"
    aria-labelledby="initiatePayoutLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="initiatePayoutLabel">
            Transfer Funds
          </h5>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <button
            type="button"
            id="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            style="display: none"
          ></button>
        </div>
        <div class="modal-body">
          <div class="center justify-content-center w-100">
            <label
              class="form-label text-center w-100"
              for="bankDetailsForm"
              style="margin-top: 5px"
              >Remaining Balance</label
            >
            <h1
              v-if="accountBalance - transfer.amount > 0"
              class="text-center w-100"
            >
              ${{ formatAccountBalance }}
            </h1>
            <h1 v-else class="text-center w-100 text-danger">
              ${{ formatAccountBalance }}
            </h1>
          </div>

          <br />
          <br />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Transfer Amount (USD)</label
          >
          <input
            required
            v-model="transfer.amount"
            id="transferAmount"
            class="form-control"
            type="number"
            @change="transferAmountChanged()"
          />
          <br />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Receiving Account</label
          >
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="externalAccount in payoutInfo.externalAccounts.filter(
                (x) => x.isDeleted === false
              )"
              :key="externalAccount.id"
            >
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                :value="externalAccount.vendorID"
                v-model="transfer.account"
              />
              {{ externalAccount.accountName.split("-")[1] }}
              <strong>...</strong>{{ externalAccount.accountNumberReference }}
            </li>
          </ul>
          <div>
            <button
              type="button"
              class="btn m-3 btn"
              data-bs-toggle="modal"
              data-bs-target="#addExternalAccount"
              data-bs-dismiss="modal"
              style="background-color: #363166; color: white; float: right"
            >
              Add External Account
            </button>
          </div>
        </div>
        <hr />
        <div
          class="card card-body mb-3 information-card"
          style="cursor: pointer"
          v-if="this.subscriptionTier < 3 || !this.subscriptionTier"
          @click="transferFunds(true)"
        >
          <span
            ><i class="fa-solid fa-bolt-lightning" style="color: #363166"></i>
            Priority Transfer (3%)</span
          >
          <br />
          <div class="">
            * Free with Summer Athletes
            <span class="badge actionFeature">Hall of Fame</span> *
          </div>
          <div>
            <small class="text-muted"
              >Receive transfer up to 5 days sooner</small
            >
          </div>
        </div>
        <div
          class="card card-body mb-3 information-card"
          style="cursor: pointer"
          @click="transferFunds(false)"
          v-if="this.subscriptionTier < 3 || !this.subscriptionTier"
        >
          <span
            ><i
              class="fa-solid fa-money-bill-transfer"
              style="color: #363166"
            ></i>
            Standard Transfer</span
          >
          <div>
            <small class="text-muted"
              >Receive transfer in 5 - 7 business days</small
            >
          </div>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="btn btn-secondary btn-lg"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>

          <div
            class="card card-body information-card"
            style="cursor: pointer; max-width: 312px; margin-left: 12px"
            v-if="this.subscriptionTier >= 3"
            @click="transferFunds(true)"
          >
            <span
              ><i class="fa-solid fa-bolt-lightning" style="color: #363166"></i>
              Priority Transfer (Free)</span
            >
            <div>
              <small class="text-muted"
                >Receive transfer up to 3 days sooner</small
              >
            </div>
          </div>

          <button
            type="button"
            v-if="this.subscriptionTier < 3 || !this.subscriptionTier"
            class="btn btn-primary btn-lg w-50"
            @click="transferFunds()"
          >
            Transfer Funds
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal2 from "sweetalert2";
import numeral from "numeral";
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    transfer: Object,
    payoutInfo: Object,
    accountBalance: Number,
    userID: String,
    subscriptionTier: Number,
  },
  methods: {
    transferFunds(priorityTransfer) {
      if (!parseFloat(this.transfer.amount) > 0 || !this.transfer.account) {
        swal2.fire({
          title: "Incomplete Transfer Details",
          icon: "error",
          html: `Please fill out all transfer details (amount and account) before proceeding.`,
        });
        return;
      }
      if (
        this.payoutInfo.payoutsPermitted &&
        parseFloat(this.transfer.amount) <=
          parseFloat(this.payoutInfo.maxPayoutPermitted) &&
        parseFloat(this.transfer.amount) +
          parseFloat(this.payoutInfo.payoutTotal) <=
          parseFloat(this.payoutInfo.maxTotalPayoutAmountPermitted)
      ) {
        if (priorityTransfer) {
          swal2
            .fire({
              title: "Confirm Transfer",
              icon: "warning",
              html: `Are you sure you want to withdraw <strong>$${
                this.getFormattedTransferAmount
              }</strong> from your Summer Athletes account? <br /> <br />
Once the funds have been transferred, that money is unreachable by Summer Athletes. <br /> <br />Would you still like to initiate the transfer? <br /> <p style="color: grey; font-size: 15px">Priority Transfer Fee: ${
                this.subscriptionTier >= 3 ? "<s>$" : ""
              }${
                this.subscriptionTier >= 3
                  ? (parseFloat(this.transfer.amount) * 0.03).toFixed(2)
                  : ""
              }${this.subscriptionTier >= 3 ? "</s>" : ""}${
                this.subscriptionTier >= 3 ? " $0.00!" : "$"
              }${
                this.subscriptionTier < 3
                  ? (parseFloat(this.transfer.amount) * 0.03).toFixed(2)
                  : ""
              }

</p>
`,
              confirmButtonText:
                '<i class="fa-solid fa-money-bill-transfer"></i> Transfer',
              showCancelButton: true,
            })
            .then((willTransfer) => {
              if (willTransfer.isConfirmed) {
                swal2.fire({
                  title: "Transferring...",
                  html: "please wait...",
                  icon: "info",
                });
                let url =
                  process.env.VUE_APP_DB_URL + "banking/initiateTransfer";
                axios
                  .post(
                    url,
                    {
                      transferInfo: this.transfer,
                      priorityTransfer: priorityTransfer,
                      userID: this.userID,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt"),
                      },
                    }
                  )
                  .then((response) => {
                    if (response.status === 201) {
                      this.payoutInfo = response.data.payoutInfo;
                      this.accountBalance = response.data.accountBalance;
                      this.$emit("transferInitiated", {
                        payoutInfo: response.data.payoutInfo,
                        accountBalance: response.data.accountBalance,
                      });
                      swal2.fire({
                        title: "Transfer Successful",
                        icon: "success",
                        html: `Successfully initiated a transfer of <strong>$${this.getFormattedTransferAmount}</strong>
from your Summer Athletes account. <br /> <br />`,
                      });
                      document.getElementById("closeModal").click();
                    }
                  });
              }
            });
        } else {
          swal2
            .fire({
              title: "Confirm Transfer",
              icon: "warning",
              html: `Are you sure you want to withdraw <strong>$${this.getFormattedTransferAmount}</strong> from your Summer Athletes account? <br /> <br />
Once the funds have been transferred, that money is unreachable by Summer Athletes. <br /> <br />Would you still like to initiate the transfer?
`,
              confirmButtonText:
                '<i class="fa-solid fa-money-bill-transfer"></i> Transfer',
              showCancelButton: true,
            })
            .then((willTransfer) => {
              if (willTransfer.isConfirmed) {
                swal2.fire({
                  title: "Transferring...",
                  html: "please wait...",
                  icon: "info",
                });
                let url =
                  process.env.VUE_APP_DB_URL + "banking/initiateTransfer";
                axios
                  .post(
                    url,
                    {
                      transferInfo: this.transfer,
                      userID: this.userID,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt"),
                      },
                    }
                  )
                  .then((response) => {
                    if (response.status === 201) {
                      this.payoutInfo = response.data.payoutInfo;
                      this.accountBalance = response.data.accountBalance;
                      this.$emit("transferInitiated", {
                        payoutInfo: response.data.payoutInfo,
                        accountBalance: response.data.accountBalance,
                      });
                      swal2.fire({
                        title: "Transfer Initiated",
                        icon: "success",
                        html: `Successfully initiated a transfer of <strong>$${this.getFormattedTransferAmount}</strong>
from your Summer Athletes account. <br /> <br />`,
                      });
                      document.getElementById("closeModal").click();
                    }
                  });
              }
            });
        }
      } else {
        swal2.fire({
          title: "We're Sorry!",
          icon: "error",
          html: `This exceeds your current manual-transfer limit. Please reach out to the Summer Athletes team for assistance.`,
        });
      }
    },
    transferAmountChanged() {
      if (this.accountBalance - this.transfer.amount < 0) {
        this.transfer.amount = this.accountBalance;
      }
      if (this.transfer.amount < 0) {
        this.transfer.amount = 0;
      }
    },
  },

  computed: {
    formatAccountBalance() {
      return numeral(this.accountBalance - this.transfer.amount).format(
        "0,0.00"
      );
    },

    getFormattedTransferAmount() {
      return numeral(this.transfer.amount).format("0,0.00");
    },

    formatNonTransferredAccountBalance() {
      return numeral(this.accountBalance).format("0,0.00");
    },
  },
};
</script>

<style scoped>
.actionFeature {
  background-color: #a39ed0;
}
</style>
