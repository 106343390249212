<template>
  <div>
    <!-- start of if statements to determine proper type of input-->

    <!--height type-->
    <div v-if="this.field.fieldType == 'height'">
      <label for="cardName" class="card-input__label">{{
        field.fieldName
      }}</label>
      <div class="row">
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Feet</option>
            <option value="0" class="option">0</option>
            <option value="1" class="option">1</option>
            <option value="2" class="option">2</option>
            <option value="3" class="option">3</option>
            <option value="4" class="option">4</option>
            <option value="5" class="option">5</option>
            <option value="6" class="option">6</option>
            <option value="7" class="option">7</option>
            <option value="8" class="option">8</option>
          </select>
        </div>
        <div class="card-input col">
          <select
            class="card-input__input -select"
            v-model="fieldValue2"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Inches</option>
            <option value="'0" class="option">0</option>
            <option value="'1" class="option">1</option>
            <option value="'2" class="option">2</option>
            <option value="'3" class="option">3</option>
            <option value="'4" class="option">4</option>
            <option value="'5" class="option">5</option>
            <option value="'6" class="option">6</option>
            <option value="'7" class="option">7</option>
            <option value="'8" class="option">8</option>
            <option value="'9" class="option">9</option>
            <option value="'10" class="option">10</option>
            <option value="'11" class="option">11</option>
          </select>
        </div>
      </div>
    </div>
    <!--email input type-->
    <div v-if="this.field.fieldType == 'email'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <input
            type="email"
            placeholder="Email"
            id="cardName"
            class="card-input__input"
            v-model="fieldValue"
            @change="handleChange"
            required
          />
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'phoneNumber'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          field.fieldName
        }}</label>
        <input
          type="text"
          id="phoneNumber"
          class="card-input__input"
          placeholder="Phone Number"
          v-model="fieldValue"
          @change="handleChange"
          required
        />
      </div>
    </div>
    <!--Text-short Input-->
    <div v-if="this.field.fieldType == 'textShort'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <input
            type="text"
            id="cardName"
            class="card-input__input"
            v-model="fieldValue"
            @change="handleChange"
            required
          />
        </div>
      </div>
    </div>
    <!--Text-Long Input-->
    <div v-if="this.field.fieldType == 'textLong'">
      <div class="row">
        <div class="card-input col">
          <label for="cardName" class="card-input__label">{{
            field.fieldName
          }}</label>
          <textarea
            type="text"
            id="cardName"
            class="card-input__input"
            rows="4"
            cols="50"
            style="height: 120px"
            v-model="fieldValue"
            @change="handleChange"
            required
          ></textarea>
        </div>
      </div>
    </div>
    <!--Phone Number Type-->
    <div v-if="this.field.fieldType == 'number'">
      <div class="card-input">
        <label for="phoneNumber" class="card-input__label">{{
          field.fieldName
        }}</label>
        <input
          type="number"
          id="Number"
          class="card-input__input"
          placeholder="Number"
          v-model="fieldValue"
          @change="handleChange"
          required
        />
      </div>
    </div>
    <!--yesNo Type-->
    <div v-if="this.field.fieldType == 'yesNo'">
      <div class="col">
        <div class="card-input">
          <label for="phoneNumber" class="card-input__label">{{
            field.fieldName
          }}</label>
          <select
            class="card-input__input -select"
            style="background-position: 95% center"
            v-model="fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Yes/No</option>
            <option value="Yes" class="option">Yes</option>
            <option value="No" class="option">No</option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="this.field.fieldType == 'dropdown'">
      <div class="col">
        <div class="card-input">
          <label for="phoneNumber" class="card-input__label">{{
            field.fieldName
          }}</label>
          <select
            class="card-input__input -select"
            style="background-position: 95% center"
            v-model="fieldValue"
            @change="handleChange"
            required
          >
            <option disabled selected value="">Select</option>
            <template v-for="option in this.field.fieldOptions">
              <option :key="option" :value="option" class="option">
                {{ option }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </div>

    <div v-if="this.field.fieldType == 'fileUpload'">
      <div class="row">
        <div class="card-input col">
          <p
            class="hoverLink"
            :id="'fileDownload' + field.fieldName + camperId"
            @click="getCampFile('fileDownload' + field.fieldName)"
            style="text-decoration: underline; color: #363166"
          >
            <span
              >{{ this.field.fieldName }}
              <i class="fa-solid fa-file-arrow-down fa-lg"></i>
              <i
                v-if="this.uploadedFileName !== ''"
                class="fa-sharp fa-solid fa-circle-check fa-lg"
                style="color: #19d412; margin-left: 5px"
              ></i
            ></span>
          </p>
        </div>
        <div class="card-input col">
          <p v-if="this.uploadedFileName !== ''" style="color: #19d412">
            {{ this.uploadedFileName }}
          </p>
          <input
            type="file"
            accept="*"
            @change="uploadCamperFile($event)"
            class="hidden"
            :id="'file' + field.fieldName + camperId"
          /><label
            class="link-primary hoverLink text-center center"
            :for="'file' + this.field.fieldName + camperId"
            ><span class="text-end"
              >Upload file
              <i class="fa-sharp fa-solid fa-file-arrow-up fa-lg"></i
            ></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  components: {},
  props: {
    field: Object,
    prePopulatedFieldValue: String,
    prePopulatedFieldType: String,
    camperId: Number,
  },
  data() {
    return {
      fieldValue: "",
      fieldValue2: "",
      uploadedFileName: "",
      uploadedFileLocation: "",
      S3key: "",
    };
  },
  methods: {
    uploadCamperFile(event) {
      const URL = process.env.VUE_APP_DB_URL + "util/uploadCamperFileNoSave";
      let data = new FormData();
      data.append("file", event.target.files[0]);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((res) => {
          console.log(res.data);
          this.uploadedFileLocation = res.data.location;
          this.uploadedFileName = res.data.name;
          this.S3key = res.data.S3Key;
        })
        .catch(function (err) {
          this.uploadedFileLocation = "";
          this.uploadedFileName = "";
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        })
        .finally(() => {
          this.$emit("changed", {
            value: this.uploadedFileLocation,
            isFile: true,
            S3Key: this.S3key,
          });
        });
    },
    handleChange() {
      let temp = this.fieldValue + this.fieldValue2;
      this.$emit("changed", { value: temp });
    },
    getCampFile(domID) {
      let url =
        process.env.VUE_APP_DB_URL +
        "util/getCampFile?S3Key=" +
        this.field.fileLocation.slice(42) +
        "&fileName=" +
        this.field.fileName;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          responseType: "blob",
        })
        .then(async (response) => {
          // console.log(response.data);
          // const downloadBlobURL = window.URL.createObjectURL(downloadBlob);
          const downloadBlobURL = window.URL.createObjectURL(
            new Blob([response.data])
          );

          console.log(downloadBlobURL);
          document.getElementById(domID).href = downloadBlobURL;
          // this.selectedFile = response.data;

          const link = document.createElement("a");
          link.href = downloadBlobURL;
          link.setAttribute("download", this.field.fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (err) {
          console.log(err);

          swal("Error", err, "error");
        });
    },
  },

  created() {
    if (this.field.fieldType == "dropdown") {
      this.field.fieldOptions = this.field.fieldOptions.filter((n) => n);
      // for (let optionIndex in this.field.fieldOptions) {
      //   if (!this.field.fieldOptions[optionIndex]) {
      //     this.field.fieldOptions.splice(optionIndex, 1);
      //   }
      // }
      //t3
    }
  },
  mounted() {
    if (this.prePopulatedFieldValue) {
      if (this.prePopulatedFieldType === "height") {
        console.log(this.prePopulatedFieldValue.split("'")[0]);
        console.log(this.prePopulatedFieldValue.split("'")[1]);
        this.fieldValue = this.prePopulatedFieldValue.split("'")[0];
        this.fieldValue2 = "'" + this.prePopulatedFieldValue.split("'")[1];
      } else {
        this.fieldValue = this.prePopulatedFieldValue;
      }
    }
  },
};
</script>
<style>
.hidden {
  opacity: 0.01;
}
.hoverLink {
  cursor: pointer;
}
</style>
