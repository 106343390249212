<template>
  <div class="wrapper" id="app">
    <div class="card-form shadow-lg">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div
          v-if="authLoading"
          class="card-form center text-center justify-content-center"
        >
          <img
            src="../../../public/1495.gif"
            alt="Loading"
            class="center text-center justify-content-center"
          />
        </div>
        <div v-else class="card-form">
          <div class="card-input">
            <label for="email" class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              placeholder="Email"
              v-model="login.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Password</label>
            <input
              type="password"
              id="password"
              class="card-input__input"
              placeholder="Password"
              v-model="login.password"
            />
          </div>
        </div>

        <button
          class="card-form__button gradient-button"
          @click="this.loginMember"
        >
          Sign in
        </button>
        <p>
          <center>
            <router-link to="/memberforgotpassword" style="text-align: center"
              >Forgot Password</router-link
            >
          </center>
        </p>
      </div>
      <!-- (c) 2005, 2023. Authorize.Net is a registered trademark of CyberSource Corporation -->
      <!--      <div class="AuthorizeNetSeal" src="//verify.authorize.net:443/anetseal/seal.js" v-bind:key="{ANS_customer_id: 'e6adf9e6-2043-4e61-8ca3-d812f4ebf49e'}" > </div>-->
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      login: {
        email: "",
        password: "",
      },
      authLoading: false,
    };
  },
  methods: {
    async loginMember() {
      let url = process.env.VUE_APP_DB_URL + "membership/memberLogin";
      if (this.validateInfo() == 0) {
        axios
          .post(url, this.login, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          })
          .then((response) => {
            let token = response.data.token;
            let member = response.data.member;
            localStorage.setItem("jwt", token);
            localStorage.setItem("member", member);
            if (token) {
              swal("Success", "Login Successful", "success");
              this.$router.push("/MemberDashboard");
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message);
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },

    validateInfo() {
      if (this.login.email == "") {
        swal("Error", "Please enter email", "error");
        return 1;
      }
      if (this.login.password == "") {
        swal("Error", "Please enter password", "error");
        return 1;
      }
      return 0;
    },
  },
  created() {},
};
</script>
<style>
.card-form {
  /*-webkit-mask-image: -webkit-gradient(linear, left top, left bottom,*/
  /*from(rgba(0,0,1,1)), to(rgba(0,0,0,.9)));*/
  /*box-shadow: 0 30px 40px rgba(0,0,0,.9);*/
}

.gradient-button {
  background: rgb(54, 49, 102);
  background: linear-gradient(
    90deg,
    rgba(54, 49, 102, 1) 8%,
    rgba(25, 212, 18, 1) 78%
  );
}
</style>
