<template>
  <div class="justify-content-center">
    <li class="list-group-item">
      <h2 class="text-center">
        <b>{{ this.camperFirstName }} {{ this.camperLastName }}</b>
      </h2>
      <h5 class="text-center">
        <b
          >School:
          {{ this.camperSchool ? this.camperSchool : "Unspecified" }}</b
        >
      </h5>
    </li>
  </div>
</template>
<script>
export default {
  data() {
    return {
      camperFirstName: "",
      camperLastName: "",
      camperSchool: "",
    };
  },
  components: {},
  props: {
    firstName: String,
    lastName: String,
    school: String,
  },
  created() {
    this.camperFirstName = this.firstName;
    this.camperLastName = this.lastName;
    this.camperSchool = this.school;
  },
};
</script>
