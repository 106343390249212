<template>
  <div class="container">
    <section style="margin-top: 7%">
      <div class="container mt-5">
        <div class="card-form">
          <div class="card-form__inner" style="text-align: center">
            <img
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
            />

            <draggable :list="this.customAddOns" class="draggable">
              <li
                class="list-group-item"
                v-for="(item, index) in this.customAddOns"
                :key="'customAddOn' + index"
              >
                <div class="row">
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3"
                      >Pricing Add-on Name</label
                    >
                    <input
                      v-model="item.addOnName"
                      required
                      id="form6Example1"
                      class="card-input__input"
                    />
                  </div>
                  <div class="col" style="margin-top: 5px">
                    <label class="form-label" for="form6Example3">Price</label>
                    <input
                      v-model="item.addOnPrice"
                      required
                      type="number"
                      id="form6Example1"
                      class="card-input__input"
                    />
                  </div>
                  <i
                    class="fa-solid fa-trash removeAddOn"
                    @click="removeAddOn(index)"
                  ></i>
                </div>
              </li>
            </draggable>

            <button class="card-form__button" @click="addField">
              Add Pricing Option
            </button>
            <button
              class="card-form__button"
              @click="backToCamp"
              style="margin-top: 10px"
            >
              Back to Event Overview
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      customAddOns: [{ addOnName: "", addOnPrice: "" }],
    };
  },
  props: {
    addOns: Array,
  },
  methods: {
    prepopulate() {
      if (this.addOns.length > 0)
        this.customAddOns = [...this.addOns, ...this.customAddOns];
    },
    addField() {
      this.customAddOns.push({ addOnName: "", addOnPrice: "" });
    },
    backToCamp() {
      this.$emit(
        "back",
        this.customAddOns.filter((x) => x.addOnName && x.addOnPrice)
      );
    },
    removeAddOn(index) {
      this.customAddOns.splice(index, 1);
    },
  },
  mounted() {
    this.prepopulate();
  },
};
</script>
<style>
.removeAddOn {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeAddOn:hover {
  color: darkred;
  cursor: pointer;
}
.draggable > li {
  cursor: grab;
}
</style>
