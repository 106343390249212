<script setup></script>

<template>
  <div
    class="modal fade"
    id="ContactModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :style="{ maxHeight: '550px', minWidth: isMobile ? '375px' : '700px' }"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content rounded-5">
        <Contact
          style="opacity: 1"
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :recruitID="recruitID"
        ></Contact>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "./ContactCard.vue";
export default {
  components: {
    Contact,
  },

  props: {
    primaryColor: String,
    secondaryColor: String,
    recruitID: String,
    isMobile: Boolean,
  },
};
</script>

<style scoped></style>
