<template>
  <div class="wrapper container">
    <ClientLayout :user="user"></ClientLayout>
    <v-tour name="viewTeamCampTour" :steps="determineSteps"></v-tour>

    <section class="container" style="margin-top: 7%">
      <div
        class="container mt-5"
        v-bind:style="{ marginLeft: isMobile ? '21px' : '' }"
      >
        <div
          class="card-form"
          v-show="!this.messageBool && !this.textBool"
          style="min-width: 100%"
        >
          <div class="card-form__inner" style="text-align: center">
            <center>
              <img
                :src="getCampLogo"
                class="card-img-top rounded-corners"
                id="logoTop"
                style="max-width: 50%; max-height: 30%"
              />
            </center>
            <h2 class="card-title">
              <b>{{ this.camp.campName }}</b>
            </h2>

            <div class="row mt-3 justify-content-center">
              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-6': !this.camp.tShirtsProvided,
                  'col-md-5': this.camp.tShirtsProvided,
                  'col-sm-6': true,
                  'text-start': true,
                  'justify-content-center': true,
                  'camp-step-0': true,
                }"
              >
                <h3 class="text-center">Details</h3>
                <div class="m-2">
                  <b>Camp Organization</b>:
                  {{ this.camp.campOrganization }}
                </div>

                <div class="m-2">
                  <b>Camp Location</b>: {{ this.camp.campLocation }}
                </div>

                <div class="m-2"><b>Current Teams</b>: {{ getTeams }}</div>
                <div class="m-2"><b>Current Campers</b>: {{ getCampers }}</div>

                <div v-show="!getParticipants" class="m-2">
                  <b
                    >Maximum Registrants:
                    {{ this.camp.campMaxParticipants }}
                  </b>
                </div>

                <div v-show="getParticipants" class="m-2">
                  <b>Unlimited Participants</b>
                </div>

                <div v-show="this.camp.requireWaiver" class="m-2">
                  <b>Requiring waiver</b>
                </div>

                <div v-show="!this.camp.requireWaiver" class="m-2">
                  <b>Not requiring waiver</b>
                </div>

                <div class="m-2">
                  <div>
                    <b>Registration Deadline </b>:
                    {{ getFormattedRegDate }}
                  </div>
                  <div v-show="this.camp.acceptLateRegistration">
                    <b>**Accepting Late Registrants</b>
                  </div>

                  <div v-show="!this.camp.acceptLateRegistration">
                    <b>**Not Accepting Late Registrants</b>
                  </div>
                </div>

                <div class="mt-3">
                  <b>Camp Days</b>:
                  <div
                    v-for="(day, index) in this.camp.campDates"
                    :key="index"
                    class="m-3"
                  >
                    <div>
                      <b>Day {{ index + 1 }}</b>
                      <div>
                        <div>Start Time: {{ dayToString(day.start) }}</div>
                        <div>End Time: {{ dayToString(day.end) }}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mt-3">
                  <b>Camp Description</b>: {{ this.camp.campDescription }}
                </div>
              </div>

              <div
                v-show="this.camp.tShirtsProvided"
                class="information-card col-md-2 col-sm-6 camp-step-1"
              >
                <div>
                  <h3>T-Shirt Sales</h3>
                  <div class="text-start">
                    <div>Youth Small: {{ getYS }}</div>
                    <div>Youth Medium: {{ getYM }}</div>
                    <div>Youth Large: {{ getYL }}</div>
                    <div>Adult Small: {{ getAS }}</div>
                    <div>Adult Medium: {{ getAM }}</div>
                    <div>Adult Large: {{ getAL }}</div>
                    <div>Adult XL: {{ getAXL }}</div>
                    <div>Adult XXL: {{ getAXXL }}</div>
                  </div>
                </div>
                <div v-show="!this.camp.tShirtsProvided">
                  Shirts not provided
                </div>
              </div>

              <div
                v-bind:class="{
                  'information-card': true,
                  'col-md-5': !this.camp.tShirtsProvided,
                  'col-md-4': this.camp.tShirtsProvided,
                  'col-sm-6': true,
                  'text-center': true,
                  'camp-step-2': true,
                }"
              >
                <h3>Payment Details</h3>
                <div>
                  <b>Camp Price</b>: ${{ this.camp.campPrice
                  }}{{ getCollectFromString }}
                </div>

                <div><b>Current Revenue</b>: ${{ getMoney }}</div>

                <br />
                <b>Revenue Goal: ${{ getRevenueGoal }}</b>
                <div class="mt-3">
                  <VueEllipseProgress
                    :progress="getRevenueGoalProgress"
                    animation="rs 1500 700"
                    color="#19d412"
                    line-mode="in 10"
                    empty-color="#363166"
                  >
                    ${{ getMoney }} / ${{ getRevenueGoal }}
                    <br />
                    <br />
                    Keep Going!
                  </VueEllipseProgress>
                </div>
                <div class="mt-3" v-if="getFormattedAddOns.length != 0">
                  <b>Add On Purchases</b>
                  <div v-for="index in getFormattedAddOns.length" :key="index">
                    <ViewCampCustomAddOns
                      :addOnName="getFormattedAddOns[index - 1].addOnName"
                      :numCampers="getFormattedAddOns[index - 1].numCampers"
                    ></ViewCampCustomAddOns>
                  </div>
                </div>
              </div>
            </div>

            <h5 class="panel-title">Post Camp to My Website</h5>

            <label class="switch">
              <input
                type="checkbox"
                @click="toggleCampToWebsite"
                :checked="this.camp.isPostedToWebsite"
              />
              <div class="slider round"></div>
            </label>

            <h3 class="card-title mt-5">Active Links</h3>
            <div class="row center justify-content-center">
              <a
                class="card2 col-md-3 col-sm-6 camp-step-3"
                :href="getCampURL"
                target="_blank"
              >
                <h5>Link to team registration page</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>

              <a
                class="card2 col-md-3 col-sm-6 camp-step-4"
                :href="getQrLink"
                target="_blank"
              >
                <h5>Team registration QR Code</h5>
                <div class="go-corner" href="#">
                  <div class="go-arrow">→</div>
                </div>
              </a>
            </div>

            <h3 class="card-title mt-5 camp-step-5">Action Hub</h3>

            <div class="row justify-content-center">
              <div
                @click="handleClick()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-users fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Team List</label
                    >
                  </span>
                </h5>
                <p>View and manage every team</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>

              <div
                class="information-card col-md-3 col-sm-6 actionHub"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <JsonExcel
                  :data="formattedCampers"
                  :name="camp.campName + '.xls'"
                  :fields="json_fields"
                >
                  <h5 class="card-title text-start">
                    <span class="badge actionFeature">Feature</span>
                  </h5>
                  <h5 class="card-body">
                    <span
                      ><i
                        class="fa-solid fa-download fa-xl m-1"
                        style="color: #363166"
                      ></i>
                      <label style="margin-right: 3px; cursor: pointer">
                        Download Data</label
                      >
                    </span>
                  </h5>
                  <p class>Take your campers' info offline</p>
                  <div
                    class="card-footer"
                    style="background-color: #a39ed0"
                  ></div>
                </JsonExcel>
              </div>

              <div
                class="information-card col-md-3 col-sm-6 actionHub"
                v-bind:style="{
                  maxWidth: isMobile() ? '100%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <JsonExcel
                  :data="formattedTeams"
                  :name="camp.campName + '.xls'"
                  :fields="json_fields"
                >
                  <h5 class="card-title text-start">
                    <span class="badge actionFeature">Feature</span>
                  </h5>
                  <h5 class="card-body">
                    <span
                      ><i
                        class="fa-solid fa-download fa-xl m-1"
                        style="color: #363166"
                      ></i>
                      <label style="margin-right: 3px; cursor: pointer">
                        Download Teams</label
                      >
                    </span>
                  </h5>
                  <p class>Take your teams' info offline</p>
                  <div
                    class="card-footer"
                    style="background-color: #a39ed0"
                  ></div>
                </JsonExcel>
              </div>

              <div
                @click="copyCamp()"
                class="information-card col-md-3 col-sm-6 actionHub pointer"
                v-bind:style="{
                  maxWidth: isMobile() ? '200%' : '275px',
                  maxHeight: '200px',
                  cursor: 'pointer',
                }"
              >
                <h5 class="card-title text-start">
                  <span class="badge actionFeature">Feature</span>
                </h5>
                <h5 class="card-body">
                  <span
                    ><i
                      class="fa-solid fa-copy fa-xl m-1"
                      style="color: #363166"
                    ></i>
                    <label style="margin-right: 3px; cursor: pointer">
                      Copy Camp</label
                    >
                  </span>
                </h5>
                <p>Don't waste time re-typing</p>
                <div
                  class="card-footer"
                  style="background-color: #a39ed0"
                ></div>
              </div>
            </div>
            <button
              class="card-form__button"
              @click="deleteCamp"
              style="
                margin-top: 10px;
                margin-bottom: 10px;
                background-color: red;
              "
            >
              Delete League/Tournament
            </button>
          </div>
        </div>

        <!--Start of Message Fuctionality-->
        <div class="container" v-show="messageBool || textBool">
          <div class="row">
            <div v-if="!isMobile()" class="col-2">
              <br /><br /><br /><br /><br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">Email</h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-1"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-success"
                      >New</span
                    >
                  </h5>
                </div>
              </div>
              <!--            </div>-->
            </div>
            <div v-else>
              <br />
              <!--            <div class="shadow-lg">-->
              <div
                class="card message_tab rounded-3"
                @click="setTabStyle('email')"
              >
                <h5
                  class="card-header"
                  id="emailTabHeader"
                  style="background-color: #19d412"
                ></h5>
                <div
                  class="card-body"
                  id="emailTabBody"
                  style="background: white"
                >
                  <h5 class="card-text text-center">Email</h5>
                </div>
              </div>

              <div
                class="card message_tab rounded-3 mt-1"
                @click="setTabStyle('text')"
              >
                <p class="card-header" id="textTabHeader"></p>
                <div class="card-body" id="textTabBody">
                  <h5 class="card-text text-center">
                    Text
                    <span
                      class="position-absolute top-100 start-100 translate-middle badge rounded-pill bg-success"
                      >New</span
                    >
                  </h5>
                </div>
              </div>
              <br />
            </div>
            <div class="col">
              <div class="card-form" v-show="this.messageBool">
                <div class="card-form__inner">
                  <img
                    src="../../../public/SummerAthletes.png"
                    class="card-img-top"
                  />
                  <div class="card-form">
                    <div class="card-input">
                      <label for="email" class="card-input__label"
                        >Subject</label
                      >
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Subject"
                        v-model="message.subject"
                      />
                    </div>
                    <div class="card-input">
                      <label for="email" class="card-input__label">Intro</label>
                      <input
                        type="email"
                        id="email"
                        class="card-input__input"
                        placeholder="Hi Everyone,"
                        v-model="message.intro"
                      />
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 1</label>

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 1"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body1"
                      ></textarea>
                    </div>
                    <div class="card-input">
                      <label class="card-input__label">Paragraph 2</label>
                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Paragraph 2 (optional)"
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="message.body2"
                      ></textarea>
                    </div>
                  </div>

                  <button class="card-form__button" @click="sendEmail">
                    Send email to all campers
                  </button>

                  <!--                  <button class="card-form__button" @click="sendEmailToCoaches">-->
                  <!--                    Send email to all coaches-->
                  <!--                  </button>-->
                  <button class="card-form__button">
                    <a @click="setTabStyle('back')">Back to camp</a>
                  </button>
                </div>
              </div>
              <!-- START OF TEXT MESSAGE PORTION-->
              <div class="card-form" v-show="this.textBool">
                <div class="card-form__inner">
                  <img
                    src="../../../public/SummerAthletes.png"
                    class="card-img-top"
                  />
                  <div class="card-form">
                    <div class="card-input">
                      <label class="card-input__label"
                        >Text Message (Mass-Send Limit:
                        <div
                          class="badge rounded-pill bg-secondary"
                          v-show="this.camp.textLimit < 3"
                        >
                          {{ this.camp.textLimit }}/3
                        </div>
                        <div
                          class="badge rounded-pill bg-danger"
                          v-show="this.camp.textLimit == 3"
                        >
                          {{ this.camp.textLimit }}/3
                        </div>
                        <div
                          class="badge rounded-pill bg-secondary"
                          v-show="!this.camp.textLimit"
                        >
                          0/3
                        </div>
                        )</label
                      >

                      <textarea
                        id="textarea"
                        class="card-input__input"
                        placeholder="Friendly reminder..."
                        rows="4"
                        cols="50"
                        style="height: 150px"
                        v-model="text.content"
                      ></textarea>
                    </div>
                  </div>

                  <button class="card-form__button" @click="sendText">
                    Send text to all campers
                  </button>
                  <!--                  <button class="card-form__button" @click="sendTextToCoaches">-->
                  <!--                    Send text to all coaches-->
                  <!--                  </button>-->
                  <button class="card-form__button">
                    <a @click="setTabStyle('back')">Back to camp</a>
                  </button>
                </div>
              </div>
              <!-- END OF TEXT MESSAGE PORTION-->
            </div>
          </div>
        </div>

        <!--End of Message Functionality-->
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import JsonExcel from "vue-json-excel";
import VueJwtDecode from "vue-jwt-decode";
import ViewCampCustomAddOns from "../components/ViewCampCustomAddOns.vue";
import swal from "sweetalert";
import ClientLayout from "../components/ClientLayout.vue";
import { VueEllipseProgress } from "vue-ellipse-progress";
export default {
  components: {
    VueEllipseProgress,
    ClientLayout,
    JsonExcel,
    ViewCampCustomAddOns,
  },
  name: "view-team-camp-tour",
  data() {
    return {
      formattedCampers: [],
      formattedTeams: [],
      team_fields: {
        "Team Name": "teamName",
        "Coach First Name": "teamCoachFirstName",
        "Coach Last Name": "teamCoachLastName",
        "Coach Email": "teamCoachEmail",
        "Coach Phone Number": "teamCoachPhoneNumber",
      },
      json_fields: {
        Team: "team",
        "Camper First Name": "firstName",
        "Camper Last Name": "lastName",
        "Camper T-shirt size": "tShirtSize",
        "Camper Emergency Contact First Name": {
          callback: (value) => {
            return value.emergencyContactInfo.firstName;
          },
        },
        "Camper Emergency Contact Last Name": {
          callback: (value) => {
            return value.emergencyContactInfo.lastName;
          },
        },
        "Camper Emergency Contact Email": {
          callback: (value) => {
            return value.emergencyContactInfo.email;
          },
        },
        "Camper Emergency Contact Phone Number": {
          callback: (value) => {
            return value.emergencyContactInfo.phoneNumber;
          },
        },
        "Camper Emergency Contact Address": {
          callback: (value) => {
            return value.emergencyContactInfo.address;
          },
        },
        "Camper Emergency Contact City": {
          callback: (value) => {
            return value.emergencyContactInfo.city;
          },
        },
        "Camper Emergency Contact State": {
          callback: (value) => {
            return value.emergencyContactInfo.state;
          },
        },
        "Camper Emergency Contact Zip Code": {
          callback: (value) => {
            return value.emergencyContactInfo.zipCode;
          },
        },
      },
      steps: [
        {
          target: ".camp-step-0", // We're using document.querySelector() under the hood
          content: `The <strong>Details</strong> section shows all details of the League/Tournament you created.`,
          params: {
            enableScrolling: false,
            placement: "left",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-1", // We're using document.querySelector() under the hood
          content: `The <strong>T-Shirt Sales</strong> column specifies all T-Shirt sales for this specific League/Tournament. <br/> <br /> Each size has a value that shows how many people have purchased it.`,
          params: {
            enableScrolling: false,
            placement: "right",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-2", // We're using document.querySelector() under the hood
          content: `The <strong>Payment Details</strong> section gives an overview of all revenue information. <br/> <br /> It shows what the base price is, how much the League/Tournament has earned, and how close you are to your goals.`,
          params: {
            enableScrolling: false,
            placement: "right",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-3", // We're using document.querySelector() under the hood
          content: `The <strong>Link to Team Registration</strong> is how you get people signed up and <strong><em>make money</em></strong> on the platform! <br/> <br /> This is where teams go to sign up for your event. After a team is signed up via the Team Lead, a link is provided to that coach to get team members signed up for your event under their correct team name. <br /><br /> Be sure to post this out on social media as soon as possible, to help advertise for the event! We recommend at least <br/> <strong><em>one month in advance of advertising!</strong></em>`,
          params: {
            enableScrolling: false,
            placement: "left",
            highlight: true,
          },
        },
        {
          target: ".camp-step-4", // We're using document.querySelector() under the hood
          content: `The <strong>QR Code</strong> takes your teams to the exact same page as the sign-up link! <br/> <br /> Please be sure to post this out wherever you can to get your teams signed up, so the teams can get their team members signed up. It is most useful for both online and physical fliers!`,
          params: {
            enableScrolling: false,
            placement: "right",
            highlight: true,
          },
        },
        {
          target: ".camp-step-5", // We're using document.querySelector() under the hood
          content: `The <strong>Action Hub</strong> is your League/Tournament command station. <br/> <br /> Manage your teams and their team members, message your registrants, and work with your data, all in one spot!`,
          params: {
            enableScrolling: false,
            placement: "top",
            // highlight: true,
          },
        },
      ],
      mobileSteps: [
        {
          target: ".camp-step-0", // We're using document.querySelector() under the hood
          content: `The <strong>Details</strong> section shows all details of the League/Tournament you created.`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-1", // We're using document.querySelector() under the hood
          content: `The <strong>T-Shirt Sales</strong> column specifies all T-Shirt sales for this specific League/Tournament. <br/> <br /> Each size has a value that shows how many people have purchased it.`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-2", // We're using document.querySelector() under the hood
          content: `The <strong>Payment Details</strong> section gives an overview of all revenue information. <br/> <br /> It shows what the base price is, how much the League/Tournament has earned, and how close you are to your goals.`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            // highlight: true,
          },
        },
        {
          target: ".camp-step-3", // We're using document.querySelector() under the hood
          content: `The <strong>Link to Team Registration</strong> is how you get people signed up and <strong><em>make money</em></strong> on the platform! <br/> <br /> This is where teams go to sign up for your event. After a team is signed up via the Team Lead, a link is provided to that coach to get team members signed up for your event under their correct team name. <br /><br /> Be sure to post this out on social media as soon as possible, to help advertise for the event! We recommend at least <br/> <strong><em>one month in advance of advertising!</strong></em>`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            highlight: true,
          },
        },
        {
          target: ".camp-step-4", // We're using document.querySelector() under the hood
          content: `The <strong>QR Code</strong> takes your teams to the exact same page as the sign-up link! <br/> <br /> Please be sure to post this out wherever you can to get your teams signed up, so the teams can get their team members signed up. It is most useful for both online and physical fliers!`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            highlight: true,
          },
        },
        {
          target: ".camp-step-5", // We're using document.querySelector() under the hood
          content: `The <strong>Action Hub</strong> is your League/Tournament command station. <br/> <br /> Manage your teams and their team members, message your registrants, and work with your data, all in one spot!`,
          params: {
            enableScrolling: false,
            placement: "bottom",
            // highlight: true,
          },
        },
      ],
      messageBool: false,
      textBool: false,
      message: {
        subject: "",
        intro: "",
        body1: "",
        body2: "",
      },
      text: {
        content: "",
      },
      campId: 0,
      user: {},
      temp: {},
      money: 0,
      camp: {
        campName: "",
        campDescription: "",
        campLocation: "",
        campLogo: "",
        campPrice: null,
        campOrganization: "",
        campDate: "",
        campRegistrationDeadline: "",
        campMaxParticipants: "",
        acceptLateRegistration: "",
        requireWaiver: "",
        tShirtsProvided: "",
        campStripeId: "",
        campers: [],
      },
    };
  },
  methods: {
    toggleCampToWebsite() {
      this.camp.isPostedToWebsite = !this.camp.isPostedToWebsite;
      let url = process.env.VUE_APP_DB_URL + "teamCamp/toggleCampToWebsite";
      axios.post(
        url,
        {
          campId: this.camp._id,
          isPostedToWebsite: this.camp.isPostedToWebsite,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        }
      );
    },
    formatCamperDataForExport() {
      let campers = [];
      for (let team in this.camp.teams) {
        for (let camper in this.camp.teams[team].campers) {
          let temp = this.camp.teams[team].campers[camper];
          temp.team = this.camp.teams[team].teamName;
          campers.push(temp);
        }
      }
      this.formattedCampers = campers;
    },

    formatTeamDataForExport() {
      let teams = [];
      for (let team in this.camp.teams) {
        let temp = this.camp.teams[team];

        teams.push(temp);
      }
      this.formattedTeams = teams;
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    addCustomFieldsExport() {
      //camperCustomFields
      for (let x = 0; x < this.camp.camperCustomFields.length; x++) {
        this.json_fields[this.camp.camperCustomFields[x].fieldName] = {
          callback: (value) => {
            return value.customFieldValues[x].fieldValue;
          },
        };
      }
      //coachCustomFields
      for (let x = 0; x < this.camp.coachCustomFields.length; x++) {
        this.team_fields[this.camp.coachCustomFields[x].fieldName] = {
          callback: (value) => {
            return value.customFieldValues[x].fieldValue;
          },
        };
      }
    },
    addCustomAddOnsExport() {
      if (this.camp.customAddOns) {
        for (let x = 0; x < this.camp.camperCustomAddOns.length; x++) {
          this.json_fields[
            "Camper Purchased " + this.camp.camperCustomAddOns[x].addOnName
          ] = {
            callback: (value) => {
              return value.customAddOnValues[x].isPurchased;
            },
          };
        }
      }
    },
    async sendEmail() {
      let url = process.env.VUE_APP_DB_URL + "user/emailAll";
      axios
        .post(
          url,
          {
            subject: this.message.subject,
            intro: this.message.intro,
            body1: this.message.body1,
            body2: this.message.body2,
            email: this.user.email,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            campId: this.camp.campStripeId,
            type: "camp",
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
          this.newMembership = this.savedData;
        });
      swal("Success", "Message Sent!", "success");
    },
    async sendText() {
      if (this.camp.textLimit > 2) {
        swal(
          "Sorry!",
          "You have already reached your text limit for this camp",
          "error"
        );
      } else {
        let url = process.env.VUE_APP_DB_URL + "user/textAll";
        axios
          .post(
            url,
            {
              content: this.text.content,
              phoneNumber: this.user.phoneNumber,
              campId: this.camp.campStripeId,
              coachID: this.user._id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            }
          )
          .then((response) => {
            if (response.data.success) {
              this.camp.textLimit += 1;
              swal("Success", "Message Sent!", "success");
            } else {
              swal(
                "Sorry!",
                "You have already reached your text limit for this camp",
                "error"
              );
            }
          })
          .catch(function (err) {
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
            this.newMembership = this.savedData;
          });
      }
    },
    dayToString(date) {
      let temp = new Date(date);
      return temp.toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    handleClick() {
      this.$router.push("/Dashboard/TeamList/" + this.campId);
    },
    copyCamp() {
      this.$router.push("/Dashboard/CreateCamp/Team/" + this.campId);
    },

    async getCamps() {
      let url =
        process.env.VUE_APP_DB_URL + "teamCamp/teamcamps?id=" + this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i]._id == this.campId) {
              this.camp = response.data[i];
              if (response.data[i].teams.length < 3) {
                if (!this.camp.tShirtsProvided) {
                  this.steps.splice(1, 1);
                  console.log(this.steps);
                }
                this.$tours["viewTeamCampTour"].start();
              }
            }
          }

          this.formatCamperDataForExport();
          this.formatTeamDataForExport();
          this.addCustomFieldsExport();
          this.addCustomAddOnsExport();
          if (this.camp.isPostedToWebsite == null) {
            this.camp.isPostedToWebsite = false;
          }
          this.this.temp = response.data;
        })
        .catch(function (err) {
          console.log(err);
          // let error = err.response;
          // swal("Error", error.data.errorMessage, "error");
        });
    },
    //delete camps
    async deleteCamp() {
      swal({
        title: "Confirm Deletion",
        text: "Once the camp is deleted, all camper information is lost, and you are responsible for reaching out to the campers to refund and inform them of the cancellation. ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let url = process.env.VUE_APP_DB_URL + "teamCamp/deleteTeamCamp";
          axios
            .post(
              url,
              {
                campId: this.campId,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
            });
          swal("Success", "Camp Deleted", "success");
          this.$router.push("/Dashboard");
        }
      });
    },
    setTabStyle(type) {
      this.messageBool = type === "email";
      this.textBool = type === "text";
      var emailTabHeader = document.getElementById("emailTabHeader");
      var emailTabBody = document.getElementById("emailTabBody");
      var textTabHeader = document.getElementById("textTabHeader");
      var textTabBody = document.getElementById("textTabBody");
      if (type === "email") {
        emailTabHeader.style.backgroundColor = "#19d412";
        emailTabBody.style.backgroundColor = "white";
        textTabHeader.style.backgroundColor = "lightgrey";
        textTabBody.style.backgroundColor = "whitesmoke";
      } else if (type === "text") {
        textTabHeader.style.backgroundColor = "#19d412";
        textTabBody.style.backgroundColor = "white";
        emailTabHeader.style.backgroundColor = "lightgrey";
        emailTabBody.style.backgroundColor = "whitesmoke";
      } else {
        emailTabHeader.style.backgroundColor = "#19d412";
        emailTabBody.style.backgroundColor = "white";
        textTabHeader.style.backgroundColor = "lightgrey";
        textTabBody.style.backgroundColor = "whitesmoke";
      }
    },
  },

  created() {
    this.getUserDetails();
    this.campId = this.$route.params.id;
    this.getCamps();
  },
  computed: {
    determineSteps() {
      if (this.isMobile()) {
        return this.mobileSteps;
      }
      return this.steps;
    },
    getCollectFromString() {
      if (this.camp.collectFrom == "team") {
        return "/per team";
      }
      return "/per camper";
    },
    getCampLogo() {
      if (this.camp.campLogo != "" && this.camp.campLogo != null) {
        return this.camp.campLogo;
      }
      return "https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png";
    },
    getFormattedAddOns() {
      let temp = [];
      let count;
      if (this.camp.customAddOns) {
        for (count = 0; count < this.camp.camperCustomAddOns.length; count++) {
          let numCampersRegistered = 0;
          let x;
          let i;
          if (this.camp.teams) {
            for (x = 0; x < this.camp.teams.length; x++) {
              for (i = 0; i < this.camp.teams[x].campers.length; i++) {
                if (
                  this.camp.teams[x].campers[i].customAddOnValues[count]
                    .isPurchased
                ) {
                  numCampersRegistered = numCampersRegistered + 1;
                }
              }
            }
          }
          temp.push({
            addOnName: this.camp.camperCustomAddOns[count].addOnName,
            numCampers: numCampersRegistered,
          });
        }
      }
      return temp;
    },
    getFormattedData() {
      let exportData = [];
      for (let x = 0; x++; x < this.camp.campers.length) {
        exportData[x] = this.camp.campers[x];
      }
      return exportData;
    },
    getCampURL() {
      return process.env.VUE_APP_TEAMCAMP_URL + "register/team/" + this.campId;
    },
    getQrLink() {
      return (
        "https://api.qrserver.com/v1/create-qr-code/?data=" +
        process.env.VUE_APP_TEAMCAMP_URL +
        "register/team/" +
        this.campId +
        "&amp;size=100x100"
      );
    },
    getCampDays() {
      return this.camp.campDates;
    },
    getParticipants() {
      return this.camp.campMaxParticipants == null;
    },
    getFormattedRegDate() {
      let temp = new Date(this.camp.campRegistrationDeadline);
      let time = temp.toLocaleDateString();
      return time;
    },
    getFormattedCampDate() {
      // let time = null;
      // try {
      //   let temp = new Date(this.camp.campDates[0].start);
      //   time = temp.toLocaleString();
      // } catch (err) {
      //   return time;
      // }
      //return time;
      return new Date();
    },
    getCampers() {
      let numCampers = 0;
      for (let team in this.camp.teams) {
        numCampers = this.camp.teams[team].campers.length + numCampers;
      }
      return numCampers;
    },
    getMoney() {
      let campBaseCost = 0;
      //cycle campers
      let addOnCost = 0;
      if (this.camp.collectFrom == "camper") {
        campBaseCost = this.getCampers * this.camp.campPrice;
        for (let team in this.camp.teams) {
          for (
            let camper = 0;
            camper < this.camp.teams[team].campers.length;
            camper++
          ) {
            for (
              let addOn = 0;
              addOn <
              this.camp.teams[team].campers[camper].customAddOnValues.length;
              addOn++
            ) {
              if (
                this.camp.teams[team].campers[camper].customAddOnValues[addOn]
                  .isPurchased
              ) {
                addOnCost =
                  addOnCost +
                  this.camp.teams[team].campers[camper].customAddOnValues[addOn]
                    .addOnPrice;
              }
            }
          }
        }
      } else {
        if (this.camp.teams) {
          campBaseCost = this.camp.teams.length * this.camp.campPrice;
        } else {
          campBaseCost = 0;
        }
        for (let team in this.camp.teams) {
          for (
            let addOn = 0;
            addOn < this.camp.teams[team].customAddOnValues.length;
            addOn++
          ) {
            if (this.camp.teams[team].customAddOnValues[addOn].isPurchased) {
              addOnCost =
                addOnCost +
                this.camp.teams[team].customAddOnValues[addOn].addOnPrice;
            }
          }
        }
      }

      return campBaseCost + addOnCost;
    },
    getTeams() {
      return this.camp.teams ? this.camp.teams.length : 0;
    },
    getYS() {
      let size = "YS";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }

      return shirts;
    },
    getYM() {
      let size = "YM";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getYL() {
      let size = "YL";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getAS() {
      let size = "AS";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getAM() {
      let size = "AM";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getAL() {
      let size = "AL";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getAXL() {
      let size = "AXL";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getAXXL() {
      let size = "AXXL";
      let shirts = 0;
      if (this.camp.teams) {
        for (let i = 0; i < this.camp.teams.length; i++) {
          for (let x = 0; x < this.camp.teams[i].campers.length; x++) {
            console.log(this.camp.teams[i].campers[x].tShirtsSize);
            if (this.camp.teams[i].campers[x].tShirtSize == size) {
              shirts++;
            }
          }
        }
      }
      return shirts;
    },
    getRevenueGoal() {
      return this.camp.campRevenueGoal ? this.camp.campRevenueGoal : 20000;
    },
    getRevenueGoalProgress() {
      const currentRevenue = this.getMoney;
      const goal = this.camp.campRevenueGoal
        ? this.camp.campRevenueGoal
        : 10000;
      const percentage = (currentRevenue / goal) * 100;
      return percentage > 100 ? 100 : percentage;
    },
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.message_tab {
  background: whitesmoke;
  border-color: whitesmoke;
  border-width: 3px;
  transition: all 0.2s ease-in-out;
}
.message_tab .card-header {
  background: lightgrey;
  transition: 0.3s;
}
/*.message_tab:hover .card-header{*/
/*  background: #19d412;*/
/*}*/
.message_tab:hover {
  background: white;
  cursor: pointer;
  transform: scale(1.1);
}

.off_tab {
  background: whitesmoke;
}
.on_tab {
  background: #19d412;
}

img.rounded-corners {
  border-radius: 30px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #363166;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #363166;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #363166;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #363166;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(2);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(2.15);
  }
}

.actionFeature {
  background-color: #a39ed0;
}

.action-button {
  background-color: #363166;
}

.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}
.actionHub:hover {
  box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.5);
  cursor: pointer;
}
</style>
