<template>
  <div class="d-flex justify-content-between">
    <div>{{ camperName }} Registration</div>
    <div>${{ this.camperPrice.toFixed(2) }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    camperNumber: Number,
    camperPrice: Number,
    camperName: String,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
