<template>
  <div
    id="app"
    class="mt-5 container"
    :style="{ marginLeft: isMobile ? '21px' : '' }"
  >
    <div class="card-form">
      <div class="card-form__inner">
        <img
          src="../../../../public/SummerAthletes.png"
          class="card-img-top rounded-corners"
          alt=""
        />
        <ul class="list-group" style="margin-top: 20px">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>Summer Athletes </b>
              <span
                class="badge actionFeature"
                style="margin-left: 7px; font-size: 15px"
              >
                {{ packageName }}
              </span>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Package Price</div>
              <div>${{ this.price.toFixed(2) }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Credit Processing Fee</div>
              <div>${{ (this.price * 0.05).toFixed(2) }}</div>
            </div>
          </li>
        </ul>
        <div>
          <br />
          <button class="card-form__button rounded-pill" @click="submitPayment">
            Pay ${{ (this.price * 1.05).toFixed(2) }}
          </button>
          <br />
          <br />
          <br />
          <button
            :class="{
              'card-form__button': true,
              'w-25': !isMobile,
              'w-50': isMobile,
              'rounded-pill': true,
            }"
            :style="{
              backgroundColor: '#363166',
              color: 'white',
            }"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isMobile: Boolean,
    packageName: String,
    price: Number,
  },
  data() {
    return {};
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submitPayment() {
      this.$emit("proceed");
    },
  },
  created() {},
  computed: {},
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
.actionFeature {
  background-color: #a39ed0;
}
</style>
