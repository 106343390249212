<template>
  <div>
    <div class="card-input">
      <input
        type="text"
        id="city"
        placeholder="Title"
        class="card-input__input"
        v-model="local.title"
        style="margin-bottom: 0"
        @change="handleChange"
      />
    </div>
    <textarea
      class="form-control"
      id="form6Example7"
      rows="4"
      placeholder="Notes"
      style="margin-bottom: 15px"
      required
      v-model="local.notes"
      @change="handleChange"
    ></textarea>
  </div>
</template>
<script>
export default {
  data() {
    return {
      local: {},
    };
  },
  props: {
    note: Object,
  },
  methods: {
    handleChange() {
      this.$emit("changed", this.local);
    },
  },

  created() {
    this.local = this.note;
  },
};
</script>
