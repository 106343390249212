var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container justify-content-center",style:({ marginLeft: _vm.isMobile() ? '21px' : '' }),attrs:{"id":"app"}},[_c('Layout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container justify-content-center"},[_c('div',{staticClass:"container mt-5 justify-content-center"},[_c('div',{staticClass:"row text-center v-step-3",staticStyle:{"margin-top":"35px"}},[_c('h1',{staticClass:"text-center"},[_vm._v("My Activities")]),_c('h5',{staticClass:"fst-italic text-center mb-3"},[_vm._v(" Stay updated and in the know on all your activities ")]),_c('div',{staticClass:"col-md-4 col-sm-6 mt-3",style:({
            width: _vm.isMobile() ? '99%' : '',
            marginLeft: _vm.isMobile() ? '21px' : '',
            textAlign: _vm.isMobile() ? 'center' : '',
          })},_vm._l((_vm.getRecruitActivities),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 0)?_c('ActivityCard',{staticClass:"grow",attrs:{"activity":event},on:{"show-details":_vm.showActivityDetails}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
            width: _vm.isMobile() ? '99%' : '',
            marginLeft: _vm.isMobile() ? '21px' : '',
            textAlign: _vm.isMobile() ? 'center' : '',
          })},_vm._l((_vm.getRecruitActivities),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 1)?_c('ActivityCard',{staticClass:"grow",attrs:{"activity":event},on:{"show-details":_vm.showActivityDetails}}):_vm._e()],1)}),0),_c('div',{staticClass:"col-md-4 col-sm-6",style:({
            width: _vm.isMobile() ? '99%' : '',
            marginLeft: _vm.isMobile() ? '21px' : '',
            textAlign: _vm.isMobile() ? 'center' : '',
          })},_vm._l((_vm.getRecruitActivities),function(event,index){return _c('ul',{key:event._id,staticClass:"list-group"},[(index % 3 == 2)?_c('ActivityCard',{staticClass:"grow",attrs:{"activity":event},on:{"show-details":_vm.showActivityDetails}}):_vm._e()],1)}),0)])]),_c('activity-details-modal',{ref:"activityModal",attrs:{"activity":_vm.getSelectedActivity}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }