<template>
  <div id="app">
    <nav
      class="navbar navbar-expand-lg fixed-top"
      v-bind:style="{
        backgroundColor: primaryColor,
        boxShadow: '0 30px 60px 0 rgba(90, 116, 148, 0.5)',
      }"
    >
      <div class="container">
        <p style="color: white; font-size: 150%; margin-top: 5px">
          {{ title }}
        </p>

        <div class="navbar-nav">
          <ul class="navbar-nav">
            <!-- <li class="nav-item" style="margin-left: 5px">
                <a
                  href="https://www.summerathletes.com"
                  class="btn btn-primary"
                  target="_blank"
                  >Powered By Summer Athletes</a
                >
              </li> -->
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
//import axios from "axios";
//import swal from "sweetalert";
export default {
  components: {},
  data() {
    return {};
  },
  props: {
    title: String,
    primaryColor: String,
  },
  methods: {},
  created() {},
  computed: {},
};
</script>
<style scoped>
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
</style>
