<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner">
          <img
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
          />
          <div class="card-body">
            <center>
              <h1 class="card-title">Success!</h1>
              <br />
              <h3>You're signed up</h3>
              <br />
              <h5>
                We’re so happy we were able to help you register and pay. Stay a game changer!
              </h5>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
