import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=1935b8c0&scoped=true"
import script from "./Footer.vue?vue&type=script&setup=true&lang=js"
export * from "./Footer.vue?vue&type=script&setup=true&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=1935b8c0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1935b8c0",
  null
  
)

export default component.exports