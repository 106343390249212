<template>
  <div class="card" style="max-height: 1200px; overflow: auto">
    <table class="table table-hover" style="">
      <thead>
        <tr>
          <th scope="col" style="position: sticky; top: 0px">Name</th>
          <th scope="col" style="position: sticky; top: 0px">
            First Month's Price
          </th>
          <th scope="col" style="position: sticky; top: 0px">
            Subsequent Monthly Price
          </th>
          <th scope="col" style="position: sticky; top: 0px">
            Amount of Referrals
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="recruitReferral in this.recruitReferrals"
          v-bind:key="recruitReferral.id"
        >
          <td>
            {{
              recruitReferral.name
                ? recruitReferral.name
                : recruitReferral.referralProvider.name
            }}
            <br />
            <a
              target="_blank"
              :href="getReferralLink(recruitReferral.referralCode)"
              >Referral Link</a
            >
          </td>
          <td>{{ recruitReferral.firstMonthPrice }}</td>
          <td>
            {{ recruitReferral.recurringMonthlyPrice }}
          </td>
          <td>{{ recruitReferral.referrals }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    recruitReferrals: Array,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    getReferralLink(referralCode) {
      return (
        process.env.VUE_APP_CLIENT_URL +
        "RecruitExternalCheckout?referralCode=" +
        referralCode
      );
    },
  },
  computed: {},
};
</script>

<style scoped></style>
