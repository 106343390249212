<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner">
          <img src="../../../public/SummerAthletes.png" class="card-img-top" />
          <div class="card-body">
            <center>
              <h1 class="card-title">Welcome to your future.</h1>
              <br />
              <br />
              <p>
                Summer Athletes is an online platform for athletes to create a
                profile and customized recruiting website. Coaches can find
                athletes and interact with them through their website.
              </p>
              <button class="card-form__button" @click="this.toSignup">
                Are <b>you</b> ready?
              </button>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    toSignup() {
      this.$router.push("/RecruitExternalCheckout");
    },
  },
};
</script>
<style scoped>
</style>
