<template>
  <div
    class="information-card p-4 shadow-sm"
    @click="buySubscription(subscription)"
    v-bind:style="{
      maxWidth: isMobile ? '90%' : '275px',
      cursor: 'pointer',
      backgroundColor: '#363166 !important',
      color: 'white',
      borderRadius: '10px',
      position: 'relative',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }"
  >
    <!-- Most Popular Tag -->
    <div class="most-popular-tag text-center text-uppercase mb-3">
      Most Popular
    </div>

    <h5 class="card-title text-center mb-3">
      <span class="badge actionFeature mb-2 d-block">{{
        subscription.subscriptionName
      }}</span>
      <span
        class="text-muted"
        style="font-size: 15px; color: white !important"
        >{{ yearly ? "Billed Annually" : "Billed Monthly" }}</span
      >
    </h5>

    <h5 class="card-body text-center">
      <!-- Regular Pricing Display -->
      <div>
        <label
          v-if="yearly"
          class="d-block"
          style="text-decoration: line-through"
          >${{ getFakeYearly }}</label
        >
        <span class="price">
          <i
            class="fa-solid fa-ranking-star fa-xl m-1"
            style="color: #ffc107"
          ></i>
          <label
            class="d-inline-block"
            style="font-size: 24px; font-weight: bold; color: white"
          >
            ${{ yearly ? getYearly + " / year" : getMonthly + " / month" }}
          </label>
        </span>
      </div>
    </h5>

    <button
      class="btn btn-light rounded-pill mt-4 w-100"
      id="regularSignInBtn"
      @click="buySubscription(subscription)"
    >
      <h5>
        {{ this.getButtonText }}
      </h5>
    </button>

    <p class="text-muted text-center mt-2" style="color: white !important">
      Everything in the Pro, Plus...
    </p>

    <!-- Feature List -->
    <ul class="plan-details__item list-unstyled">
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Customer Checkout Fee ~
          {{ (subscription.customerUpchargePercentage - 3).toFixed(1) }}%
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Calendar/Session Scheduling
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Enhanced Banking Suite
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Free Priority Transfers
        </p>
      </li>
      <li class="mb-3">
        <p class="plan-details__item-title">
          <i class="fa-solid fa-check-circle" style="color: #19d412"></i>
          Unlimited Mass Texting
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";

export default {
  props: {
    promo: {
      type: Boolean,
      default: false,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subscription: {
        monthlyPrice: 0,
        yearlyPrice: 0,
        customerUpchargePercentage: 0,
        subscriptionName: "",
      },
    };
  },
  methods: {
    buySubscription(plan) {
      this.$emit("buySubscription", plan);
    },
    getSubscription() {
      let url =
        process.env.VUE_APP_DB_URL +
        "subscription/getSubscriptionByName?name=All Star";
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.subscription = response.data.subscription;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getSubscription();
  },

  computed: {
    getFakeYearly() {
      return numeral(this.subscription.monthlyPrice * 12).format("0,0.00");
    },

    getYearly() {
      return numeral(this.subscription.yearlyPrice).format("0,0.00");
    },

    getMonthly() {
      return numeral(this.subscription.monthlyPrice).format("0,0.00");
    },
    getButtonText() {
      return "Get Started";
    },
  },
};
</script>

<style scoped>
/* Most Popular Tag */
.most-popular-tag {
  background-color: #ffc107;
  padding: 0.5rem;
  color: #363166;
  font-weight: bold;
  border-radius: 5px;
}

/* Action Feature Badge */
.actionFeature {
  background-color: #007bff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.grow {
  transition: all 0.3s ease-in-out;
}

.grow:hover {
  transform: scale(1.05);
}

.original-price {
  text-decoration: line-through;
  color: #999;
}

.promotional-price {
  font-weight: bold;
  color: #4caf50;
}

.list-unstyled li {
  display: flex;
  align-items: center;
}

.list-unstyled li i {
  margin-right: 8px;
}

.card-body {
  font-size: 20px;
  font-weight: bold;
  color: #ffc107;
}

.price {
  font-size: 24px;
  color: #ffc107;
}

.plan-details__item-title {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
