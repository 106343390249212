var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-form",style:({
      width: _vm.isMobile() ? '' : '25rem',
      marginBottom: '15px',
    })},[_c('div',{staticClass:"card-form__inner",style:({
        width: _vm.isMobile() ? '' : '25rem',
        textAlign: _vm.isMobile() ? 'center' : '',
      })},[(!this.logo)?_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":require("../../../public/SummerAthletes.png")}}):_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.logo}}),_c('div',{staticClass:"card-body mt-3"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(this.title))]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.getDate)+" ")]),_c('button',{staticClass:"card-form__button rounded-pill"},[_c('a',{on:{"click":function($event){return _vm.handleClick()}}},[_vm._v("View Details")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }