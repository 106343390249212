<template>
  <div
    class="card grow m-3 card-form__inner"
    style="max-width: 700px; min-height: 203px"
  >
    <div v-if="runLoading" class="card-body row">
      <div class="col-md-6 col-sm-6" style="margin-top: 10px">
        <h2 v-bind:style="{ textAlign: isMobile ? 'center' : '' }">
          <b v-if="loadPartOne">Mapping AI Routes...</b>
          <b v-else-if="loadPartTwo">Implementing AI Security...</b>
          <b v-else-if="loadPartThree">Running AI Diagnostics...</b>
          <b v-else-if="loadPartFour">Finishing AI Algorithms...</b>
        </h2>
        <div class="progress" v-if="loadPartOne">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 25%"
          ></div>
        </div>
        <div class="progress" v-if="loadPartTwo">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
            role="progressbar"
            aria-valuenow="60"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 50%"
          ></div>
        </div>
        <div class="progress" v-if="loadPartThree">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
            role="progressbar"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 75%"
          ></div>
        </div>
        <div class="progress" v-if="loadPartFour">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated progress-bar-background"
            role="progressbar"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 90%"
          ></div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <div
          v-bind:style="{
            textAlign: isMobile ? 'right' : '',
            marginTop: '10px',
            marginRight: '10px',
          }"
        >
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card-body row">
      <div class="col-md-10 col-sm-6" style="margin-top: 10px">
        <h2 class="text-start">
          <b
            ><a :href="this.url" target="_blank">{{ this.title }} </a>
          </b>
        </h2>
        <div v-if="this.fullyLoaded" class="progress">
          <div
            class="progress-bar progress-bar-background"
            role="progressbar"
            aria-valuenow="90"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 100%"
          ></div>
        </div>
      </div>
      <div class="col-md-2 col-sm-6">
        <div
          v-bind:style="{
            textAlign: isMobile ? 'right' : '',
            marginTop: '10px',
            marginRight: '10px',
          }"
          v-if="this.title !== 'Free Landing page'"
        >
          <a
            data-bs-toggle="modal"
            data-bs-target="#websiteSettings"
            target="_blank"
            style="cursor: pointer"
            @click="openWebsiteSettingsModal()"
            ><i class="fa-solid fa-gear fa-2xl"></i
          ></a>
        </div>
      </div>
      <br />
      <span
        ><i class="fa-solid fa-circle-check" style="color: #19d412"></i> Website
        Active</span
      >
      <span
        ><i class="fa-solid fa-circle-check" style="color: #19d412"></i>
        Monitoring Active</span
      >
    </div>
  </div>
</template>
<script>
// import swal from "sweetalert";
// import axios from "axios";
export default {
  data() {
    return {
      isCheckedIn: false,
      copied: false,
      runLoading: false,
      loadPartOne: true,
      loadPartTwo: false,
      loadPartThree: false,
      loadPartFour: false,
      fullyLoaded: false,
    };
  },
  components: {},
  props: {
    userId: String,
    title: String,
    url: String,
    id: String,
    subdomain: String,
    websiteSelected: Object,
    newWebsiteID: String,
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    openWebsiteSettingsModal() {
      this.websiteSelected.title = this.title;
      this.websiteSelected.url = this.url;
      this.websiteSelected.subdomain = this.subdomain;
    },
  },

  created() {},
  computed: {},
  mounted() {
    if (this.id === this.newWebsiteID) {
      this.runLoading = true;
      setTimeout(() => {
        this.runLoading = false;
        this.loadPartOne = true;
        this.loadPartFour = false;
        this.fullyLoaded = true;
      }, 60000);
      setTimeout(() => {
        this.loadPartOne = false;
        this.loadPartTwo = true;
      }, 15000);
      setTimeout(() => {
        this.loadPartTwo = false;
        this.loadPartThree = true;
      }, 30000);
      setTimeout(() => {
        this.loadPartThree = false;
        this.loadPartFour = true;
      }, 45000);
      setTimeout(() => {
        this.fullyLoaded = false;
      }, 61000);
    }
  },
};
</script>

<style>
.card3 {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;
}

.checkbox:checked:before {
  background-color: green;
}
.progress-bar-background {
  background-color: #363166 !important;
}

.progress-bar-background-complete {
  background-color: #19d412 !important;
}
</style>
