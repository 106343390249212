var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    forgot: true,
    loginFootball: _vm.getBackgroundClass === 'loginFootball',
    loginBaseball: _vm.getBackgroundClass === 'loginBaseball',
    loginBasketball: _vm.getBackgroundClass === 'loginBasketball',
    loginSoccer: _vm.getBackgroundClass === 'loginSoccer',
  }},[_c('ForgotPasswordComponent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }