<template>
  <div
    class="card m-3 card-form__inner"
    style="max-width: 700px; min-height: 300px"
  >
    <div class="card-body row">
      <div class="col-md-6 col-sm-6" style="margin-top: 10px">
        <img :src="getImage" class="profile-image" />
        <h2 v-bind:style="{ textAlign: isMobile ? 'center' : '' }">
          <b>{{ this.memberFirstName }} {{ this.memberLastName }}</b>
        </h2>
        <button class="card-form__button" style="margin-top: 10px">
          <a @click="toMemberProfile()">View Profile</a>
        </button>
      </div>
      <div
        class="col-md-6 col-sm-6"
        v-bind:style="{ textAlign: isMobile ? 'center' : 'left' }"
      >
        <div
          v-bind:class="{
            row: true,
            'justify-content-end': !isMobile(),
            'justify-content-center': isMobile(),
            'text-end': !isMobile(),
            'm-3': isMobile(),
          }"
        >
          <span v-if="this.isActive"
            >Active
            <i
              class="fa-solid fa-circle-check fa-2xl"
              style="color: #32bd28"
            ></i>
          </span>
          <span v-else
            >Inactive
            <i class="fa-solid fa-ban fa-2xl" style="color: #db2c1f"></i>
          </span>
        </div>
        <div class="row m-3 text-start">
          <span
            ><i
              class="fa-solid fa-credit-card fa-xl m-1"
              style="color: #363166"
            ></i>
            <label style="margin-right: 3px">
              Payment plan: {{ getSubscription }}</label
            >
          </span>
        </div>
        <div class="row m-3 text-start">
          <span
            ><i
              class="fa-solid fa-calendar-day fa-xl m-1"
              style="color: #363166"
            ></i>
            <label style="margin-right: 3px">
              Started {{ getStartDate }}
            </label>
          </span>
        </div>
        <div v-if="getTShirtString" class="row m-3 text-start">
          <span>
            <i class="fa-solid fa-shirt fa-xl m-1" style="color: #363166"></i>
            <label for="Received" style="margin-right: 3px"
              >Size: {{ getTShirtString }}
            </label>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      membershipID: "",
      memberFirstName: "",
      memberLastName: "",
      memberIsCheckedIn: "",
      memberTShirtReceived: "",
      memberTShirtSize: "",
      showInfo: false,
    };
  },
  components: {},
  props: {
    firstName: String,
    lastName: String,
    subscriptionID: String,
    tShirtSize: String,
    membershipName: String,
    memberID: String,
    isActive: Boolean,
    paymentPeriod: String,
    startDate: Date,
    profileImage: String,
    user: {},
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    toMemberProfile() {
      this.$router.push(
        "/Dashboard/MembershipCheckIn/" +
          this.membershipID +
          "/" +
          this.memberID +
          "/" +
          this.subscriptionID
      );
    },
  },

  created() {
    this.membershipID = this.$route.params.id;
    this.memberFirstName = this.firstName;
    this.memberLastName = this.lastName;
    this.memberIsCheckedIn = this.isCheckedIn;
    this.memberTShirtReceived = this.tShirtReceived;
    this.memberTShirtSize = this.tShirtSize;
    this.membershipName = this.campName;
    this.memberEmergencyContactInfo = this.emergencyContactInfo;
  },
  computed: {
    getImage() {
      if (!this.profileImage) {
        return require("../../../../public/headshot-icon.png");
      } else {
        return this.profileImage;
      }
    },
    getTShirtString() {
      if (this.memberTShirtSize === "YS") {
        return "(Youth Small)";
      }
      if (this.memberTShirtSize === "YM") {
        return "(Youth Medium)";
      }
      if (this.memberTShirtSize === "YL") {
        return "(Youth Large)";
      }
      if (this.memberTShirtSize === "AS") {
        return "(Adult Small)";
      }
      if (this.memberTShirtSize === "AM") {
        return "(Adult Medium)";
      }
      if (this.memberTShirtSize === "AL") {
        return "(Adult Large)";
      }
      if (this.memberTShirtSize === "AXL") {
        return "(Adult XL)";
      }
      if (this.memberTShirtSize === "AXXL") {
        return "(Adult XXL)";
      }

      return null;
    },

    getSubscription() {
      if (this.paymentPeriod === "weeklyPrice") {
        return "Weekly";
      } else if (this.paymentPeriod === "monthlyPrice") {
        return "Monthly";
      } else if (this.paymentPeriod === "semiAnnualPrice") {
        return "Semi-Annual";
      } else if (this.paymentPeriod === "annualPrice") {
        return "Annual";
      } else {
        return "Inactive";
      }
    },

    getStartDate() {
      if (this.startDate) {
        let local = moment(this.startDate, "YYYY-MM-DD");
        return moment(local.local()).fromNow();
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
.profile-image {
  float: right;
  height: 100%;
  margin-right: 60% !important;
  margin-bottom: 0 !important;
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.card3 {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;

  .go-corner {
    opacity: 0.7;
  }

  &:hover {
    border: 1px solid #363166;
    background-color: #f0eff7;
    /*box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);*/
    /*z-index: 500;*/
    transition: 0.3s linear;
  }
}

.card3:hover {
  p {
    transition: 0.3s linear;
  }
  .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
}
</style>
