<template>
  <div id="app">
    <TeamCheckoutFree v-if="this.isFree"></TeamCheckoutFree>
    <TeamCheckoutPaid v-if="this.isPaid"></TeamCheckoutPaid>
  </div>
</template>

<script>
import TeamCheckoutPaid from "../../components/TeamCamps/PayPerTeam/TeamCheckout.vue";
import TeamCheckoutFree from "../../components/TeamCamps/PayPerCamper/TeamCheckout.vue";
import axios from "axios";

export default {
  components: {
    TeamCheckoutFree,
    TeamCheckoutPaid,
  },
  data() {
    return {
      camp: {},
    };
  },
  methods: {
    async getCampInfo() {
      let url =
        process.env.VUE_APP_DB_URL + "teamCamp/getTeamCamp?id=" + this.campId;
      axios.get(url, {}).then((response) => {
        this.camp = response.data;
      });
    },
  },
  created() {
    this.campId = this.$route.params.campId;
    this.getCampInfo();
    //make axios api call to get camp data here
  },
  computed: {
    isFree() {
      return this.camp.collectFrom == "camper";
    },
    isPaid() {
      return this.camp.collectFrom == "team";
    },
  },
};
</script>
