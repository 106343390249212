var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.photoRightAlign)?_c('article',{staticClass:"postcard dark red",staticStyle:{"display":"none"}},[_vm._m(0),_vm._m(1)]):_vm._e(),_c('article',{staticClass:"postcard dark blue",style:(_vm.getCardColor)},[_c('a',{staticClass:"postcard__img_link",attrs:{"href":"#"}},[_c('img',{staticClass:"postcard__img",attrs:{"src":_vm.photo,"alt":"Image Title"}})]),_c('div',{staticClass:"postcard__text"},[_c('h1',{staticClass:"postcard__title m-1",style:({
          color: _vm.photoRightAlign ? _vm.secondaryColor : _vm.primaryColor,
        })},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.heading))])]),_c('div',{staticClass:"postcard__bar m-1",style:({
          color: _vm.secondaryColor,
          backgroundColor: _vm.photoRightAlign ? _vm.primaryColor : _vm.secondaryColor,
        })}),_c('div',{staticClass:"postcard__preview-txt m-1",staticStyle:{"color":"white"},domProps:{"innerHTML":_vm._s(_vm.description)}}),(_vm.photoRightAlign)?_c('ul',{staticClass:"postcard__tagbox"},[_c('li',{staticClass:"tag__item"},[_vm._v("Dedicated")]),_c('li',{staticClass:"tag__item"},[_vm._v("Driven")]),_c('li',{staticClass:"tag__item"},[_vm._v("Unbreakable Focus")])]):_c('ul',{staticClass:"postcard__tagbox"},[_c('li',{staticClass:"tag__item"},[_vm._v("Unstoppable")]),_c('li',{staticClass:"tag__item"},[_vm._v("Groundbreaking")]),_c('li',{staticClass:"tag__item"},[_vm._v("Team Leader")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"postcard__img_link",attrs:{"href":"#"}},[_c('img',{staticClass:"postcard__img",attrs:{"src":"https://picsum.photos/501/500","alt":"Image Title"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"postcard__text"},[_c('h1',{staticClass:"postcard__title red"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Podcast Title")])]),_c('div',{staticClass:"postcard__subtitle small"},[_c('time',{attrs:{"datetime":"2020-05-25 12:00:00"}},[_c('i',{staticClass:"fas fa-calendar-alt mr-2"}),_vm._v("Mon, May 25th 2020 ")])]),_c('div',{staticClass:"postcard__bar"}),_c('div',{staticClass:"postcard__preview-txt"}),_c('ul',{staticClass:"postcard__tagbox"},[_c('li',{staticClass:"tag__item"},[_c('i',{staticClass:"fas fa-tag mr-2"}),_vm._v("Podcast")]),_c('li',{staticClass:"tag__item"},[_c('i',{staticClass:"fas fa-clock mr-2"}),_vm._v("55 mins.")]),_c('li',{staticClass:"tag__item play red"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-play mr-2"}),_vm._v("Play Episode")])])])])
}]

export { render, staticRenderFns }