<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner">
          <img
            src="../../../../public/DashLogo.png"
            class="card-img-top"
            style="max-width: 50px"
          />
          <div class="card-body">
            <center>
              <h3>Success!</h3>
              <br />
              <h5>
                Thank you for your purchase. You should be logged into your
                account in a new tab, but if not <a :href="link">click here</a>.
              </h5>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    link: String,
  },
  data() {
    return {};
  },
};
</script>
