<template>
  <div>
    <div v-if="this.field.isPurchased">
      <div class="d-flex justify-content-between">
        <div>{{ this.field.addOnName }}</div>
        <div>${{ this.field.addOnPrice.toFixed(2) }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    field: Object,
  },
  data() {
    return {
      fieldValue: "",
      fieldValue2: "",
    };
  },
  computed: {
    getField() {
      return this.field;
    },
  },
};
</script>
<style></style>
