<template>
  <div>
    <div
      :class="{
        wrapperNew: true,
        'rounded-5': true,
        'mb-5': isMobile,
      }"
      :style="{ marginRight: isMobile ? '33px' : '' }"
    >
      <div class="colsNew">
        <div class="colNew" style="max-height: 550px">
          <div class="containerNew">
            <div
              class="front"
              :style="{
                backgroundImage: `url(${photo})`,
                minHeight: isMobile ? '612px' : '575px',
                maxHeight: isMobile ? '612px' : '575px',
                minWidth: isMobile ? '350px' : '550px',
                maxWidth: isMobile ? '350px' : '550px',
              }"
            >
              <div class="inner">
                <p>{{ recruitName }}</p>
                <span>Changing the Game</span>
              </div>
            </div>
            <div
              class="back"
              :style="{
                minHeight: isMobile ? '612px' : '575px',
                maxHeight: isMobile ? '612px' : '575px',
                minWidth: isMobile ? '350px' : '550px',
                maxWidth: isMobile ? '350px' : '550px',
                background: '#3e3e3e',
              }"
            >
              <div class="inner">
                <ContactCard
                  class=""
                  :style="{
                    minHeight: isMobile ? '580px' : '540px',
                    maxHeight: isMobile ? '580px' : '540px',
                    minWidth: isMobile ? '300px' : '500px',
                    maxWidth: isMobile ? '300px' : '500px',
                  }"
                  :primaryColor="this.primaryColor"
                  :secondaryColor="this.secondaryColor"
                  :recruitID="recruitID"
                  :isMobile="isMobile"
                ></ContactCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactCard from "../Contact/ContactCard.vue";
export default {
  components: { ContactCard },
  props: {
    primaryColor: String,
    secondaryColor: String,
    photo: String,
    isMobile: Boolean,
    recruitID: String,
    recruitName: String,
  },
};
</script>

<style>
.wrapperNew {
  width: 90%;
  margin: 0 auto;
  max-width: 80rem;
}

.colsNew {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.colNew {
  width: calc(100%);
  margin: 1rem;
  cursor: pointer;
}

.containerNew {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.front,
.back {
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-position: center;
  -webkit-transition: -webkit-transform 5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 5s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 5s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 5s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: center;
  min-height: 280px;
  height: auto;
  border-radius: 10px;
  font-size: 1.5rem;
}

.front:after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  opacity: 0.6;
  background-color: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 10px;
}
.containerNew:hover .front,
.containerNew:hover .back {
  -webkit-transition: -webkit-transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: -webkit-transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  -o-transition: transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transition: transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1),
    -webkit-transform 1.5s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.inner {
  -webkit-transform: translateY(-50%) translateZ(60px);
  transform: translateY(-50%) translateZ(60px) scale(0.99);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
}

.containerNew .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerNew .front {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerNew:hover .back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.containerNew:hover .front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.front .inner p {
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.front .inner p:after {
  content: "";
  width: 4rem;
  height: 2px;
  position: absolute;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -0.75rem;
}

.front .inner span {
  font-family: "Montserrat";
  font-weight: 300;
}
</style>
