<template>
  <div
    class="modal fade"
    id="ShowPhotoModal"
    tabindex="-1"
    aria-labelledby="ShowPhotoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">My Highlight Photo</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img
            :src="photo"
            alt=""
            style="max-height: 1100px; max-width: 770px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowPhotoModal",
  props: {
    photo: String,
  },
};
</script>

<style scoped></style>
