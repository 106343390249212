var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container",staticStyle:{"min-width":"100%"}},[_c('ClientLayout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container"},[_c('div',{staticClass:"container mt-5",style:({ marginLeft: _vm.isMobile ? '21px' : '' })},[_c('div',{staticClass:"card-form",staticStyle:{"min-width":"100%"}},[_c('center',[_c('img',{directives:[{name:"show",rawName:"v-show",value:(!this.isLoaded),expression:"!this.isLoaded"}],staticClass:"animated-gif center",attrs:{"id":"mygif","src":require("../../../../public/1495.gif")}})]),(this.isLoaded)?_c('div',{staticClass:"card-form__inner",staticStyle:{"text-align":"center"}},[_c('center',[_c('img',{staticClass:"card-img-top rounded-corners",staticStyle:{"box-shadow":"0 2px 2px 0 rgba(90, 116, 148, 0.4)"},style:({
                display: 'block',
                maxWidth: !_vm.isMobile() ? '100vh' : '100%',
                maxHeight: '35vh',
                width: 'auto',
                height: 'auto',
              }),attrs:{"src":_vm.getCreative,"id":"logoTop"}})]),_c('h2',{staticClass:"card-title"},[_c('b',[_vm._v(_vm._s(_vm.getTitle))])]),_c('div',{staticClass:"row mt-3 justify-content-center"},[_c('div',{class:{
                'information-card': true,
                'col-md-4': true,

                'col-sm-6': true,
                'text-start': true,
                'justify-content-center': true,
                'camp-step-0': true,
              }},[_c('h3',{staticClass:"text-center"},[_vm._v("Campaign Details")]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Platform")]),_vm._v(": "+_vm._s(this.data.platform))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Clicks")]),_vm._v(": "+_vm._s(_vm.getClicks)+" ")]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Impressions")]),_vm._v(": "+_vm._s(_vm.getImpressions))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Reach")]),_vm._v(": "+_vm._s(_vm.getReach))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Start Time")]),_vm._v(": "+_vm._s(_vm.getStart))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("End Time")]),_vm._v(": "+_vm._s(_vm.getEnd))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Description")]),_vm._v(": "+_vm._s(_vm.getDescription))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Post Caption")]),_vm._v(": "+_vm._s(_vm.getCaption))]),_c('div',{staticClass:"m-2"},[_c('b',[_vm._v("Website Target")]),_vm._v(": "),_c('a',{attrs:{"href":_vm.getWebsite}},[_vm._v(_vm._s(_vm.getWebsite))])])]),_c('div',{staticClass:"information-card col-md-3 col-sm-6 camp-step-1"},[_c('div',[_c('h3',[_vm._v("Age Demographics")]),_c('div',{staticClass:"text-start"},[_c('table',{staticClass:"table"},[_vm._m(0),_vm._l((_vm.getAgeCampaignData),function(data,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(data.age))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(data.clicks)))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(data.reach)))])])})],2)])])]),_c('div',{class:{
                'information-card': true,
                'col-md-4': true,
                'col-sm-6': true,
                'text-center': true,
                'camp-step-2': true,
              }},[_c('h3',[_vm._v("Location Demographics")]),_c('hr'),_c('table',{staticClass:"table"},[_vm._m(1),_vm._l((_vm.getRegionCampaignData),function(data,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(data.region))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(data.clicks)))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(data.reach)))])])})],2),_c('div',{staticClass:"mt-3"})])])],1):_vm._e()],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Age")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Clicks")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Reach")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Region")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Clicks")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Reach")])])])
}]

export { render, staticRenderFns }