<template>
  <div class="wrapper container">
    <section class="container">
      <div class="container mt-5">
        <div class="card-form">
          <div class="card-form__inner" style="text-align: center">
            <img
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
            />
            <li
              class="list-group-item"
              v-for="index in this.numFields"
              :key="index"
            >
              <div class="row">
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Field Name</label
                  >
                  <input
                    v-model="names[index - 1]"
                    required
                    id="form6Example1"
                    class="card-input__input"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Input Type</label
                  >
                  <select
                    class="card-input__input -select"
                    v-model="inputTypes[index - 1]"
                    required
                  >
                    <option disabled selected value="">Type</option>
                    <option value="height" class="option">Height</option>
                    <option value="email" class="option">Email</option>
                    <option value="phoneNumber" class="option">
                      Phone Number
                    </option>
                    <option value="textShort" class="option">Text-Short</option>
                    <option value="textLong" class="option">Text-Long</option>
                    <option value="number" class="option">Number</option>
                    <option value="yesNo" class="option">Yes/No</option>
                  </select>
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="inputPerson"
                    >Collect From</label
                  >
                  <select
                    class="card-input__input -select"
                    id="inputPerson"
                    v-model="inputPersons[index - 1]"
                    required
                  >
                    <option disabled selected value="">From</option>
                    <option value="coach" class="option">Coach</option>
                    <option value="player" class="option">Player</option>
                  </select>
                </div>
              </div>
            </li>

            <button class="card-form__button" @click="addField">
              Add custom field
            </button>
            <button
              class="card-form__button"
              @click="backToCamp"
              style="margin-top: 10px"
            >
              Back to camp overview
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      numFields: 0,
      names: [],
      inputTypes: [],
      inputPersons: [],
    };
  },

  props: {
    toggle: Number,
    customFields: Array,
  },
  methods: {
    prepopulate() {
      console.log("pre");
      console.log(this.numFields);
      this.numFields = this.customFields.length;
      for (let x = 0; x < this.customFields.length; x++) {
        this.names[x] = this.customFields[x].fieldName;
        this.inputTypes[x] = this.customFields[x].fieldType;
        this.inputPersons[x] = this.customFields[x].fieldPerson;
      }
    },
    addField() {
      console.log("here");
      this.numFields = this.numFields + 1;
      this.names.push("");
      this.inputTypes.push("");
      this.inputPersons.push("");
    },
    backToCamp() {
      let data = [this.names, this.inputTypes, this.inputPersons];
      let temp = data[0].length;
      for (let x = 0; x < temp; x++) {
        if (data[0][x] == "" || data[1][x] == "" || data[2][x] == "") {
          data[0].splice(x, 1);
          data[1].splice(x, 1);
          data[2].splice(x, 1);
          x = x - 1;
          temp = temp - 1;
        }
      }
      console.log(data[0]);
      console.log(data[1]);

      this.$emit("back", data);
    },
  },

  created() {
    console.log(this.fields);
    this.toggle1 = this.toggle;
  },
  mounted() {
    this.prepopulate();
  },
};
</script>
