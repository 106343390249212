<template>
  <div class="wrapper" id="app">
    <CampInfo
      v-if="this.toggle == 0"
      :campLogo="getCampLogo"
      :campName="this.camp.campName"
      :campDescription="this.camp.campDescription"
      :campLocation="this.camp.campLocation"
      :campPrice="this.camp.campPrice"
      :campDates="this.camp.campDates"
      :campRegistrationDeadline="this.camp.campRegistrationDeadline"
      :campAddOns="this.addOns"
      :campWaiver="getCampWaiver"
      :hidePlatformFees="this.camp.hidePlatformFees"
      buttonName="Proceed to checkout"
      priceSuperlative=" per camper"
      @proceed="toTeamInfo"
    ></CampInfo>
    <CamperInfo
      v-if="this.toggle == 1"
      :customFields="this.camp.camperCustomFields"
      :campLogo="getCampLogo"
      :tshirtBool="this.camp.tShirtsProvided"
      buttonName="Proceed to payment details"
      @proceed="toPayment"
    ></CamperInfo>
    <Payment v-if="this.toggle == 2" @proceed="toOrderSummary"></Payment>
    <OrderSummary
      v-if="this.toggle == 3"
      :campLogo="getCampLogo"
      :campName="this.camp.campName"
      :initial="this.camp.campPrice"
      :addOns="this.addOns"
      :hidePlatformFees="this.camp.hidePlatformFees"
      @proceed="registerCamper"
    ></OrderSummary>
  </div>
</template>

<script>
import CampInfo from "../../../components/CheckoutProcess/CampInfo.vue";
import CamperInfo from "../../../components/CheckoutProcess/CamperInfo.vue";
import Payment from "../../../components/CheckoutProcess/Payment.vue";
import OrderSummary from "../../../components/CheckoutProcess/OrderSummary.vue";
import swal from "sweetalert";
import axios from "axios";

export default {
  components: {
    CampInfo,
    CamperInfo,
    Payment,
    OrderSummary,
  },
  data() {
    return {
      campId: "",
      teamId: "",
      toggle: 0,
      camp: {},
      camperInfo: {},
      emergencyContactInfo: {},
      customFieldValues: [],
      addOns: [],
      billingInfo: {},
    };
  },
  methods: {
    formatEmergencyContactInfo() {
      if (this.emergencyContactInfo.emergencyBool) {
        this.emergencyContactInfo.address = this.billingInfo.address;
        this.emergencyContactInfo.city = this.billingInfo.city;
        this.emergencyContactInfo.email = this.billingInfo.email;
        this.emergencyContactInfo.firstName = this.billingInfo.firstName;
        this.emergencyContactInfo.lastName = this.billingInfo.lastName;
        this.emergencyContactInfo.phoneNumber = this.billingInfo.phoneNumber;
        this.emergencyContactInfo.state = this.billingInfo.state;
        this.emergencyContactInfo.zipCode = this.billingInfo.zipCode;
      }
    },
    formatAddOns() {
      for (let x = 0; x < this.camp.camperCustomAddOns.length; x++) {
        this.addOns.push({
          addOnName: this.camp.camperCustomAddOns[x].addOnName,
          addOnPrice: this.camp.camperCustomAddOns[x].addOnPrice,
          isPurchased: false,
        });
      }
    },
    async getCampInfo() {
      let url =
        process.env.VUE_APP_DB_URL + "teamCamp/getTeamCamp?id=" + this.campId;
      axios.get(url, {}).then((response) => {
        this.camp = response.data;
        let today = new Date();
        let deadline = new Date(this.camp.campRegistrationDeadline);
        let campStart = new Date(this.camp.campDates[0].start);
        this.formatAddOns();
        if (
          today > campStart ||
          (today > deadline && !this.camp.acceptLateRegistration)
        ) {
          window.location.href = "https://camps.summerathletes.com";
        }
      });
    },
    async registerCamper() {
      let url = process.env.VUE_APP_DB_URL + "teamCamp/registerPaidCamper";
      swal({
        title: "Wait for confirmation",
        text: "Please wait for confirmation",
        icon: "warning",
        buttons: false,
      });
      this.formatEmergencyContactInfo();
      await axios
        .post(url, {
          campId: this.campId,
          teamId: this.teamId,
          camperInfo: this.camperInfo,
          emergencyContactInfo: this.emergencyContactInfo,
          customFieldValues: this.customFieldValues,
          billingInfo: this.billingInfo,
          addOns: this.addOns,
        })
        .then(function () {
          swal("Success", "Registration Successful", "success");
          setTimeout(function () {
            let redirect = process.env.VUE_APP_CAMP_URL + "success";
            window.location.href = redirect;
          }, 1000);
          this.gif = false;
        })
        .catch(function (err) {
          console.log("err");

          let error = err.response;
          try {
            swal("Error", error.data.errorMessage, "error");
          } catch (e) {
            console.log("post failure");
          }
        });
      this.gif = false;
      this.toggle = 2;
    },
    validateInfo() {
      if (this.info.camperFirstName == "") {
        swal("Error", "Please enter camper first name", "error");
        return 1;
      }
      if (this.info.camperLastName == "") {
        swal("Error", "Please enter camper last name", "error");
        return 1;
      }
      if (this.info.camperSize == "" && this.tshirtBool) {
        swal("Error", "Please enter camper shirt size", "error");
        return 1;
      }

      //Check to make sure if custom fields exist for the camp. If so, validate camper inputs for them.
      if (
        this.customFields &&
        this.customFields[0] &&
        this.fieldValueInputs &&
        this.fieldValueInputs[0] !== {}
      ) {
        if (this.fieldValueInputs.length !== this.customFields.length) {
          swal(
            "Error",
            "Please fill out all form fields before continuing",
            "error"
          );
          return 1;
        } else {
          for (let index = 0; index < this.customFields.length; index++) {
            if (
              this.fieldValueInputs[index].fieldName !==
              this.customFields[index].fieldName
            ) {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            } else if (this.fieldValueInputs[index].fieldValue === "") {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            }
          }
        }
      }
      return 0;
    },
    toOrderSummary(x) {
      this.billingInfo = x;
      this.toggle = 3;
    },
    toPayment(x) {
      this.camperInfo = x.camperInfo;
      this.emergencyContactInfo = x.emergencyContactInfo;
      this.customFieldValues = x.customFieldValues;
      console.log(x);
      this.toggle = 2;
    },
    toTeamInfo() {
      this.toggle = 1;
    },
  },
  created() {
    this.campId = this.$route.params.campId;
    this.teamId = this.$route.params.teamId;
    this.getCampInfo();
    //make axios api call to get camp data here
  },
  computed: {
    getCampWaiver() {
      let waiver =
        "Summer Athletes accepts no liability for injury at any camp, and all refunds are handled by the camp organizer.";
      if (this.camp.requireWaiver) {
        waiver =
          "Summer Athletes and the organizer accept no liability for any injury at this camp, and all refunds are handled by the camp organizer.";
        if (this.camp.customWaiver != null) {
          if (this.camp.customWaiver != "")
            waiver =
              this.camp.customWaiver +
              "\n\nIn addition to the waiver above, Summer Athletes accepts no liability for injury at any camp. All refunds are handled by the camp organizer.";
        }
      }
      return waiver;
    },
    getCampLogo() {
      if (this.camp.campLogo != "" && this.camp.campLogo != null) {
        return this.camp.campLogo;
      }
      return "https://app.summerathletes.com/img/SummerAthletes.44f4dff8.png";
    },
  },
};
</script>
<style scoped>
body {
  background: #ddeefc;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 35px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 15px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #19d412;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  box-shadow: 3px 10px 20px 0 rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}
.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #08142f;
  height: 100%;
  border-radius: 5px;
  filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
  transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  padding-bottom: 6px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__dateTitle {
    font-size: 12px;
    padding-bottom: 5px;
  }
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}
.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}
.card-input {
  margin-bottom: 20px;
}
.card-input__label {
  margin-bottom: 5px;
  color: #000000;
  width: 100%;
  display: block;
  user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
}
.card-input__input:hover,
.card-input__input:focus {
  border-color: #19d412;
}
.card-input__input:focus {
  box-shadow: 0 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}
.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) {
  .github-btn:hover {
    transform: scale(1.1);
    box-shadow: 0 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
</style>
