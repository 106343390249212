<script setup></script>

<template>
  <div class="external" style="overflow-x: hidden">
    <div
      class="horizontal-scroll-wrapper"
      :style="{ overflowX: 'hidden', height: isMobile ? '200vw' : '125vw' }"
    >
      <div
        v-for="(video, index) in this.videos"
        :key="video._id"
        :class="{
          'img-wrapper': true,
          slower: index % 3 === 0,
          faster: index % 3 === 1,
          'slower vertical': index % 3 === 2,
        }"
      >
        <a
          rel="noopener"
          style="cursor: pointer"
          data-bs-toggle="modal"
          data-bs-target="#ShowVideoModal"
          @click="selectedVideo = video.url"
          ><video
            :src="video.url"
            controls
            style="max-height: 300px; max-width: 575px"
          />
        </a>
      </div>
      <div
        v-for="(photo, index) in this.photos"
        :key="photo._id"
        :class="{
          'img-wrapper': true,
          slower: index % 3 === 0,
          faster: index % 3 === 1,
          'slower vertical': index % 3 === 2,
        }"
      >
        <a
          rel="noopener"
          style="cursor: pointer"
          data-bs-toggle="modal"
          data-bs-target="#ShowPhotoModal"
          @click="selectedPhoto = photo.url"
          ><img :src="photo.url" alt="" />
        </a>
      </div>

      <!--      <div class="img-wrapper slower">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/stylish-parisian-cafe-terrace-279/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--          ><img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6c85858b-f495-4a54-9270-e6356f024c85basketballgym.jpg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper faster">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/retro-boy-doll-wearing-elegant-clothes-330/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower vertical">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/clocks-shop-exposition-window-reflecting-the-streets-277/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower slower-down">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/swans-and-ducks-swimming-in-a-river-264/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6c85858b-f495-4a54-9270-e6356f024c85basketballgym.jpg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/sidewalk-terrace-of-a-blue-facade-cafe-312/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/paris-waterfront-at-sunset-1555/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper faster1">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/old-man-leaning-over-the-barrier-looking-at-the-river-265/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower slower2">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/cafe-terrace-with-a-row-of-retro-tables-261/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/street-scene-with-pedestrians-and-dogs-318/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/tourist-barge-on-the-river-seine-near-notre-dame-266/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->

      <!--      <div class="img-wrapper slower last">-->
      <!--        <a-->
      <!--          href="https://altphotos.com/photo/skulls-decoration-in-a-shop-window-331/"-->
      <!--          target="_blank"-->
      <!--          rel="noopener"-->
      <!--        >-->
      <!--          <img-->
      <!--            src="https://camp-logos.s3.amazonaws.com/6a6b2469-5b65-4d0a-86a8-772bb996c252bball1.jpeg"-->
      <!--            alt=""-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <!--    <header>-->
    <!--      <p>Rise To Greatness.</p>-->
    <!--      <h1>Checkout My Gallery</h1>-->
    <!--    </header>-->
    <ShowPhotoModal :photo="this.selectedPhoto"></ShowPhotoModal>

    <ShowVideoModal :video="this.selectedVideo"></ShowVideoModal>
  </div>
</template>

<script>
import ShowPhotoModal from "./ShowPhotoModal.vue";
import ShowVideoModal from "./ShowVideoModal.vue";
export default {
  components: {
    ShowPhotoModal,
    ShowVideoModal,
  },
  props: {
    photos: Array,
    videos: Array,
    secondaryColor: String,
    isMobile: Boolean,
  },

  data() {
    return {
      selectedPhoto: "",
      selectedVideo: "",
    };
  },

  mounted() {
    let root = document.querySelector(":root");
    root.style.setProperty("--secondaryColor", this.secondaryColor);
  },
};
</script>

<style scoped>
:root {
  --secondaryColor: #000;
}

.external {
  overflow: hidden;
  height: 100vh;
}

.horizontal-scroll-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75vh;
  transform: rotate(-90deg) translate3d(0, -100vh, 0);
  transform-origin: right top;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 1px;
  transform-style: preserve-3d;
  padding: 0 0 10rem;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.horizontal-scroll-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.horizontal-scroll-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.img-wrapper {
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  transform-origin: 50% 50%;
  transform: rotate(90deg) translateZ(0.1px) scale(0.9) translateX(0px)
    translateY(-3vh);
  transition: 1s;
}

.img-wrapper:hover {
  min-height: 65vh;
}

.slower {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(0%)
    translateY(-10vh);
}
.slower1 {
  transform: rotate(90deg) translateZ(-0.25px) scale(1.05) translateX(0%)
    translateY(8vh);
}
.slower2 {
  transform: rotate(90deg) translateZ(-0.3px) scale(1.3) translateX(0%)
    translateY(2vh);
}
.slower-down {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(0%)
    translateY(16vh);
}
.faster {
  transform: rotate(90deg) translateZ(0.15px) scale(0.8) translateX(0%)
    translateY(14vh);
}
.faster1 {
  transform: rotate(90deg) translateZ(0.05px) scale(0.8) translateX(0%)
    translateY(10vh);
}
.fastest {
  transform: rotate(90deg) translateZ(0.22px) scale(0.7) translateX(-10vh)
    translateY(-15vh);
}
.vertical {
  transform: rotate(90deg) translateZ(-0.15px) scale(1.15) translateX(0%)
    translateY(0%);
}
.last {
  transform: rotate(90deg) translateZ(-0.2px) scale(1.1) translateX(25vh)
    translateY(-8vh);
}
header {
  bottom: 1rem;
}
.img-wrapper a {
  overflow: hidden;
  display: block;
  padding: 1vh;
  background: var(--secondaryColor);
  box-shadow: 0 10px 50px #5f2f1182;
}
img {
  max-width: 45vh;
  max-height: 50vh;
  transition: 0.5s;
  vertical-align: top;
  filter: saturate(40%) sepia(30%) hue-rotate(5deg);
}
a:hover img {
  filter: none;
}
.icon svg {
  width: 50px;
  fill: currentcolor;
}
</style>
