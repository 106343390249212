<script setup></script>

<template>
  <div>
    <nav class="fixed-top" role="navigation" :style="getMobileNavStyle">
      <div id="menuToggleRecruit">
        <input id="menuCheckBoxInput" type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul
          id="menuRecruit"
          :style="{ backgroundColor: '#110f16', color: primaryColor }"
        >
          <li @click="goToHome()"><h1>Home</h1></li>
          <li @click="goToMetrics()"><h1>My Metrics</h1></li>
          <li @click="goToHighlights()"><h1>Highlights</h1></li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
  },
  methods: {
    goToHome() {
      document.getElementById("menuCheckBoxInput").checked = false;
      window.scrollTo(0, 0);
      this.$emit("goToHome");
    },

    goToMetrics() {
      document.getElementById("menuCheckBoxInput").checked = false;
      window.scrollTo(0, 0);

      this.$emit("goToMetrics");
    },

    goToHighlights() {
      document.getElementById("menuCheckBoxInput").checked = false;
      window.scrollTo(0, 0);

      this.$emit("goToHighlights");
    },
  },
  computed: {
    getMobileNavStyle() {
      return `
      background: linear-gradient(0.75turn, ${this.primaryColor}, ${this.secondaryColor});
      height: 65px;
      `;
    },
  },
};
</script>

<style>
ul {
  padding: 0;
  list-style-type: none;
}

#menuToggleRecruit {
  display: flex;
  flex-direction: column;
  position: relative;
  top: 25px;
  left: 25px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggleRecruit input {
  display: flex;
  width: 40px;
  height: 32px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

#menuToggleRecruit span {
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: var(--primaryColor);
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggleRecruit span:first-child {
  transform-origin: 0% 0%;
}

#menuToggleRecruit span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggleRecruit input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #36383f;
}
#menuToggleRecruit input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggleRecruit input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menuRecruit {
  position: absolute;
  width: 300px;
  height: 1000px;
  box-shadow: 0 0 10px #85888c;
  margin: -50px 0 0 -50px;
  padding: 50px;
  padding-top: 125px;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuRecruit li {
  padding: 10px 0;
  transition-delay: 2s;
}

#menuToggleRecruit input:checked ~ ul {
  transform: none;
}
</style>
