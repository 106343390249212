<template>
  <div class="container">
    <!-- <v-tour name="myTour" :steps="this.steps"></v-tour> -->
    <section style="margin-top: 8%; margin-bottom: 4%">
      <div class="container mt-5">
        <div
          :class="{
            'card-form': true,
            'justify-content-center': true,
          }"
          v-bind:style="{
            marginLeft: isMobile() ? '21px' : '',
          }"
        >
          <div
            :class="{ 'card-form__inner': true }"
            v-bind:style="{
              minWidth: isMobile() ? '100%' : '',
              // minWidth: isMobile() ? '23rem' : '75%',
            }"
            style="margin-top: 0px"
          >
            <center>
              <img
                src="../../../public/SummerAthletes.png"
                class="card-img-top"
                style="max-width: 400px; max-height: 300px"
              />

              <!--              <img-->
              <!--                v-else-->
              <!--                :src="this.website.profile"-->
              <!--                class="card-img-top"-->
              <!--                style="max-width: 500px"-->
              <!--              />-->
              <img
                id="mygif"
                src="../../../public/1495.gif"
                class="animated-gif center"
                v-show="this.gif"
              />
            </center>
            <div style="min-height: 244px; max-height: 244px">
              <!--              <Transition name="slide-fade">-->
              <div v-if="this.formToggle === 0">
                <h5>1) Setup Your Website</h5>
                <hr />
                <label class="form-label mt-3" for="form6Example1"
                  >Domain Name</label
                >
                <i
                  class="fa-solid fa-circle-info fa-lg"
                  style="color: #45487a; cursor: pointer; margin-left: 3px"
                  @click="domainNameExplanation"
                ></i>
                <div class="row g-3 align-items-center">
                  <div class="col-auto" style="padding-right: 0">
                    <label for="inputPassword6" class="col-form-label"
                      >https://</label
                    >
                  </div>
                  <div
                    class="col-auto"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <input
                      type="text"
                      id="form6Example1"
                      class="form-control"
                      placeholder="TomBrady"
                      v-model="website.domainName"
                      required
                    />
                  </div>
                  <div
                    class="col-auto"
                    style="padding-right: 0; padding-left: 0"
                  >
                    <label for="inputPassword6" class="col-form-label"
                      >.summerathletes.com</label
                    >
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-6 col-sm-6">
                    <label class="form-label">Primary Color</label>
                    <i
                      class="fa-solid fa-circle-info fa-lg"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="pColorExplanation"
                    ></i>
                    <br />
                    <input
                      id="colorPicker"
                      type="color"
                      v-model="website.primaryColor"
                    />
                    <div
                      class="color-display rounded"
                      :style="{ backgroundColor: website.primaryColor }"
                    >
                      {{ website.primaryColor }}
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-6">
                    <label class="form-label">Accent Color</label>
                    <i
                      class="fa-solid fa-circle-info fa-lg"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="sColorExplanation"
                    ></i
                    ><br />
                    <input
                      id="colorPicker"
                      type="color"
                      v-model="website.secondaryColor"
                    />
                    <div
                      class="color-display rounded"
                      :style="{ backgroundColor: website.secondaryColor }"
                    >
                      {{ website.secondaryColor }}
                    </div>
                  </div>
                </div>
              </div>
              <!--              </Transition>-->
              <!--              <Transition name="slide-fade">-->
              <div v-if="this.formToggle === 1">
                <h5>2) Show Off Your Highlights</h5>
                <hr />

                <div class="mb-3">
                  <label for="formFile" class="form-label"
                    >Upload Profile Photo</label
                  >
                  <input
                    class="form-control"
                    type="file"
                    id="formFile"
                    @change="uploadImage($event)"
                  />
                </div>
                <br />
                <div class="mb-3">
                  <button
                    class="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadImages"
                    style="color: white; background-color: #363166"
                  >
                    Upload Additional Images - At Least 3
                  </button>
                  <br /><br />
                  <UploadFilesModal
                    @uploadAdditionalFile="uploadAdditionalFile"
                  ></UploadFilesModal>
                </div>
                <br />
              </div>
              <!--              </Transition>-->
              <!--              <Transition name="slide-fade">-->
              <div v-if="this.formToggle === 2">
                <h5>3) Tell Us More About Yourself</h5>
                <hr />
                <label class="form-label mt-3"
                  >Personal Statement
                  <span>
                    <i
                      class="fa-solid fa-circle-info fa-lg"
                      style="color: #45487a; cursor: pointer; margin-left: 3px"
                      @click="descriptionExplanation"
                    ></i></span
                ></label>

                <textarea
                  class="form-control"
                  rows="4"
                  placeholder="e.g. Personal Statement here"
                  v-model="website.personalStatement"
                  minlength="250"
                  required
                ></textarea>
              </div>
              <!--              </Transition>-->
            </div>
            <br />
            <div class="row justify-content-center" v-if="formToggle < 2">
              <div class="col-md-3" v-if="formToggle > 0">
                <button
                  class="card-form__button mt-3"
                  @click="formToggle--"
                  v-if="formToggle > 0"
                  style="margin-top: 10px"
                >
                  <i class="fa-solid fa-arrow-left"></i> Back
                </button>
              </div>
              <div class="col-md-9" v-if="formToggle < 2">
                <button
                  class="card-form__button mt-3"
                  @click="formToggle++"
                  style="margin-top: 10px"
                >
                  Next <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-3">
                <button
                  class="card-form__button mt-3"
                  @click="formToggle--"
                  v-if="formToggle > 0"
                  style="margin-top: 10px"
                >
                  <i class="fa-solid fa-arrow-left"></i> Back
                </button>
              </div>
              <div class="col-md-9">
                <button
                  class="card-form__button mt-3"
                  @click="generateWebsite"
                  style="margin-top: 10px"
                >
                  <div v-if="this.generating">
                    <div class="spinner-grow spinner-grow-sm m-1"></div>
                    <div class="spinner-grow spinner-grow-sm m-1"></div>
                    <div class="spinner-grow spinner-grow-sm m-1"></div>
                  </div>
                  <div v-else>Generate Website</div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
import UploadFilesModal from "./UploadFilesModal.vue";

export default {
  components: { UploadFilesModal },
  data() {
    return {
      generating: false,
      user: {},
      gif: false,
      formToggle: 0,
      website: {
        profile: null,
        images: [],
        primaryColor: null,
        secondaryColor: null,
        domainName: null,
        personalStatement: null,
        coachEmail: "",
      },
      clickedConfirm: false,
      images: [],
    };
  },
  methods: {
    setAddressData(e) {
      console.log(e);
      this.website.location = e.toString();
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    logoExplanation() {
      swal({
        title: "Logo",
        text: "This will be the main logo of who the website it for.",
        icon: "info",
      });
    },
    additionalImagesExplanation() {
      swal({
        title: "Additional Images",
        text: "These are any additional images that you want on your website. We recommend at least 3 on top of your logo, but 5 is preferred.",
        icon: "info",
      });
    },
    pColorExplanation() {
      swal({
        title: "Primary Color",
        text: "Here you will pick the primary color of your website. Our AI will make this be the primary color in the theme of the website.",
        icon: "info",
      });
    },
    sColorExplanation() {
      swal({
        title: "Secondary Color",
        text: "Here you will pick the secondary color of your website. Our AI will make this be an accent color on your website.",
        icon: "info",
      });
    },
    domainNameExplanation() {
      swal({
        title: "Domain Name",
        text: "This is the main URL name of your website. Most people just do their first and last name. No spaces or special characters are allowed.",
        icon: "info",
      });
    },
    locationExplanation() {
      swal({
        title: "Location",
        text: "This is the location of where you are located. Our AI will optimize your website to appear in searches in this area.",
        icon: "info",
      });
    },
    themeDescriptionExplanation() {
      swal({
        title: "Theme Description",
        text: 'Describe some adjectives that describe the overall design and feel of the website you want. Some description examples are "Light", "Modern","Minimalistic", "Vibrant", "Professional", etc...',
        icon: "info",
      });
    },
    pagesExplanation() {
      swal({
        title: "Website Page",
        text: "List some of the pages that you want on your website. E.G. Home page, contact us page, history, about us, etc...",
        icon: "info",
      });
    },
    descriptionExplanation() {
      swal({
        title: "Personal Statement",
        text: "This is a personal statement about who you are as a person, and what kind of athlete and person you are. Our AI will take in this as input to create you the best looking website possible. At least 3 sentences are recommended.",
        icon: "info",
      });
    },
    additionalInstructionsExplanation() {
      swal({
        title: "Additional Instructions",
        text: "Add any additional instructions about the website you want. Some common examples are to make the website have lots of sports specific photos, or to use a certain tone of voice when creating the website.",
        icon: "info",
      });
    },
    validateForm() {
      if (!this.website.profile) {
        swal(
          "Error",
          "You must upload a profile photo for your website.",
          "error"
        );
        return true;
      }
      if (this.website.images.length < 3) {
        swal(
          "Error",
          "You must have at least 3 images on your website.",
          "error"
        );
        return true;
      }
      if (
        this.website.primaryColor == null ||
        this.website.primaryColor == ""
      ) {
        swal("Error", "The primary color must be filled out.", "error");
        return true;
      }
      if (
        this.website.secondaryColor == null ||
        this.website.secondaryColor == ""
      ) {
        swal("Error", "The secondary color must be filled out.", "error");
        return true;
      }
      if (this.website.domainName == null || this.website.domainName == "") {
        swal("Error", "Domain name must be filled out.", "error");
        return true;
      }
      if (!this.isValidSubdomain(this.website.domainName)) {
        swal(
          "Error",
          "Domain name can only contain letters or numbers.",
          "error"
        );
        return true;
      }
      if (
        this.website.personalStatement == null ||
        this.website.personalStatement == ""
      ) {
        swal("Error", "The personal statement must be filled out.", "error");
        return true;
      }

      return false;
    },
    isValidSubdomain(inputString) {
      return /^[a-zA-Z0-9]*$/.test(inputString);
    },
    uploadAdditionalFile(event) {
      const URL = process.env.VUE_APP_DB_URL + "util/uploadFile";
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", event.target.files[0]);
      console.log(data);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((response) => {
          this.website.images.push(response.data);
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },
    uploadImage(event) {
      this.gif = true;
      const URL = process.env.VUE_APP_DB_URL + "util/uploadFile";
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", event.target.files[0]);
      console.log(data);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((response) => {
          this.website.profile = response.data;
          this.gif = false;
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },
    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    logUserOut() {
      localStorage.removeItem("jwt");
      this.$router.push("/");
    },
    showInfoAlert() {
      swal({
        title: "Welcome!",
        text: "After filling out your athletic information, you can create your own website profile to highlight your talents to college coaches and general recruiters. Upload just a few highlight images, fill out a short personal statement, and our AI systems will handle the rest.",
        icon: "info",
        button: "OK",
      });
    },
    async generateWebsite() {
      this.clickedConfirm = true;
      console.log(this.validateForm());
      if (this.validateForm()) {
        console.log("not returning");
        return;
      }
      this.generating = true;
      swal({
        title: "Generating Athlete Profile...",
        text: "The Summer Athletes A.I. is generating your athlete profile now. Please stay on this page and do not refresh.",
        icon: "info",
      });
      let url = process.env.VUE_APP_DB_URL + "recruit/createNewRecruitWebsite";
      this.website.coachEmail = this.user.email;
      this.website.websiteTemplateType = "CustomTemplateOne";
      this.website.videos = [];
      axios
        .post(
          url,
          {
            id: this.user._id,
            website: this.website,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          //let newWebsiteID =
          //response.data.aiWebsites[response.data.aiWebsites.length - 1].id;
          //this.$router.push("/Dashboard/WebsiteOverview/" + newWebsiteID);
          swal(
            "Success",
            "Website Created! Please wait a minute for SA-I to finish building",
            "success"
          );
          let user = response.data.recruit;
          localStorage.setItem("user", JSON.stringify(user));
          this.$router.push("/Recruit/Dashboard");
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Our server is receiving more requests than expected. Please wait a few minutes and try again",
            "error"
          );
        });
    },
  },
  created() {
    this.getUserDetails();
  },

  mounted() {
    this.showInfoAlert();
  },
  computed: {},
};
</script>
<style>
.editor-datetime {
  position: relative;
}
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeDay:hover {
  color: darkred;
  cursor: pointer;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.nav {
  margin-top: 40px;
}

.pull-right {
  float: right;
}

a,
a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #777;
  cursor: pointer;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #45487a;
}

.arrow-steps .step.incomplete {
  color: #fff;
  background-color: #ffcc00;
}

.arrow-steps .step.finished {
  color: #fff;
  background-color: #19d412;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #45487a;
}

.arrow-steps .step.incomplete:after {
  border-left: 17px solid #ffcc00;
}

.arrow-steps .step.finished:after {
  border-left: 17px solid #19d412;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
