<template>
  <div
    class="modal fade"
    id="viewScheduledSessionsOnDay"
    tabindex="-1"
    aria-labelledby="viewScheduledSessionsOnDayLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-center"
            id="viewScheduledSessionsOnDayLabel"
          >
            Scheduled Sessions - {{ dateOnThisDay }}
          </h5>

          <button
            type="button"
            class="btn-close"
            id="viewScheduledSessionsOnDayClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow: auto">
          <div
            v-for="(session, index) in allEventsOnThisDay"
            :key="session.camperName + index"
            class="row"
          >
            <div v-if="!session.isCamp">
              <div class="row">
                <label><b>Registrant:</b> {{ session.camperName }}</label>
                <label class="form-label" for="form6Example3">
                  <b>Booked Timeslot:</b> {{ session.title }}
                </label>
                <label class="form-label" for="form6Example3">
                  <b>Session Type:</b> {{ session.campName }}
                </label>
              </div>
              <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="goToCamper(session.campID, session.camperID)"
                  >
                    View Registrant
                  </button>
                </div>
              </div>
              <br />
              <br />
              <hr v-if="index !== allEventsOnThisDay.length - 1" />
            </div>
            <div v-else>
              <div class="row">
                <label><b>Event:</b> {{ session.title }}</label>
                <label class="form-label" for="form6Example3">
                  <b>Number of Registrants:</b> {{ session.camperLength }}
                </label>
              </div>
              <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="goToCamp(session.campID)"
                  >
                    View Event
                  </button>
                </div>
              </div>
              <br />
              <br />
              <hr v-if="index !== allEventsOnThisDay.length - 1" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import swal from "sweetalert";
// import swal2 from "sweetalert2";
// import axios from "axios";
export default {
  data() {
    return {
      dayApplicable: "TODAY",
      startTime: "",
      endTime: "",
    };
  },
  components: {},
  props: {
    allEventsOnThisDay: Array,
    dateOnThisDay: String,
  },
  methods: {
    goToCamper(campID, camperID) {
      document.getElementById("viewScheduledSessionsOnDayClose").click();
      this.$router.push("/Dashboard/CheckIn/" + campID + "/" + camperID);
    },
    goToCamp(campID) {
      document.getElementById("viewScheduledSessionsOnDayClose").click();
      this.$router.push("/Dashboard/ViewCamp/individual/" + campID);
    },
  },
  computed: {
    getSessionDate() {
      return new Date().toLocaleDateString();
    },
  },

  mounted() {},
};
</script>

<style scoped></style>
