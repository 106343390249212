import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueTour from "vue-tour";
import BootstrapVue from "bootstrap-vue";

require("vue-tour/dist/vue-tour.css");
Vue.use(BootstrapVue);
Vue.use(VueTour);

// Import date picker css
import "@babel/polyfill";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

const base = axios.create({
  baseURL: "http://localhost:4000",
});

import GAuth from "vue-google-oauth2";

const scopes = [
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
  "openid",
];

const gauthOption = {
  clientId:
    "624139819306-iujuqtacguevtuh4dpu0i886v7iaotff.apps.googleusercontent.com",
  scope: scopes,
  prompt: "select_account",
};
Vue.use(GAuth, gauthOption);

const subdomain = window.location.hostname.split(".")[0];
console.log(subdomain);

Vue.prototype.$http = base;
Vue.config.productionTip = false;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
