<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner">
          <img
            src="../../../../public/SummerAthletes.png"
            class="card-img-top"
          />
          <div class="card-body">
            <h5 class="card-title text-center">
              We're sorry, this event is now closed to new registrants!
            </h5>
            <br />
            <h5 class="card-subtitle mb-2 text-muted text-center fst-italic">
              Please contact the organizer to ask if they can increase the limit
              of registrations.
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
