var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information-card p-4 shadow-sm",style:({
    maxWidth: _vm.isMobile ? '90%' : '275px',
    cursor: 'pointer',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  }),on:{"click":function($event){return _vm.buySubscription(_vm.subscription)}}},[_c('h5',{staticClass:"card-title text-center mb-3"},[_c('span',{staticClass:"badge actionFeature mb-2 d-block"},[_vm._v(_vm._s(_vm.subscription.subscriptionName))]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm.yearly ? "Billed Annually" : "Billed Monthly"))])]),_c('h5',{staticClass:"card-body text-center"},[(_vm.yearly)?_c('label',{staticClass:"d-block",staticStyle:{"font-size":"15px","text-decoration":"line-through"}},[_vm._v(" $"+_vm._s(_vm.getFakeYearly)+" ")]):_vm._e(),_c('span',{staticClass:"price"},[_c('i',{staticClass:"fa-solid fa-trophy fa-xl m-1",staticStyle:{"color":"#363166"}}),_c('label',{staticClass:"d-inline-block",staticStyle:{"font-size":"24px","font-weight":"bold"}},[_vm._v(" $"+_vm._s(_vm.yearly ? _vm.getYearly + " / year" : _vm.getMonthly + " / month")+" ")])])]),_c('button',{staticClass:"btn btn-primary rounded-pill mt-4 w-100",attrs:{"id":"regularSignInBtn"},on:{"click":function($event){return _vm.buySubscription(_vm.subscription)}}},[_c('h5',[_vm._v("Get a Demo")])]),_c('p',{staticClass:"text-muted text-center mt-2"},[_vm._v(" Everything in the All Star, Plus... ")]),_c('ul',{staticClass:"plan-details__item list-unstyled"},_vm._l((_vm.features),function(feature,index){return _c('li',{key:index,staticClass:"mb-3",class:{ 'has-description': feature.description }},[_c('p',{staticClass:"plan-details__item-title"},[_c('i',{staticClass:"fa-solid fa-check-circle",staticStyle:{"color":"#19d412"}}),_vm._v(" "+_vm._s(feature.title)+" ")]),(feature.description)?_c('div',{staticClass:"plan-details__item-description env-dark"},[_c('div',{staticClass:"tooltip-arrow"}),_c('p',[_vm._v(_vm._s(feature.description))])]):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }