<template>
  <div class="m-1">
    <div
      class="row justify-content-center mt-5"
      style="max-height: 350px; min-height: 350px"
    >
      <div v-if="this.selectedPhoto.url">
        <img
          :src="this.selectedPhoto.url"
          class="rounded-3"
          style="max-width: 100%; max-height: 400px"
        />
      </div>
      <div v-else>
        <video
          controls
          :src="this.selectedVideo.url"
          class="rounded-3"
          style="max-width: 100%; max-height: 400px"
        />
      </div>
    </div>
    <br />
    <div class="row justify-content-center mt-5">
      <div class="col-3 mb-2" v-for="video in videos" :key="video._id">
        <video
          @click="selectVideo(video)"
          :src="video.url"
          class="img-fluid rounded-3"
          style="
            max-height: 100px;
            min-height: 100px;
            max-width: 100px;
            min-width: 100px;
            background-color: black;
          "
        />
      </div>
      <div class="col-3 mb-2" v-for="photo in photos" :key="photo._id">
        <img
          @click="selectPhoto(photo)"
          :src="photo.url"
          :alt="photo.description"
          class="img-fluid rounded-3"
          style="
            max-height: 100px;
            min-height: 100px;
            max-width: 100px;
            min-width: 100px;
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
    photos: Array,
    videos: Array,
  },
  data() {
    return {
      // Define your data here
      selectedPhoto: {
        url: "",
      },

      selectedVideo: {
        url: "",
      },
    };
  },
  methods: {
    selectPhoto(photo) {
      this.selectedPhoto = photo;
      this.selectedVideo = {};
    },

    selectVideo(video) {
      this.selectedVideo = video;
      this.selectedPhoto = {};
    },
  },

  created() {
    if (this.photos) {
      this.selectedPhoto = this.photos[0];
    }
  },

  mounted() {
    let root = document.querySelector(":root");
    root.style.setProperty("--secondaryColor", this.secondaryColor);
  },

  computed: {},
};
</script>

<style scoped>
:root {
  --secondaryColor: #000;
}
.img-wrapper a {
  overflow: hidden;
  display: block;
  padding: 1vh;
  background: var(--secondaryColor);
  box-shadow: 0 10px 50px #5f2f1182;
}
</style>
