<template>
  <div
    :class="{
      'card-form': true,
      container: true,
      'd-flex': true,
      'justify-content-center': true,
      'mx-4': true,
    }"
    style="min-width: 100%"
  >
    <div :class="{ 'card-form__inner': true }" style="min-width: 100%">
      <a href="/Dashboard/Admin/Payouts" class="btn btn-primary mb-5"
        >Back to Payouts</a
      >
      <br />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Provider Name<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        placeholder="e.g. Matt Smith"
        v-model="recruitReferral.referralProvider.name"
        required
      />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Email<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        v-model="recruitReferral.referralProvider.email"
        required
      />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Phone Number<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        v-model="recruitReferral.referralProvider.phoneNumber"
        required
      />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Type of Contact<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        placeholder="e.g. Opendorse Athlete"
        v-model="recruitReferral.referralProvider.typeOfProvider"
        required
      />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >First Month's Price<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        placeholder="e.g. 5"
        v-model="recruitReferral.firstMonthPrice"
        required
      />

      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Subsequent Monthly Price<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        placeholder="e.g. 15"
        v-model="recruitReferral.recurringMonthlyPrice"
        required
      />

      <button @click="addRecruitReferral" class="btn btn-primary mt-5">
        Add Recruit Referral
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import swal from "sweetalert";
export default {
  components: {},
  data() {
    return {
      user: {},
      recruitReferral: {
        firstMonthPrice: null,
        recurringMonthlyPrice: null,
        referralCode: null,
        referralProvider: {
          name: null,
          email: null,
          phone: null,
          typeOfProvider: null,
        },
      },
    };
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },

    addRecruitReferral() {
      let url = process.env.VUE_APP_DB_URL + "admin/addRecruitReferral";
      axios
        .post(
          url,
          { recruitReferral: this.recruitReferral },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          if (response) {
            swal({
              title: "Success",
              text: "Recruit Referral has been added successfully",
              icon: "success",
            });
            this.$emit("recruitReferralAdded", response.data.recruitReferral);
          }
        });
    },
  },
  created() {
    this.getUserDetails();
  },
};
</script>
<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > .col {
  align-self: stretch;
  margin-bottom: 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 102px;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.admin-section {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid grey;
}
.row {
  align-items: flex-start;
}
.total-payout {
  position: absolute;
  font-size: 15px;
  color: red;
  width: auto !important;
}
</style>
