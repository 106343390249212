<template>
  <div
    id="paypal-button-container-P-8RE83682KM904240AMZMJQ6Q"
    :style-object="style"
  ></div>
</template>

<script>
import { loadScript } from "@paypal/paypal-js";
//import Vue from "vue";
import swal from "sweetalert";
import axios from "axios";

export default {
  components: {},
  props: {
    planID: String,
    initialActivity: Object,
  },
  data() {
    return {
      paypalRequestId: "",
    };
  },

  methods: {},
  computed: {
    style: function () {
      return {
        shape: "pill",
        color: "blue",
        layout: "horizontal",
        label: "paypal",
        tagline: false,
      };
    },
  },
  async mounted() {
    const paypalSdk = await loadScript({
      clientId: process.env.VUE_APP_PAYPAL_CLIENT_ID,
      currency: "USD",
      components: "buttons",
      enableFunding: "venmo,paylater",
      vault: true,
    });
    let fundingSource = paypalSdk.FUNDING.PAYPAL;

    console.log(fundingSource);
    await paypalSdk
      .Buttons({
        fundingSource: fundingSource,
        vault: true,
        style: {
          shape: "pill",
          color: "blue",
          layout: "vertical",
          label: "subscribe",
        },
        createSubscription: async (data, actions) => {
          return actions.subscription.create({
            /* Creates the subscription */

            plan_id: this.planID,
          });
        },
        onApprove: async (data, actions) => {
          console.log(actions);
          console.log(data);
          let url = process.env.VUE_APP_DB_URL + "paypal/subscriptionApproved";
          await axios
            .post(url, {
              subscriptionID: data.subscriptionID,
              initialActivity: this.initialActivity,
            })
            .then((response) => {
              swal({
                title: "Success",
                text: "You have successfully subscribed!",
                icon: "success",
              });
              let token = response.data.token;
              let user = response.data.tempRecruit;
              localStorage.setItem("jwt", token);
              localStorage.setItem("user", JSON.stringify(user));
              if (token) {
                swal("Success", "Purchase Successful", "success");
                this.$router.push("/recruit/Dashboard/profile");
              }
              console.log(response.data);
            })
            .catch((error) => {
              console.log(error);
              swal({
                title: "Error",
                text: error,
                icon: "error",
              });
            });
        },
      })
      .render("#paypal-button-container-P-8RE83682KM904240AMZMJQ6Q");
  },
};
</script>
