<template>
  <div
    class="modal fade"
    id="uploadImages"
    tabindex="-1"
    aria-labelledby="uploadImagesLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="uploadImagesLabel">
            Upload Additional {{ isForEdit ? "Files" : "Images" }} - Preferably
            5
          </h5>
        </div>
        <div class="modal-body">
          <input
            type="file"
            ref="fileInput"
            class="form-control"
            @change="uploadAdditionalFile($event)"
          />
          <br />
          <input
            type="file"
            ref="fileInput"
            class="form-control"
            @change="uploadAdditionalFile($event)"
          />
          <br />
          <input
            type="file"
            ref="fileInput"
            class="form-control"
            @change="uploadAdditionalFile($event)"
          />
          <br />
          <input
            type="file"
            ref="fileInput"
            class="form-control"
            @change="uploadAdditionalFile($event)"
          />
          <br />
          <input
            type="file"
            ref="fileInput"
            class="form-control"
            @change="uploadAdditionalFile($event)"
          />
          <br />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="websiteSettingsClose"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            id="websiteSettingsClose"
            class="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isForEdit: Boolean,
  },
  methods: {
    uploadAdditionalFile(event) {
      this.$emit("uploadAdditionalFile", event);
    },
  },
};
</script>
<style scoped></style>
