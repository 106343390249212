<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner">
          <div class="card-body">
            <center>
              <h1 class="card-title">Expired Account</h1>
              <br />
              <br />
              <h5>The owner of this website's account has been suspended.</h5>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
