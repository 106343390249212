var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card m-3 card-form__inner",staticStyle:{"max-width":"700px","min-height":"300px"}},[_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-md-6 col-sm-6",staticStyle:{"margin-top":"10px"}},[_c('img',{staticClass:"profile-image",attrs:{"src":_vm.getImage}}),_c('h2',{style:({ textAlign: _vm.isMobile ? 'center' : '' })},[_c('b',[_vm._v(_vm._s(this.memberFirstName)+" "+_vm._s(this.memberLastName))])]),_c('button',{staticClass:"card-form__button",staticStyle:{"margin-top":"10px"}},[_c('a',{on:{"click":function($event){return _vm.toMemberProfile()}}},[_vm._v("View Profile")])])]),_c('div',{staticClass:"col-md-6 col-sm-6",style:({ textAlign: _vm.isMobile ? 'center' : 'left' })},[_c('div',{class:{
          row: true,
          'justify-content-end': !_vm.isMobile(),
          'justify-content-center': _vm.isMobile(),
          'text-end': !_vm.isMobile(),
          'm-3': _vm.isMobile(),
        }},[(this.isActive)?_c('span',[_vm._v("Active "),_c('i',{staticClass:"fa-solid fa-circle-check fa-2xl",staticStyle:{"color":"#32bd28"}})]):_c('span',[_vm._v("Inactive "),_c('i',{staticClass:"fa-solid fa-ban fa-2xl",staticStyle:{"color":"#db2c1f"}})])]),_c('div',{staticClass:"row m-3 text-start"},[_c('span',[_c('i',{staticClass:"fa-solid fa-credit-card fa-xl m-1",staticStyle:{"color":"#363166"}}),_c('label',{staticStyle:{"margin-right":"3px"}},[_vm._v(" Payment plan: "+_vm._s(_vm.getSubscription))])])]),_c('div',{staticClass:"row m-3 text-start"},[_c('span',[_c('i',{staticClass:"fa-solid fa-calendar-day fa-xl m-1",staticStyle:{"color":"#363166"}}),_c('label',{staticStyle:{"margin-right":"3px"}},[_vm._v(" Started "+_vm._s(_vm.getStartDate)+" ")])])]),(_vm.getTShirtString)?_c('div',{staticClass:"row m-3 text-start"},[_c('span',[_c('i',{staticClass:"fa-solid fa-shirt fa-xl m-1",staticStyle:{"color":"#363166"}}),_c('label',{staticStyle:{"margin-right":"3px"},attrs:{"for":"Received"}},[_vm._v("Size: "+_vm._s(_vm.getTShirtString)+" ")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }