var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"container"},[(_vm.user && !_vm.isMobile)?_c('ClientLayout',{attrs:{"user":_vm.user}}):_vm._e(),_c('div',{staticClass:"row justify-content-center mt-5"},[(this.lock)?_c('div',[_c('div',{staticClass:"alert alert-danger text-center",attrs:{"role":"alert"}},[_vm._v(" Your account has been suspended due to failed charges on your card. Please re-sign up with correct billing information. ")]),_c('br'),_c('br')]):_vm._e(),_c('div',{class:{
          'col-4': true,
          'col-sm-2': true,

          'text-muted': this.yearly,
          'text-center': _vm.isMobile,
          'text-end': !_vm.isMobile,
        }},[(!_vm.isMobile)?_c('h4',[_vm._v("Billed Monthly")]):_c('h5',[_vm._v("Billed Monthly")])]),_c('label',{staticClass:"switch col-4"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":this.yearly},on:{"click":function($event){return _vm.setPeriod()}}}),_c('div',{staticClass:"slider round"})]),_c('div',{class:{
          'col-4': true,
          'col-sm-2': true,
          'text-muted': !this.yearly,
          'text-start': !_vm.isMobile,
          'text-center': _vm.isMobile,
        }},[(!_vm.isMobile)?_c('h4',[_vm._v("Billed Annually")]):_c('h5',[_vm._v("Billed Annually")])]),_c('br'),_c('br'),_c('br'),(this.yearly)?_c('h5',{staticClass:"text-center text-muted"},[_vm._v(" Get up to 3 Months - For Free! ")]):_c('h5',{staticClass:"text-center text-muted"},[_vm._v("No Commitment!")])]),(!_vm.checkingRookie)?_c('div',{staticClass:"row justify-content-center mt-5"},[_c('ProDetailsCard',{staticClass:"col-md-4 col-sm-6",style:({
          border:
            this.wantedSubscription.subscriptionName === 'Pro'
              ? '3px solid #007bff'
              : '3px solid #3',
        }),attrs:{"yearly":this.yearly,"isMobile":this.isMobile,"subscription":this.pro},on:{"buySubscription":_vm.buySubscription}}),_c('AllStarDetailsCard',{staticClass:"col-md-4 col-sm-6",style:({
          border:
            this.wantedSubscription.subscriptionName === 'All Star'
              ? '3px solid #007bff'
              : '',
        }),attrs:{"yearly":this.yearly,"isMobile":this.isMobile},on:{"buySubscription":_vm.buySubscription}}),_c('HallOfFameDetailsCard',{staticClass:"col-md-4 col-sm-6",style:({
          border:
            this.wantedSubscription.subscriptionName === 'Hall of Fame'
              ? '3px solid #007bff'
              : '',
        }),attrs:{"yearly":this.yearly,"isMobile":this.isMobile},on:{"buySubscription":_vm.buySubscription}})],1):_c('div',{staticClass:"row justify-content-center mt-5"},[_c('div',{staticClass:"actionHub pointer"},[_vm._m(0),_c('br'),_c('div',{staticClass:"row justify-content-center mt-3"},[_c('a',{staticClass:"card2 col-md-3 col-sm-6 camp-step-4",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getSchoolRegistrationLink}},[_c('h5',{staticClass:"text-center"},[_vm._v("Yes")]),_vm._m(1)]),_c('a',{staticClass:"card2 col-md-3 col-sm-6 camp-step-4",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getSubscriptionRookieCheckoutLink}},[_c('h5',{staticClass:"text-center"},[_vm._v("No")]),_vm._m(2)])])])]),(_vm.currentSubscription.subscriptionName)?_c('div',{staticClass:"row justify-content-center mt-5"},[_c('h3',{staticClass:"text-center"},[_vm._v(" Current Plan: "),_c('span',{staticClass:"badge actionFeature"},[_vm._v(_vm._s(_vm.currentSubscription.subscriptionName)+" "),_c('br')])])]):_vm._e()],1),_c('button',{staticClass:"btn-close",staticStyle:{"display":"none"},attrs:{"type":"button","id":"advertisementPopUpClose","data-bs-dismiss":"modal","aria-label":"Close"},on:{"click":function($event){return _vm.console.log('Clicked')}}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"border":"none"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Do you have a school affiliated email?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"go-corner",attrs:{"href":"#"}},[_c('div',{staticClass:"go-arrow"},[_vm._v("→")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"go-corner",attrs:{"href":"#"}},[_c('div',{staticClass:"go-arrow"},[_vm._v("→")])])
}]

export { render, staticRenderFns }