<template>
  <div
    class="modal fade"
    id="addExternalAccount"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="exampleModalLabel">
            External Account Details
          </h5>

          <button
            type="button"
            class="btn-close"
            id="linkExternalAccountClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <img
            src="../../../../../public/checkRoutingExample.png"
            style="max-width: 80%"
            class="center text-center"
          />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Routing Number</label
          >
          <input
            v-model="newAccount.routingNumber"
            required
            id="form6Example1"
            class="form-control"
            type="number"
          />
          <br />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Account Number</label
          >
          <input
            v-model="newAccount.accountNumber"
            required
            id="form6Example1"
            class="form-control"
            type="number"
          />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Confirm Account Number</label
          >
          <input
            v-model="newAccount.accountNumberConfirmation"
            required
            id="form6Example1"
            class="form-control"
            type="number"
          />
          <br />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Account Nickname
            <i
              class="fa-solid fa-circle-info"
              style="color: #45487a; cursor: pointer"
              @click="showAccountNicknameExplanation"
            ></i
          ></label>
          <input
            v-model="newAccount.accountNickname"
            required
            id="form6Example1"
            class="form-control"
            type="text"
          />
          <br />
          <label
            class="form-label"
            for="bankDetailsForm"
            style="margin-top: 5px"
            >Account Type</label
          >
          <ul class="list-group">
            <li class="list-group-item">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="CHECKING"
                v-model="newAccount.type"
              />
              Checking
            </li>
            <li class="list-group-item">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="SAVING"
                checked
                v-model="newAccount.type"
              />
              Savings
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="linkExternalAccount()"
          >
            Add Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    newAccount: Object,
    userID: String,
  },
  methods: {
    linkExternalAccount() {
      if (
        this.newAccount.accountNumber !==
        this.newAccount.accountNumberConfirmation
      ) {
        swal(
          "Account Mismatch",
          "Account Numbers do not match. Please check them and try again",
          "error"
        );
        return;
      }
      if (
        !this.newAccount.routingNumber ||
        !this.newAccount.accountNumber ||
        !this.newAccount.accountNumberConfirmation ||
        !this.newAccount.accountNickname ||
        !this.newAccount.type
      ) {
        swal(
          "Details Incomplete",
          "Please fill out all form fields and try again",
          "error"
        );
        return;
      }
      swal("Loading...", "", "info");

      let url = process.env.VUE_APP_DB_URL + "banking/linkExternalAccount";
      let account = this.newAccount;
      axios
        .post(
          url,
          { newAccount: account, userID: this.userID },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
          }
        )
        .then((response) => {
          this.payoutInfo = response.data.payoutInfo;
          this.$emit("accountLinked", { payoutInfo: response.data.payoutInfo });
          document.getElementById("linkExternalAccountClose").click();
          swal("Success", "Bank Account Link Successful", "success");
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },

    showAccountNicknameExplanation() {
      swal({
        title: "Account Nickname",
        text: "This is the name you would like to identify your account by. It is only for your convenience, and does not change any banking records.",
        icon: "info",
      });
    },
  },
};
</script>

<style scoped></style>
