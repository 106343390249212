<template>
  <div>
    <b>{{ this.addOnName }}: </b>{{ this.numCampers }}
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    addOnName: String,
    numCampers: Number,
  },
  data() {
    return {};
  },
  methods: {},

  created() {},
};
</script>
<style></style>
