<template>
  <div class="wrapper container">
    <div class="card-form shadow-lg" style="margin-top: 0px">
      <div class="card-form__inner" style="margin-top: 0px">
        <center class="m-3">
          <img
            src="../../../public/SummerAthletes.png"
            class="card-img-top"
            style="max-width: 400px; max-height: 300px"
          />
        </center>
        <div class="modal-body text-center">
          <a
            class="card2 camp-step-3"
            style="
              display: block;
              margin: 10px auto;
              cursor: pointer;
              color: #363166;
              text-decoration: none;
              padding: 20px;
              border: 1px solid #ccc;
              border-radius: 5px;
              background-color: #f8f9fa;
            "
            @click="selectOption('parentAthlete')"
          >
            <h5>I am a parent or athlete</h5>
            <div class="go-corner" href="#">
              <div class="go-arrow">→</div>
            </div>
          </a>
          <a
            class="card2 camp-step-3"
            style="
              display: block;
              margin: 10px auto;
              cursor: pointer;
              color: #363166;
              text-decoration: none;
              padding: 20px;
              border: 1px solid #ccc;
              border-radius: 5px;
              background-color: #f8f9fa;
            "
            @click="selectOption('coach')"
          >
            <h5>I am a coach</h5>
            <div class="go-corner" href="#">
              <div class="go-arrow">→</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      user: null,
    };
  },
  methods: {
    selectOption(option) {
      if (option == "coach") {
        this.$router.push("/externalcheckoutsecondary");
      } else {
        this.$router.push("/RecruitExternalCheckout");
      }
    },
  },
  created() {},
  mounted() {},
  computed: {},
};
</script>
<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.actionFeature {
  background-color: #a39ed0;
}
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
  z-index: 30;
}

.navigation .container {
  max-width: unset;
  height: 100%;
  padding: 0 40px;
  display: flex;
  align-items: center;
}

.sa-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-logo {
  border-radius: 20%;
}

.sa-header .sa-logo-wrapper {
  width: 90px;
  display: flex;
}

.sa-nav-wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 54px;
}

.sa-nav-wrapper .sa-menu {
  height: 100%;
  display: flex;
  gap: 40px;
}

.sa-menu .sa-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  color: black;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover,
.sa-menu .sa-menu-item.active {
  color: #363166;
}

.sa-menu .sa-menu-item::after {
  content: "";
  width: 100%;
  height: 3px;
  background-color: #363166;
  position: absolute;
  bottom: 0;
  left: -100%;
  transition: all 0.3s ease;
}

.sa-menu .sa-menu-item:hover::after,
.sa-menu .sa-menu-item.active::after {
  left: 0;
}

.sa-menu-btns-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sa-image {
  width: 100%;
  height: 100%;
  display: block;
}

.sa-image-cover {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.sa-image-cover.fit-pos-top {
  object-position: top;
}

.sa-image-contained {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}
</style>
