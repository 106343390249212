<template>
  <div>
    <sidebar-menu
      v-if="isMobile()"
      :menu="this.getMenu"
      style="margin-top: 50px"
      widthCollapsed="45px"
      :collapsed="true"
      :user="user"
    >
    </sidebar-menu>
    <sidebar-menu
      v-else
      :menu="this.getMenu"
      style="margin-top: 50px"
      class="menu-step-0"
      widthCollapsed="60px"
      :collapsed="true"
      :user="user"
    />
  </div>
</template>
<script>
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import axios from "axios";

export default {
  components: { SidebarMenu },

  props: {
    user: Object,
  },
  data() {
    return {
      menu: [],
      steps: [
        {
          target: ".menu-step-0", // We're using document.querySelector() under the hood
          content: `The <strong>Menu Bar</strong> is your action hub. <br/> <br /> Create, View, Edit, and Check all your information.`,
          params: {
            enableScrolling: false,
            placement: "right",
            // highlight: true,
          },
        },
      ],
      foundUser: {
        subscription: {
          subscriptionTier: 0,
          subscriptionMongoID: "",
        },
      },
      subscriptions: [],
    };
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  computed: {
    getMenu() {
      return [
        {
          header: true,
          title: "Main Navigation",
          hiddenOnCollapse: true,
        },
        {
          href: "/Recruit/Dashboard",
          title: "Home",
          icon: "fa-solid fa-house",
        },

        {
          href: "/Recruit/Dashboard/Profile",
          title: "Athlete Information",
          icon: "fa-solid fa-user",
        },
        {
          href: "/Recruit/Dashboard/Website",
          title: "My Athlete Profile",
          icon: "fa-solid fa-pen-to-square",
        },
        {
          href: "/Recruit/Dashboard/Inbox",
          title: "Messages",
          icon: "fa-solid fa-comments",
          badge: {
            text: this.getMessageCount,
            class: "vsm--badge_default",
            attributes: {
              "data-badge-variant": "danger",
              "data-badge-size": "lg",
              "data-badge-position": "bottom-right",
            },
            element: "span",
          },
        },
        {
          href: "/Recruit/Dashboard/Activities",
          title: "Activites",
          icon: "fa-solid fa-person-running",
        },
        {
          href: "/Recruit/Dashboard/Settings",
          title: "Account Settings",
          icon: "fa-solid fa-gear",
        },
      ];
    },
    getMessageCount() {
      let unReadMessages = this.user.messages.filter((x) => !x.hasBeenRead);
      return unReadMessages.length;
    },
  },
  mounted() {
    this.foundUser = this.user;
    let url =
      process.env.VUE_APP_DB_URL +
      "recruit/getRecruit?id=" +
      this.foundUser._id;
    axios.get(url).then((response) => {
      this.user = response.data;
      localStorage.setItem("user", this.user);
    });
  },
};
</script>
<style>
.v-sidebar-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 999;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  -webkit-transition: 0.3s max-width ease;
  transition: 0.3s max-width ease;
}
.v-sidebar-menu * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.v-sidebar-menu .vsm--dropdown > .vsm--list {
  padding: 5px;
}
.v-sidebar-menu .vsm--item {
  position: relative;
  display: block;
  width: 100%;
  white-space: nowrap;
}
.v-sidebar-menu .vsm--link {
  cursor: pointer;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
  line-height: 30px;
  text-decoration: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 20;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.v-sidebar-menu .vsm--link_exact-active,
.v-sidebar-menu .vsm--link_active {
  font-weight: 600;
}
.v-sidebar-menu .vsm--link_disabled {
  opacity: 0.4;
  pointer-events: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  height: 30px;
  line-height: 30px;
  width: 30px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  text-align: center;
  border-radius: 3px;
}
.v-sidebar-menu .vsm--icon {
  display: inline-block;
  margin-right: 10px;
}
.v-sidebar-menu .vsm--title {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.v-sidebar-menu .vsm--arrow {
  width: 30px;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  -webkit-transition: 0.3s -webkit-transform ease;
  transition: 0.3s -webkit-transform ease;
  transition: 0.3s transform ease;
  transition: 0.3s transform ease, 0.3s -webkit-transform ease;
}
.v-sidebar-menu .vsm--arrow:after {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
}
.v-sidebar-menu .vsm--arrow_open {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.v-sidebar-menu .vsm--arrow_slot:after {
  display: none;
}
.v-sidebar-menu .vsm--header {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  white-space: nowrap;
  text-transform: uppercase;
}
.v-sidebar-menu .vsm--badge_default {
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 3px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.v-sidebar-menu .vsm--toggle-btn {
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: 900;
  height: 50px;
  cursor: pointer;
  border: none;
  width: 100%;
}
.v-sidebar-menu .vsm--toggle-btn:after {
  content: "\f337";
  font-family: "Font Awesome 5 Free";
}
.v-sidebar-menu .vsm--toggle-btn_slot:after {
  display: none;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: transparent !important;
}
.v-sidebar-menu.vsm_rtl {
  right: 0;
  left: inherit;
  text-align: right;
  direction: rtl;
}
.v-sidebar-menu.vsm_rtl .vsm--icon {
  margin-left: 10px;
  margin-right: 0px;
}
.v-sidebar-menu.vsm_relative {
  position: relative;
  height: 100%;
}
.v-sidebar-menu .expand-enter-active,
.v-sidebar-menu .expand-leave-active {
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
  overflow: hidden;
}
.v-sidebar-menu .expand-enter,
.v-sidebar-menu .expand-leave-to {
  height: 0 !important;
}
.v-sidebar-menu .slide-animation-enter-active {
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.v-sidebar-menu .slide-animation-leave-active {
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.v-sidebar-menu .slide-animation-enter,
.v-sidebar-menu .slide-animation-leave-to {
  width: 0 !important;
}
.v-sidebar-menu .fade-animation-enter-active {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.v-sidebar-menu .fade-animation-leave-active {
  -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}
.v-sidebar-menu .fade-animation-enter,
.v-sidebar-menu .fade-animation-leave-to {
  opacity: 0 !important;
  visibility: hidden !important;
}
.v-sidebar-menu .vsm--mobile-item > .vsm--item {
  padding: 0 !important;
  margin: 0 !important;
}
.v-sidebar-menu .vsm--mobile-item > .vsm--item > .vsm--link {
  margin: 0 !important;
  background-color: transparent !important;
  line-height: 30px !important;
}
.v-sidebar-menu {
  background-color: #363166;
}
.v-sidebar-menu .vsm--link {
  color: #fff;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px #19d412 inset;
  box-shadow: 3px 0px 0px 0px #19d412 inset;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: #1e1e21;
}
.v-sidebar-menu .vsm--link_hover,
.v-sidebar-menu .vsm--link:hover {
  background-color: rgba(30, 30, 33, 0.5);
}
.v-sidebar-menu .vsm--link_mobile-item {
  color: #fff;
}
.v-sidebar-menu .vsm--link_mobile-item.vsm--link_hover,
.v-sidebar-menu .vsm--link_mobile-item:hover {
  color: #fff;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover .vsm--icon {
  color: #fff;
  background-color: #19d412;
}
.v-sidebar-menu .vsm--dropdown .vsm--list {
  background-color: #36363b;
}
.v-sidebar-menu .vsm--mobile-bg {
  background-color: #19d412;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
  color: #fff;
  background-color: #19d412;
}
.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
  background-color: #19d412;
}
.v-sidebar-menu.vsm_rtl .vsm--link_level-1.vsm--link_active,
.v-sidebar-menu.vsm_rtl .vsm--link_level-1.vsm--link_exact-active {
  -webkit-box-shadow: -3px 0px 0px 0px #19d412 inset;
  box-shadow: -3px 0px 0px 0px #19d412 inset;
}
.v-sidebar-menu .vsm--header {
  color: rgba(255, 255, 255, 0.7);
}
.v-sidebar-menu .vsm--badge_default {
  color: #fff;
  background-color: red;
}
.v-sidebar-menu .vsm--toggle-btn {
  color: #fff;
  background-color: #1e1e21;
}
.v-sidebar-menu.vsm_white-theme {
  background-color: #fff;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: #262626;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: 3px 0px 0px 0px #19d412 inset;
  box-shadow: 3px 0px 0px 0px #19d412 inset;
}
.v-sidebar-menu.vsm_white-theme
  .vsm--link_level-1.vsm--link_exact-active
  .vsm--icon,
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
  color: #fff;
  background-color: #262626;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: #bbc5d6;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_hover,
.v-sidebar-menu.vsm_white-theme .vsm--link:hover {
  background-color: rgba(242, 242, 242, 0.5);
}
.v-sidebar-menu.vsm_white-theme .vsm--link_mobile-item {
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_mobile-item.vsm--link_hover,
.v-sidebar-menu.vsm_white-theme .vsm--link_mobile-item:hover {
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed
  .vsm--link_level-1.vsm--link_hover
  .vsm--icon,
.v-sidebar-menu.vsm_white-theme.vsm_collapsed
  .vsm--link_level-1:hover
  .vsm--icon {
  color: #fff;
  background-color: #19d412;
}
.v-sidebar-menu.vsm_white-theme .vsm--dropdown .vsm--list {
  background-color: #e3e3e3;
}
.v-sidebar-menu.vsm_white-theme .vsm--mobile-bg {
  background-color: #19d412;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1 {
  color: #fff;
  background-color: #19d412;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--item_open
  .vsm--link_level-1
  .vsm--icon {
  background-color: #19d412;
}
.v-sidebar-menu.vsm_white-theme.vsm_rtl .vsm--link_level-1.vsm--link_active,
.v-sidebar-menu.vsm_white-theme.vsm_rtl
  .vsm--link_level-1.vsm--link_exact-active {
  -webkit-box-shadow: -3px 0px 0px 0px #19d412 inset;
  box-shadow: -3px 0px 0px 0px #19d412 inset;
}
.v-sidebar-menu.vsm_white-theme .vsm--header {
  color: rgba(38, 38, 38, 0.7);
}
.v-sidebar-menu.vsm_white-theme .vsm--badge_default {
  color: #262626;
  background-color: #f2f2f2;
}
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  color: #262626;
  background-color: #f2f2f2;
}
</style>
