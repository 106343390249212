var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    login: true,
    loginFootball: _vm.getBackgroundClass === 'loginFootball',
    loginBaseball: _vm.getBackgroundClass === 'loginBaseball',
    loginBasketball: _vm.getBackgroundClass === 'loginBasketball',
    loginSoccer: _vm.getBackgroundClass === 'loginSoccer',
  }},[_c('membership-login-component'),_c('div',{staticClass:"AuthorizeNetSeal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }