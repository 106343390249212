var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",attrs:{"id":"app"}},[_c('div',{staticClass:"card-form"},[_c('div',{staticClass:"card-form__inner"},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.campLogo,"id":"logoTop"}}),_vm._m(0),_c('h5',[_vm._v("Session Date")]),_c('div',[_c('VueDatePicker',{attrs:{"color":this.primaryColor,"max-date":this.getMaxDate,"min-date":this.getMinDate,"allowed-dates":this.getAllAllowedDates,"id":"datePicker"},model:{value:(_vm.chosenDate),callback:function ($$v) {_vm.chosenDate=$$v},expression:"chosenDate"}}),_c('hr'),_c('h5',[_vm._v("Available Times")]),(!_vm.isMobile)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-sm-6"},_vm._l((_vm.getAvailableTimes),function(time,index){return _c('div',{key:time + 'first'},[(index % 2 == 0)?_c('div',{staticClass:"card-body shadow rounded-5 text-center grow",style:({
                  maxWidth: '250px',
                  minHeight: '50px',
                  minWidth: '175px',
                  cursor: 'pointer',
                  backgroundColor:
                    _vm.selectedTime === time ? _vm.primaryColor : 'white',
                  color: _vm.selectedTime === time ? 'white' : 'black',
                }),attrs:{"id":time + 'first'},on:{"click":function($event){return _vm.selectTime(time, 'first')}}},[_c('div',{staticClass:"m-3",staticStyle:{"cursor":"pointer"}},[_c('label',{staticClass:"mt-3",staticStyle:{"cursor":"pointer"}},[_vm._v(_vm._s(_vm.formatTime(time)))])])]):_vm._e()])}),0),_c('div',{staticClass:"col-md-6 col-sm-6"},_vm._l((_vm.getAvailableTimes),function(time,index){return _c('div',{key:time + 'second'},[(index % 2 == 1)?_c('div',{staticClass:"card-body shadow rounded-5 text-center grow",style:({
                  maxWidth: '250px',
                  minHeight: '50px',
                  minWidth: '175px',
                  cursor: 'pointer',
                  backgroundColor:
                    _vm.selectedTime === time ? _vm.primaryColor : 'white',
                  color: _vm.selectedTime === time ? 'white' : 'black',
                }),attrs:{"id":time + 'second'},on:{"click":function($event){return _vm.selectTime(time, 'second')}}},[_c('div',{staticClass:"m-3",staticStyle:{"cursor":"pointer"}},[_c('label',{staticClass:"mt-3",staticStyle:{"cursor":"pointer"},attrs:{"id":time + 'first' + 'text'}},[_vm._v(_vm._s(_vm.formatTime(time)))])])]):_vm._e()])}),0)]):_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6 col-sm-6 justify-content-center"},_vm._l((_vm.getAvailableTimes),function(time){return _c('div',{key:time + 'first'},[_c('div',{staticClass:"card-body shadow rounded-5 text-center grow center justify-content-center",staticStyle:{"max-width":"250px","min-height":"50px","min-width":"175px","cursor":"pointer","background-color":"white"},attrs:{"id":time + 'first'},on:{"click":function($event){return _vm.selectTime(time, 'first')}}},[_c('div',{staticClass:"m-3",staticStyle:{"cursor":"pointer"}},[_c('label',{staticClass:"mt-3",staticStyle:{"cursor":"pointer"},attrs:{"id":time + 'second' + 'text'}},[_vm._v(_vm._s(_vm.formatTime(time)))])])])])}),0)]),_c('hr'),(!_vm.isMobile)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-sm-6"},[_c('button',{staticClass:"card-form__button rounded-pill",style:({
                maxHeight: _vm.isMobile ? '60%' : '',
                backgroundColor: _vm.secondaryColor,
                color: 'white',
              }),on:{"click":_vm.back}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Back ")])]),_c('div',{staticClass:"col-md-9 col-sm-6"},[_c('button',{staticClass:"card-form__button rounded-pill",style:({ backgroundColor: _vm.primaryColor, color: 'white' }),on:{"click":_vm.submitSessionInfo}},[_vm._v(" "+_vm._s(this.buttonName)+" ")])])]):_c('div',[_c('button',{staticClass:"card-form__button rounded-pill",style:({ backgroundColor: _vm.primaryColor, color: 'white' }),on:{"click":_vm.submitSessionInfo}},[_vm._v(" "+_vm._s(this.buttonName)+" ")]),_c('br'),_c('br'),_c('br'),_c('button',{class:{
              'card-form__button': true,
              'w-50': _vm.isMobile,

              'rounded-pill': true,
            },style:({
              backgroundColor: _vm.secondaryColor,
              color: 'white',
            }),on:{"click":_vm.back}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Back ")])])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body",staticStyle:{"margin-top":"10px"}},[_c('h3',{staticClass:"card-title text-center",staticStyle:{"margin-bottom":"10px"}},[_vm._v(" Session Details ")]),_c('br')])
}]

export { render, staticRenderFns }