<template>
  <div
    class="modal fade"
    id="viewScheduledSession"
    tabindex="-1"
    aria-labelledby="viewScheduledSessionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-center"
            id="viewScheduledSessionLabel"
            v-if="!currentScheduledSession.isCamp"
          >
            Scheduled Session - {{ getSessionDate }}
          </h5>
          <h5
            class="modal-title text-center"
            id="viewScheduledSessionLabel"
            v-else
          >
            Payment Link - {{ currentScheduledSession.title }}
          </h5>

          <button
            type="button"
            class="btn-close"
            id="viewScheduledSessionClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="!currentScheduledSession.isCamp">
            <label
              ><b>Registrant:</b>
              {{ currentScheduledSession.camperName }}</label
            >
            <hr />
            <label class="form-label" for="form6Example3">
              <b>Booked Timeslot:</b> {{ currentScheduledSession.title }}
            </label>
            <hr />
            <label class="form-label" for="form6Example3">
              <b>Session Type:</b> {{ currentScheduledSession.campName }}
            </label>
          </div>

          <div v-else>
            <label><b>Start Date: </b> {{ getCampStartDate }}</label>
            <br />
            <label><b>End Date: </b> {{ getCampEndDate }}</label>
            <hr />
            <label
              ><b>Number of Registrants: </b>
              {{ currentScheduledSession.camperLength }}</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button type="button" class="btn btn-primary" @click="goToCamp()">
            View Event
          </button>
          <button
            v-if="fromBookedSessions"
            type="button"
            class="btn btn-primary"
            @click="goToCamper()"
          >
            View Registrant
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import swal from "sweetalert";
// import swal2 from "sweetalert2";
// import axios from "axios";
export default {
  data() {
    return {
      dayApplicable: "TODAY",
      startTime: "",
      endTime: "",
    };
  },
  components: {},
  props: {
    currentScheduledSession: Object,
    fromBookedSessions: Boolean,
  },
  methods: {
    goToCamper() {
      document.getElementById("viewScheduledSessionClose").click();
      this.$router.push(
        "/Dashboard/CheckIn/" +
          this.currentScheduledSession.campID +
          "/" +
          this.currentScheduledSession.camperID
      );
    },

    goToCamp() {
      document.getElementById("viewScheduledSessionClose").click();
      this.$router.push(
        "/Dashboard/ViewCamp/individual/" + this.currentScheduledSession.campID
      );
    },
  },
  computed: {
    getSessionDate() {
      return new Date(this.currentScheduledSession.start).toLocaleDateString();
    },
    getCurrentSessionStartTime() {
      return this.currentScheduledSession.title.split(" - ")[0].slice(0, -2);
    },
    getCurrentSessionEndTime() {
      return this.currentScheduledSession.title.split(" - ")[1].slice(0, -2);
    },
    getCampStartDate() {
      return new Date(this.currentScheduledSession.start).toLocaleDateString();
    },

    getCampEndDate() {
      return new Date(this.currentScheduledSession.end).toLocaleDateString();
    },
  },

  mounted() {
    // if (this.currentScheduledSession?.time) {
    //   this.startTime = this.currentScheduledSession.title
    //     .split(" - ")[0]
    //     .slice(0, -2);
    //   this.endTime = this.currentScheduledSession.title
    //     .split(" - ")[1]
    //     .slice(0, -2);
    // }
  },
};
</script>

<style scoped></style>
