<template>
  <div class="row d-flex">
    <div class="col-md-3">
      <input
        class="form-check-input me-2"
        type="checkbox"
        value=""
        v-model="field.isPurchased"
      />{{ this.field.addOnName }}
    </div>
    <div class="col-md-3">${{ this.field.addOnPrice.toFixed(2) }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    field: Object,
  },
  data() {
    return {};
  },
  methods: {},

  created() {},
};
</script>
<style></style>
