<template>
  <div
    class="modal fade"
    id="viewExternalAccountDetails"
    tabindex="-1"
    aria-labelledby="viewExternalAccountDetailsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-center"
            id="viewExternalAccountDetailsLabel"
          >
            External Account Details
          </h5>
        </div>
        <div class="modal-body">
          <div class="row">
            <h5 class="col-md-6 col-sm-6 text-center">Account Name:</h5>
            <h5 class="col-md-6 col-sm-6 text-center">
              {{
                viewingAccount.accountName
                  ? viewingAccount.accountName.split("-")[1]
                  : ""
              }}
            </h5>
          </div>
          <hr />
          <div class="row">
            <h5 class="col-md-6 col-sm-6 text-center">Routing Number:</h5>
            <h5 class="col-md-6 col-sm-6 text-center">
              <strong>...</strong>{{ viewingAccount.routingNumberReference }}
            </h5>
          </div>
          <div class="row">
            <h5 class="col-md-6 col-sm-6 text-center">Account Number:</h5>
            <h5 class="col-md-6 col-sm-6 text-center">
              <strong>...</strong>{{ viewingAccount.accountNumberReference }}
            </h5>
          </div>
          <hr />
          <div class="row">
            <h5 class="col-md-6 col-sm-6 text-center">Account Type:</h5>
            <h5 class="col-md-6 col-sm-6 text-center">
              {{ viewingAccount.accountType }}
            </h5>
          </div>
          <div class="row">
            <h5 class="col-md-6 col-sm-6 text-center">Account Class:</h5>
            <h5 class="col-md-6 col-sm-6 text-center">
              {{ viewingAccount.accountClass }}
            </h5>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="viewExternalAccountDetailsClose"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn"
            data-bs-toggle="modal"
            data-bs-target="#initiatePayout"
            data-bs-dismiss="modal"
            style="background-color: #363166; color: white"
            @click="setBankAccount()"
          >
            Transfer Funds
          </button>
          <button type="button" class="btn btn-danger" @click="unlinkAccount()">
            Unlink Account
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal2 from "sweetalert2";
import swal from "sweetalert";
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    viewingAccount: Object,
    transfer: Object,
    userID: String,
  },
  methods: {
    setBankAccount() {
      this.transfer.account = this.viewingAccount.vendorID;
    },

    unlinkAccount() {
      swal2
        .fire({
          title: "Unlink Account",
          icon: "warning",
          html: `Are you sure you want to unlink <strong>${
            this.viewingAccount.accountName.split("-")[1]
          }</strong> from Summer Athletes?<br /> <br />`,
          confirmButtonText: '<i class="fa-solid fa-link-slash"></i> Unlink',
          showCancelButton: true,
        })
        .then((willTransfer) => {
          if (willTransfer.isConfirmed) {
            let url =
              process.env.VUE_APP_DB_URL + "banking/unlinkExternalAccount";
            let account = this.viewingAccount;
            axios
              .post(
                url,
                { account: account, userID: this.userID },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("jwt"),
                  },
                }
              )
              .then((response) => {
                document
                  .getElementById("viewExternalAccountDetailsClose")
                  .click();
                this.payoutInfo = response.data.payoutInfo;
                this.$emit("updatedPayoutInfo", {
                  payoutInfo: response.data.payoutInfo,
                });
                swal(
                  "Success",
                  "Bank Account Unlinked Successfully",
                  "success"
                );
              })
              .catch(function (err) {
                let error = err.response;
                swal("Error", error.data.errorMessage, "error");
              });
          }
        });
    },
  },
};
</script>

<style scoped></style>
