<script setup></script>

<template>
  <div>
    <div class="background rounded-4">
      <div class="container">
        <div class="screen">
          <div class="screen-header" data-bs-dismiss="modal">
            <div class="screen-header-left">
              <div class="screen-header-button close"></div>
              <div class="screen-header-button maximize"></div>
              <div class="screen-header-button minimize"></div>
            </div>
            <div class="screen-header-right">
              <div class="screen-header-ellipsis"></div>
              <div class="screen-header-ellipsis"></div>
              <div class="screen-header-ellipsis"></div>
            </div>
          </div>
          <div class="screen-body" :style="{ color: primaryColor }">
            <div class="screen-body-item left" :style="{ color: primaryColor }">
              <div class="app-title" :style="{ color: primaryColor }">
                <span>CONTACT</span>
                <span>ME</span>
                <span
                  :style="{
                    content: '',
                    display: 'block',
                    position: 'absolute',
                    left: '0',
                    bottom: '-10px',
                    width: '25px',
                    height: '4px',
                    background: this.secondaryColor,
                  }"
                ></span>
              </div>
              <div class="app-contact">my hudl : hudl link</div>
            </div>
            <div class="screen-body-item" autocomplete="off">
              <div class="app-form" autocomplete="off">
                <div class="app-form-group" autocomplete="off">
                  <input
                    class="app-form-control"
                    autocomplete="nope"
                    placeholder="NAME"
                    v-model="name"
                  />
                </div>
                <div class="app-form-group">
                  <input
                    class="app-form-control"
                    autocomplete="nope"
                    placeholder="EMAIL"
                    v-model="email"
                  />
                </div>
                <div class="app-form-group">
                  <input
                    class="app-form-control"
                    autocomplete="nope"
                    placeholder="PHONE"
                    v-model="phoneNumber"
                  />
                </div>
                <div class="app-form-group message">
                  <input
                    class="app-form-control"
                    autocomplete="nope"
                    placeholder="MESSAGE"
                    v-model="message"
                  />
                </div>
                <div class="app-form-group buttons">
                  <button
                    id="closeContactModal"
                    class="app-form-button"
                    data-bs-dismiss="modal"
                    :style="{ color: this.secondaryColor }"
                  >
                    CANCEL
                  </button>
                  <button
                    class="app-form-button"
                    :style="{ color: this.primaryColor }"
                    @click="sendMessage()"
                  >
                    SEND
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="credits" style="color: white">
          Powered by
          <a
            class="credits-link"
            href="https://app.summerathletes.com/RecruitExternalCheckout"
            target="_blank"
            style="color: #19d412"
          >
            <img
              src="../../../../../../public/DashLogo.png"
              alt=""
              style="margin-left: 5px; max-height: 25px"
            />
            Summer Athletes
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import swal2 from "sweetalert2";

export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
    recruitID: String,
  },

  data() {
    return {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
    };
  },

  methods: {
    async sendMessage() {
      // Send the form data to your server here
      let url = process.env.VUE_APP_DB_URL + "recruit/contactUs";
      await axios
        .post(url, {
          name: this.name,
          email: this.email,
          phoneNumber: this.phoneNumber,
          message: this.message,
          recruitID: this.recruitID,
        })
        .then(() => {
          document.getElementById("closeContactModal").click();
          swal2.fire({
            title: `<h3 style="color: ${this.secondaryColor}">Message Sent</h5>`,
            html: `<h5 style="color: ${this.secondaryColor}">Thank you for your message. I will get back to you shortly.</h5>`,
            icon: "success",
            iconColor: this.secondaryColor,
            background: `linear-gradient(0.5turn, ${this.primaryColor}, #110f16, #110f16)`,
            confirmButtonColor: this.primaryColor,
          });
        });
    },
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
button,
input {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}

.background {
  display: flex;
  background: #3e3e3e;
}

.container {
  margin: auto;
  padding: 10px;
}

.screen {
  position: relative;
  background: #3e3e3e;
  border-radius: 15px;
}

.screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #ea1d6f;
  font-size: 26px;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  font-size: 14px;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-control::placeholder {
  color: #666;
}

.app-form-control:focus {
}

.app-form-button {
  background: none;
  border: none;
  color: #ea1d6f;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #b9134f;
}

.credits {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: #ffa4bd;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 16px;
  font-weight: normal;
}

.credits-link {
  display: flex;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 12px;
  }

  .app-title:after {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .screen-body-item {
    padding: 0;
  }
}
</style>
