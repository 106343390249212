var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-form",style:({
      width: _vm.isMobile() ? '' : '25rem',
      marginBottom: '15px',
    })},[_c('div',{staticClass:"card-form__inner",style:({
        width: _vm.isMobile() ? '' : '25rem',
        textAlign: _vm.isMobile() ? 'center' : '',
      })},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.image}}),_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(this.title))]),_c('div',[_c('h4',{staticClass:"card-title",staticStyle:{"float":"right"}},[_c('i',{staticClass:"fa-solid fa-circle-info",staticStyle:{"color":"#45487a","cursor":"pointer"},on:{"click":_vm.showDescription}})])]),(this.status == 'Available')?_c('button',{staticClass:"card-form__button rounded-pill"},[_c('a',{on:{"click":function($event){return _vm.launchCampaign()}}},[_vm._v("Launch Campaign")])]):_c('button',{staticClass:"card-form__button rounded-pill",staticStyle:{"background-color":"#361666"}},[_c('a',{on:{"click":function($event){return _vm.viewResults()}}},[_vm._v("View Results")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }