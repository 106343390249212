var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-form",style:({
      width: _vm.isMobile() ? '' : '22rem',
      marginBottom: '15px',
    })},[_c('div',{staticClass:"card-form__inner",staticStyle:{"width":"25rem","text-align":"center"},style:({
        width: _vm.isMobile() ? '' : '22em',
        textAlign: _vm.isMobile() ? 'center' : '',
      })},[(!this.logo)?_c('img',{staticClass:"card-img-top",attrs:{"src":require("../../../public/SummerAthletes.png")}}):_c('img',{staticClass:"card-img-top",attrs:{"src":this.logo}}),_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(this.title))]),_c('p',{staticClass:"card-text"},[_vm._v(_vm._s(this.memberName))]),(_vm.isActive)?_c('button',{staticClass:"card-form__button"},[_c('a',{on:{"click":function($event){return _vm.handleClick()}}},[_vm._v("View Recurring Payment")])]):_c('button',{staticClass:"card-form__button",staticStyle:{"background-color":"red"}},[_c('a',{on:{"click":function($event){return _vm.showCancelInfo()}}},[_vm._v("Canceled")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }