<template>
  <div class="container" :style="{ minHeight: isMobile ? '1500px' : '1000px' }">
    <div class="row">
      <div :class="{ 'col-md-6': true, 'mt-5': isMobile }">
        <MetricPortfolioCard
          :primaryColor="primaryColor"
          :secondaryColor="secondaryColor"
          :isMobile="isMobile"
          :photo="metricsPage.photoOne"
          :subheading="metricsPage.subheadingOne"
          :athleticInfo="recruitProfile.athleticInfo"
          :state="recruitProfile.state"
        ></MetricPortfolioCard>
      </div>
      <div class="col-md-6">
        <div class="card full-metric-card dark blue">
          <div class="card-body" style="color: white">
            <h5 class="card-title">Academics</h5>
            <div class="card-body">
              <div class="row">
                <div class="col-md-2"><h3>GPA</h3></div>
                <div class="col-md-8">
                  <div class="progress" style="height: 30px">
                    <div
                      class="progress-bar"
                      :style="{
                        width: `${(this.gpa / 4.0) * 100}%`,
                        backgroundColor: this.primaryColor,
                      }"
                    >
                      {{ this.gpa.toFixed(2) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h3>{{ this.growthGPA.toFixed(2) }}</h3>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-2"><h3>ACT</h3></div>
                <div class="col-md-8">
                  <div class="progress" style="height: 30px">
                    <div
                      class="progress-bar"
                      :style="{
                        width: `${(this.act / 36) * 100}%`,
                        backgroundColor: this.primaryColor,
                      }"
                    >
                      {{ this.act.toFixed(0) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h3>{{ this.growthACT.toFixed(0) }}</h3>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-2"><h3>SAT</h3></div>
                <div class="col-md-8">
                  <div class="progress" style="height: 30px">
                    <div
                      class="progress-bar"
                      :style="{
                        width: `${(this.sat / 1600) * 100}%`,
                        backgroundColor: this.primaryColor,
                      }"
                    >
                      {{ this.sat.toFixed(0) }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <h3>{{ this.growthSAT.toFixed(0) }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row justify-content-center mt-5"
      :style="{
        overflowY: isMobile ? 'hidden' : '',
        maxHeight: isMobile ? '350px' : '',
      }"
    >
      <MetricBanner
        :primaryColor="this.primaryColor"
        :secondaryColor="this.secondaryColor"
        :state="recruitProfile.state"
        :academicInfo="recruitProfile.academicInfo"
        :athleticInfo="recruitProfile.athleticInfo"
      ></MetricBanner>
    </div>
    <div :style="{ marginBottom: isMobile ? '500px' : '' }"></div>
  </div>
</template>

<script>
import MetricBanner from "./MetricBanner.vue";
import MetricPortfolioCard from "./MetricPortfolioCard.vue";

export default {
  components: { MetricBanner, MetricPortfolioCard },
  props: {
    primaryColor: String,
    secondaryColor: String,
    isMobile: Boolean,
    recruitProfile: Object,
    metricsPage: Object,
  },

  data() {
    return {
      growthGPA: 0.0,
      growthACT: 0,
      growthSAT: 0,
      gpa: 3.7,
      act: 28,
      sat: 1000,
    };
  },

  created() {
    if (this.recruitProfile.academicInfo.gpa) {
      this.gpa = parseFloat(this.recruitProfile.academicInfo.gpa);
    }

    if (this.recruitProfile.academicInfo.act) {
      this.act = parseFloat(this.recruitProfile.academicInfo.act);
    }

    if (this.recruitProfile.academicInfo.sat) {
      this.sat = parseFloat(this.recruitProfile.academicInfo.sat);
    }
  },

  mounted() {
    const gpaInterval = setInterval(() => {
      this.growthGPA += 0.01;
      if (this.growthGPA.toFixed(2) === this.gpa.toFixed(2)) {
        clearInterval(gpaInterval);
      }
    }, 5);

    const actInterval = setInterval(() => {
      this.growthACT += 1;
      if (this.growthACT.toFixed(0) === this.act.toFixed(0)) {
        clearInterval(actInterval);
      }
    }, 50);

    const satInterval = setInterval(() => {
      this.growthSAT += 25;
      if (this.growthSAT.toFixed(0) === this.sat.toFixed(0)) {
        clearInterval(satInterval);
      }
    }, 50);
  },
};
</script>

<style scoped>
.full-metric-card {
}

.dark {
  background: #110f16;
}

.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
