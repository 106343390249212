<template>
  <div class="wrapper" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <div
      class="card-form"
      v-bind:style="{
        marginTop: '3%',
        maxWidth: isMobile() ? '100%' : '75%',
        marginLeft: isMobile() ? '21px' : '',
      }"
    >
      <div class="card-form__inner">
        <div class="row">
          <div class="col profile">
            <span
              ><img :src="getImage" class="image-profile" />
              <label for="file-input"
                ><i
                  class="fa-solid fa-plus fa-3x"
                  style="
                    color: #19d412;
                    position: relative;
                    top: 70px;
                    cursor: pointer;
                  "
                ></i
              ></label>
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event)"
                id="file-input"
                hidden
              />
            </span>
          </div>
        </div>
        <div class="row justify-content-end text-end">
          <span class="m-3" v-if="this.status === 'active'"
            >Active Member
            <i
              class="fa-solid fa-circle-check fa-2xl"
              style="color: #32bd28"
            ></i>
          </span>
          <span v-else class="m-3"
            >Inactive
            <i class="fa-solid fa-ban fa-2xl" style="color: #db2c1f"></i>
          </span>
        </div>
        <h1 class="text-center m-5">
          {{ membership.memberFirstName }} {{ membership.memberLastName }}'s
          Profile
        </h1>
        <h5 class="text-center mb-5">
          <span
            ><i
              class="fa-brands fa-creative-commons-sampling fa-lg m-1"
              style="color: #363166"
            ></i>
            Status: {{ getStatusText }}
          </span>
        </h5>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="firstName" class="card-input__label"
                >Emergency Contact First Name</label
              >
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                placeholder="First Name"
                v-model="member.emergencyContactInfo.firstName"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="lastName" class="card-input__label"
                >Emergency Contact Last Name</label
              >
              <input
                type="text"
                id="lastName"
                class="card-input__input"
                placeholder="Last Name"
                v-model="member.emergencyContactInfo.lastName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label class="card-input__label"
                >Emergency Contact Phone Number</label
              >
              <input
                type="text"
                id="phoneNumber"
                class="card-input__input"
                placeholder="Phone Number"
                v-model="member.emergencyContactInfo.phoneNumber"
              />
            </div>
          </div>
          <div class="col">
            <div class="card-input">
              <label for="email" class="card-input__label"
                >Emergency Contact Email</label
              >
              <input
                type="email"
                id="email"
                class="card-input__input"
                placeholder="Email"
                v-model="member.emergencyContactInfo.email"
              />
            </div>
          </div>
        </div>

        <div class="card-input">
          <label>Address</label>
          <input
            type="text"
            id="address"
            class="card-input__input"
            placeholder="Address"
            v-model="member.emergencyContactInfo.address"
          />
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="city" class="card-input__label">City</label>
              <input
                type="text"
                id="city"
                placeholder="City"
                class="card-input__input"
                v-model="member.emergencyContactInfo.city"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardMonth">State</label>
              <select
                required
                class="card-input__input"
                id="cardMonth"
                v-model="member.emergencyContactInfo.state"
              >
                <option value="" disabled selected>State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardCvv">Zip Code</label>
              <input
                type="text"
                class="card-input__input"
                placeholder="Zip Code"
                v-model="member.emergencyContactInfo.zipCode"
              />
            </div>
          </div>
        </div>

        <!--Start of tri-column info-->
        <div class="row">
          <!--Start of col 1-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getTriCol.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getTriCol[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 0
                "
                :field="getTriCol[index - 1]"
                @change="formatData($event, value2)"
              ></CustomFieldDisplay>
            </li>
          </div>
          <!--Start of col 2-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getTriCol.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getTriCol[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 1
                "
                :field="getTriCol[index - 1]"
                @change="formatData($event, value2)"
              ></CustomFieldDisplay>
            </li>
          </div>

          <!--Start of col 3-->
          <div class="col">
            <li
              style="list-style-type: none"
              v-for="index in getTriCol.length"
              :key="index"
            >
              <CustomFieldDisplay
                v-if="
                  getTriCol[index - 1].fieldType != 'textLong' &&
                  (index + getTextLongNum) % 3 == 2
                "
                :field="getTriCol[index - 1]"
                @change="formatData($event, index)"
              ></CustomFieldDisplay>
            </li>
          </div>
        </div>
        <!--Start of text long info-->
        <li
          style="list-style-type: none"
          v-for="index in getTriCol.length"
          :key="index + getTriCol.length"
        >
          <CustomFieldDisplay
            v-if="getTriCol[index - 1].fieldType == 'textLong'"
            :field="getTriCol[index - 1]"
          ></CustomFieldDisplay>
        </li>
        <!--Start of notes section-->
        <li
          style="list-style-type: none"
          v-for="index in this.numFields"
          :key="index"
        >
          <CamperNotes
            :note="memberNotes[index - 1]"
            @changed="noteChange($event, index)"
          ></CamperNotes>
        </li>
        <div class="card-form m-3" style="min-width: 75%">
          <h3 @click="toggleUploadedDocuments()">
            Uploaded Files
            <i
              v-if="!this.showUploadedDocuments"
              class="fa-solid fa-caret-right"
            ></i>
            <i v-else class="fa-solid fa-caret-down"></i>
          </h3>
          <div v-if="this.showUploadedDocuments">
            <div
              class="card-form__content"
              style="list-style-type: none"
              v-for="index in this.membership.files.length"
              :key="index"
            >
              {{ index }}.
              <a
                href="#"
                @click="
                  getMemberFile(
                    membership.files[index - 1].S3Key,
                    membership.files[index - 1].fileName
                  )
                "
                :id="membership.files[index - 1].S3Key"
              >
                {{ membership.files[index - 1].fileName }}
              </a>
            </div>
          </div>
        </div>

        <div class="card-form m-3" style="min-width: 75%">
          <h3 @click="togglePaymentDetails()" class="mt-5">
            Payment Details
            <i
              v-if="!this.showPaymentDetails"
              class="fa-solid fa-caret-right"
            ></i>
            <i v-else class="fa-solid fa-caret-down"></i>
          </h3>
          <div v-if="this.showPaymentDetails">
            <div class="card-body row">
              <h5>Current Plan</h5>
              <hr />
              <label
                ><strong>${{ subscriptionDetails.amount }}</strong>
                {{ getMembershipPaymentPeriod(membership.payPeriod) }}</label
              >
              <br />
              <label><strong>Joined on:</strong> {{ getStartDate }}</label>
              <label
                ><strong>Plan Renews on:</strong> {{ getRenewalDate }}</label
              >
              <br />
              <br />
              <br />
              <h5>Billing Information</h5>
              <hr />
              <label>
                <b>First Name:</b>
                {{ billingInfo.firstName }}
              </label>
              <label> <b>Last Name:</b> {{ billingInfo.lastName }} </label>
              <label>
                <b>Email:</b> {{ subscriptionDetails.profile.email }}
              </label>
              <label>
                <b>Address:</b> {{ billingInfo.address }},
                {{ billingInfo.city }}, {{ billingInfo.state }},
                {{ billingInfo.zip }}
              </label>
              <label>
                <b>Phone Number:</b> {{ billingInfo.phoneNumber }}
              </label>
              <label
                ><b>Card On File:</b>
                {{
                  subscriptionDetails.profile.paymentProfile.payment.creditCard
                    .cardNumber
                }}</label
              >
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-md-3 col-sm-6">
            <label
              for="inner-file-input"
              class="card-form__button"
              style="margin-top: 10px"
            >
              <center class="mt-2">&#8593; Upload file &#8593;</center>
            </label>
            <div class="card-input">
              <input
                type="file"
                accept="*"
                @change="uploadMemberFile($event)"
                id="inner-file-input"
                hidden
              />
            </div>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button"
              @click="addNotes"
              style="margin-top: 10px"
            >
              Add notes section
            </button>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button"
              @click="saveMemberInfo"
              style="margin-top: 10px"
            >
              Save customer info
            </button>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button"
              type="submit"
              style="margin-top: 10px"
              @click="backToMemberList"
            >
              Back to customer list
            </button>
          </div>
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button"
              @click="cancelMembership"
              v-bind:style="{
                marginTop: '10px',
                backgroundColor: 'red',
                minHeight: isMobile() ? '70px' : '',
              }"
            >
              Stop payments
            </button>
          </div>
        </div>
      </div>
      <!--      <div v-bind:class="{ row: true, 'mb-3': !isMobile() }">-->
      <!--        <div class="col justify-content-start">-->
      <!--          <button-->
      <!--            class="card-form__button"-->
      <!--            type="submit"-->
      <!--            v-bind:style="{-->
      <!--              maxWidth: '100%',-->
      <!--              minHeight: isMobile() ? '70px' : '',-->
      <!--            }"-->
      <!--            @click="backToMemberList"-->
      <!--          >-->
      <!--            Back to Member List-->
      <!--          </button>-->
      <!--        </div>-->

      <!--        <div v-if="this.status === 'active'" class="col justify-content-end">-->
      <!--          <button-->
      <!--            class="card-form__button"-->
      <!--            type="submit"-->
      <!--            v-bind:style="{-->
      <!--              maxWidth: '100%',-->
      <!--              minHeight: isMobile() ? '70px' : '',-->
      <!--              backgroundColor: 'red',-->
      <!--            }"-->
      <!--            @click="cancelMembership"-->
      <!--          >-->
      <!--            Cancel Membership-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";
import ClientLayout from "../../components/ClientLayout.vue";
import CustomFieldDisplay from "../../components/CustomFieldDisplay.vue";
import CamperNotes from "../../components/Campers/CamperNotes.vue";
import moment from "moment/moment";
// import CustomFieldDisplay from "../../components/CustomFieldDisplay.vue";
// import CamperNotes from "../../components/CamperNotes.vue";
// import swal from "sweetalert";
// import axios from "axios";
// import VueJwtDecode from "vue-jwt-decode";

export default {
  components: {
    CamperNotes,
    CustomFieldDisplay,
    ClientLayout,
  },
  data() {
    return {
      user: {},
      data: {},
      showUploadedDocuments: false,
      showPaymentDetails: false,
      membershipID: null,
      memberID: null,
      member: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        emergencyContactInfo: {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
        },
        camperNotes: [],
        status: "active",
      },
      subscriptionDetails: null,
      subscriptionID: null,
      membership: {
        membershipFirstName: "",
        membershipLastName: "",
        email: "",
        phone: "",
        emergencyContactInfo: {
          firstName: "",
          lastName: "",
          address: "",
          city: "",
          state: "",
          zipCode: "",
        },
        files: [],
        memberNotes: [],
        status: "active",
        customFieldValues: [],
      },
      customFieldValues: [],
      memberNotes: [],
      billingInfo: null,
      status: null,
      numFields: 0,
      profileUrl: "",
    };
  },

  methods: {
    uploadImage(event) {
      console.log("PROFILE");
      //this.gif = true;
      const URL = process.env.VUE_APP_DB_URL + "util/setProfileImage";
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("type", "Membership");
      data.append("eventId", this.membershipID);
      data.append("subscriptionId", this.membership.subscriptionID);
      data.append("profileId", this.memberID);
      data.append("file", event.target.files[0]);
      console.log(data);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((response) => {
          console.log(response);
          this.profileUrl = response.data.location;
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    //api call to get member data
    getMemberData() {
      let url =
        process.env.VUE_APP_DB_URL +
        "membership/getMemberForMembership?membershipID=" +
        this.membershipID +
        "&memberID=" +
        this.memberID +
        "&subscriptionID=" +
        this.subscriptionID;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.member = response.data.member;

          this.subscriptionDetails = response.data.subscriptionDetails;
          this.billingInfo = this.subscriptionDetails.profile.shippingProfile;
          this.status = this.subscriptionDetails.status;

          for (const membership of this.member.memberships) {
            if (
              membership.membershipID == this.membershipID &&
              membership.subscriptionID == this.subscriptionID
            ) {
              this.membership = membership;
              // if (!this.membership.memberNotes) {
              //   this.membership.memberNotes = [];
              // } else {
              //   this.membership.memberNotes = membership.memberNotes;
              // }
              // if (!this.membership.files) {
              //   this.membership.files = [];
              // }
              this.profileUrl = this.membership.profileImage;
              this.customFieldValues = membership.customFieldValues;
              if (!membership.memberNotes) {
                this.memberNotes = [];
                this.numFields = 0;
              } else {
                this.numFields = membership.memberNotes.length;
                this.memberNotes = membership.memberNotes;
                console.log(this.memberNotes);
              }
              this.subscriptionID = membership.subscriptionID;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatData() {
      console.log("formatting");
      for (let i = 0; i < this.membership.customFieldValues.length; i++) {
        console.log("test");
        if (this.membership.customFieldValues[i].value2) {
          this.membership.customFieldValues[i].fieldValue =
            this.membership.customFieldValues[i].fieldValue +
            "'" +
            this.membership.customFieldValues[i].value2;
          delete this.membership.customFieldValues[i].value2;
        }
      }
    },

    getSaveData() {
      console.log("formatting");
      let data = {
        memberID: this.memberID,
        membershipID: this.membershipID,
        subscriptionID: this.subscriptionID,
        memberInfo: {},
      };

      for (let i = 0; i < this.membership.customFieldValues.length; i++) {
        console.log("test");
        if (this.membership.customFieldValues[i].value2) {
          this.membership.customFieldValues[i].fieldValue =
            this.membership.customFieldValues[i].fieldValue +
            "'" +
            this.membership.customFieldValues[i].value2;
          delete this.membership.customFieldValues[i].value2;
        }
      }
      data.memberInfo.customFieldValues = this.membership.customFieldValues;
      data.memberInfo.emergencyContactInfo = this.member.emergencyContactInfo;
      data.memberInfo.memberNotes = this.memberNotes;
      return data;
    },
    noteChange(x, y) {
      console.log(x);
      console.log(y);
      this.memberNotes[y - 1] = x;
    },
    getMembershipPaymentPeriod(payPeriod) {
      switch (payPeriod) {
        case "weeklyPrice":
          return "Weekly";
        case "monthlyPrice":
          return "Monthly";
        case "semiAnnualPrice":
          return "Semi-Annually";
        case "annualPrice":
          return "Annually";
        default:
          return "Invalid";
      }
    },
    backToMemberList() {
      this.$router.push("/Dashboard/MembershipCheckIn/" + this.membershipID);
    },
    cancelMembership() {
      let data = {
        accountInfo: {
          memberID: this.memberID,
          firstName: this.member.firstName,
          lastName: this.member.lastName,
          email: this.member.email,
        },
        membershipInfo: {
          membershipID: this.membershipID,
          membershipName: this.membership.membershipName,
          subscriptionID: this.subscriptionID,
          memberFirstName: this.membership.memberFirstName,
          memberLastName: this.membership.memberLastName,
        },
      };

      let url =
        process.env.VUE_APP_DB_URL +
        "membership/cancelMember?membershipID=" +
        this.membershipID +
        "&memberID=" +
        this.memberID;
      //axios post to cancel membership
      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          if (response.data.success) {
            this.$router.push(
              "/Dashboard/MembershipCheckIn/" + this.membershipID
            );
            swal("Success", "Payments stopped successfully!", "success");
          }
        })
        .catch((error) => {
          console.log(error);
          swal(
            "We're Sorry!",
            `We are not able to cancel your membership at this time. Please contact ${this.coachEmail} for assistance.`,
            "error"
          );
        });
    },
    uploadMemberFile(event) {
      console.log("MEMBER FILE");
      this.gif = true;
      const URL =
        process.env.VUE_APP_DB_URL +
        "util/uploadMemberFile?memberID=" +
        this.memberID +
        "&membershipID=" +
        this.membershipID +
        "&subscriptionID=" +
        this.subscriptionID;
      let data = new FormData();
      data.append("name", "camper-" + this.$route.params.profile);
      data.append("file", event.target.files[0]);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then(() => {
          console.log(event.target.files[0]);
          this.gif = false;
          swal(
            "Success",
            `${event.target.files[0].name} uploaded successfully. You may need to refresh the page.`,
            "success"
          );
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },
    getMemberFile(S3Key, fileName) {
      let url =
        process.env.VUE_APP_DB_URL +
        "util/getMemberFile?S3Key=" +
        S3Key +
        "&fileName=" +
        fileName;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
          responseType: "blob",
        })
        .then(async (response) => {
          // console.log(response.data);
          // const downloadBlobURL = window.URL.createObjectURL(downloadBlob);
          const downloadBlobURL = window.URL.createObjectURL(
            new Blob([response.data])
          );

          console.log(downloadBlobURL);
          document.getElementById(S3Key).href = downloadBlobURL;
          // this.selectedFile = response.data;

          const link = document.createElement("a");
          link.href = downloadBlobURL;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (err) {
          console.log(err);

          swal("Error", err, "error");
        });
    },
    saveMemberInfo() {
      swal({
        title: "Confirm",
        text: "Confirm save of member information",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willSave) => {
        if (willSave) {
          let data = this.getSaveData();
          let url =
            process.env.VUE_APP_DB_URL + "membership/updateMemberByCoach";
          axios
            .post(url, data, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            })
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
              this.newCamp = this.savedData;
            });

          swal("Success", "Camper Info Updated", "success");
        }
      });
    },
    addNotes() {
      console.log("here");
      let temp = { title: "", notes: "" };
      this.memberNotes.push(temp);
      this.numFields++;
    },
    toggleUploadedDocuments() {
      this.showUploadedDocuments = !this.showUploadedDocuments;
    },
    togglePaymentDetails() {
      this.showPaymentDetails = !this.showPaymentDetails;
    },
  },
  created() {
    this.membershipID = this.$route.params.membershipID;
    this.memberID = this.$route.params.memberID;
    this.subscriptionID = this.$route.params.subscriptionID;
    this.getMemberData();
  },
  computed: {
    getImage() {
      if (!this.profileUrl) {
        return require("../../../../public/headshot-icon.png");
      } else {
        return this.profileUrl;
      }
    },
    getTriCol() {
      let temp = [];
      if (this.customFieldValues) {
        for (let x = 0; x < this.customFieldValues.length; x++) {
          temp.push(this.customFieldValues[x]);
        }
      }
      return temp;
    },
    getTextLongNum() {
      let temp = 0;
      if (this.customFieldValues) {
        for (let x = 0; x < this.customFieldValues.length; x++) {
          if (this.customFieldValues[x].fieldType == "textLong")
            temp = temp + 1;
        }
      }
      return temp;
    },
    getStatusText() {
      //switch case for expired, suspended, canceled, or terminated
      switch (this.status) {
        case "active":
          return "Making Active Payments";
        case "expired":
          return "Expired";
        case "suspended":
          return "Payments Suspended";
        case "canceled":
          return "Payments Canceled";
        case "terminated":
          return "Payments Terminated";
        default:
          return "Invalid";
      }
    },
    getStartDate() {
      return new Date(this.membership.startDate).toDateString();
    },
    getRenewalDate() {
      const memberStartDate = moment(
        new Date(this.membership.startDate),
        "YYYY-MM-DD"
      );
      let workingDate = moment(new Date(), "YYYY-MM-DD");
      while (memberStartDate.isBefore(workingDate)) {
        if (this.membership.payPeriod == "weeklyPrice") {
          memberStartDate.add(1, "weeks");
        } else if (this.membership.payPeriod == "monthlyPrice") {
          memberStartDate.add(1, "months");
        } else if (this.membership.payPeriod == "semiAnnualPrice") {
          memberStartDate.add(6, "months");
        } else if (this.membership.payPeriod == "annualPrice") {
          memberStartDate.add(12, "months");
        }
      }

      return new Date(memberStartDate).toDateString();
    },
  },
};
</script>
<style>
.profile {
  position: relative !important;
  width: 120px;
  height: 120px;
}
.image-profile {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}
.information-card {
  display: block;
  top: 0px;
  position: relative;
  background-color: white;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #363166;
  transition: 0.3s linear;

  .go-corner {
    opacity: 0.7;
  }

  &:hover {
    border: 1px solid #363166;
    background-color: #f0eff7;
    /*box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);*/
    /*z-index: 500;*/
    transition: 0.3s linear;
  }
}

.information-card:hover {
  p {
    transition: 0.3s linear;
  }
  .go-corner {
    transition: opactiy 0.3s linear;
    opacity: 1;
  }
}
</style>
