var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('table',{staticClass:"table table-hover"},[_vm._m(1),_c('tbody',_vm._l((_vm.getTransactionList),function(transaction){return _c('tr',{key:transaction.id},[_c('td',[_vm._v(_vm._s(transaction.type))]),_c('td',{style:({ maxWidth: _vm.isMobile ? '125px' : '', overflow: 'hidden' })},[_vm._v(" "+_vm._s(transaction.name)+" ")]),_c('td',[(
              transaction.status === 'REFUNDED' ||
              transaction.type === 'Payout' ||
              transaction.type === 'Purchase'
            )?_c('div',[_c('strong',[_vm._v("-$"+_vm._s(_vm.formatCurrency(transaction.paid)))])]):_c('div',[_c('strong',[_vm._v("+$"+_vm._s(_vm.formatCurrency(transaction.paid)))])])])])}),0)]),_c('center',[_c('button',{staticClass:"btn rounded-pill",staticStyle:{"background-color":"#363166","color":"white"},on:{"click":function($event){return _vm.viewTransactions()}}},[_vm._v(" View All Transactions ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"card-title"},[_c('b',[_vm._v("Recent Transactions ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"position":"sticky","top":"0px"},attrs:{"scope":"col"}},[_vm._v("Type")]),_c('th',{staticStyle:{"position":"sticky","top":"0px","max-width":"125px"},attrs:{"scope":"col"}},[_vm._v(" Name ")]),_c('th',{staticStyle:{"position":"sticky","top":"0px"},attrs:{"scope":"col"}},[_vm._v("Amount")])])])
}]

export { render, staticRenderFns }