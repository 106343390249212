<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="this.campLogo" class="card-img-top rounded-corners" />
        <h3 class="text-center">Finalize Registration</h3>
        <br />
        <ul class="list-group" style="margin-top: 20px">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>{{ this.campName }}</b>
            </div>
          </li>
          <li class="list-group-item">
            <div class="d-flex justify-content-between">
              <div>Authorize Athlete Profile</div>
              <div>$0.00</div>
            </div>
          </li>
        </ul>

        <PayPalSubscription
          class="mt-3"
          :planID="freeTrialPlanID"
          :camperInfo="this.camperInfo"
          :additionalCampers="this.additionalCampers"
          :campId="this.campId"
          @checkedOut="this.checkedOut"
        ></PayPalSubscription>
        <p class="text-sm text-muted fst-italic text-center">
          You must authorize permissions for your athlete profile to continue
        </p>
        <br />
        <br />
        <br />
        <button
          :class="{
            'card-form__button': true,
            'w-25': !isMobile,
            'w-50': isMobile,
            'rounded-pill': true,
          }"
          :style="{
            backgroundColor: secondaryColor,
            color: 'white',
          }"
          @click="back"
        >
          <i class="fa-solid fa-arrow-left"></i>
          Back
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import PayPalSubscription from "../PayPalSubscription.vue";
import swal from "sweetalert";

export default {
  components: { PayPalSubscription },
  props: {
    secondaryColor: String,
    isMobile: Boolean,
    campLogo: String,
    campName: String,
    freeTrialPlanID: String,
    camperInfo: Object,
    additionalCampers: Array,
    campId: String,
  },

  data() {
    return {
      test: "",
    };
  },
  methods: {
    back() {
      swal({
        title: "Confirm Athlete Profile",
        text: "You must confirm your athlete profile before continuing.",
        icon: "warning",
      });
    },

    checkedOut() {
      swal({
        title: "Success!",
        text: "You have successfully checked out!",
        icon: "success",
        buttons: false,
        timer: 2000,
      });
      this.$router.push("/Camps/Success");
    },
  },
};
</script>

<style scoped></style>
