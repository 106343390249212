var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
      wrapperNew: true,
      'rounded-5': true,
      'mb-5': _vm.isMobile,
    },style:({ marginRight: _vm.isMobile ? '33px' : '' })},[_c('div',{staticClass:"colsNew"},[_c('div',{staticClass:"colNew",staticStyle:{"max-height":"550px"}},[_c('div',{staticClass:"containerNew"},[_c('div',{staticClass:"front",style:({
              backgroundImage: `url(${_vm.photo})`,
              minHeight: _vm.isMobile ? '612px' : '575px',
              maxHeight: _vm.isMobile ? '612px' : '575px',
              minWidth: _vm.isMobile ? '350px' : '550px',
              maxWidth: _vm.isMobile ? '350px' : '550px',
            })},[_c('div',{staticClass:"inner"},[_c('p',[_vm._v(_vm._s(_vm.recruitName))]),_c('span',[_vm._v("Changing the Game")])])]),_c('div',{staticClass:"back",style:({
              minHeight: _vm.isMobile ? '612px' : '575px',
              maxHeight: _vm.isMobile ? '612px' : '575px',
              minWidth: _vm.isMobile ? '350px' : '550px',
              maxWidth: _vm.isMobile ? '350px' : '550px',
              background: '#3e3e3e',
            })},[_c('div',{staticClass:"inner"},[_c('ContactCard',{style:({
                  minHeight: _vm.isMobile ? '580px' : '540px',
                  maxHeight: _vm.isMobile ? '580px' : '540px',
                  minWidth: _vm.isMobile ? '300px' : '500px',
                  maxWidth: _vm.isMobile ? '300px' : '500px',
                }),attrs:{"primaryColor":this.primaryColor,"secondaryColor":this.secondaryColor,"recruitID":_vm.recruitID,"isMobile":_vm.isMobile}})],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }