<template>
  <div class="wrapper" id="app">
    <Layout :user="user"></Layout>
    <div
      class="card-form"
      v-bind:style="{
        marginTop: '3%',
        maxWidth: isMobile() ? '100%' : '75%',
        marginLeft: isMobile() ? '8%' : '',
      }"
    >
      <div class="card-form__inner">
        <div class="row justify-content-start">
          <div class="col-md-2 profile justify-content-start">
            <span class="position-relative d-inline-block">
              <img :src="getImage" class="image-profile" />
              <label for="image-input" class="image-label">
                <i
                  class="fa-solid fa-plus"
                  style="color: #19d412; cursor: pointer"
                ></i>
              </label>
              <input
                type="file"
                accept="image/*"
                @change="uploadImage($event)"
                id="image-input"
                hidden
              />
            </span>
          </div>
          <div class="col-md-8 col-sm-6 mt-5">
            <h1><center>Athlete Information</center></h1>
          </div>
          <div class="col-md-2 col-sm-6">
            <img
              src="../../../public/SummerAthletes.png"
              class="card-img-top"
              style="max-width: 400px; max-height: 300px"
            />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="firstName" class="card-input__label"
                >Athlete First Name</label
              >
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                placeholder="First Name"
                v-model="data.recruitProfile.firstName"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="lastName" class="card-input__label"
                >Athlete Last Name</label
              >
              <input
                type="text"
                id="lastName"
                class="card-input__input"
                placeholder="Last Name"
                v-model="data.recruitProfile.lastName"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label class="card-input__label">Contact Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                class="card-input__input"
                placeholder="Phone Number"
                v-model="data.recruitProfile.contactPhone"
              />
            </div>
          </div>
          <div class="col">
            <div class="card-input">
              <label for="email" class="card-input__label">Contact Email</label>
              <input
                type="email"
                id="email"
                class="card-input__input"
                placeholder="Email"
                v-model="data.recruitProfile.contactEmail"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="city" class="card-input__label">School</label>
              <input
                type="text"
                id="city"
                placeholder="City"
                class="card-input__input"
                v-model="data.recruitProfile.school"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardMonth">State</label>
              <select
                required
                class="card-input__input"
                id="cardMonth"
                v-model="data.recruitProfile.state"
              >
                <option value="" disabled selected>State</option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
            </div>
          </div>

          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardMonth">Graduation Class</label>
              <select
                required
                class="card-input__input"
                id="cardMonth"
                v-model="data.recruitProfile.class"
              >
                <option value="" disabled selected>Graduation Class</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
                <option value="2032">2032+</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="firstName" class="card-input__label"
                >Twitter Profile Link</label
              >
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                placeholder="https:///twitter.com/FutureCollegeAthlete"
                v-model="data.recruitProfile.profileLinks.twitter"
              />
            </div>
          </div>
          <div class="col-md-6 col-sm-6">
            <div class="card-input">
              <label for="lastName" class="card-input__label"
                >Hudl Profile Link</label
              >
              <input
                type="text"
                id="lastName"
                class="card-input__input"
                placeholder="https:///hudl.com/profile/12345678/FutureCollegeAthlete"
                v-model="data.recruitProfile.profileLinks.hudl"
              />
            </div>
          </div>
        </div>
        <br />
        <h4><center>Athletic Information</center></h4>
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="height" class="card-input__label">Height</label>
              <input
                type="text"
                id="height"
                placeholder="Height"
                class="card-input__input"
                v-model="data.recruitProfile.athleticInfo.height"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="weight">Weight (in lbs)</label>
              <input
                type="text"
                id="weight"
                placeholder="Weight"
                class="card-input__input"
                v-model="data.recruitProfile.athleticInfo.weight"
              />
            </div>
          </div>

          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="cardMonth">Primary Sport</label>
              <select
                required
                class="card-input__input"
                id="cardMonth"
                v-model="data.recruitProfile.athleticInfo.primarySport"
              >
                <option value="" disabled selected>Primary Sport</option>
                <option value="Undecided">Undecided</option>
                <option value="Baseball">Baseball</option>
                <option value="Basketball">Basketball</option>
                <option value="Biking">Biking</option>
                <option value="BMX">BMX</option>
                <option value="Cheerleading">Cheerleading</option>
                <option value="Cross Country">Cross Country</option>
                <option value="Football">Football</option>
                <option value="Golf">Golf</option>
                <option value="Gymnastics">Gymnastics</option>
                <option value="Hockey">Hockey</option>
                <option value="Lacrosse">Lacrosse</option>
                <option value="MartialArts">Martial Arts</option>
                <option value="MotorCross">Motor Cross</option>
                <option value="Rowing">Rowing</option>
                <option value="Rugby">Rugby</option>
                <option value="Skiing">Skiing</option>
                <option value="Softball">Softball</option>
                <option value="Snowboarding">Snowboarding</option>
                <option value="Swimming and Diving">Swimming and Diving</option>
                <option value="Tennis">Tennis</option>
                <option value="Track and Field">Track and Field</option>
                <option value="Volleyball">Volleyball</option>
                <option value="WaterSports">Water Sports</option>
                <option value="Wrestling">Wrestling</option>
              </select>
            </div>
          </div>
        </div>
        <!--Start of additional fields-->
        <div>
          <draggable
            v-model="data.recruitProfile.athleticInfo.additionalMetrics"
            class="draggable"
          >
            <li
              class="list-group-item"
              v-for="(metric, index) in data.recruitProfile.athleticInfo
                .additionalMetrics"
              :key="index"
            >
              <div class="row">
                <div class="col" style="margin-top: 5px">
                  <label class="form-label">Metric Title</label>
                  <input
                    v-model="metric.title"
                    required
                    class="card-input__input"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label">Metric Value</label>
                  <input
                    v-model="metric.value"
                    required
                    class="card-input__input"
                  />
                </div>
                <div class="col-1" style="margin-top: 30px">
                  <button
                    class="btn btn-danger"
                    @click="removeMetric(index)"
                    title="Remove Metric"
                  >
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
              </div>
            </li>
          </draggable>
          <div class="row justify-content-center mb-5">
            <div class="col-md-3 col-sm-6">
              <button
                class="card-form__button rounded-pill"
                @click="addMetric"
                style="color: white; background-color: #363166"
              >
                Add Metric
              </button>
            </div>
          </div>
        </div>
        <!--End of additional fields-->

        <br />
        <h4><center>Academic Information</center></h4>
        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="gpa" class="card-input__label">GPA</label>
              <input
                type="text"
                id="gpa"
                placeholder="4.0"
                class="card-input__input"
                v-model="data.recruitProfile.academicInfo.gpa"
              />
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="act">ACT</label>
              <input
                type="text"
                id="act"
                placeholder="36"
                class="card-input__input"
                v-model="data.recruitProfile.academicInfo.act"
              />
            </div>
          </div>

          <div class="col-md-4 col-sm-6">
            <div class="card-input">
              <label for="sat">SAT</label>
              <input
                type="text"
                id=""
                placeholder="1600"
                class="card-input__input"
                v-model="data.recruitProfile.academicInfo.sat"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4 col-sm-6">
            <center>
              <button
                :class="{
                  'card-form__button': true,
                  'rounded-pill': true,
                  disabled:
                    !this.data.recruitProfile.firstName ||
                    !this.data.recruitProfile.lastName,
                }"
                :disabled="
                  !this.data.recruitProfile.firstName ||
                  !this.data.recruitProfile.lastName
                "
                @click="saveProfile"
              >
                {{
                  this.data.recruitProfile.firstName
                    ? "Save " +
                      this.data.recruitProfile.firstName +
                      "'s Profile"
                    : "Save Profile"
                }}
              </button>
            </center>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--import swal from "sweetalert";
import axios from "axios";--->
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
import Layout from "../components/Layout.vue";
import draggable from "vuedraggable";

export default {
  components: {
    Layout,
    draggable,
  },
  data() {
    return {
      user: {},
      data: {
        recruitProfile: {
          imageURL: "",
          firstName: "",
          lastName: "",
          contactPhone: "",
          contactEmail: "",
          school: "",
          class: "",
          state: "",
          athleticInfo: {
            height: "",
            weight: "",
            primarySport: "",
            additionalMetrics: [],
          },
          academicInfo: {
            gpa: "",
            act: "",
            sat: "",
          },
          profileLinks: {
            facebook: "",
            instagram: "",
            twitter: "",
            hudl: "",
          },
        },
      },
    };
  },

  methods: {
    addMetric() {
      this.data.recruitProfile.athleticInfo.additionalMetrics.push({
        title: "",
        value: "",
      });
    },
    removeMetric(index) {
      this.data.recruitProfile.athleticInfo.additionalMetrics.splice(index, 1);
    },
    uploadImage(event) {
      let id = this.user._id;
      //this.gif = true;
      const URL = process.env.VUE_APP_DB_URL + "util/setProfileImage";
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("type", "recruit");
      data.append("profileId", id);
      data.append("file", event.target.files[0]);
      console.log(data);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((response) => {
          console.log(response);
          this.data.recruitProfile.imageURL = response.data.location;
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },

    saveProfile() {
      console.log("saving");
      swal({
        title: "Confirm",
        text: "Confirm save of information",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willSave) => {
        if (willSave) {
          console.log("here");
          let url = process.env.VUE_APP_DB_URL + "recruit/setRecruitProfile";
          axios
            .post(
              url,
              {
                recruitID: this.user._id,
                recruitProfile: this.data.recruitProfile,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .then(() => {
              swal("Success", "Athlete Profile Updated", "success");
              if (this.user.activities.length > 0) {
                this.$router.push("/Recruit/Dashboard/Activities");
              } else {
                this.$router.push("/Recruit/Dashboard/Website");
              }
            })
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
              this.newCamp = this.savedData;
            });
        }
      });
    },

    getUserDetails() {
      this.user = JSON.parse(localStorage.getItem("user"));
    },
    async getRecruitData() {
      let id = this.user._id;
      console.log(id);
      let url = process.env.VUE_APP_DB_URL + "recruit/getRecruit?id=" + id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.data.recruitProfile = response.data.recruitProfile;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    areNamesFilled() {
      let { firstName, lastName } = this.data.recruitProfile;
      return (
        firstName &&
        lastName &&
        firstName.trim() !== "" &&
        lastName.trim() !== ""
      );
    },
  },
  created() {
    this.getUserDetails();
    console.log(this.data.recruitProfile);
    this.getRecruitData();
  },
  computed: {
    getImage() {
      if (!this.data.recruitProfile.imageURL) {
        return require("../../../public/headshot-icon.png");
      } else {
        return this.data.recruitProfile.imageURL;
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.areNamesFilled()) {
      swal({
        title: "Incomplete Profile",
        text: "First and last name must be filled out.",
        icon: "warning",
        button: "OK",
        dangerMode: true,
      }).then(() => {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>
<style scoped>
.image-profile {
  display: block;
  max-width: 100%;
  height: auto;
}

.image-label {
  position: absolute;
  bottom: -10px; /* Adjust this value as needed */
  right: -10px; /* Adjust this value as needed */
  font-size: 2rem; /* Adjust this value to make the plus button smaller */
}

.disabled {
  opacity: 0.5;
  background-color: gray;
  cursor: not-allowed;
}
</style>
