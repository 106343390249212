<template>
  <div class="wrapper container">
    <ClientLayout :user="user"></ClientLayout>
    <EventType v-show="this.status == 0"></EventType>
  </div>
</template>
<script>
import VueJwtDecode from "vue-jwt-decode";
import ClientLayout from "../components/ClientLayout.vue";
import EventType from "../components/EventType.vue";

export default {
  components: { ClientLayout, EventType },
  data() {
    return {
      CampBool: false,
      status: 0,
    };
  },

  props: {},
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
  },

  created() {
    this.getUserDetails();
  },
};
</script>
