<template>
  <div
    class="card-form"
    v-bind:style="{
      width: isMobile() ? '' : '22rem',
      marginBottom: '15px',
    }"
  >
    <div
      class="card-form__inner"
      style="width: 25rem; text-align: center"
      v-bind:style="{
        width: isMobile() ? '' : '22em',
        textAlign: isMobile() ? 'center' : '',
      }"
    >
      <img
        v-if="!this.logo"
        src="../../../public/SummerAthletes.png"
        class="card-img-top"
      />
      <img v-else :src="this.logo" class="card-img-top" />
      <div class="card-body">
        <h2 class="card-title">{{ this.title }}</h2>
        <h5 class="card-text">{{ this.coachName }}</h5>
        <hr />
        <div class="card-text">
          <div>
            <h5>Due: ${{ getDateRangeOne }}</h5>
          </div>
        </div>

        <div>Total Earnings: ${{ getMoney }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  components: {},
  props: {
    title: String,
    logo: String,
    membershipID: String,
    members: Array,
    coachName: String,
    membershipPayPeriod: Object,
    startDateRange: String,
    endDateRange: String,
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  computed: {
    getDateRangeOne: function () {
      let totalAmountCharged = 0;
      for (const member of this.members) {
        if (this.startDateRange && this.endDateRange) {
          if (member.paymentPeriod === "weeklyPrice") {
            const weeklyPrice = this.membershipPayPeriod.weeklyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              new Date(this.startDateRange),
              "YYYY-MM-DD"
            );
            let endDateRange = moment(
              new Date(this.endDateRange),
              "YYYY-MM-DD"
            );

            let currentChargeDate = startDateRange;

            if (!member.isActive) {
              if (member.endDate) {
                endDateRange = moment(member.endDate, "YYYY-MM-DD");
              } else {
                endDateRange = startDateRange.add(1, "days");
              }
            }
            for (let index = 0; index < 1000; index++) {
              let addAmount = 7 * index;
              currentChargeDate = memberStartDate
                .clone()
                .add(addAmount, "days");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += weeklyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
            if (!member.isActive && member.chargeFailure) {
              totalAmountCharged = totalAmountCharged - weeklyPrice;
            }
          }

          if (member.paymentPeriod === "monthlyPrice") {
            const monthlyPrice = this.membershipPayPeriod.monthlyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              new Date(this.startDateRange),
              "YYYY-MM-DD"
            );
            let endDateRange = moment(
              new Date(this.endDateRange),
              "YYYY-MM-DD"
            );
            let currentChargeDate = startDateRange;
            if (!member.isActive) {
              if (member.endDate) {
                endDateRange = moment(member.endDate, "YYYY-MM-DD");
              } else {
                endDateRange = startDateRange.add(1, "days");
              }
            }
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += monthlyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
            if (!member.isActive && member.chargeFailure) {
              totalAmountCharged = totalAmountCharged - monthlyPrice;
            }
          }

          if (member.paymentPeriod === "semiAnnualPrice") {
            const semiAnnualPrice = this.membershipPayPeriod.semiAnnualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              new Date(this.startDateRange),
              "YYYY-MM-DD"
            );
            let endDateRange = moment(
              new Date(this.endDateRange),
              "YYYY-MM-DD"
            );
            let currentChargeDate = startDateRange;
            if (!member.isActive) {
              if (member.endDate) {
                endDateRange = moment(member.endDate, "YYYY-MM-DD");
              } else {
                endDateRange = startDateRange.add(1, "days");
              }
            }
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                if (index % 6 === 0) {
                  totalAmountCharged += semiAnnualPrice;
                }
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
            if (!member.isActive && member.chargeFailure) {
              totalAmountCharged = totalAmountCharged - semiAnnualPrice;
            }
          }

          if (member.paymentPeriod === "annualPrice") {
            const annualPrice = this.membershipPayPeriod.annualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              new Date(this.startDateRange),
              "YYYY-MM-DD"
            );
            let endDateRange = moment(
              new Date(this.endDateRange),
              "YYYY-MM-DD"
            );
            let currentChargeDate = startDateRange;
            if (!member.isActive) {
              if (member.endDate) {
                endDateRange = moment(member.endDate, "YYYY-MM-DD");
              } else {
                endDateRange = startDateRange.add(1, "days");
              }
            }
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "years");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += annualPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
            if (!member.isActive && member.chargeFailure) {
              totalAmountCharged = totalAmountCharged - annualPrice;
            }
          }
        }
      }
      return totalAmountCharged.toFixed(2);
    },

    getMoney() {
      let totalAmountCharged = 0;

      for (const member of this.members) {
        if (member.paymentPeriod === "weeklyPrice") {
          const today = new Date();
          const weeklyPrice = this.membershipPayPeriod.weeklyPrice;
          const startDate = moment(member.startDate, "YYYY-MM-DD");
          let current;
          if (member.isActive) {
            current = moment(today, "YYYY-MM-DD");
          } else {
            if (member.endDate) {
              current = moment(member.endDate, "YYYY-MM-DD");
            } else {
              current = startDate.add(1, "days");
            }
          }
          let totalTimesCharged = current.diff(startDate, "weeks") + 1;
          if (!member.isActive && member.chargeFailure) {
            totalTimesCharged = totalTimesCharged - 1;
          }
          totalAmountCharged += weeklyPrice * totalTimesCharged;
        }
        if (member.paymentPeriod === "monthlyPrice") {
          const today = new Date();
          const monthlyPrice = this.membershipPayPeriod.monthlyPrice;
          const startDate = moment(member.startDate, "YYYY-MM-DD");
          let current;
          if (member.isActive) {
            current = moment(today, "YYYY-MM-DD");
          } else {
            if (member.endDate) {
              current = moment(member.endDate, "YYYY-MM-DD");
            } else {
              current = startDate.add(1, "days");
            }
          }
          let totalTimesCharged = current.diff(startDate, "months") + 1;
          if (!member.isActive && member.chargeFailure) {
            totalTimesCharged = totalTimesCharged - 1;
          }
          totalAmountCharged += monthlyPrice * totalTimesCharged;
        }
        if (member.paymentPeriod === "semiAnnualPrice") {
          const today = new Date();
          const semiAnnualPrice = this.membershipPayPeriod.semiAnnualPrice;
          const startDate = moment(member.startDate, "YYYY-MM-DD");
          let current;
          if (member.isActive) {
            current = moment(today, "YYYY-MM-DD");
          } else {
            if (member.endDate) {
              current = moment(member.endDate, "YYYY-MM-DD");
            } else {
              current = startDate.add(1, "days");
            }
          }
          let totalTimesCharged = current.diff(startDate, "months");
          if (!member.isActive && member.chargeFailure) {
            totalTimesCharged = totalTimesCharged - 1;
          }
          console.log(totalTimesCharged);
          totalAmountCharged +=
            semiAnnualPrice * (Math.floor(totalTimesCharged / 6) + 1);
        }
        if (member.paymentPeriod === "annualPrice") {
          const today = new Date();
          const annualPrice = this.membershipPayPeriod.annualPrice;
          const startDate = moment(member.startDate, "YYYY-MM-DD");
          let current;
          if (member.isActive) {
            current = moment(today, "YYYY-MM-DD");
          } else {
            if (member.endDate) {
              current = moment(member.endDate, "YYYY-MM-DD");
            } else {
              current = startDate.add(1, "days");
            }
          }
          let totalTimesCharged = current.diff(startDate, "years") + 1;
          if (!member.isActive && member.chargeFailure) {
            totalTimesCharged = totalTimesCharged - 1;
          }
          totalAmountCharged += annualPrice * totalTimesCharged;
        }
      }
      return totalAmountCharged.toFixed(2);
    },
  },
};
</script>
