<template>
  <div class="container" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img
          :src="this.campLogo"
          class="card-img-top rounded-corners"
          id="logoTop"
        />

        <div class="card-body" style="margin-top: 10px">
          <h3 class="card-title text-center" style="margin-bottom: 10px">
            Session Details
          </h3>
          <br />
        </div>
        <h5>Session Date</h5>
        <div>
          <VueDatePicker
            :color="this.primaryColor"
            :max-date="this.getMaxDate"
            :min-date="this.getMinDate"
            :allowed-dates="this.getAllAllowedDates"
            v-model="chosenDate"
            id="datePicker"
          ></VueDatePicker>
          <hr />
          <h5>Available Times</h5>
          <div class="row" v-if="!isMobile">
            <div class="col-md-6 col-sm-6">
              <div
                v-for="(time, index) in getAvailableTimes"
                :key="time + 'first'"
              >
                <div
                  class="card-body shadow rounded-5 text-center grow"
                  :id="time + 'first'"
                  :style="{
                    maxWidth: '250px',
                    minHeight: '50px',
                    minWidth: '175px',
                    cursor: 'pointer',
                    backgroundColor:
                      selectedTime === time ? primaryColor : 'white',
                    color: selectedTime === time ? 'white' : 'black',
                  }"
                  v-if="index % 2 == 0"
                  @click="selectTime(time, 'first')"
                >
                  <div class="m-3" style="cursor: pointer">
                    <label class="mt-3" style="cursor: pointer">{{
                      formatTime(time)
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div
                v-for="(time, index) in getAvailableTimes"
                :key="time + 'second'"
              >
                <div
                  class="card-body shadow rounded-5 text-center grow"
                  :style="{
                    maxWidth: '250px',
                    minHeight: '50px',
                    minWidth: '175px',
                    cursor: 'pointer',
                    backgroundColor:
                      selectedTime === time ? primaryColor : 'white',
                    color: selectedTime === time ? 'white' : 'black',
                  }"
                  :id="time + 'second'"
                  @click="selectTime(time, 'second')"
                  v-if="index % 2 == 1"
                >
                  <div class="m-3" style="cursor: pointer">
                    <label
                      class="mt-3"
                      style="cursor: pointer"
                      :id="time + 'first' + 'text'"
                      >{{ formatTime(time) }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-else>
            <div class="col-md-6 col-sm-6 justify-content-center">
              <div v-for="time in getAvailableTimes" :key="time + 'first'">
                <div
                  class="card-body shadow rounded-5 text-center grow center justify-content-center"
                  :id="time + 'first'"
                  style="
                    max-width: 250px;
                    min-height: 50px;
                    min-width: 175px;
                    cursor: pointer;
                    background-color: white;
                  "
                  @click="selectTime(time, 'first')"
                >
                  <div class="m-3" style="cursor: pointer">
                    <label
                      class="mt-3"
                      style="cursor: pointer"
                      :id="time + 'second' + 'text'"
                      >{{ formatTime(time) }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row" v-if="!isMobile">
            <div class="col-md-3 col-sm-6">
              <button
                class="card-form__button rounded-pill"
                :style="{
                  maxHeight: isMobile ? '60%' : '',
                  backgroundColor: secondaryColor,
                  color: 'white',
                }"
                @click="back"
              >
                <i class="fa-solid fa-arrow-left"></i>
                Back
              </button>
            </div>

            <div class="col-md-9 col-sm-6">
              <button
                class="card-form__button rounded-pill"
                :style="{ backgroundColor: primaryColor, color: 'white' }"
                @click="submitSessionInfo"
              >
                {{ this.buttonName }}
              </button>
            </div>
          </div>
          <div v-else>
            <button
              class="card-form__button rounded-pill"
              :style="{ backgroundColor: primaryColor, color: 'white' }"
              @click="submitSessionInfo"
            >
              {{ this.buttonName }}
            </button>
            <br />
            <br />
            <br />
            <button
              :class="{
                'card-form__button': true,
                'w-50': isMobile,

                'rounded-pill': true,
              }"
              :style="{
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import moment from "moment";

export default {
  components: {
    VueDatePicker,
  },
  props: {
    sessionInfo: Object,
    campLogo: String,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
    availability: Array,
    buttonName: String,
    chosenDate: String,
    selectedTime: String,
  },
  data() {
    return {
      openSeatsPerTimeSlot: {},
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submitSessionInfo() {
      if (!this.chosenDate) {
        swal({
          title: "Error",
          text: "Please select a date",
          icon: "error",
        });
        return;
      }
      if (!this.selectedTime) {
        swal({
          title: "Error",
          text: "Please select a time",
          icon: "error",
        });
        return;
      }

      const existingSessionsThisDay = this.getExistingSessionsThisDay;

      let timeSlotCounter = 0;
      for (const session of existingSessionsThisDay) {
        if (this.selectedTime === session.time) {
          timeSlotCounter += 1;
        }
      }

      const timeSlotsLeft =
        this.sessionInfo.maxSessionParticipants *
          this.openSeatsPerTimeSlot[this.selectedTime] -
        timeSlotCounter;

      let data = {
        date: this.chosenDate,
        time: this.selectedTime,
        timeSlotsLeft: timeSlotsLeft,
      };
      this.$emit("submitSessionInfo", data);
    },
    getAllAllowedDates(val) {
      let currentDateInRange = moment(val).format("YYYY-MM-DD");
      for (const availability of this.availability) {
        let skipIteration = false;
        let availabilityDate = moment(availability.date).format("YYYY-MM-DD");
        if (availabilityDate == currentDateInRange && !availability.isDeleted) {
          return true;
        } else if (availability.repeating && !availability.isDeleted) {
          if (moment(currentDateInRange).isAfter(moment(availabilityDate))) {
            for (const alteration of availability.alterations) {
              let alterationDate = moment(alteration.date).format("YYYY-MM-DD");
              if (currentDateInRange == alterationDate) {
                if (alteration.isDeleted) {
                  skipIteration = true;
                  break;
                } else {
                  return true;
                }
              }
            }
            if (!skipIteration) {
              if (availability.repeatingSetting === "DAY") {
                return true;
              } else if (availability.repeatingSetting === "WEEK") {
                for (
                  let weekIndex = 0;
                  weekIndex < this.sessionInfo.maxAdvanceNotice;
                  weekIndex++
                ) {
                  let testDate = moment(availabilityDate)
                    .add(weekIndex, "weeks")
                    .format("YYYY-MM-DD");
                  if (testDate == currentDateInRange) {
                    return true;
                  }
                }
              } else if (availability.repeatingSetting === "MONTH") {
                for (
                  let monthIndex = 0;
                  monthIndex < this.sessionInfo.maxAdvanceNotice;
                  monthIndex++
                ) {
                  let testDate = moment(availabilityDate)
                    .add(monthIndex, "months")
                    .format("YYYY-MM-DD");
                  if (testDate == currentDateInRange) {
                    return true;
                  }
                }
              }
            }
          }
        }
      }
      return false;
    },
    formatTime(time) {
      let startTime = time.split(" - ")[0];
      let endTime = time.split(" - ")[1];

      let startHour = startTime.split(":")[0];
      let endHour = endTime.split(":")[0];

      let startTag = "";
      let endTag = "";

      if (parseInt(startHour) < 12) {
        startTag = "am";
      } else {
        if (startHour > 12) {
          startHour = parseInt(startHour) - 12;
        }
        startTag = "pm";
      }

      if (parseInt(endHour) < 12) {
        endTag = "am";
      } else {
        if (endHour > 12) {
          endHour = parseInt(endHour) - 12;
        }
        endTag = "pm";
      }

      return `${startHour}:${startTime.split(":")[1]}${startTag} - ${endHour}:${
        endTime.split(":")[1]
      }${endTag}`;
    },

    selectTime(time, location) {
      this.selectedTime = time;
      let selectedTimeBox = document.getElementById(time + location);
      if (selectedTimeBox.style.backgroundColor !== "white") {
        selectedTimeBox.style.backgroundColor = "white";
        selectedTimeBox.style.color = "black";
        this.selectedTime = "";
      } else {
        selectedTimeBox.style.backgroundColor = this.primaryColor;
        selectedTimeBox.style.color = "white";
        let allTimes = document.getElementsByClassName(
          "card-body shadow rounded-5 text-center grow"
        );
        for (let timeBox of allTimes) {
          if (
            timeBox.style.backgroundColor !== "white" &&
            timeBox.id !== time + location
          ) {
            timeBox.style.backgroundColor = "white";
            timeBox.style.color = "black";
          }
        }
      }
    },
    setTimeSlotMasterList() {
      let officialTimes = this.getOfficialTimeSlots;
      let timeSlotMasterList = officialTimes.timeSlotMasterList;
      let officialTimeSlots = officialTimes.officialTimeSlots;
      this.openSeatsPerTimeSlot = {};

      //Calculate how many seats are available for each time slot
      for (const timeSlot of officialTimeSlots) {
        for (const comparingTimeSlot of timeSlotMasterList) {
          if (timeSlot === comparingTimeSlot) {
            if (this.openSeatsPerTimeSlot[timeSlot]) {
              this.openSeatsPerTimeSlot[timeSlot]++;
            } else {
              this.openSeatsPerTimeSlot[timeSlot] = 1;
            }
          }
        }
      }
      this.openSeatsPerTimeSlot.calculated = true;
    },
  },
  created() {},
  computed: {
    getOfficialTimeSlots() {
      let timeRanges = [];
      for (const availability of this.availability) {
        if (!availability.isDeleted) {
          let chosenDate = moment(this.chosenDate).format("YYYY-MM-DD");
          if (chosenDate == moment(availability.date).format("YYYY-MM-DD")) {
            timeRanges.push(
              availability.startTime + " - " + availability.endTime
            );
          } else if (availability.repeating) {
            let usedAlteration = false;
            for (const alteration of availability.alterations) {
              let alterationDate = moment(alteration.date).format("YYYY-MM-DD");
              if (alterationDate == chosenDate) {
                if (alteration.isDeleted) {
                  usedAlteration = true;
                  break;
                } else {
                  timeRanges.push(
                    alteration.startTime + " - " + alteration.endTime
                  );
                  usedAlteration = true;
                  break;
                }
              }
            }
            if (!usedAlteration) {
              if (availability.repeatingSetting === "DAY") {
                timeRanges.push(
                  availability.startTime + " - " + availability.endTime
                );
              } else if (availability.repeatingSetting === "WEEK") {
                for (
                  let weekIndex = 0;
                  weekIndex < this.sessionInfo.maxAdvanceNotice;
                  weekIndex++
                ) {
                  let testDate = moment(availability.date)
                    .add(weekIndex, "weeks")
                    .format("YYYY-MM-DD");
                  if (testDate == chosenDate) {
                    timeRanges.push(
                      availability.startTime + " - " + availability.endTime
                    );
                  }
                }
              } else if (availability.repeatingSetting === "MONTH") {
                for (
                  let monthIndex = 0;
                  monthIndex < this.sessionInfo.maxAdvanceNotice;
                  monthIndex++
                ) {
                  let testDate = moment(availability.date)
                    .add(monthIndex, "months")
                    .format("YYYY-MM-DD");
                  if (testDate == chosenDate) {
                    timeRanges.push(
                      availability.startTime + " - " + availability.endTime
                    );
                  }
                }
              }
            }
          }
        }
      }
      let timeSlotMasterList = [];
      let officialTimeSlots = [];

      for (const timeRange of timeRanges) {
        let singleTimeSlots = [];
        let time1 = moment(this.chosenDate + " " + timeRange.split(" - ")[0]);
        let time2 = moment(this.chosenDate + " " + timeRange.split(" - ")[1]);
        for (let i = 0; i < 100; i++) {
          let previousTestTime = time1;
          if (i > 0) {
            previousTestTime = moment(
              this.chosenDate +
                " " +
                singleTimeSlots[singleTimeSlots.length - 1].split(" - ")[1]
            );
          }
          let testTime = moment(previousTestTime).add(
            this.sessionInfo.sessionDuration,
            "minutes"
          );

          let showTime =
            moment(previousTestTime).format("HH:mm") +
            " - " +
            moment(testTime).format("HH:mm");
          if (moment(testTime).isAfter(moment(time2))) {
            break;
          }
          if (!officialTimeSlots.includes(showTime)) {
            officialTimeSlots.push(showTime);
          }
          singleTimeSlots.push(showTime);
          timeSlotMasterList.push(showTime);
        }
      }

      officialTimeSlots = officialTimeSlots.sort(
        (a, b) =>
          a.split(" - ")[0].split(":")[0] - b.split(" - ")[0].split(":")[0]
      );
      return {
        officialTimeSlots: officialTimeSlots,
        timeSlotMasterList: timeSlotMasterList,
      };
    },

    getAvailableTimes() {
      let officialTimeSlots = this.getOfficialTimeSlots.officialTimeSlots;
      const existingSessionsThisDay = this.getExistingSessionsThisDay;
      let tempOfficialTimeSlots = [...officialTimeSlots];

      this.setTimeSlotMasterList();
      for (const timeSlot of tempOfficialTimeSlots) {
        let timeSlotCounter = 0;
        for (const session of existingSessionsThisDay) {
          if (timeSlot === session.time) {
            timeSlotCounter++;
            if (
              this.sessionInfo.maxSessionParticipants *
                this.openSeatsPerTimeSlot[timeSlot] ===
              timeSlotCounter
            ) {
              officialTimeSlots.splice(officialTimeSlots.indexOf(timeSlot), 1);
            }
          }
        }
      }

      return officialTimeSlots;
    },
    getMaxDate() {
      let today = new Date();
      return moment(today)
        .add(parseInt(this.sessionInfo.maxAdvanceNotice), "days")
        .format("YYYY-MM-DD");
    },
    getMinDate() {
      let today = new Date();
      return moment(today)
        .add(parseInt(this.sessionInfo.minAdvanceNotice), "days")
        .format("YYYY-MM-DD");
    },

    getExistingSessionsThisDay() {
      return this.sessionInfo.scheduledSessions.filter((session) => {
        return (
          moment(session.date).add(1, "days").format("YYYY-MM-DD") ===
          moment(this.chosenDate).format("YYYY-MM-DD")
        );
      });
    },
  },
  mounted() {
    if (!this.selectedTime) {
      document.getElementById("datePicker").click();
    }
    for (
      let i = 0;
      i < this.sessionInfo.minAdvanceNotice + this.sessionInfo.maxAdvanceNotice;
      i++
    ) {
      let date = moment(this.chosenDate).add(i, "days").format("YYYY-MM-DD");
      let value = this.getAllAllowedDates(date);
      if (value) {
        this.chosenDate = date;
        break;
      }
    }
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}

.grow {
  transition: all 0.5s;
}
.grow:hover {
  transform: scale(1.1);
}
</style>
