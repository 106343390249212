<script setup></script>

<template>
  <div
    class="modal fade"
    id="advertisementPopUp"
    tabindex="-1"
    aria-labelledby="advertisementPopUpLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center" id="advertisementPopUpLabel">
            Upgrade for the Best!
          </h5>

          <button
            type="button"
            class="btn-close"
            id="advertisementPopUpClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <button
            type="button"
            id="closeModal"
            class="btn-close"
            data-bs-dismiss="modal"
            style="display: none"
          ></button>
        </div>
        <div class="modal-body">
          <div class="wrapper">
            <div class="container">
              <div class="row justify-content-center mt-5">
                <div
                  :class="{
                    'col-4': true,
                    'text-muted': this.yearly,
                    'text-center': true,
                  }"
                >
                  <h4 v-if="!isMobile">Billed Monthly</h4>
                  <h5 v-else>Billed Monthly</h5>
                </div>

                <label class="switch col-4">
                  <input
                    type="checkbox"
                    @click="setPeriod()"
                    :checked="this.yearly"
                  />
                  <div class="slider round"></div>
                </label>

                <div
                  :class="{
                    'col-4': true,
                    'text-muted': !this.yearly,
                    'text-center': true,
                  }"
                >
                  <h4 v-if="!isMobile">Billed Annually</h4>
                  <h5 v-else>Billed Annually</h5>
                </div>
                <br />
                <br />
                <br />
                <h5 v-if="this.yearly" class="text-center text-muted">
                  Get up to 3 Months - For Free!
                </h5>
                <h5 v-else class="text-center text-muted">No Commitment!</h5>
              </div>
              <div class="row justify-content-center mt-5">
                <ProDetailsCard
                  v-if="this.showPro"
                  class="col-md-6 col-sm-6"
                  :yearly="this.yearly"
                  :isMobile="this.isMobile"
                  :style="{
                    border:
                      currentSubscriptionTier === 2
                        ? '3px solid #007bff'
                        : '3px solid #3',
                  }"
                  @buySubscription="buySubscription"
                ></ProDetailsCard>
                <AllStarDetailsCard
                  v-if="this.showAllStar"
                  class="col-md-6 col-sm-6"
                  :yearly="this.yearly"
                  :isMobile="this.isMobile"
                  :style="{
                    border:
                      currentSubscriptionTier === 3 ? '3px solid #007bff' : '',
                  }"
                  @buySubscription="buySubscription"
                ></AllStarDetailsCard>
                <HallOfFameDetailsCard
                  class="col-md-6 col-sm-6"
                  :yearly="this.yearly"
                  :isMobile="this.isMobile"
                  :style="{
                    border:
                      currentSubscriptionTier === 4 ? '3px solid #007bff' : '',
                  }"
                  @buySubscription="buySubscription"
                ></HallOfFameDetailsCard>
              </div>
              <div
                class="row justify-content-center mt-5"
                v-if="currentSubscriptionName !== 'New'"
              >
                <h3 class="text-center">
                  Current Plan:
                  <span class="badge actionFeature"
                    >{{ currentSubscriptionName }}
                    <br />
                  </span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProDetailsCard from "../../components/Subscriptions/ProDetailsCard.vue";
import AllStarDetailsCard from "../../components/Subscriptions/AllStarDetailsCard.vue";
import HallOfFameDetailsCard from "../../components/Subscriptions/HallOfFameDetailsCard.vue";
export default {
  components: {
    ProDetailsCard,
    AllStarDetailsCard,
    HallOfFameDetailsCard,
  },
  props: {
    currentSubscriptionName: String,
    currentSubscriptionTier: Number,
  },
  data() {
    return {
      yearly: false,
      currentStatus: "",
    };
  },
  methods: {
    setPeriod() {
      this.yearly = !this.yearly;
    },
    buySubscription(subscription) {
      document.getElementById("advertisementPopUpClose").click();
      console.log(subscription);
      this.$router.push(
        "/SubscriptionChoices/Checkout/" + subscription._id + "/" + this.yearly
      );
    },
  },
  created() {},
  computed: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    showPro() {
      if (this.currentSubscriptionTier > 1) {
        return false;
      }
      return true;
    },
    showAllStar() {
      if (this.currentSubscriptionTier > 2) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
}

.switch input {
  display: none;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #39f600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #39f600;
}

input:checked + .slider:before {
  -webkit-transform: translateX(46px);
  -ms-transform: translateX(46px);
  transform: translateX(46px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.actionFeature {
  background-color: #a39ed0;
}
</style>
