var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container",attrs:{"id":"checkIn"}},[_c('ClientLayout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"main container"},[_c('div',{staticClass:"card card-form__inner mt-5"},[_c('div',{staticClass:"card-title text-center justify-content-center"},[(this.getProgress === '0%' || this.getProgress === '25%')?_c('h1',[_vm._v(" Let's Get Started! ")]):(this.getProgress === '50%')?_c('h1',[_vm._v("You're Half Way There!")]):(this.getProgress === '75%')?_c('h1',[_vm._v("You're Almost Done!")]):(this.getProgress === '100%')?_c('h1',[_vm._v("Great Work!")]):_vm._e(),_c('div',{staticClass:"progress center text-center mt-3",staticStyle:{"height":"20px","max-width":"400px","margin-left":"auto","margin-right":"auto"}},[_c('div',{class:{
              'progress-bar': true,
              'progress-bar-striped': true,
              'progress-bar-animated': this.getProgress !== '100%',
            },style:({ width: this.getProgress, backgroundColor: '#19d412' }),attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}})])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"accordion",attrs:{"id":"accordionPanelsStayOpenExample"}},[_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"panelsStayOpen-headingOne"}},[_c('button',{class:{
                  'accordion-button': true,
                  collapsed: _vm.createdAIWebsite,
                },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#panelsStayOpen-collapseOne","aria-expanded":!_vm.createdAIWebsite,"aria-controls":"panelsStayOpen-collapseOne"}},[_vm._v(" Build Your AI Website "),_c('span',{staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.markAsComplete('aiWebsites')}}},[(_vm.tourGuideVideos.aiWebsitesComplete)?_c('i',{staticClass:"fa-solid fa-check-circle fa-lg",staticStyle:{"margin-left":"5px","color":"#19d412"}}):_vm._e(),(!_vm.tourGuideVideos.aiWebsitesComplete)?_c('button',{staticClass:"btn btn-primary m-1"},[_vm._v(" "+_vm._s(_vm.tourGuideVideos.aiWebsitesComplete ? "Completed" : "Mark as Complete")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.tourGuideVideos.aiWebsitesComplete ? "Completed" : "Mark as Complete")+" ")])])])]),_c('div',{class:{
                'accordion-collapse': true,
                collapse: true,
                show: !_vm.createdAIWebsite,
              },attrs:{"id":"panelsStayOpen-collapseOne","aria-labelledby":"panelsStayOpen-headingOne"}},[_c('div',{staticClass:"accordion-body"},[_c('strong',[_vm._v("Generating your AI Website is the first step to success.")]),_vm._v(" Creating a website for your business can significantly enhance your online presence, attract more customers, and boost your overall brand image. A well designed website serves as a virtual storefront for your customers, providing potential clients with a convenient platform to explore your services, learn about your team, and register for your events. "),_c('br'),_c('br'),_vm._m(0),_c('br'),_c('router-link',{attrs:{"to":"/Dashboard/WebsiteSetup"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Get Started!")])])],1)])]),_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"panelsStayOpen-headingTwo"}},[_c('button',{class:{
                  'accordion-button': true,
                  collapsed: !_vm.createdAIWebsite || _vm.createdCamp,
                  lock: !_vm.createdAIWebsite,
                },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#panelsStayOpen-collapseTwo","aria-expanded":_vm.createdAIWebsite && !_vm.createdCamp,"aria-controls":"panelsStayOpen-collapseTwo"}},[(!_vm.createdAIWebsite)?_c('i',{staticClass:"fa-solid fa-lock",staticStyle:{"margin-right":"5px"}}):_vm._e(),_vm._v(" Create Your First Payment Link "),(this.createdAIWebsite)?_c('span',{staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.markAsComplete('camp')}}},[(_vm.tourGuideVideos.createCampComplete)?_c('i',{staticClass:"fa-solid fa-check-circle fa-lg",staticStyle:{"margin-left":"5px","color":"#19d412"}}):_vm._e(),(!_vm.tourGuideVideos.createCampComplete)?_c('button',{staticClass:"btn btn-primary m-1"},[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createCampComplete ? "Completed" : "Mark as Complete")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createCampComplete ? "Completed" : "Mark as Complete")+" ")])]):_vm._e()])]),(_vm.createdAIWebsite)?_c('div',{class:{
                'accordion-collapse': true,
                collapse: true,
                show: _vm.createdAIWebsite && !_vm.createdCamp,
              },attrs:{"id":"panelsStayOpen-collapseTwo","aria-labelledby":"panelsStayOpen-headingTwo"}},[_c('div',{staticClass:"accordion-body"},[_c('strong',[_vm._v("Creating payment links is the lifeblood of the platform.")]),_vm._v(" Having events on Summer Athletes can significantly enhance your business and provide numerous benefits to your clients. By using our intuitive and user-friendly platform, you can easily create and manage your clinics, camp training programs, and individual coaching sessions. This will not only save you tremendous time and effort, but also ensure that your events are well-organized and professionally presented. "),_c('br'),_c('br'),_vm._m(1),_c('br'),_c('router-link',{attrs:{"to":"/Dashboard/CreateCamp/Individual"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Get Started!")])])],1)]):_vm._e()]),_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"panelsStayOpen-headingThree"}},[_c('button',{class:{
                  'accordion-button': true,
                  collapsed: !_vm.createdCamp || _vm.createdMembership,
                  lock: !_vm.createdCamp,
                },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#panelsStayOpen-collapseThree","aria-expanded":_vm.createdCamp && !_vm.createdMembership,"aria-controls":"panelsStayOpen-collapseThree"}},[(!_vm.createdCamp)?_c('i',{staticClass:"fa-solid fa-lock",staticStyle:{"margin-right":"5px"}}):_vm._e(),_vm._v(" Setup Your First Membership "),(this.createdCamp)?_c('span',{staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.markAsComplete('membership')}}},[(_vm.tourGuideVideos.createMembershipComplete)?_c('i',{staticClass:"fa-solid fa-check-circle fa-lg",staticStyle:{"margin-left":"5px","color":"#19d412"}}):_vm._e(),(!_vm.tourGuideVideos.createMembershipComplete)?_c('button',{staticClass:"btn btn-primary m-1"},[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createMembershipComplete ? "Completed" : "Mark as Complete")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createMembershipComplete ? "Completed" : "Mark as Complete")+" ")])]):_vm._e()])]),(_vm.createdCamp)?_c('div',{class:{
                'accordion-collapse': true,
                collapse: true,
                show: _vm.createdCamp && !_vm.createdMembership,
              },attrs:{"id":"panelsStayOpen-collapseThree","aria-labelledby":"panelsStayOpen-headingThree"}},[_c('div',{staticClass:"accordion-body"},[_c('strong',[_vm._v("Memberships are what make your business skyrocket.")]),_vm._v(" One of the most significant benefits of having a membership is the "),_c('i',[_vm._v("recurring revenue")]),_vm._v(" it can generate. Recurring revenue is a powerful financial tool that can help you build a stable and predictable income stream, providing you with the resources you need to grow your business and achieve your financial goals. By offering valuable membership programs, you can attract and retain loyal customers, who will continue to support your business over time. This can help you build a strong and sustainable business model, ensuring your long-term financial success. All of this can be achieved directly through the Summer Athletes! "),_c('br'),_c('br'),_vm._m(2),_c('br'),_c('router-link',{attrs:{"to":"/Dashboard/CreateMembership"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Get Started!")])])],1)]):_vm._e()]),_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"panelsStayOpen-headingFour"}},[_c('button',{class:{
                  'accordion-button': true,
                  collapsed: !_vm.createdMembership || _vm.createdScheduledSession,
                  lock: !_vm.createdMembership,
                },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#panelsStayOpen-collapseFour","aria-expanded":_vm.createdMembership && !_vm.createdScheduledSession,"aria-controls":"panelsStayOpen-collapseFour"}},[(!_vm.createdMembership)?_c('i',{staticClass:"fa-solid fa-lock",staticStyle:{"margin-right":"5px"}}):_vm._e(),_vm._v(" Get Started with Scheduled Sessions "),(this.createdMembership)?_c('span',{staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.markAsComplete('scheduledSession')}}},[(_vm.tourGuideVideos.createScheduledSessionComplete)?_c('i',{staticClass:"fa-solid fa-check-circle fa-lg",staticStyle:{"margin-left":"5px","color":"#19d412"}}):_vm._e(),(!_vm.tourGuideVideos.createScheduledSessionComplete)?_c('button',{staticClass:"btn btn-primary m-1"},[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createScheduledSessionComplete ? "Completed" : "Mark as Complete")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.tourGuideVideos.createScheduledSessionComplete ? "Completed" : "Mark as Complete")+" ")])]):_vm._e()])]),(_vm.createdMembership)?_c('div',{class:{
                'accordion-collapse': true,
                collapse: true,
                show: _vm.createdMembership && !_vm.createdScheduledSession,
              },attrs:{"id":"panelsStayOpen-collapseFour","aria-labelledby":"panelsStayOpen-headingFour"}},[_c('div',{staticClass:"accordion-body"},[_c('strong',[_vm._v("Calendar scheduling is crucial to your business performance.")]),_vm._v(" Scheduled sessions not only ensure that your clients receive consistent, high-quality training, but also help you manage your time and resources more effectively. Our calendar scheduling feature is designed to have your clients book sessions with you directly online, at a timeslot that is best for them, based on your optimal availability. With this tool, you can easily set up and manage your training sessions, allowing you to focus on what you do best - coaching your athletes. "),_c('br'),_c('br'),_vm._v(" This feature enables you to create custom schedules for each of your training programs, ensuring that your clients receive the right training at the right time. Moreover, everything is fully integrated into our platform, allowing you to easily track your clients' progress and attendance. This means that you can quickly identify any potential issues and take proactive steps to address them, ensuring that your clients receive the best possible training experience. "),_c('br'),_c('br'),_vm._m(3),_c('br'),(this.user.subscription.subscriptionTier > 2)?_c('router-link',{attrs:{"to":"/Dashboard/CalendarAvailability"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Get Started!")])]):_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.showAdPopUp()}}},[_vm._v(" Get Started! ")])],1)]):_vm._e()]),_c('div',{staticClass:"accordion-item"},[_c('h2',{staticClass:"accordion-header",attrs:{"id":"panelsStayOpen-headingFive"}},[_c('button',{class:{
                  'accordion-button': true,
                  collapsed: !_vm.createdMembership,
                  lock:
                    !_vm.createdMembership ||
                    (!_vm.createdScheduledSession &&
                      this.user.subscription.subscriptionTier > 2),
                },attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#panelsStayOpen-collapseFive","aria-expanded":_vm.createdScheduledSession ||
                  (_vm.createdMembership &&
                    this.user.subscription.subscriptionTier < 3),"aria-controls":"panelsStayOpen-collapseFive"}},[(
                    !_vm.createdMembership ||
                    (!_vm.createdScheduledSession &&
                      this.user.subscription.subscriptionTier > 2)
                  )?_c('i',{staticClass:"fa-solid fa-lock",staticStyle:{"margin-right":"5px"}}):_vm._e(),_vm._v(" Increasing Your Business & Customer Base "),(this.createdScheduledSession)?_c('span',{staticStyle:{"margin-left":"15px"},on:{"click":function($event){return _vm.markAsComplete('increaseBusiness')}}},[(_vm.tourGuideVideos.increaseBusinessComplete)?_c('i',{staticClass:"fa-solid fa-check-circle fa-lg",staticStyle:{"margin-left":"5px","color":"#19d412"}}):_vm._e(),(!_vm.tourGuideVideos.increaseBusinessComplete)?_c('button',{staticClass:"btn btn-primary m-1"},[_vm._v(" "+_vm._s(_vm.tourGuideVideos.increaseBusinessComplete ? "Completed" : "Mark as Complete")+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.tourGuideVideos.increaseBusinessComplete ? "Completed" : "Mark as Complete")+" ")])]):_vm._e()])]),(
                _vm.createdScheduledSession ||
                (_vm.createdMembership &&
                  this.user.subscription.subscriptionTier < 3)
              )?_c('div',{class:{
                'accordion-collapse': true,
                collapse: true,
                show:
                  _vm.createdScheduledSession ||
                  (_vm.createdMembership &&
                    this.user.subscription.subscriptionTier < 3),
              },attrs:{"id":"panelsStayOpen-collapseFive","aria-labelledby":"panelsStayOpen-headingFive"}},[_c('div',{staticClass:"accordion-body"},[_c('strong',[_vm._v("Increasing your business has never been easier now that you've joined the team.")]),_vm._v(" Summer Athletes is designed to help you increase your online presence and reach a wider audience. Our platform integrates seamlessly with popular social media platforms, allowing you to easily promote your coaching services and connect with potential clients. This means that you can easily expand your reach and grow your business, without having to spend a fortune on advertising. "),_c('br'),_c('br'),_vm._v(" We are the premier platform for coaches who want to grow their business, increase their customer base, and establish their brand as a reputable and reliable coach. With our tools, you can easily manage your training sessions, communicate with your clients, and expand your online presence, all while receiving the support and training you need to succeed. So what are you waiting for? "),_c('strong',[_vm._v("Start posting your links out and get started today!")]),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('router-link',{attrs:{"to":'/Coach/' + this.user._id,"target":"_blank"}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v("Get Started!")])])],1)]):_vm._e()])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embed-responsive embed-responsive-16by9 controls"},[_c('iframe',{staticClass:"embed-responsive-item controls",attrs:{"src":"https://tour-guide-media.s3.amazonaws.com/AI+Websites+Demo+HD.mp4","allowfullscreen":"","sandbox":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embed-responsive embed-responsive-16by9 controls"},[_c('iframe',{staticClass:"embed-responsive-item controls",attrs:{"src":"https://tour-guide-media.s3.amazonaws.com/Easy+Payment+Link+Demo+HD.mp4","allowfullscreen":"","sandbox":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embed-responsive embed-responsive-16by9 controls"},[_c('iframe',{staticClass:"embed-responsive-item controls",attrs:{"src":"https://tour-guide-media.s3.amazonaws.com/Create+Recurring+Payments+HD.mp4","allowfullscreen":"","sandbox":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embed-responsive embed-responsive-16by9 controls"},[_c('iframe',{staticClass:"embed-responsive-item controls",attrs:{"src":"https://tour-guide-media.s3.amazonaws.com/Scheduled+Sessions+Demo+HD.mp4","allowfullscreen":"","sandbox":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"embed-responsive embed-responsive-16by9 controls"},[_c('iframe',{staticClass:"embed-responsive-item controls",attrs:{"src":"https://tour-guide-media.s3.amazonaws.com/Increase+Sales+Demo+HD.mp4","allowfullscreen":"","sandbox":""}})])
}]

export { render, staticRenderFns }