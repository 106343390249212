<template>
  <div>
    <div class="container mt-5">
      <div class="card-form">
        <div class="card-form__inner" v-if="!this.isVerified">
          <img src="../../../public/SummerAthletes.png" class="card-img-top" />
          <div class="card-body">
            <h1 class="card-title text-center">
              <span
                >Verifying...
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div></span
              >
            </h1>
          </div>
        </div>
        <div class="card-form__inner" v-else>
          <img src="../../../public/SummerAthletes.png" class="card-img-top" />
          <div class="card-body">
            <h5 class="card-title text-center">
              Successfully verified your account!
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import swal from "sweetalert";

export default {
  data() {
    return {
      userID: "",
      isVerified: false,
    };
  },
  created() {
    this.userID = this.$route.params.userID;
    if (this.userID) {
      //post to db to verify user
      let url = process.env.VUE_APP_DB_URL + "user/verifyEmail";

      axios
        .post(url, { userID: this.userID })
        .then((response) => {
          if (response.data.success) {
            this.isVerified = true;
            swal({
              title: "Success!",
              text: "Your account has been verified!",
              icon: "success",
            });
          }
        })
        .catch(function (err) {
          console.log(err);
          swal("Error", "Email Cannot Be Verified", "error").then(function () {
            console.log(this);
          });
        });
    }
  },
};
</script>
