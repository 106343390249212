<template>
  <!--  <div v-if="isMobile()">-->
  <!--    <CoachDashboardMobile></CoachDashboardMobile>-->
  <!--  </div>-->
  <div class="wrapper container justify-content-center" id="app">
    <ClientLayout :user="user"></ClientLayout>
    <section
      class="container justify-content-center"
      v-bind:style="{ marginLeft: isMobile() ? '10px' : '' }"
    >
      <div v-if="this.tableView" class="container mt-5 justify-content-center">
        <div
          :class="{
            row: true,
            'justify-content-center': isMobile(),
            'justify-content-end': !isMobile(),
          }"
        >
          <button
            class="card-form__button col-md-2 col-sm-6 mb-3"
            @click="tableView = !tableView"
            :style="{ marginLeft: isMobile() ? '21px' : '', maxWidth: '300px' }"
          >
            <span
              ><i
                class="fa-solid fa-table-columns fa-lg"
                style="color: #363166"
              ></i>
              <label style="margin-left: 10px; cursor: pointer">
                Split View</label
              >
            </span>
          </button>
        </div>
        <div class="card">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">Type</th>
                <th scope="col">Event Name</th>
                <th scope="col">Payout</th>
                <th scope="col">Projected Pay Date</th>
                <th scope="col">Payout 2</th>
                <th scope="col">Projected Pay Date 2</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="event in getEventList" v-bind:key="event.id">
                <td>{{ event.type }}</td>
                <td>
                  {{ event.name }}
                </td>
                <td v-if="event.type === 'Membership'">
                  ${{ getMembershipMoneyPayDateOne(event.fullEvent) }} USD
                </td>
                <td v-else-if="event.type === 'Camp'">
                  ${{ getCampMoney(event.fullEvent) }} USD
                </td>
                <td v-else-if="event.type === 'League/Tournament'">
                  ${{ getTeamCampMoney(event.fullEvent) }} USD
                </td>
                <td v-if="event.type === 'Membership'">
                  {{ getMembershipPayDateFirst() }}
                </td>
                <td v-else>
                  {{ getPayDate(event.fullEvent) }}
                </td>
                <td v-if="event.type === 'Membership'">
                  ${{ getMembershipMoneyPayDateTwo(event.fullEvent) }} USD
                </td>
                <td v-else>---</td>
                <td v-if="event.type === 'Membership'">
                  {{ getMembershipPayDateSecond() }}
                </td>
                <td v-else>---</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="!this.tableView" class="container mt-5 justify-content-center">
        <div
          :class="{
            row: true,
            'justify-content-center': isMobile(),
            'justify-content-end': !isMobile(),
          }"
        >
          <button
            class="card-form__button col-md-2 col-sm-6 mb-3"
            @click="tableView = !tableView"
            :style="{ marginLeft: isMobile() ? '21px' : '', maxWidth: '300px' }"
          >
            <span
              ><i class="fa-solid fa-table fa-lg" style="color: #363166"></i>
              <label style="margin-left: 10px; cursor: pointer">
                Table View</label
              >
            </span>
          </button>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-4 col-sm-6">
            <div class="row">
              <div
                class="information-card"
                style="max-height: 700px; overflow: auto; min-height: 700px"
              >
                <h2 class="text-center">Camp Revenue</h2>
                <hr />
                <div v-for="camp in this.camps" :key="camp" class="m-3">
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{ camp.campName }}</h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        Projected Paydate: {{ getPayDate(camp) }}
                      </h6>
                      <p class="card-text">${{ getCampMoney(camp) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="row">
              <div
                class="information-card"
                style="max-height: 700px; overflow: auto; min-height: 700px"
              >
                <h2 class="text-center">League/Tournament Revenue</h2>
                <hr />
                <div
                  v-for="teamCamp in this.teamCamps"
                  :key="teamCamp"
                  class="m-3"
                >
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">{{ teamCamp.campName }}</h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        Projected Paydate: {{ getPayDate(teamCamp) }}
                      </h6>
                      <p class="card-text">${{ getTeamCampMoney(teamCamp) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="row">
              <div
                class="information-card"
                style="max-height: 700px; overflow: auto; min-height: 700px"
              >
                <h2 class="text-center">Membership Revenue</h2>
                <hr />
                <div
                  v-for="membership in this.memberships"
                  :key="membership"
                  class="m-3"
                >
                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title">
                        {{ membership.membershipName }}
                      </h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        Projected Paydate 1:
                        {{ getMembershipPayDateFirst() }}
                      </h6>
                      <p class="card-text">
                        ${{ getMembershipMoneyPayDateOne(membership) }}
                      </p>
                      <h6 class="card-subtitle mb-2 text-muted">
                        Projected Paydate 2:
                        {{ getMembershipPayDateSecond() }}
                      </h6>
                      <p class="card-text">
                        ${{ getMembershipMoneyPayDateTwo(membership) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import ClientLayout from "../components/ClientLayout.vue";
// import CoachDashboardMobile from "../Mobile/CoachDashboardMobile.vue";
import swal from "sweetalert";
import moment from "moment/moment";

export default {
  components: {
    ClientLayout,
  },
  data() {
    return {
      user: {},
      totalCash: null,
      totalCampers: null,
      camps: [],
      teamCamps: [],
      memberships: [],
      events: {},
      maxCampValue: 0,
      campPayoutTotal: 0,
      tableView: !this.isMobile(),
    };
  },
  methods: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },

    async getCamps() {
      let url =
        process.env.VUE_APP_DB_URL + "individualCamp/camps?id=" + this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.camps = response.data;
          this.camps.sort((a, b) =>
            a.campDates[a.campDates.length - 1].end <
            b.campDates[b.campDates.length - 1].end
              ? 1
              : -1
          );
          this.events.camps = this.camps;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
      url =
        process.env.VUE_APP_DB_URL + "teamCamp/teamCamps?id=" + this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.teamCamps = response.data;
          this.teamCamps.sort((a, b) =>
            a.campDates[a.campDates.length - 1].end <
            b.campDates[b.campDates.length - 1].end
              ? 1
              : -1
          );
          this.events.teamCamps = this.teamCamps;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },

    async getMemberships() {
      const url =
        process.env.VUE_APP_DB_URL +
        "membership/getCoachMemberships?id=" +
        this.user._id;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.memberships = response.data;
          this.memberships.sort((a, b) => (a.date > b.date ? 1 : -1));
          this.events.memberships = this.memberships;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    createFirstEvent() {
      this.$router.push("/Dashboard/CreateEvent");
    },
    getCampMoney(camp) {
      let total = 0;
      let addOnCost = 0;
      for (let camper = 0; camper < camp.campers.length; camper++) {
        addOnCost = 0;
        for (
          let addOn = 0;
          addOn < camp.campers[camper].customAddOnValues.length;
          addOn++
        ) {
          if (
            camp.campers[camper].customAddOnValues[addOn].isPurchased == true
          ) {
            addOnCost =
              addOnCost +
              camp.campers[camper].customAddOnValues[addOn].addOnPrice;
          }
        }
        if (camp.campers[camper].usedDiscountCode) {
          total = total + addOnCost + camp.discountPrice;
        } else {
          total = total + addOnCost + camp.campPrice;
        }
      }

      return total.toFixed(2);
    },
    getTeamCampMoney(teamCamp) {
      let campBaseCost = 0;
      //cycle campers
      let addOnCost = 0;
      if (teamCamp.collectFrom == "camper") {
        campBaseCost = this.getTeamCampers(teamCamp) * teamCamp.campPrice;
        for (let team in teamCamp.teams) {
          for (
            let camper = 0;
            camper < teamCamp.teams[team].campers.length;
            camper++
          ) {
            for (
              let addOn = 0;
              addOn <
              teamCamp.teams[team].campers[camper].customAddOnValues.length;
              addOn++
            ) {
              if (
                teamCamp.teams[team].campers[camper].customAddOnValues[addOn]
                  .isPurchased
              ) {
                addOnCost =
                  addOnCost +
                  teamCamp.teams[team].campers[camper].customAddOnValues[addOn]
                    .addOnPrice;
              }
            }
          }
        }
      } else {
        campBaseCost = teamCamp.teams.length * teamCamp.campPrice;
        for (let team in teamCamp.teams) {
          for (
            let addOn = 0;
            addOn < teamCamp.teams[team].customAddOnValues.length;
            addOn++
          ) {
            if (teamCamp.teams[team].customAddOnValues[addOn].isPurchased) {
              addOnCost =
                addOnCost +
                teamCamp.teams[team].customAddOnValues[addOn].addOnPrice;
            }
          }
        }
      }
      return (campBaseCost + addOnCost).toFixed(2);
    },
    getTeamCampers(teamCamp) {
      let numCampers = 0;
      for (let team in teamCamp.teams) {
        numCampers = teamCamp.teams[team].campers.length + numCampers;
      }
      return numCampers;
    },
    getPayDate(camp) {
      let endCampDate = moment(camp.campDates[camp.campDates.length - 1].end);

      return endCampDate.add(4, "days").format("YYYY-MM-DD");
    },

    getMembershipPayDateFirst() {
      let today = moment(Date.now());
      if (moment(Date.now()).date() < 19) {
        return today.date(19).format("YYYY-MM-DD");
      } else {
        return today.date(31).add(4, "days").format("YYYY-MM-DD");
      }
    },
    getMembershipPayDateSecond() {
      let today = moment(Date.now());
      if (moment(Date.now()).date() < 19) {
        return today.date(31).add(4, "days").format("YYYY-MM-DD");
      } else {
        return today.date(19).add(1, "months").format("YYYY-MM-DD");
      }
    },
    getMembershipMoneyPayDateOne(membership) {
      let totalAmountCharged = 0;
      for (const member of membership.members) {
        if (member.isActive) {
          if (member.paymentPeriod === "weeklyPrice") {
            console.log("Week");

            const weeklyPrice = membership.membershipPayPeriod.weeklyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(this.getMembershipPayDateSecond())
              .subtract(4, "days")
              .subtract(1, "months")
              .format("YYYY-MM-DD");

            const endDateRange = moment(this.getMembershipPayDateFirst())
              .subtract(4, "days")
              .format("YYYY-MM-DD");

            console.log(startDateRange, endDateRange);
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              let addAmount = 7 * index;
              currentChargeDate = memberStartDate
                .clone()
                .add(addAmount, "days");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += weeklyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "monthlyPrice") {
            const monthlyPrice = membership.membershipPayPeriod.monthlyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            // const startDateRange = moment().date(1);
            // const endDateRange = moment().date(15);
            const startDateRange = moment(this.getMembershipPayDateSecond())
              .subtract(4, "days")
              .subtract(1, "months")
              .format("YYYY-MM-DD");

            const endDateRange = moment(this.getMembershipPayDateFirst())
              .subtract(4, "days")
              .format("YYYY-MM-DD");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += monthlyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "semiAnnualPrice") {
            console.log("Semi");

            const semiAnnualPrice =
              membership.membershipPayPeriod.semiAnnualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(this.getMembershipPayDateSecond())
              .subtract(4, "days")
              .subtract(1, "months")
              .format("YYYY-MM-DD");

            const endDateRange = moment(this.getMembershipPayDateFirst())
              .subtract(4, "days")
              .format("YYYY-MM-DD");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                if (index % 6 === 0) {
                  totalAmountCharged += semiAnnualPrice;
                }
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "annualPrice") {
            console.log("Year");

            const annualPrice = membership.membershipPayPeriod.annualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(this.getMembershipPayDateSecond())
              .subtract(4, "days")
              .subtract(1, "months")
              .format("YYYY-MM-DD");

            const endDateRange = moment(this.getMembershipPayDateFirst())
              .subtract(4, "days")
              .format("YYYY-MM-DD");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "years");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += annualPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }
        }
      }
      return totalAmountCharged.toFixed(2);
    },

    getMembershipMoneyPayDateTwo(membership) {
      let totalAmountCharged = 0;
      for (const member of membership.members) {
        if (member.isActive) {
          if (member.paymentPeriod === "weeklyPrice") {
            const weeklyPrice = membership.membershipPayPeriod.weeklyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              this.getMembershipPayDateFirst()
            ).subtract(4, "days");
            const endDateRange = moment(
              this.getMembershipPayDateSecond()
            ).subtract(4, "days");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              let addAmount = 7 * index;
              currentChargeDate = memberStartDate
                .clone()
                .add(addAmount, "days");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += weeklyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "monthlyPrice") {
            const monthlyPrice = membership.membershipPayPeriod.monthlyPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              this.getMembershipPayDateFirst()
            ).subtract(4, "days");
            const endDateRange = moment(
              this.getMembershipPayDateSecond()
            ).subtract(4, "days");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += monthlyPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "semiAnnualPrice") {
            const semiAnnualPrice =
              membership.membershipPayPeriod.semiAnnualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              this.getMembershipPayDateFirst()
            ).subtract(4, "days");
            const endDateRange = moment(
              this.getMembershipPayDateSecond()
            ).subtract(4, "days");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "months");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                if (index % 6 === 0) {
                  totalAmountCharged += semiAnnualPrice;
                }
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }

          if (member.paymentPeriod === "annualPrice") {
            const annualPrice = membership.membershipPayPeriod.annualPrice;
            const memberStartDate = moment(
              new Date(member.startDate),
              "YYYY-MM-DD"
            );
            const startDateRange = moment(
              this.getMembershipPayDateFirst()
            ).subtract(4, "days");
            const endDateRange = moment(
              this.getMembershipPayDateSecond()
            ).subtract(4, "days");
            let currentChargeDate = startDateRange;
            for (let index = 0; index < 1000; index++) {
              currentChargeDate = memberStartDate.clone().add(index, "years");
              if (
                currentChargeDate.isSameOrAfter(startDateRange) &&
                currentChargeDate.isSameOrBefore(endDateRange)
              ) {
                totalAmountCharged += annualPrice;
              } else if (currentChargeDate.isAfter(endDateRange)) {
                break;
              }
            }
          }
        }
      }
      return totalAmountCharged.toFixed(2);
    },
  },

  created() {
    this.getUserDetails();
    this.getCamps();
    this.getMemberships();
  },

  computed: {
    getEventList() {
      let today = moment(Date.now());
      // if (moment(Date.now()).date() < 19) {
      //     return today.date(19).format("YYYY-MM-DD");
      // } else {
      //     return today.date(31).add(4, "days").format("YYYY-MM-DD");
      // }
      let temp = [];
      let temp1 = [];
      temp = this.camps.map(function (x) {
        let tempObj = {};
        tempObj.id = x._id;
        tempObj.logo = x.campLogo;
        tempObj.date = x.campDates[0].start;
        tempObj.type = "Camp";
        tempObj.name = x.campName;
        tempObj.fullEvent = x;
        return tempObj;
      });
      temp1 = this.teamCamps.map(function (x) {
        let tempObj = {};
        tempObj.id = x._id;
        tempObj.logo = x.campLogo;
        tempObj.date = x.campDates[0].start;
        tempObj.type = "League/Tournament";
        tempObj.name = x.campName;
        tempObj.fullEvent = x;
        return tempObj;
      });
      temp = temp.concat(temp1);
      temp1 = this.memberships.map(function (x) {
        let tempObj = {};
        tempObj.id = x._id;
        tempObj.logo = x.membershipLogo;
        tempObj.date =
          moment(Date.now()).date() < 19
            ? today.date(19).format("YYYY-MM-DD")
            : today.date(31).add(4, "days").format("YYYY-MM-DD");
        tempObj.type = "Membership";
        tempObj.name = x.membershipName;
        tempObj.fullEvent = x;
        return tempObj;
      });
      temp = temp.concat(temp1);
      temp.sort((a, b) => (a.date < b.date ? 1 : -1));
      return temp;
    },
    getWebsiteLink() {
      return "/coach/" + this.user._id;
    },
    getTotalCampMoney() {
      let total = 0;
      let campBaseCost = 0;
      let addOnCost = 0;
      //cycle individual camps
      for (let camp = 0; camp < this.camps.length; camp++) {
        campBaseCost = 0;

        //cycle campers
        for (
          let camper = 0;
          camper < this.camps[camp].campers.length;
          camper++
        ) {
          addOnCost = 0;
          for (
            let addOn = 0;
            addOn < this.camps[camp].campers[camper].customAddOnValues.length;
            addOn++
          ) {
            if (
              this.camps[camp].campers[camper].customAddOnValues[addOn]
                .isPurchased == true
            ) {
              addOnCost =
                addOnCost +
                this.camps[camp].campers[camper].customAddOnValues[addOn]
                  .addOnPrice;
            }
          }
          if (this.camps[camp].campers[camper].usedDiscountCode) {
            campBaseCost =
              campBaseCost + addOnCost + this.camps[camp].discountPrice;
          } else {
            campBaseCost =
              campBaseCost + addOnCost + this.camps[camp].campPrice;
          }
        }
        total = total + campBaseCost;
      }
      return total;
    },
  },
};
</script>
<style scoped>
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .content {
  padding-top: 102px;
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.order {
  animation: bounce 0.7s infinite alternate;
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
</style>
