<template>
  <div
    class="col"
    style="min-width: 350px; max-width: 350px"
    @click="goToMetricsPage()"
  >
    <div class="ag-format-container" style="min-width: 250px; max-width: 250px">
      <div class="ag-courses_box" style="min-width: 250px; max-width: 250px">
        <div class="ag-courses_item" style="min-width: 250px; max-width: 250px">
          <a
            href="#"
            class="ag-courses-item_link"
            style="text-decoration: none"
            @click="goToMetricPage()"
          >
            <div
              class="ag-courses-item_bg"
              :style="{
                backgroundColor: this.secondaryColor,
                textDecoration: 'none',
              }"
            ></div>

            <div
              class="ag-courses-item_title"
              :style="{ color: this.primaryColor, textDecoration: 'none' }"
            >
              {{ value }}
            </div>
            <div class="ag-courses-item_date-box">
              <span
                class="ag-courses-item_date"
                :style="{ color: this.primaryColor, textDecoration: 'none' }"
              >
                {{ title.toUpperCase() }}
              </span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    primaryColor: String,
    secondaryColor: String,
    title: String,
    value: String,
  },

  methods: {
    goToMetricsPage() {
      console.log("Hit on card");
      this.$emit("goToMetricsPage");
    },
  },
};
</script>

<style>
.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #121212;

  overflow: hidden;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #f9b234;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
</style>
