<script setup></script>

<template>
  <div>
    <nav
      v-if="!isMobile"
      class="navbar navbar-expand-lg fixed-top"
      :style="getNavStyle"
    >
      <h4 style="color: white; margin-left: 15px; opacity: 1">
        <ul class="navbar-nav ms-auto" style="margin-right: 25px; opacity: 1">
          <li class="m-3" @click="goToHome()">
            <a :style="{ '--fill-color': this.primaryColor }" href="#">{{
              getRecruitName
            }}</a>
          </li>
        </ul>
      </h4>
      <ul class="navbar-nav ms-auto" style="margin-right: 25px; opacity: 1">
        <li class="m-3" @click="goToMetrics()">
          <a :style="{ '--fill-color': this.secondaryColor }" href="#"
            >my metrics</a
          >
        </li>
        <li class="m-3" @click="goToHighlights()">
          <a :style="{ '--fill-color': this.secondaryColor }" href="#"
            >highlights</a
          >
        </li>
      </ul>
    </nav>
    <div v-else>
      <MobileNavBar
        :primaryColor="primaryColor"
        :secondaryColor="secondaryColor"
        @goToHome="goToHome()"
        @goToMetrics="goToMetrics()"
        @goToHighlights="goToHighlights()"
      ></MobileNavBar>
    </div>
  </div>
</template>

<script>
import MobileNavBar from "./MobileNavBar.vue";
export default {
  components: { MobileNavBar },
  props: {
    primaryColor: String,
    secondaryColor: String,
    recruitProfile: {
      type: Object,
      required: true,
    },
    isMobile: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    goToHome() {
      window.scrollTo(0, 0);

      this.$emit("goToHome");
    },
    goToMetrics() {
      window.scrollTo(0, 0);

      this.$emit("goToMetrics");
    },
    goToHighlights() {
      window.scrollTo(0, 0);

      this.$emit("goToHighlights");
    },
  },
  computed: {
    getNavStyle() {
      return `
      background: linear-gradient(0.75turn, ${this.primaryColor}, ${this.secondaryColor});
      max-height: 100px;
      opacity: .7
      `;
    },

    getRecruitName() {
      return this.recruitProfile.firstName + " " + this.recruitProfile.lastName;
    },
  },
  mounted() {},
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

.gradient {
}

ul {
  display: flex;
  align-items: flex-start;
  list-style-type: none;

  li {
    padding: 6px 0;

    a {
      position: relative;
      display: block;
      padding: 4px 0;
      font: 700 3rem Raleway, sans-serif;
      text-decoration: none;
      text-transform: uppercase;
      -webkit-text-stroke: 2px var(--fill-color);
      background: linear-gradient(var(--fill-color) 0 100%) left / 0 no-repeat;
      color: transparent;
      background-clip: text;
      transition: 0.5s linear;

      &:hover {
        background-size: 100%;
      }
    }
  }
}
.cls-logo2 {
  fill: #f85051;
}
</style>
