<template>
  <div class="wrapper" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img src="../../../public/SummerAthletes.png" class="card-img-top" />
        <div class="card-form">
          <div class="card-input">
            <label for="email" class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              placeholder="Email"
              v-model="member.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Reset Password Code</label>
            <input
              type="resetToken"
              id="resetToken"
              class="card-input__input"
              placeholder="Reset Password Code"
              v-model="member.resetPasswordToken"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">New Password</label>
            <input
              type="password"
              id="newPassword"
              class="card-input__input"
              placeholder="New Password"
              v-model="member.newPassword"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Confirm Password</label>
            <input
              type="password"
              id="passwordConfirm"
              class="card-input__input"
              placeholder="Confirm Password"
              v-model="member.passwordConfirm"
            />
          </div>
        </div>

        <button
          class="card-form__button gradient-button"
          @click="this.resetPassword"
        >
          Reset Password
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      member: {
        email: "",
        resetPasswordToken: "",
        newPassword: "",
        passwordConfirm: "",
      },
    };
  },
  methods: {
    async resetPassword() {
      let url = process.env.VUE_APP_DB_URL + "membership/memberResetPassword";
      if (this.validateInfo()) {
        axios
          .post(url, this.member)
          .then((response) => {
            let success = response.data.success;
            if (success) {
              swal("Success", "Password Updated Successfully", "success");
              this.$router.push("/");
            }
          })
          .catch(function (err) {
            console.log(err.response.data.errorMessage.message);
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },
    validateInfo() {
      if (this.member.email == "") {
        swal("Error", "Please enter email", "error");
        return false;
      } else if (this.member.newPassword == "") {
        swal("Error", "Please enter password", "error");
        return false;
      }
      if (this.member.newPassword !== this.member.passwordConfirm) {
        swal(
          "Error",
          "Passwords do not match. Please check your spelling and capitalization and try again.",
          "error"
        );
        return false;
      }
      return true;
    },
  },
};
</script>
