<template>
  <div>
    <CustomTemplateOneEdit
      v-if="this.website.websiteTemplateType === 'CustomTemplateOne'"
      :website="this.website"
      :user="this.user"
    ></CustomTemplateOneEdit>
  </div>
</template>
<script>
import CustomTemplateOneEdit from "./CustomTemplateOneEdit.vue";
export default {
  components: {
    CustomTemplateOneEdit,
  },
  props: {
    website: Object,
    user: Object,
  },
};
</script>
