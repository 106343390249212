var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card grow m-3 card-form__inner",staticStyle:{"max-width":"700px","min-height":"203px"}},[(_vm.runLoading)?_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-md-6 col-sm-6",staticStyle:{"margin-top":"10px"}},[_c('h2',{style:({ textAlign: _vm.isMobile ? 'center' : '' })},[(_vm.loadPartOne)?_c('b',[_vm._v("Mapping AI Routes...")]):(_vm.loadPartTwo)?_c('b',[_vm._v("Implementing AI Security...")]):(_vm.loadPartThree)?_c('b',[_vm._v("Running AI Diagnostics...")]):(_vm.loadPartFour)?_c('b',[_vm._v("Finishing AI Algorithms...")]):_vm._e()]),(_vm.loadPartOne)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated progress-bar-background",staticStyle:{"width":"25%"},attrs:{"role":"progressbar","aria-valuenow":"25","aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e(),(_vm.loadPartTwo)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated progress-bar-background",staticStyle:{"width":"50%"},attrs:{"role":"progressbar","aria-valuenow":"60","aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e(),(_vm.loadPartThree)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated progress-bar-background",staticStyle:{"width":"75%"},attrs:{"role":"progressbar","aria-valuenow":"90","aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e(),(_vm.loadPartFour)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-striped progress-bar-animated progress-bar-background",staticStyle:{"width":"90%"},attrs:{"role":"progressbar","aria-valuenow":"90","aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e()]),_c('div',{staticClass:"col-md-6 col-sm-6"},[_c('div',{style:({
          textAlign: _vm.isMobile ? 'right' : '',
          marginTop: '10px',
          marginRight: '10px',
        })},[_vm._m(0)])])]):_c('div',{staticClass:"card-body row"},[_c('div',{staticClass:"col-md-10 col-sm-6",staticStyle:{"margin-top":"10px"}},[_c('h2',{staticClass:"text-start"},[_c('b',[_c('a',{attrs:{"href":this.url,"target":"_blank"}},[_vm._v(_vm._s(this.title)+" ")])])]),(this.fullyLoaded)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar progress-bar-background",staticStyle:{"width":"100%"},attrs:{"role":"progressbar","aria-valuenow":"90","aria-valuemin":"0","aria-valuemax":"100"}})]):_vm._e()]),_c('div',{staticClass:"col-md-2 col-sm-6"},[(this.title !== 'Free Landing page')?_c('div',{style:({
          textAlign: _vm.isMobile ? 'right' : '',
          marginTop: '10px',
          marginRight: '10px',
        })},[_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#websiteSettings","target":"_blank"},on:{"click":function($event){return _vm.openWebsiteSettingsModal()}}},[_c('i',{staticClass:"fa-solid fa-gear fa-2xl"})])]):_vm._e()]),_c('br'),_vm._m(1),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border text-primary",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Website Active")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fa-solid fa-circle-check",staticStyle:{"color":"#19d412"}}),_vm._v(" Monitoring Active")])
}]

export { render, staticRenderFns }