<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="this.campLogo" class="card-img-top rounded-corners" />

        <h3 class="text-center" style="margin-top: 20px">Registrant Info</h3>
        <div class="row">
          <div class="card-input col">
            <label for="cardName" class="card-input__label">First Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="camperInfo.firstName"
              data-ref="cardName"
              autocomplete="given-name"
              required
            />
          </div>

          <div class="card-input col">
            <label for="cardName" class="card-input__label">Last Name</label>
            <input
              type="text"
              id="cardName"
              class="card-input__input"
              v-model="camperInfo.lastName"
              data-ref="cardName"
              autocomplete="family-name"
            />
          </div>
        </div>
        <!--Start of Custom Forms-->
        <li
          style="list-style-type: none"
          v-for="index in customFields.length"
          :key="index"
        >
          <CustomField
            :field="customFields[index - 1]"
            :prePopulatedFieldValue="fieldValueInputs[index - 1].fieldValue"
            :prePopulatedFieldType="fieldValueInputs[index - 1].fieldType"
            @changed="handleChange(index, $event, false)"
          ></CustomField>
        </li>

        <!--End of Custom Forms-->
        <div v-if="this.tshirtBool">
          <label>T-shirt size</label>
          <select
            class="card-input__input -select"
            style="margin-bottom: 20px"
            v-model="camperInfo.tShirtSize"
            required
          >
            <option disabled selected value="">Size</option>
            <option value="YS" class="option">Youth Small</option>
            <option value="YM" class="option">Youth Medium</option>
            <option value="YL" class="option">Youth Large</option>
            <option value="AS" class="option">Adult Small</option>
            <option value="AM" class="option">Adult Medium</option>
            <option value="AL" class="option">Adult Large</option>
            <option value="AXL" class="option">Adult XLarge</option>
            <option value="AXXL" class="option">Adult XXLarge</option>
          </select>
        </div>

        <div v-if="this.getAddOnLength != 0" class="list-group-item">
          <hr />

          <b>Optional Add Ons</b>
          <ul>
            <li
              v-for="index in this.getAddOnLength"
              :key="index"
              class="list-group-item"
            >
              <CustomAddOnForm :field="campAddOns[index - 1]"></CustomAddOnForm>
            </li>
            <li class="list-group-item" v-if="!hideRecruitAds">
              <div class="row d-flex">
                <div class="col-md-6">
                  <input
                    class="form-check-input me-2"
                    type="checkbox"
                    value=""
                    v-model="recruitPurchaseTemp"
                    @click="
                      recruitPurchaseOptionClickedChanged(
                        recruitPurchaseTemp,
                        false
                      )
                    "
                  />Athlete Profile
                </div>
                <div class="col-md-6">
                  ${{ recruitSubscriptionPrice }}.00 / month
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div v-else-if="!hideRecruitAds">
          <hr />

          <b>Optional Add Ons</b>
          <ul>
            <li class="list-group-item">
              <div class="row d-flex">
                <div class="col-md-6">
                  <input
                    class="form-check-input me-2"
                    type="checkbox"
                    value=""
                    v-model="recruitPurchaseTemp"
                    @click="
                      recruitPurchaseOptionClickedChanged(
                        recruitPurchaseTemp,
                        false
                      )
                    "
                  />Athlete Profile
                </div>
                <div class="col-md-6">
                  ${{ recruitSubscriptionPrice }}.00 / month
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div
          v-if="
            this.addingAdditionalCampers || this.additionalCampers.length > 0
          "
        >
          <li
            class="list-group-item m-3 v-step-5"
            v-for="(camper, camperIndex) in this.additionalCampers"
            :key="'campDate' + camperIndex"
          >
            <div
              class="row"
              style="
                border: 3px solid #45487a;
                border-radius: 15px;
                max-width: 558px;
              "
            >
              <h3 class="text-center" style="margin-top: 20px">
                Registrant {{ camperIndex + 2 }} Info
              </h3>
              <div class="card-input col">
                <label for="cardName" class="card-input__label"
                  >First Name</label
                >
                <input
                  type="text"
                  id="cardName"
                  class="card-input__input"
                  v-model="camper.firstName"
                  data-ref="cardName"
                  autocomplete="given-name"
                  required
                />
              </div>

              <div class="card-input col">
                <label for="cardName" class="card-input__label"
                  >Last Name</label
                >
                <input
                  type="text"
                  id="cardName"
                  class="card-input__input"
                  v-model="camper.lastName"
                  data-ref="cardName"
                  autocomplete="family-name"
                />
              </div>
              <!--Start of Custom Forms-->
              <li
                style="list-style-type: none"
                v-for="index in camper.customFields.length"
                :key="index + camper.id"
              >
                <CustomField
                  :field="camper.customFields[index - 1]"
                  :prePopulatedFieldValue="
                    camper.fieldValueInputs[index - 1].fieldValue
                  "
                  :prePopulatedFieldType="
                    camper.fieldValueInputs[index - 1].fieldType
                  "
                  :camperId="camper.id"
                  @changed="handleChange(index, $event, true, camperIndex)"
                ></CustomField>
              </li>

              <!--End of Custom Forms-->
              <div v-if="camper.tshirtBool">
                <label>T-shirt size</label>
                <select
                  class="card-input__input -select"
                  style="margin-bottom: 20px"
                  v-model="camper.tShirtSize"
                  required
                >
                  <option disabled selected value="">Size</option>
                  <option value="YS" class="option">Youth Small</option>
                  <option value="YM" class="option">Youth Medium</option>
                  <option value="YL" class="option">Youth Large</option>
                  <option value="AS" class="option">Adult Small</option>
                  <option value="AM" class="option">Adult Medium</option>
                  <option value="AL" class="option">Adult Large</option>
                  <option value="AXL" class="option">Adult XLarge</option>
                  <option value="AXXL" class="option">Adult XXLarge</option>
                </select>
              </div>

              <i
                class="fa-solid fa-trash removeDay"
                @click="removeAdditionalCamper(camperIndex)"
              ></i>
              <div
                v-if="getAddOnLength != 0"
                class="list-group-item"
                style="margin-left: 12px"
              >
                <hr />

                <b>Optional Add Ons</b>
                <ul>
                  <li
                    v-for="index in getAddOnLength"
                    :key="index + camper.id"
                    class="list-group-item"
                  >
                    <CustomAddOnForm
                      :field="camper.campAddOns[index - 1]"
                    ></CustomAddOnForm>
                  </li>
                </ul>
              </div>
              <div v-if="!hideRecruitAds">
                <hr />

                <b>Optional Add Ons</b>
                <ul>
                  <li class="list-group-item">
                    <div class="row d-flex">
                      <div class="col-md-6">
                        <input
                          class="form-check-input me-2"
                          type="checkbox"
                          value=""
                          v-model="camper.recruitPurchase"
                          @click="
                            recruitPurchaseOptionClickedChanged(
                              camper.recruitPurchase,
                              true,
                              camperIndex
                            )
                          "
                        />Athlete Profile
                      </div>
                      <div class="col-md-6">
                        ${{ recruitSubscriptionPrice }}.00 / month
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </div>
        <div v-if="checkAddRegistrantButton">
          <center>
            <button
              class="btn btn-block mb-4 rounded-pill btn-lg"
              @click="addAdditionalCamper"
              :style="{
                marginTop: '10px',
                backgroundColor: secondaryColor,
                color: 'white',
              }"
            >
              Add Registrant - ${{ getFormattedAdditionalCamperPrice }}
            </button>
          </center>
        </div>

        <h3 v-show="!emergencyContactInfo.emergencyBool">
          Emergency Contact Information
        </h3>
        <div
          v-show="!emergencyContactInfo.emergencyBool"
          style="margin-top: 15px"
        >
          <div class="row">
            <div class="card-input col">
              <label class="card-input__label">First Name</label>
              <input
                type="text"
                id="firstName"
                class="card-input__input"
                v-model="emergencyContactInfo.firstName"
              />
            </div>
            <div class="card-input col">
              <label class="card-input__label">Last Name</label>
              <input
                type="text"
                id="name"
                class="card-input__input"
                v-model="emergencyContactInfo.lastName"
              />
            </div>
          </div>
          <div class="card-input">
            <label class="card-input__label">Phone Number</label>
            <input
              type="text"
              id="phoneNumber"
              class="card-input__input"
              v-model="emergencyContactInfo.phoneNumber"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Email</label>
            <input
              type="email"
              id="email"
              class="card-input__input"
              v-model="emergencyContactInfo.email"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">Address</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.address"
            />
          </div>
          <div class="card-input">
            <label class="card-input__label">City</label>
            <input
              type="text"
              id="address"
              class="card-input__input"
              v-model="emergencyContactInfo.city"
            />
          </div>

          <div class="card-form__row">
            <div class="card-form__col">
              <div class="card-form__group">
                <label for="cardMonth" class="card-input__label">State</label>
                <select
                  class="card-input__input -select"
                  id="cardMonth"
                  v-model="emergencyContactInfo.state"
                >
                  <option value="" disabled selected>State</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AZ">Arizona</option>
                  <option value="AR">Arkansas</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DE">Delaware</option>
                  <option value="DC">District Of Columbia</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
            <div class="card-form__col">
              <div class="card-input">
                <label for="cardCvv">Zip Code</label>
                <input
                  type="text"
                  class="card-input__input"
                  v-model="emergencyContactInfo.zipCode"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="this.initial + addOnTotal > 0">
          <hr />

          <h5 class="text-center">Pay With</h5>
          <div class="row mt-3">
            <div class="col-md-6 col-sm-6">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="paypal"
                  v-model="paymentType"
                />
                <label class="form-check-label" for="inlineRadio1"
                  ><a title="PayPal"
                    ><img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/2560px-PayPal.svg.png"
                      alt="PayPal"
                      style="max-height: 30px; max-width: 100px"
                    /> </a
                ></label>
              </div>
              <br />
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="paylater"
                  v-model="paymentType"
                />
                <label class="form-check-label" for="inlineRadio2"
                  ><a title="PayLater"
                    ><img
                      src="https://www.ppro.com/wp-content/uploads/2021/06/paypal-credit.png"
                      alt="PayLater"
                      style="max-height: 30px; max-width: 100px"
                    /> </a
                ></label>
              </div>
              <br />
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio3"
                  value="venmo"
                  v-model="paymentType"
                />
                <label class="form-check-label" for="inlineRadio3"
                  ><a title="Venmo"
                    ><img
                      src="https://images.ctfassets.net/gkyt4bl1j2fs/273APNSE63eFeQGVaNokaJ/da5948b50660aa0a99b02ea2e6990e2f/Blue.png?w=424&h=84&q=50&fm=webp&bg=transparent"
                      alt="venmo"
                      style="max-height: 30px; max-width: 100px"
                    /> </a
                ></label>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 mt-5">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio4"
                  value="creditcard"
                  v-model="paymentType"
                />
                <label class="form-check-label" for="inlineRadio4"
                  ><h5>
                    <i
                      class="fa-solid fa-credit-card fa-lg"
                      style="color: #0d0d0d"
                    ></i>
                    Credit/Debit
                  </h5></label
                >
              </div>
              <br />
              <br />
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio5"
                  value="ACHPayment"
                  v-model="paymentType"
                />
                <label class="form-check-label" for="inlineRadio5"
                  ><h5>
                    <i class="fa-solid fa-building-columns"></i>
                    E-Check
                  </h5></label
                >
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <input
          v-if="!this.requireEmergency && this.initial + addOnTotal > 0"
          class="form-check-input me-2"
          type="checkbox"
          value=""
          id="form6Example8"
          v-model="emergencyContactInfo.emergencyBool"
        />
        <label
          v-if="!this.requireEmergency && this.initial + addOnTotal > 0"
          class="form-check-label"
          for="form6Example8"
        >
          Emergency Contact is Billing Info
        </label>
        <br />
        <div class="row" v-if="!isMobile">
          <div class="col-md-3 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              :style="{
                maxHeight: isMobile ? '60%' : '',
                backgroundColor: secondaryColor,
                color: 'white',
              }"
              @click="back"
            >
              <i class="fa-solid fa-arrow-left"></i>
              Back
            </button>
          </div>

          <div class="col-md-9 col-sm-6">
            <button
              class="card-form__button rounded-pill"
              :style="{ backgroundColor: primaryColor, color: 'white' }"
              @click="submitCamperInfo"
            >
              {{ this.buttonName }}
            </button>
          </div>
        </div>
        <div v-else>
          <button
            class="card-form__button rounded-pill"
            :style="{ backgroundColor: primaryColor, color: 'white' }"
            @click="submitCamperInfo"
          >
            {{ this.buttonName }}
          </button>
          <br />
          <br />
          <br />
          <button
            :class="{
              'card-form__button': true,
              'w-50': isMobile,

              'rounded-pill': true,
            }"
            :style="{
              backgroundColor: secondaryColor,
              color: 'white',
            }"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
// import axios from "axios";
import CustomField from "../../components/CustomField.vue";
import CustomAddOnForm from "../CustomAddOnForm.vue";
import numeral from "numeral";

export default {
  components: {
    CustomAddOnForm,
    CustomField,
  },
  props: {
    tshirtBool: Boolean,
    customFields: Array,
    campLogo: String,
    campAddOns: Array,
    requireEmergency: Boolean,
    buttonName: String,
    camperInfo: Object,
    emergencyContactInfo: Object,
    paymentType: String,
    fieldValueInputs: Array,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
    initial: Number,
    addOnTotal: Number,
    multipleCamperPrices: Array,
    additionalCampers: Array,
    timeSlotsLeft: Number,
    sessionSupported: Boolean,
    recruitSubscriptionPrice: Number,
    recruitPurchase: Boolean,
    hideRecruitAds: Boolean,
  },
  data() {
    return {
      requiredFilesShown: false,
      addingAdditionalCampers: false,
      recruitPurchaseTemp: this.recruitPurchase,
    };
  },
  methods: {
    handleChange(index, field, applyToAdditionalCamper, camperIndex = null) {
      console.log(index);
      console.log(field);
      console.log(camperIndex);
      if (applyToAdditionalCamper) {
        console.log("additional camper");
        this.additionalCampers[camperIndex].fieldValueInputs[index - 1] =
          this.customFields.map((item) => ({ ...item }))[index - 1];
        this.additionalCampers[camperIndex].fieldValueInputs[
          index - 1
        ].fieldValue = field.value;
        if (field.isFile)
          this.additionalCampers[camperIndex].fieldValueInputs[
            index - 1
          ].S3Key = field.S3Key;
        delete this.additionalCampers[camperIndex].fieldValueInputs[index - 1]
          ._id;
      } else {
        console.log("Other Camper");
        this.fieldValueInputs[index - 1] = this.customFields[index - 1];
        this.fieldValueInputs[index - 1].fieldValue = field.value;
        if (field.isFile) this.fieldValueInputs[index - 1].S3Key = field.S3Key;
        delete this.fieldValueInputs[index - 1]._id;
      }
    },
    recruitPurchaseOptionClickedChanged(
      recruitPurchase,
      additionalCamper,
      index
    ) {
      if (recruitPurchase) {
        swal({
          title: "Are you sure?",
          text: "You will be missing out on the Summer Athletes - Recruitment system. This allows your child to create an athlete recruitment profile, be in a system of thousands of coaches, see all of your event updates and messages sent from your coach, and receive additional discounts, all in one service.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willCancel) => {
          // Delete account
          if (willCancel) {
            if (additionalCamper) {
              this.additionalCampers[index].recruitPurchase = false;
            } else {
              this.recruitPurchaseTemp = false;
            }
          } else {
            if (additionalCamper) {
              this.additionalCampers[index].recruitPurchase = true;
            } else {
              this.recruitPurchaseTemp = true;
            }
          }
        });
      } else {
        swal({
          title: "Success!",
          text: "You are gaining access to the Summer Athletes - Recruitment system. This allows your child to create an athlete recruitment profile, be in a system of thousands of coaches, see all of your event updates and messages sent from your coach, and receive additional discounts, all in one service.",
          icon: "success",
        });
      }
    },
    submitCamperInfo() {
      this.recruitPurchase = this.recruitPurchaseTemp;
      if (this.validateInfo() === 0) {
        window.scrollTo(0, 0);
        for (let additionalCamper of this.additionalCampers) {
          additionalCamper.customFieldValues =
            additionalCamper.fieldValueInputs;
          additionalCamper.addOns = additionalCamper.campAddOns.map(
            (addOn) => ({ ...addOn })
          );
          if (this.sessionSupported) {
            additionalCamper.scheduledSession =
              this.camperInfo.scheduledSession;
          }
        }
        let data = {
          camperInfo: this.camperInfo,
          emergencyContactInfo: this.emergencyContactInfo,
          customFieldValues: this.fieldValueInputs,
          paymentType: this.paymentType,
          additionalCampers: this.additionalCampers,
          recruitPurchase: this.recruitPurchase,
        };
        this.$emit("proceed", data);
      }
    },
    back() {
      this.$emit("back");
    },
    validateInfo() {
      if (!this.camperInfo.firstName) {
        swal("Error", "Please enter camper first name", "error");
        return 1;
      }
      if (!this.camperInfo.lastName) {
        swal("Error", "Please enter camper last name", "error");
        return 1;
      }
      if (!this.camperInfo.tShirtSize && this.tshirtBool) {
        swal("Error", "Please enter camper shirt size", "error");
        return 1;
      }

      //Check to make sure if custom fields exist for the camp. If so, validate camper inputs for them.
      if (
        this.customFields &&
        this.customFields[0] &&
        this.fieldValueInputs &&
        this.fieldValueInputs[0] !== {}
      ) {
        if (this.fieldValueInputs.length !== this.customFields.length) {
          swal(
            "Error",
            "Please fill out all form fields before continuing",
            "error"
          );
          return 1;
        } else {
          for (let index = 0; index < this.customFields.length; index++) {
            if (
              this.fieldValueInputs[index].fieldName !==
                this.customFields[index].fieldName &&
              this.customFields[index].fieldType == "number"
            ) {
              swal(
                "Error",
                this.customFields[index].fieldName + " must be a number",
                "error"
              );
              return 1;
            } else if (
              this.fieldValueInputs[index].fieldName !==
              this.customFields[index].fieldName
            ) {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            } else if (this.fieldValueInputs[index].fieldValue === "") {
              swal(
                "Error",
                "Please fill out all form fields before continuing",
                "error"
              );
              return 1;
            }
          }
        }
      }

      if (!(this.initial + this.addOnTotal > 0)) {
        if (!this.emergencyContactInfo.firstName) {
          swal("Error", "Please enter emergency first name", "error");
          return 1;
        } else if (!this.emergencyContactInfo.lastName) {
          swal("Error", "Please enter emergency last name", "error");
          return 1;
        } else if (!this.emergencyContactInfo.phoneNumber) {
          swal("Error", "Please enter emergency phone number", "error");
          return 1;
        } else if (!this.emergencyContactInfo.email) {
          swal("Error", "Please enter emergency email", "error");
          return 1;
        } else if (!this.emergencyContactInfo.address) {
          swal("Error", "Please enter emergency address", "error");
          return 1;
        } else if (!this.emergencyContactInfo.city) {
          swal("Error", "Please enter emergency city", "error");
          return 1;
        } else if (!this.emergencyContactInfo.state) {
          swal("Error", "Please enter emergency state", "error");
          return 1;
        } else if (!this.emergencyContactInfo.zipCode) {
          swal("Error", "Please enter emergency zip code", "error");
          return 1;
        }
      }

      for (const camper of this.additionalCampers) {
        if (!camper.firstName) {
          swal("Error", "Please enter camper first name", "error");
          return 1;
        }
        if (!camper.lastName) {
          swal("Error", "Please enter camper last name", "error");
          return 1;
        }
        if (!camper.tShirtSize && this.tshirtBool) {
          swal("Error", "Please enter camper shirt size", "error");
          return 1;
        }

        //Check to make sure if custom fields exist for the camp. If so, validate camper inputs for them.
        if (
          camper.customFields &&
          camper.customFields[0] &&
          camper.fieldValueInputs &&
          camper.fieldValueInputs[0] !== {}
        ) {
          if (camper.fieldValueInputs.length !== camper.customFields.length) {
            swal(
              "Error",
              "Please fill out all form fields before continuing",
              "error"
            );
            return 1;
          } else {
            for (let index = 0; index < camper.customFields.length; index++) {
              if (
                camper.fieldValueInputs[index].fieldName !==
                  camper.customFields[index].fieldName &&
                camper.customFields[index].fieldType == "number"
              ) {
                swal(
                  "Error",
                  this.customFields[index].fieldName + " must be a number",
                  "error"
                );
                return 1;
              } else if (
                camper.fieldValueInputs[index].fieldName !==
                camper.customFields[index].fieldName
              ) {
                swal(
                  "Error",
                  "Please fill out all form fields before continuing",
                  "error"
                );
                return 1;
              } else if (camper.fieldValueInputs[index].fieldValue === "") {
                swal(
                  "Error",
                  "Please fill out all form fields before continuing",
                  "error"
                );
                return 1;
              }
            }
          }
        }
      }
      return 0;
    },
    addAdditionalCamper() {
      this.addingAdditionalCampers = true;
      let tempCustomFields = [];
      if (this.customFields) {
        tempCustomFields = this.customFields.map((x) => ({ ...x }));
      }
      let tempAddOns = [];
      if (this.campAddOns) {
        tempAddOns = this.campAddOns.map((x) => ({ ...x }));
      }

      let tempFieldValueInputs = [];
      for (let x = 0; x < tempCustomFields.length; x++) {
        tempCustomFields[x].fieldValue = "";
        tempCustomFields[x].S3Key = "";
        tempFieldValueInputs.push("");
      }
      let id = Math.floor(Math.random() * 100000000);
      console.log(tempFieldValueInputs);
      this.additionalCampers.push({
        id: id,
        customFields: tempCustomFields,
        fieldValueInputs: tempFieldValueInputs,
        customFieldValues: [],
        campAddOns: tempAddOns,
        tshirtBool: this.tshirtBool,
        //If we don't have to hide recruit ads, default it to true
        recruitPurchase: !this.hideRecruitAds,
      });
    },
    removeAdditionalCamper(removalIndex) {
      // let additionalCamperCopy = this.additionalCampers.map((camper) => ({
      //   ...camper,
      // }));

      this.additionalCampers.splice(removalIndex, 1);
      // for (let additionalCamperIndex in this.additionalCampers) {
      //   console.log(this.additionalCampers[additionalCamperIndex]);
      //   if (additionalCamperIndex >= removalIndex) {
      //     this.additionalCampers[additionalCamperIndex] =
      //       additionalCamperCopy[additionalCamperIndex + 1];
      //   }
      // }
    },
  },

  created() {
    this.info = this.camperInfo;
    if (!this.customFields) {
      this.customFields = [];
    }
    if (!this.fieldValueInputs[0]?.fieldValue) {
      for (let x = 0; x < this.customFields.length - 1; x++) {
        this.fieldValueInputs.push("");
      }
    }

    if (this.requireEmergency) {
      this.emergencyContactInfo.emergencyBool = false;
    }
    if (this.initial + this.addOnTotal === 0) {
      this.emergencyContactInfo.emergencyBool = false;
    }
  },

  computed: {
    getAddOnTotal() {
      let temp = 0;
      for (let x = 0; x < this.addOns.length; x++) {
        if (this.addOns[x].isPurchased) {
          temp = temp + this.addOns[x].addOnPrice;
        }
      }
      return temp;
    },
    getAdditionalCamperPrice() {
      if (this.multipleCamperPrices && this.multipleCamperPrices.length > 0) {
        if (this.additionalCampers.length < this.multipleCamperPrices.length) {
          return this.multipleCamperPrices[this.additionalCampers.length]
            .camperPrice;
        } else {
          return this.multipleCamperPrices[this.multipleCamperPrices.length - 1]
            .camperPrice;
        }
      } else {
        return this.initial;
      }
    },
    getFormattedAdditionalCamperPrice() {
      return numeral(this.getAdditionalCamperPrice).format("0,0.00");
    },
    getAddOnLength() {
      try {
        return this.campAddOns.length;
      } catch (e) {
        return 0;
      }
    },
    getRunningPayTotal() {
      let total = 0;
      total = this.initial + this.addOnTotal;
      for (const camper of this.additionalCampers) {
        for (const addOn of camper.addOns) {
          if (addOn.isPurchased) {
            total = total + addOn.addOnPrice;
          }
        }
      }
      return total;
    },
    checkAddRegistrantButton() {
      if (this.sessionSupported) {
        if (this.additionalCampers.length + 1 < this.timeSlotsLeft) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.recruitPurchaseTemp = this.recruitPurchase;
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
