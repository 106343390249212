<template>
  <div class="container">
    <section style="margin-top: 7%">
      <div class="container mt-5">
        <div class="card-form">
          <h2 class="text-center">
            Purchasable Items
            <i
              class="fa-solid fa-circle-info"
              style="color: #45487a; cursor: pointer"
              @click="showPurchaseItemExplanation"
            ></i>
          </h2>
          <h5 class="text-center center mb-3">(Sold at Checkout)</h5>
          <draggable :list="this.customAddOns" class="draggable">
            <li
              class="list-group-item"
              v-for="(item, index) in this.customAddOns"
              :key="'customAddOn' + index"
            >
              <div class="row">
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    >Item Name</label
                  >
                  <input
                    v-model="item.addOnName"
                    required
                    id="form6Example1"
                    class="card-input__input"
                    @change="backToCamp"
                    :disabled="!editable && index < startNewDynamicPricingIndex"
                  />
                </div>
                <div class="col" style="margin-top: 5px">
                  <label class="form-label" for="form6Example3"
                    ><span
                      >Item Price
                      <i
                        v-if="editable"
                        class="fa-solid fa-trash removeAddOn"
                        @click="removeAddOn(index)"
                      ></i></span
                  ></label>
                  <input
                    v-model="item.addOnPrice"
                    required
                    type="number"
                    id="form6Example1"
                    class="card-input__input"
                    @change="backToCamp"
                  />
                </div>
              </div>
            </li>
          </draggable>
          <div class="row justify-content-center">
            <button
              class="btn btn-primary col-sm-6 col-md-5 m-3 rounded-pill"
              @click="addField"
            >
              Add Purchasable Item
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import swal from "sweetalert";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      customAddOns: [{ addOnName: "", addOnPrice: "" }],
    };
  },
  props: {
    addOns: Array,
    editable: Boolean,
    startNewDynamicPricingIndex: Number,
  },
  methods: {
    prepopulate() {
      if (this.addOns.length > 0)
        this.customAddOns = [...this.addOns, ...this.customAddOns];
    },
    addField() {
      this.customAddOns.push({ addOnName: "", addOnPrice: "" });
    },
    showPurchaseItemExplanation() {
      swal({
        title: "Purchasable Item Explanation",
        text: "This is where you can add a purchasable item to your registration form's cart. When a camper/parent is signing up, they will see these items as additional options to purchase along with the camp. Simply set the name of the item and its price, we'll handle the rest!",
        icon: "info",
      });
    },
    backToCamp() {
      this.$emit(
        "back",
        this.customAddOns.filter((x) => x.addOnName && x.addOnPrice)
      );
    },
    removeAddOn(index) {
      this.customAddOns.splice(index, 1);
      this.backToCamp();
    },
  },
  mounted() {
    this.prepopulate();
  },
};
</script>
<style>
.removeAddOn {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeAddOn:hover {
  color: darkred;
  cursor: pointer;
}
.draggable > li {
  cursor: grab;
}
</style>
