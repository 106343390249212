var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper container justify-content-center",attrs:{"id":"app"}},[_c('ClientLayout',{attrs:{"user":_vm.user}}),_c('section',{staticClass:"container justify-content-center"},[_c('div',{staticClass:"container mt-4 justify-content-center"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-5 col-sm-5"},[_c('div',{staticClass:"card-form",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile() ? '99%' : '',
                marginLeft: _vm.isMobile() ? '21px' : '',
                textAlign: _vm.isMobile() ? 'center' : '',
              })},[_c('dashboard-account-balance',{attrs:{"accountBalance":this.getAccountBalance}})],1)]),_c('div',{staticClass:"card-form",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile() ? '99%' : '',
                marginLeft: _vm.isMobile() ? '21px' : '',
                textAlign: _vm.isMobile() ? 'center' : 'center',
              })},[_c('dashboard-my-website',{attrs:{"totalViews":this.getTotalViews,"websiteName":this.getWebsiteName,"monthlyViews":this.getMonthlyViews,"websiteUrl":this.getWebsiteUrl,"websiteSubdomain":this.getWebsiteSubdomain}})],1)])]),_c('div',{staticClass:"col-md-7 col-sm-7 justify-content-center"},[_c('div',{staticClass:"card-form",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile() ? '99%' : '',
                marginLeft: _vm.isMobile() ? '21px' : '',
                textAlign: _vm.isMobile() ? 'center' : '',
              })},[_c('dashboard-account-overview',{attrs:{"subscriptionName":this.getSubscriptionName,"oneTimePaymentLinks":this.getOneTimePaymentLinks,"oneTimeCustomers":this.getOneTimeCustomers,"recurringPaymentLinks":this.getRecurringPaymentLinks,"activeRecurringCustomers":this.getActiveRecurringCustomers}})],1)]),_c('div',{staticClass:"card-form v-step-0",staticStyle:{"margin-bottom":"15px"}},[_c('div',{staticClass:"card-form__inner",style:({
                width: _vm.isMobile() ? '99%' : '',
                marginLeft: _vm.isMobile() ? '21px' : '',
                textAlign: _vm.isMobile() ? 'center' : '',
              })},[_c('div',[_c('DashboardRecentTransactions',{attrs:{"transactions":this.getTransactions}}),_c('br')],1)])])]),_c('div',{staticClass:"col-md-3 col-sm-3"}),_c('div',{staticClass:"col-md-4 col-sm-4 justify-content-center"})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }