<template>
  <div
    class="modal fade"
    id="viewSimpleScheduledEvent"
    tabindex="-1"
    aria-labelledby="viewSimpleScheduledEventLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title text-center"
            id="viewSimpleScheduledEventLabel"
          >
            View Event Details
          </h5>

          <button
            type="button"
            class="btn-close"
            id="viewSimpleScheduledEventClose"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <h5 class="mb-3">Date: {{ getEventDate }}</h5>
          <label
            class="form-label mt-3"
            for="form6Example1"
            style="margin-top: 5px"
            >Name<span class="text-danger">*</span></label
          >
          <input
            type="text"
            id="form6Example1"
            class="form-control v-step-2"
            placeholder="e.g. Junior High Basketball Tournament"
            v-model="simpleScheduledEvent.name"
            required
          />

          <label
            class="form-label mt-3"
            for="form6Example4"
            style="margin-top: 5px"
            >Location (Optional)
            <span
              v-if="addressVerified"
              class="text-muted"
              style="font-size: 12px"
              ><i class="fa-solid fa-circle-check"></i> Verified</span
            >
          </label>
          <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Address"
            class="card-input__input"
            :country="['us']"
            v-on:placechanged="getAddressData"
            v-on:change="setAddressData"
            v-model="simpleScheduledEvent.location"
          >
          </vue-google-autocomplete>

          <div class="row mt-3">
            <div class="col-md-7 col-sm-3">
              <label class="form-label"
                >Description<span class="text-danger">*</span></label
              >
            </div>
          </div>

          <textarea
            class="form-control v-step-4"
            rows="4"
            placeholder="e.g. The Junior High Basketball Tournament will...."
            v-model="simpleScheduledEvent.description"
            required
            style="min-height: 150px"
          ></textarea>
          <div class="row mt-3">
            <div class="col" style="margin-top: 5px">
              <label class="form-label" for="form6Example3">Start Time</label>
              <input
                v-model="schedule.startTime"
                required
                id="form6Example1"
                class="form-control mb-3"
                type="time"
              />
            </div>
            <div class="col" style="margin-top: 5px">
              <label class="form-label" for="form6Example3">End Time</label>
              <input
                v-model="schedule.endTime"
                required
                id="form6Example1"
                class="form-control mb-3"
                type="time"
              />
            </div>
          </div>

          <div class="form-check d-flex mb-4" style="margin-top: 20px">
            <input
              class="form-check-input me-2"
              type="checkbox"
              value=""
              v-model="schedule.repeating"
            />
            <label class="form-check-label v-step-8"> Is Recurring</label>
          </div>
          <div v-if="schedule.repeating">
            <ul class="list-group">
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="DAY"
                  v-model="schedule.repeatingSetting"
                />
                Every Day
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="WEEK"
                  checked
                  v-model="schedule.repeatingSetting"
                />
                Every Week
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                  value="MONTH"
                  checked
                  v-model="schedule.repeatingSetting"
                />
                Every Month
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteSimpleScheduledEvent()"
          >
            Delete Event
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="updateSimpleScheduledEvent()"
          >
            Update Event
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import swal2 from "sweetalert2";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },
  data() {
    return {
      dayApplicable: "TODAY",
      addressVerified: true,
    };
  },
  props: {
    simpleScheduledEvent: Object,
    schedule: Object,
    userID: String,
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData);
      console.log(id);
      console.log(addressData);
      this.simpleScheduledEvent.location = placeResultData.formatted_address;
      this.addressVerified = true;
    },
    setAddressData(e) {
      if (!this.simpleScheduledEvent.location || this.editMode) {
        console.log(e);
        this.simpleScheduledEvent.location = e.toString();
      }
    },
    async deleteSimpleScheduledEvent() {
      let inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            TODAY: "Today",
            TODAYANDFOLLOWING: "Today and Following",
            EVERYDAY: "Every Day",
          });
        }, 500);
      });

      if (!this.schedule.repeating) {
        inputOptions = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              TODAY: "Today",
            });
          }, 500);
        });
      }

      const { value: period } = await swal2.fire({
        title: "On which days would you like to delete this event?",
        showCancelButton: true,
        input: "radio",
        inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "Please select an option";
          }
        },
      });
      if (period) {
        swal("Loading...", "", "info");
        let url =
          process.env.VUE_APP_DB_URL + "scheduling/deleteSimpleScheduledEvent";
        axios
          .post(
            url,
            {
              eventID: this.simpleScheduledEvent._id,
              periodToDelete: period,
              dateSelected: this.schedule.date,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            }
          )
          .then((response) => {
            this.$emit("deletedSimpleScheduledEvent", {
              allAvailability: response.data.availability,
            });
            document.getElementById("viewSimpleScheduledEventClose").click();
            swal("Success", "Calendar has been updated", "success");
          })
          .catch(function (err) {
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },

    async updateSimpleScheduledEvent() {
      if (
        !this.simpleScheduledEvent.name ||
        !this.simpleScheduledEvent.description ||
        !this.schedule.startTime ||
        !this.schedule.endTime
      ) {
        swal(
          "Details Incomplete",
          "Please fill out all form fields and try again",
          "error"
        );
        return;
      }

      let inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            TODAY: "Today",
            TODAYANDFOLLOWING: "Today and Following",
            EVERYDAY: "Every Day",
          });
        }, 500);
      });

      if (!this.schedule.repeating) {
        inputOptions = new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              TODAY: "Today",
            });
          }, 500);
        });
      }
      const { value: period } = await swal2.fire({
        title: "On which days would you like to update this event?",
        showCancelButton: true,
        input: "radio",
        inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "Please select an option";
          }
        },
      });
      if (period) {
        swal("Loading...", "", "info");
        let url =
          process.env.VUE_APP_DB_URL + "scheduling/updateSimpleScheduledEvent";
        axios
          .post(
            url,
            {
              simpleScheduledEvent: this.simpleScheduledEvent,
              schedule: this.schedule,
              periodToUpdate: period,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("jwt"),
              },
            }
          )
          .then((response) => {
            this.$emit("updatedSimpleScheduledEvent", {
              allAvailability: response.data.availability,
            });
            document.getElementById("viewSimpleScheduledEventClose").click();
            swal("Success", "Calendar has been updated", "success");
          })
          .catch(function (err) {
            let error = err.response;
            swal("Error", error.data.errorMessage, "error");
          });
      }
    },
  },
  computed: {
    getEventDate() {
      return new Date(this.schedule.date).toLocaleDateString();
    },
  },
  created() {},

  mounted() {},
};
</script>

<style scoped></style>
