var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-5 container",style:({ marginLeft: _vm.isMobile ? '21px' : '' }),attrs:{"id":"app"}},[_c('div',{staticClass:"card-form"},[_c('div',{staticClass:"card-form__inner"},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":require("../../../../public/SummerAthletes.png"),"alt":""}}),_c('ul',{staticClass:"list-group",staticStyle:{"margin-top":"20px"}},[_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b',[_vm._v("Summer Athletes ")]),_c('span',{staticClass:"badge actionFeature",staticStyle:{"margin-left":"7px","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.packageName)+" ")])])]),_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Package Price")]),_c('div',[_vm._v("$"+_vm._s(this.price.toFixed(2)))])])]),_c('li',{staticClass:"list-group-item"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v("Credit Processing Fee")]),_c('div',[_vm._v("$"+_vm._s((this.price * 0.05).toFixed(2)))])])])]),_c('div',[_c('br'),_c('button',{staticClass:"card-form__button rounded-pill",on:{"click":_vm.submitPayment}},[_vm._v(" Pay $"+_vm._s((this.price * 1.05).toFixed(2))+" ")]),_c('br'),_c('br'),_c('br'),_c('button',{class:{
            'card-form__button': true,
            'w-25': !_vm.isMobile,
            'w-50': _vm.isMobile,
            'rounded-pill': true,
          },style:({
            backgroundColor: '#363166',
            color: 'white',
          }),on:{"click":_vm.back}},[_c('i',{staticClass:"fa-solid fa-arrow-left"}),_vm._v(" Back ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }