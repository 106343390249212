<template>
  <div :class="{ container: !isMobile }" id="app">
    <div class="card-form">
      <div class="card-form__inner">
        <img :src="this.membershipLogo" class="card-img-top rounded-corners" />

        <div class="card-body" style="margin-top: 10px"></div>
        <ul class="list-group">
          <li class="list-group-item">
            <div class="d-flex justify-content-start">
              <b>{{ this.membershipName }}</b>
            </div>
          </li>
          <li class="list-group-item">
            <div
              v-if="!this.hidePlatformFees"
              class="d-flex justify-content-between"
            >
              <div>Price</div>
              <div>
                ${{ this.basePriceSelected.toFixed(2) }}/{{
                  this.displayPaymentLength
                }}
              </div>
            </div>
            <div v-else class="d-flex justify-content-between">
              <div>Price</div>
              <div>${{ this.getTotal }}/{{ this.displayPaymentLength }}</div>
            </div>

            <div
              v-if="!this.hidePlatformFees"
              class="d-flex justify-content-between"
            >
              <div>Summer Athletes Platform Fee</div>
              <div>${{ getPlatformFee.toFixed(2) }}</div>
            </div>

            <div
              v-if="!this.hidePlatformFees"
              class="d-flex justify-content-between"
            >
              <div>Credit Card Processing Fee</div>
              <div>${{ getCreditFee.toFixed(2) }}</div>
            </div>

            <div
              v-if="!this.hidePlatformFees"
              class="d-flex justify-content-between"
            >
              <div>Total Payment</div>
              <div>${{ getTotal }}</div>
            </div>
          </li>
        </ul>
        <div>
          <button
            class="card-form__button rounded-pill"
            @click="submitPayment"
            :style="{ backgroundColor: primaryColor, color: 'white' }"
          >
            Pay ${{ this.getTotal }} {{ this.displayPaymentPeriod }}
          </button>
          <br />
          <br />
          <br />
          <button
            :class="{
              'card-form__button': true,
              'w-50': isMobile,
              'w-25': !isMobile,
              'rounded-pill': true,
            }"
            :style="{
              backgroundColor: secondaryColor,
              color: 'white',
            }"
            @click="back"
          >
            <i class="fa-solid fa-arrow-left"></i>
            Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import swal from "sweetalert";
// import axios from "axios";
export default {
  components: {},
  props: {
    membershipLogo: String,
    membershipName: String,
    membershipPayPeriod: Object,
    paymentPeriodSelected: String,
    basePriceSelected: Number,
    hidePlatformFees: Boolean,
    totalMembershipUpchargePercentage: Number,
    isMobile: Boolean,
    primaryColor: String,
    secondaryColor: String,
  },
  data() {
    return {
      totalPrice: 0,
      membershipDisplayName: "",
    };
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submitPayment() {
      this.$emit("proceed");
    },
  },
  created() {},
  computed: {
    getTotal() {
      let membershipPrice = this.basePriceSelected;
      if (membershipPrice < 50) {
        return (membershipPrice + 4.99).toFixed(2);
      } else {
        return (
          membershipPrice * (1 + this.totalMembershipUpchargePercentage / 100) -
          0.01
        ).toFixed(2);
      }
    },
    getPlatformFee() {
      if (this.initial + this.addOns < 40) {
        return 3.5;
      } else {
        return this.getTotal - this.getCreditFee - this.basePriceSelected;
      }
    },
    getCreditFee() {
      if (this.basePriceSelected < 40) {
        return 0.99;
      } else {
        return this.getTotal * 0.03;
      }
    },
    displayPaymentPeriod() {
      if (this.paymentPeriodSelected === "weeklyPrice") {
        return "Weekly";
      } else if (this.paymentPeriodSelected === "monthlyPrice") {
        return "Monthly";
      } else if (this.paymentPeriodSelected === "semiAnnualPrice") {
        return "Semi-Annually";
      } else if (this.paymentPeriodSelected === "annualPrice") {
        return "Annually";
      } else {
        return "";
      }
    },
    displayPaymentLength() {
      if (this.paymentPeriodSelected === "weeklyPrice") {
        return "Week";
      } else if (this.paymentPeriodSelected === "monthlyPrice") {
        return "Month";
      } else if (this.paymentPeriodSelected === "semiAnnualPrice") {
        return "6 months";
      } else if (this.paymentPeriodSelected === "annualPrice") {
        return "Year";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
img.rounded-corners {
  border-radius: 10px;
}
</style>
