<template>
  <div
    class="card-form container d-flex justify-content-center mx-4"
    :style="{ marginTop: '4%', minWidth: isMobile ? '100%' : '630px' }"
  >
    <div
      :class="{ 'card-form__inner': true }"
      v-bind:style="{
        minWidth: isMobile ? '100%' : '630px',
        // minWidth: isMobile() ? '23rem' : '75%',
      }"
    >
      <center class="m-3">
        <img
          v-if="
            (this.newCamp.campLogo == '' || this.newCamp.campLogo == null) &&
            !this.gif
          "
          src="../../../public/SummerAthletes.png"
          class="card-img-top"
          style="max-width: 400px; max-height: 300px"
        />

        <img
          v-else
          :src="this.newCamp.campLogo"
          class="card-img-top rounded-3"
          style="max-width: 500px"
        />
        <img
          id="mygif"
          src="../../../public/1495.gif"
          class="animated-gif center"
          v-show="this.gif"
        />
      </center>

      <div class="mb-3">
        <label for="formFile" class="form-label">Upload Logo</label>
        <input
          class="form-control v-step-0"
          type="file"
          id="formFile"
          @change="uploadImage($event)"
        />
      </div>
      <label class="form-label mt-3" for="form6Example1" style="margin-top: 5px"
        >Name<span class="text-danger">*</span></label
      >
      <input
        type="text"
        id="form6Example1"
        class="form-control v-step-2"
        placeholder="e.g. Junior High Boys Basketball Camp"
        v-model="newCamp.campName"
        required
      />

      <label class="form-label mt-3" for="form6Example4" style="margin-top: 5px"
        >Location<span class="text-danger">* </span>
        <span v-if="!addressVerified" class="text-muted" style="font-size: 12px"
          >Unverified</span
        >
        <span v-else class="text-muted" style="font-size: 12px"
          ><i class="fa-solid fa-circle-check"></i> Verified</span
        ></label
      >
      <vue-google-autocomplete
        ref="address"
        id="map"
        classname="form-control"
        placeholder="Address"
        class="card-input__input"
        :country="['us']"
        v-on:placechanged="getAddressData"
        v-on:change="setAddressData"
        v-model="newCamp.campLocation"
      >
      </vue-google-autocomplete>

      <label class="form-label mt-3" for="form6Example6" style="margin-top: 5px"
        >Price (In USD)<span class="text-danger">*</span></label
      >
      <input
        type="number"
        id="form6Example6"
        class="form-control mb-3"
        placeholder="75"
        min="0"
        v-model="newCamp.campPrice"
        required
      />

      <div class="row mt-5">
        <div class="col-md-7 col-sm-3">
          <label class="form-label"
            >Description<span class="text-danger">*</span></label
          >
        </div>
        <div class="col-md-5 col-sm-3 mb-3">
          <button
            class="btn w-100 text-center rounded-pill"
            style="background-color: #363166; color: white; cursor: pointer"
            @click="generateDescription"
          >
            Generate with SA-I <i class="fa-solid fa-rocket"></i>
          </button>
        </div>
      </div>

      <textarea
        class="form-control v-step-4"
        rows="4"
        placeholder="e.g. The Junior High Basketball Camp will teach your child...."
        v-model="newCamp.campDescription"
        required
        style="min-height: 150px"
      ></textarea>
      <div class="row justify-content-center">
        <div class="col-md-9 col-sm-6">
          <button
            v-if="!editMode"
            class="card-form__button create-camp-step-12 mt-3 rounded-pill"
            @click="createEasyCamp()"
            style="margin-top: 10px"
          >
            {{
              isMobile ? "Create Payment Link" : "Create One-Time Payment Link"
            }}
          </button>
          <button
            v-else
            class="card-form__button create-camp-step-12 mt-3 rounded-pill"
            @click="createEasyCamp()"
            style="margin-top: 10px"
          >
            Save changes
          </button>
        </div>
        <!--        <div-->
        <!--          class=""-->
        <!--          style="color: blue; text-decoration: underline; cursor: pointer"-->
        <!--          @click="goToAdvancedCreate()"-->
        <!--        >-->
        <!--          Advanced Camp Creation? Click Here-->
        <!--        </div>-->

        <div class="row justify-content-end mt-5 w-100">
          <br />
          <br />
          <a
            class="card2 col-md-6 col-sm-6 order"
            style="cursor: pointer"
            @click="goToAdvancedCreate()"
          >
            <h5 class="text-center">Advanced Payment Creation</h5>
            <div class="go-corner" href="#">
              <div class="go-arrow">→</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert";
import axios from "axios";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  name: "Camp Easy Form",
  props: {
    editMode: Boolean,
    newCamp: Object,
  },
  data() {
    return {
      user: {},
      addressVerified: false,
      gif: false,
      savedData: {},
      palette: [],
      imageSrc: "",
    };
  },
  methods: {
    getAddressData(addressData, placeResultData, id) {
      console.log(placeResultData);
      console.log(id);
      console.log(addressData);
      this.newCamp.campLocation = placeResultData.formatted_address;
      this.addressVerified = true;
    },
    setAddressData(e) {
      if (!this.newCamp.campLocation || this.editMode) {
        console.log(e);
        this.newCamp.campLocation = e.toString();
      }
    },
    uploadImage(event) {
      if (this.user.subscription.subscriptionTier < 2) {
        swal({
          icon: "info",
          title: "Upgrade Your Plan",
          text: "You need to be at least a Pro user to have personal branding. Please upgrade your plan to continue.",
        });
        document.getElementById("openAdvertisementPopUp").click();
        return;
      }
      // const file = event.target.files[0];
      // const reader = new FileReader();
      // reader.addEventListener(
      //   "load",
      //   () => {
      //     this.getPalette(reader.result);
      //   },
      //   false
      // );
      // if (file) {
      //   reader.readAsDataURL(file);
      // }
      this.gif = true;
      const URL = process.env.VUE_APP_DB_URL + "util/uploadFile";
      let data = new FormData();
      data.append("name", "my-picture");
      data.append("file", event.target.files[0]);
      console.log(data);
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .put(URL, data, config)
        .then((response) => {
          this.newCamp.campLogo = response.data;
          this.gif = false;
        })
        .catch(function (err) {
          console.log(err);
          swal(
            "Error",
            "Error uploading file. Please only upload image files less than 2mb.",
            "error"
          );
        });
    },

    async createEasyCamp() {
      this.savedData = this.newCamp;
      if (this.campDetailsComplete() == 1) {
        return;
      }
      swal({
        title: "Confirm Details",
        text: "Once the link has had a purchase, no changes are allowed.  Until then, everything that was filled out can be updated.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willCreate) => {
        if (willCreate) {
          console.log("HIT");
          this.$emit("createEasy", true);
          let url = this.editMode
            ? process.env.VUE_APP_DB_URL + "individualCamp/editCamp"
            : process.env.VUE_APP_DB_URL + "individualCamp/createCamp";
          axios
            .post(
              url,
              {
                id: this.user._id,
                campName: this.newCamp.campName,
                campDescription: this.newCamp.campDescription,
                campLocation: this.newCamp.campLocation,
                campLogo: this.newCamp.campLogo,
                campPrice: this.newCamp.campPrice,
                requireWaiver: this.newCamp.requireWaiver,
                tShirtsProvided: this.newCamp.tShirtsProvided,
                hidePlatformFees: !this.newCamp.showPlatformFees,
                campStripeId: this.newCamp.campStripeId,
              },
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
                },
              }
            )
            .then((response) => {
              if (this.editMode) {
                swal("Success", "Camp Edited!", "success");
              } else {
                swal("Success", "Camp Created!", "success");
              }
              this.camps = response.data;
              this.$router.push("/Dashboard");
            })
            .catch(function (err) {
              let error = err.response;
              swal("Error", error.data.errorMessage, "error");
              this.newCamp = this.savedData;
            });
        }
      });
    },

    campDetailsComplete() {
      if (!this.newCamp.campName) {
        swal("Error", "Name must be filled out.", "error");
        return 1;
      }
      if (!this.newCamp.campLocation) {
        swal("Error", "Location must be filled out.", "error");
        return 1;
      }

      if (!this.newCamp.campPrice) {
        swal("Error", "Price must be filled out.", "error");
        return 1;
      }
      if (
        this.newCamp.campPrice == 0 &&
        this.user.subscription.subscriptionTier < 3
      ) {
        swal(
          "Upgrade Your Plan",
          "You must be on at least the All Star Plan to create free events",
          "info"
        );
        document.getElementById("openAdvertisementPopUp").click();
        return 1;
      }
      if (!this.newCamp.campDescription) {
        swal("Error", "Description must be filled out.", "error");
        return 1;
      }
      return 0;
    },

    generateDescription() {
      swal("Generating...", "Running AI analysis on your event...", "info");
      let url =
        process.env.VUE_APP_DB_URL +
        `ai/generateCampDescription?campName=${this.newCamp.campName}&campLocation=${this.newCamp.campLocation}&campPrice=${this.newCamp.campPrice}&campDescription=${this.newCamp.campDescription}&userID=${this.user._id}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          console.log(response.data);
          this.newCamp.campDescription = response.data.generatedDescription;
          swal(
            "Generated!",
            "SA-I Description Generation Successful!",
            "success"
          );
        });
    },

    goToAdvancedCreate() {
      // swal message with a will confirm button to go to advanced create page
      swal({
        title: "Are you sure?",
        text: "This will take you to the advanced camp creation page and some of your current progress may be lost.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willCreate) => {
        if (willCreate) {
          window.scrollTo(0, 0);
          this.$emit("goToAdvancedCreate");
        }
      });
      return;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  mounted() {},
  computed: {
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
};
</script>
<style>
.editor-datetime {
  position: relative;
}
.btn-primary {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
}
.form-check-input:checked {
  background-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-check-input:hover .form-check-input:active,
.form-check-input:focus {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited .btn-primary:focus,
.btn-primary:active,
.btn-primary.active .input-color,
.body-color .input-border-color {
  background-color: #19d412 !important;
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border-color: #19d412 !important;
  box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset !important;
  background-color: #ffffff !important;
}
.form-control:focus,
.form-control:visited,
.form-control:active,
.form-control:hover,
.form-control:checked {
  border-color: #19d412 !important;
  background-color: #ffffff !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.border,
.border-primary {
  border-color: #19d412 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(25, 212, 18, 0.6) !important;
}
.b-card,
.b-card:hover {
  border: 1px solid #19d412 !important;
}
#nav {
  padding: 30px;
}
#nav a {
  font-weight: bold;
  color: #2c3e50;
}
#nav a.router-link-exact-active {
  color: #42b983;
}
.navbar-brand {
  position: absolute;
  left: calc(50% - 65px);
  display: block;
}
.removeDay {
  position: absolute;
  font-size: 20px;
  right: 0;
  top: 10px;
  color: red;
  width: auto !important;
}
.removeDay:hover {
  color: darkred;
  cursor: pointer;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}

.nav {
  margin-top: 40px;
}

.pull-right {
  float: right;
}

a,
a:active {
  color: #333;
  text-decoration: none;
}

a:hover {
  color: #999;
}

/* Breadcrups CSS */

.arrow-steps .step {
  font-size: 14px;
  text-align: center;
  color: #777;
  cursor: pointer;
  margin: 0 3px;
  padding: 10px 10px 10px 30px;
  min-width: 180px;
  float: left;
  position: relative;
  background-color: #d9e3f7;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -16px;
  width: 0;
  height: 0;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 17px solid #d9e3f7;
  z-index: 1;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #fff;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  color: #fff;
  background-color: #45487a;
}

.arrow-steps .step.incomplete {
  color: #fff;
  background-color: #ffcc00;
}

.arrow-steps .step.finished {
  color: #fff;
  background-color: #19d412;
}

.arrow-steps .step.current:after {
  border-left: 17px solid #45487a;
}

.arrow-steps .step.incomplete:after {
  border-left: 17px solid #ffcc00;
}

.arrow-steps .step.finished:after {
  border-left: 17px solid #19d412;
}
</style>
