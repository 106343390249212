<template>
  <div>
    <CoachNav :user="user.firstName"></CoachNav>
    <section style="margin-top: 7%">
      <div class="container mt-5">
        <a
          class="btn btn-primary"
          href="/Dashboard/Admin/Payouts"
          style="margin-left: 5px"
          >Payouts</a
        >
        <div class="chart-section">
          <Bar
            v-if="loaded"
            id="chart1"
            :options="chartOptions"
            :chartData="chart1Data"
            :height="200"
          />
          <h1 style="text-align: center">Camps Per Month</h1>
        </div>
        <div class="chart-section">
          <Bar
            v-if="loaded"
            id="chart2"
            :options="chartOptions"
            :chartData="chart2Data"
            :height="200"
          />
          <h1 style="text-align: center">Payouts Per Month</h1>
        </div>
        <div class="chart-section">
          <Bar
            v-if="loaded"
            id="chart3"
            :options="chartOptions"
            :chartData="chart3Data"
            :height="200"
          />
          <h1 style="text-align: center">Revenue earned per year</h1>
        </div>
        <div class="chart-section">
          <h1 style="text-align: center">
            Total Revenue Earned: ${{ totalRevenueEarned.toFixed(2) }}
          </h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import VueJwtDecode from "vue-jwt-decode";
import CoachNav from "../../components/CoachNav.vue";
import { Bar } from "vue-chartjs/legacy";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  components: { CoachNav, Bar },
  data() {
    return {
      user: {},
      loaded: false,
      chart1Data: {},
      chart2Data: {},
      chart3Data: {},
      chartOptions: { responsive: true, maintainAspectRatio: false },
      totalRevenueEarned: 0,
    };
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    generateRandomColor() {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + randomColor;
    },
    months() {
      return [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
    },
    parseChartData(chartData) {
      let campsPerMonth = {
        labels: this.months(),
        datasets: [],
      };
      let payoutsPerMonth = {
        labels: this.months(),
        datasets: [],
      };
      for (const year in chartData) {
        campsPerMonth.datasets.push({
          label: year,
          backgroundColor: "green",
          data: chartData[year].campCount,
        });
        payoutsPerMonth.datasets.push({
          label: year,
          backgroundColor: "green",
          data: chartData[year].payouts,
        });
      }

      // Assign unique color to each year
      let labelColors = [];
      for (let i = 0; i < Object.keys(chartData).length; i++) {
        let randomColor = "#19d412";
        while (labelColors.includes(randomColor))
          randomColor = this.generateRandomColor();
        labelColors.push(randomColor);
      }
      for (let i = 0; i < campsPerMonth.datasets.length; i++) {
        campsPerMonth.datasets[i].backgroundColor = labelColors[i];
        payoutsPerMonth.datasets[i].backgroundColor = labelColors[i];
      }

      this.chart1Data = campsPerMonth;
      this.chart2Data = payoutsPerMonth;
    },
    parseRevenueEarned(chartData) {
      var years = Object.keys(chartData).sort();
      let data = {
        labels: years,
        datasets: [],
      };
      let dataSet = { backgroundColor: "green", data: [] };
      var tempTotal = 0;
      for (const year of years) {
        dataSet.data.push(chartData[parseInt(year)]);
        tempTotal += chartData[parseInt(year)];
      }
      this.totalRevenueEarned = tempTotal;
      data.datasets.push(dataSet);
      this.chart3Data = data;
    },
  },
  created() {
    this.getUserDetails();
    this.loaded = false;
    let url = process.env.VUE_APP_DB_URL + "admin/getAllAdminChartData";
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        this.parseChartData(response.data);
      })
      .catch(function (err) {
        let error = err.response;
        swal("Error", error?.message ?? "", "error");
      });
    url = process.env.VUE_APP_DB_URL + "admin/getRevenueEarnedPerYear";
    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("jwt"),
        },
      })
      .then((response) => {
        this.parseRevenueEarned(response.data);
      })
      .catch(function (err) {
        let error = err.response;
        swal("Error", error?.message ?? "", "error");
      });
    this.loaded = true;
  },
};
</script>

<style>
.chart-section {
  margin-bottom: 40px;
}
</style>
