<template>
  <div id="app">
    <CamperCheckoutFree v-if="this.isFree"></CamperCheckoutFree>
    <CamperCheckoutPaid v-if="this.isPaid"></CamperCheckoutPaid>
  </div>
</template>

<script>
import CamperCheckoutFree from "../../components/TeamCamps/PayPerTeam/CamperCheckout.vue";
import CamperCheckoutPaid from "../../components/TeamCamps/PayPerCamper/CamperCheckout.vue";
import axios from "axios";

export default {
  components: {
    CamperCheckoutFree,
    CamperCheckoutPaid,
  },
  data() {
    return {
      camp: {},
    };
  },
  methods: {
    async getCampInfo() {
      let url =
        process.env.VUE_APP_DB_URL + "teamCamp/getTeamCamp?id=" + this.campId;
      axios.get(url, {}).then((response) => {
        this.camp = response.data;
      });
    },
  },
  created() {
    this.campId = this.$route.params.campId;
    this.getCampInfo();
    //make axios api call to get camp data here
  },
  computed: {
    isFree() {
      return this.camp.collectFrom == "team";
    },
    isPaid() {
      return this.camp.collectFrom == "camper";
    },
  },
};
</script>
