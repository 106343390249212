<template>
  <div class="wrapper">
    <section class="container" style="max-width: 100%">
      <div class="container mt-5">
        <a href="/Dashboard/Admin/Payouts" class="btn btn-primary mb-5"
          >Payouts</a
        >

        <div class="row justify-content-center">
          <div class="row">
            <div class="col">
              <label class="form-label" for="form6Example3">Start Date</label>
              <input
                v-model="startDateRange"
                required
                id="form6Example1"
                class="form-control"
                type="date"
                @change="changeStartDateRange($event)"
              />
            </div>
            <div class="col">
              <label class="form-label" for="form6Example3">End Date</label>
              <input
                v-model="endDateRange"
                required
                id="form6Example1"
                class="form-control"
                type="date"
                @change="changeEndDateRange($event)"
              />
            </div>
          </div>
          <b>
            <h1 v-if="this.memberships.length != 0" style="text-align: center">
              Memberships
            </h1>
          </b>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipPayoutCard
                class="grow"
                v-if="index % 3 == 1"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :logo="membership.membershipLogo"
                :members="membership.members"
                :coachName="membership.coachName"
                :membershipPayPeriod="membership.membershipPayPeriod"
                :startDateRange="startDateRange"
                :endDateRange="endDateRange"
              ></MembershipPayoutCard>
            </div>
          </div>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <h2 v-if="this.memberships.length == 0" style="text-align: center">
              You have no memberships currently
            </h2>
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipPayoutCard
                class="grow"
                v-if="index % 3 == 0"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :logo="membership.membershipLogo"
                :members="membership.members"
                :coachName="membership.coachName"
                :membershipPayPeriod="membership.membershipPayPeriod"
                :startDateRange="startDateRange"
                :endDateRange="endDateRange"
              ></MembershipPayoutCard>
            </div>
          </div>

          <div class="col-md-4 col-sm-6" style="max-width: 400px">
            <div
              v-for="(membership, index) in this.memberships"
              :key="membership._id"
            >
              <MembershipPayoutCard
                class="grow"
                v-if="index % 3 == 2"
                :title="membership.membershipName"
                :membershipID="membership.membershipID"
                :logo="membership.membershipLogo"
                :members="membership.members"
                :coachName="membership.coachName"
                :membershipPayPeriod="membership.membershipPayPeriod"
                :startDateRange="startDateRange"
                :endDateRange="endDateRange"
              ></MembershipPayoutCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import swal from "sweetalert";
import MembershipPayoutCard from "../../components/MembershipPayoutCard.vue";
export default {
  components: { MembershipPayoutCard },
  data() {
    return {
      user: {},
      memberships: [],
      startDateRange: "",
      endDateRange: "",
    };
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    async getAllMembershipInfo() {
      let url = process.env.VUE_APP_DB_URL + "admin/getAllMembershipInfo";
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.memberships = response.data;
        })
        .catch(function (err) {
          let error = err.response;
          swal("Error", error.data.errorMessage, "error");
        });
    },
    changeStartDateRange(event) {
      this.startDateRange = event.target.value;
    },
    changeEndDateRange(event) {
      this.endDateRange = event.target.value;
    },
  },
  created() {
    this.getUserDetails();
    this.getAllMembershipInfo();
  },
};
</script>
<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > .col {
  align-self: stretch;
  margin-bottom: 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 102px;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.admin-section {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid grey;
}
.row {
  align-items: flex-start;
}
.total-payout {
  position: absolute;
  font-size: 15px;
  color: red;
  width: auto !important;
}
</style>
