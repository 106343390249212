<template>
  <div class="wrapper">
    <div class="container mt-5">
      <AddRecruitReferral @recruitReferralAdded="recruitReferralAdded" />
      <br />
      <hr />
      <br />
      <RecruitReferralTable :recruitReferrals="this.recruitReferrals" />
    </div>
  </div>
</template>
<script>
import AddRecruitReferral from "../../components/Admin/AddRecruitReferral.vue";
import RecruitReferralTable from "../../components/Admin/RecruitReferralTable.vue";
// import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";
export default {
  components: {
    AddRecruitReferral,
    RecruitReferralTable,
  },
  data() {
    return {
      user: {},
      recruitReferrals: [],
    };
  },
  methods: {
    getUserDetails() {
      let token = localStorage.getItem("jwt");
      let decoded = VueJwtDecode.decode(token);
      this.user = decoded;
    },
    recruitReferralAdded(recruitReferral) {
      this.recruitReferrals.push(recruitReferral);
    },
    getAllRecruitReferrals() {
      let url = process.env.VUE_APP_DB_URL + "admin/getAllRecruitReferrals";
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("jwt"),
          },
        })
        .then((response) => {
          this.recruitReferrals = response.data.recruitReferrals;
        });
    },
  },
  created() {
    this.getUserDetails();
    this.getAllRecruitReferrals();
  },
};
</script>
<style scoped>
.center-flex {
  display: flex;
  justify-content: center;
  height: 100%;
}
.row > .col {
  align-self: stretch;
  margin-bottom: 20px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 102px;
}
.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.1);
}
.admin-section {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid grey;
}
.row {
  align-items: flex-start;
}
.total-payout {
  position: absolute;
  font-size: 15px;
  color: red;
  width: auto !important;
}
</style>
