<template>
  <div class="mt-5" id="app">
    <nav
      class="navbar navbar-expand-lg fixed-bottom"
      v-bind:style="{
        backgroundColor: primaryColor,
        boxShadow: '0 30px 60px 0 rgba(90, 116, 148, 0.5)',
      }"
    >
      <a href="https://summerathletes.com" class="logo"
        ><img src="../../../public/PoweredBySA.png" class="logo"
      /></a>
    </nav>
  </div>
</template>
<script>
export default {
  name: "Footer",
  props: {
    primaryColor: {
      type: String,
      default: "#363166",
    },
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.logo {
  height: 75px;
  margin-left: auto;
  margin-right: auto;
}
</style>
