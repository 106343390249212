var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-form",style:({
      width: _vm.isMobile() ? '' : '25rem',
      marginBottom: '15px',
    })},[_c('div',{staticClass:"card-form__inner",style:({
        width: _vm.isMobile() ? '' : '25rem',
        textAlign: _vm.isMobile() ? 'center' : '',
      })},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.image}}),_c('div',{staticClass:"card-body"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(this.title))]),_c('hr'),_c('div',[_c('h4',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(this.tokens))]),_c('img',{staticStyle:{"float":"left"},attrs:{"src":require("../../../../public/SaCoin.png"),"height":"29"}}),_c('h4',{staticClass:"card-title",staticStyle:{"float":"right"}},[_vm._v("$"+_vm._s(this.price))])]),_c('br'),_c('hr'),_c('button',{staticClass:"card-form__button rounded-pill"},[_c('a',{on:{"click":function($event){return _vm.purchaseWithAccountBalance()}}},[_vm._v("Purchase with account balance")])]),_c('button',{staticClass:"card-form__button rounded-pill",staticStyle:{"background-color":"#361666"}},[_c('a',{on:{"click":function($event){return _vm.purchaseCredits()}}},[_vm._v("Purchase with credit card (+5%)")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }