var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card-form",style:({
      width: _vm.isMobile() ? '' : '25rem',
      marginBottom: '15px',
    })},[_c('div',{staticClass:"card-form__inner",style:({
        width: _vm.isMobile() ? '' : '25rem',
        textAlign: _vm.isMobile() ? 'center' : '',
      })},[_c('img',{staticClass:"card-img-top rounded-corners",attrs:{"src":this.getLogo}}),_c('div',{staticClass:"card-body mt-3"},[_c('h2',{staticClass:"card-title"},[_vm._v(_vm._s(this.getTitle))]),_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.getDate)+" ")]),_c('button',{staticClass:"card-form__button rounded-pill",on:{"click":_vm.showDetails}},[_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"data-bs-toggle":"modal","data-bs-target":"#getStartedModal","target":"_blank"}},[_vm._v(" View Details ")])]),_c('button',{staticClass:"card-form__button rounded-pill",staticStyle:{"color":"white","background-color":"#363166"},on:{"click":function($event){return _vm.getInTouch()}}},[_vm._v(" Get in Touch ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }